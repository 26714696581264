/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { Text, View } from "../../components/core";

import { NAV_POINTS_ENUM } from "./constants";
import styles from "./styles";

export const ProfileNav = ({
  activeNav,
  setNav,
  outstandingCount,
  isUserPublisher,
}) => (
  <View css={styles.nav} mx="auto">
    <Text
      css={
        activeNav === NAV_POINTS_ENUM.OUTSTANDING
          ? styles.activeNav
          : styles.inactiveNav
      }
      style={{ width: window.screen.width < 500 ? 100 : {} }}
      onClick={() => {
        setNav(NAV_POINTS_ENUM.OUTSTANDING);
      }}
    >
      {`Outstanding (${outstandingCount})`}
    </Text>
    <Text
      css={
        activeNav === NAV_POINTS_ENUM.ANSWERED
          ? styles.activeNav
          : styles.inactiveNav
      }
      style={{ width: window.screen.width < 500 ? 100 : {} }}
      onClick={() => {
        setNav(NAV_POINTS_ENUM.ANSWERED);
      }}
    >
      Answered
    </Text>
    {isUserPublisher && (
      <Text
        css={
          activeNav === NAV_POINTS_ENUM.POSTS
            ? styles.activeNav
            : styles.inactiveNav
        }
        onClick={() => {
          setNav(NAV_POINTS_ENUM.POSTS);
        }}
        style={{ width: window.screen.width < 500 ? 100 : {} }}
      >
        Posts
      </Text>
    )}
  </View>
);

ProfileNav.propTypes = {
  activeNav: PropTypes.oneOf(Object.values(NAV_POINTS_ENUM)).isRequired,
  setNav: PropTypes.func.isRequired,
  outstandingCount: PropTypes.number.isRequired,
  isUserPublisher: PropTypes.bool.isRequired,
};

export default ProfileNav;
