/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { useState } from "react";

import { Button, View, Input, Text } from "../../core";
import { User } from "../user";
import UploadMediaTarget from "../uploadMediaTarget";
import styles from "./styles";
import replyStyles from "../reply/styles";
import NotificationService from "../Notification";

const MAX_QUESTION_LENGTH = 250;

export const CreateQuestion = ({
  onCancel,
  onSend,
  onContentChanged,
  userProps,
  noBackground,
  isPublisher,
  ...rest
}) => {
  const [content, setContent] = useState("");
  // TODO: this is repeated code.
  // https://github.com/bobpol/Agage_frontend/pull/41/files#diff-971ee0f341d5c4176b620f43a9ff5fb25fa76a0722d0b7dcfc91e9dbbb0c5a5aR20
  // https://github.com/bobpol/Agage_frontend/pull/41/files#diff-3db20b2d2813af46e868a6b8283c02c1430a7db119381dd8067d6c1bbbfcbb2eR26
  const [attachementData, setAttachmentData] = useState([]);
  const contentChanged = (e) => {
    // if (content.length >= MAX_QUESTION_LENGTH && e.nativeEvent.inputType === 'insertText') {
    //   return;
    // }
    const { value } = e.target;
    setContent(value);
    onContentChanged(value);
  };

  const handleCancel = () => {
    setContent("");
    onCancel();
  };

  const handleSend = () => {
    let imageLength = 0;
    attachementData.map((data) => {
      if (data.attachmentTypeId === 1) {
        imageLength++;
      }
    });
    if (content === "" || content.length > 500) {
      NotificationService.error(
        content.length > 500
          ? "Maximum 500 characters are allowed"
          : "Please add content!"
      );
    } else {
      onSend(content, attachementData);
    }
  };

  const handleFileChange = (fileData) => {
    setAttachmentData(fileData);
  };
  const backgroundStyles = noBackground ? {} : styles.rootBackground;
  return (
    <View
      css={{ ...styles.root, ...backgroundStyles }}
      fd="column"
      px={36}
      py={36}
      {...rest}
    >
      {!noBackground && (
        <View css={styles.actionRow} mb={24}>
          <Button flat ghost onClick={handleCancel} data-testid="cancel-post">
            Cancel
          </Button>
          <Button flat secondary onClick={handleSend} data-testid="send-post">
            Send
          </Button>
        </View>
      )}
      <View css={styles.contentRow} fd="column">
        <User postStyle={true} mb={12} {...userProps} />
        <View f="none" fg={1} css={styles.inputContainer}>
          <UploadMediaTarget
            onChange={handleFileChange}
            isPublisher={isPublisher}
          >
            <Input
              multiline
              css={styles.questionTextArea}
              style={{
                border:
                  content.length > 500 && !isPublisher
                    ? "1px solid red"
                    : "1px solid #556670",
                marginBottom: isPublisher ? 40 : 10,
              }}
              value={content}
              onChange={contentChanged}
              data-testid="question-content-field"
            />
            {!isPublisher && (
              <div
                css={styles.questionLimitArea}
              >{`${content.length}/500`}</div>
            )}
          </UploadMediaTarget>
        </View>
      </View>
      {/* <View css={styles.charactersLeftContainer} pt={28}>
        <View css={styles.progressBar} py={4} px={12}>
          <Text css={styles.charactersLeft} type="small">
            {`${MAX_QUESTION_LENGTH - content.length}/250`}
          </Text>
        </View>
      </View> */}
      {noBackground && (
        <View css={styles.actionRow} mt={24}>
          <Button
            css={replyStyles.cancelButton}
            flat
            ghost
            onClick={handleCancel}
            data-testid="cancel-post"
          >
            Cancel
          </Button>
          <Button
            flat
            secondary
            css={replyStyles.replyButton}
            onClick={handleSend}
            data-testid="send-post"
          >
            {isPublisher ? "Publish" : "Send"}
          </Button>
        </View>
      )}
    </View>
  );
};

CreateQuestion.propTypes = {
  noBackground: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onContentChanged: PropTypes.func,
  userProps: PropTypes.shape({
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    postTime: PropTypes.string,
  }),
};

CreateQuestion.defaultProps = {
  noBackground: false,
  onContentChanged: () => {},
  userProps: {},
};

export default CreateQuestion;
