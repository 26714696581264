import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import usersReducer from './users/reducer';
import questionReducer from './question/reducer';
import postsReducer from './posts/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  questions: questionReducer,
  posts: postsReducer,
});

export default rootReducer;
