import { defaultStyles } from "../../core";

export default {
  root: {
    // borderRadius: 1000,
    // backgroundColor: defaultStyles.colors.primary.accent,
    // width: 16,
    // height: 16,
    // padding: 6,
  },
  icon: {
    maxWidth: 16,
    maxHeight: 16,
  },
};
