/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useReducer } from 'react';

import { getVideoThumnail } from '../../../services/api/video';
import { MEDIA_TYPES } from '../../../constants';
import { UploadedImage } from '../uploadedImage';

const ACTIONS = {
  REQUEST: 'inflight',
  SUCCESS: 'success',
  ERROR: 'error',
};

const initialState = {
  data: '',
  loading: false,
  error: null,
};

const loaderReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    default:
      return initialState;
  }
};

export const DisplayMedia = ({
  attachmentTypeId,
  removeFile,
  data,
  mediaId,
  thumbnailUrl,
  index,
}) => {
  const [state, dispatch] = useReducer(loaderReducer, initialState);
  // This is a video.
  useEffect(() => {
    if (attachmentTypeId === MEDIA_TYPES.IMAGE) return;
    dispatch({ type: ACTIONS.REQUEST });
    getVideoThumnail(mediaId)
      .then((_data) => {
        dispatch({
          type: ACTIONS.SUCCESS,
          data: _data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTIONS.ERROR,
          error,
        });
      });
    // passing an empty array makes it only call the first time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (attachmentTypeId === MEDIA_TYPES.IMAGE) {
    return (
      <UploadedImage
        key={data}
        onRemoveFile={() => removeFile(data)}
        url={data}
        index={index}
      />
    );
  }
  return (
    <UploadedImage
      key={mediaId}
      onRemoveFile={() => removeFile(thumbnailUrl)}
      url={state.data || thumbnailUrl}
      index={index}
    />
  );
};

DisplayMedia.propTypes = PropTypes.shape({
  attachmentTypeId: PropTypes.oneOf(Object.values(MEDIA_TYPES)),
  title: PropTypes.string,
  data: PropTypes.string.isRequired,
  mediaId: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  embedCode: PropTypes.string,
  index: PropTypes.number.isRequired,
}).isRequired;

export default DisplayMedia;
