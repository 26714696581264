export default {
  // hashed list of question IDs, sorted by created date, indexed by user's ID
  idsByPublisherId: null,
  // the details of each question, hashed by questionId
  postsById: {},
  // signals if there's a pending request.
  pendingRequest: false,
  // List of the question IDs that the user has favorited.
  // favoriteQuestionIds: [],
  // any Error
  error: false,
};
