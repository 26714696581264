/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Component } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";

import { loadUserById } from "../../services/state/actionCreators";
import {
  APP_URLS,
  TYPE_TO_DESC_MAPPING,
  PRODUCT_DESCRIPTION_BY_KEY,
} from "../../constants";
import { GridSystem, Text, View, Loading } from "../../components/core";
import { PricingCard } from "../../components/shared";

import styles from "./styles";

const pageStyles = styles.currentPlan;

export class CurrentPlanData extends Component {
  static propTypes = {
    subscribedPublishers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        slug: PropTypes.string,
        avatar: PropTypes.shape({
          medium: PropTypes.string,
        }),
      })
    ),
    loadUser: PropTypes.func.isRequired,
    publisherId: PropTypes.number,
    isNotUpgrade: PropTypes.bool,
  };

  static defaultProps = {
    subscribedPublishers: [],
    publisherId: false,
    isNotUpgrade: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingPublishers: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { subscribedPublishers, loadUser, publisherId } = props;
    const { loadingPublishers } = state;
    const foundPublisher =
      subscribedPublishers.filter((p) => p.id === publisherId).length === 1;
    if (!foundPublisher && !loadingPublishers) {
      loadUser(publisherId);
      return {
        loadingPublishers: true,
      };
    }
    if (foundPublisher && loadingPublishers) {
      return {
        loadingPublishers: false,
      };
    }
    return null;
  }

  render() {
    const { publisherId, subscribedPublishers, isNotUpgrade } = this.props;
    const { loadingPublishers } = this.state;
    const foundPublisher = subscribedPublishers.filter(
      (p) => p.id === publisherId
    );

    const isLoading = loadingPublishers || foundPublisher.length === 0;
    let children;

    if (isLoading) {
      children = (
        <View f="none" css={{ maxWidth: 500, margin: "0 auto 64px" }}>
          <Loading />
        </View>
      );
    }

    if (!isLoading && subscribedPublishers.length === 0) {
      children = (
        <Text centered mb={14}>
          No active subscriptions, please consider subscribing.
        </Text>
      );
    }

    if (!isLoading && foundPublisher.length > 0) {
      children = foundPublisher.map((publisher) => (
        <View f="none">
          <CurrentPlan user={publisher} isNotUpgrade={isNotUpgrade} />
        </View>
      ));
    }

    return (
      <View f="none">
        <Text f="1 1 auto" type="h5" css={pageStyles.buyLabel} centered mb={50}>
          {isNotUpgrade ? "Buy Plan" : "Upgrade Plan"}
        </Text>
        {children}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  // figure out what subscriptions this user has and the publisher data for those subscriptions.
  const subscriptions = _get(
    state,
    "user.subscriptionData.subscribedPublishers",
    []
  );
  const theirIds = subscriptions.map((u) => u.id);
  const subscribedPublishers = theirIds
    .map((id) => _get(state, `users.users[${id}]`))
    .filter((s) => !!s);
  return {
    subscribedPublishers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadUser: (id) => {
    dispatch(loadUserById(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPlanData);

const CurrentPlan = ({ user, isNotUpgrade }) => {
  const loggedinUser = useSelector((store) => _get(store, "user", {}));
  const userDetails = useSelector((store) => store.user);
  const userProfile = useSelector((store) =>
    _get(store, `users.users[${userDetails.userId}]`)
  );
  const subscriptionData =
    loggedinUser.subscriptionData.subscribedPublishers.filter(
      (_i) => _i.id == user.id
    )[0];
  const totalSpots = _get(user, "products.ask.maxActiveSubscriptions");
  const numTaken = _get(user, "activeSubscribers.numAskers");
  // TODO Move this to the PricingCard component
  let askSpotsCopy = `${totalSpots - numTaken}/${totalSpots} spots left!`;
  if (parseInt(numTaken, 10) >= parseInt(totalSpots, 10)) {
    askSpotsCopy = "Sold out";
  }

  const priceData = {
    view: {
      price: `$${_get(subscriptionData, "products.view.cost")}`,
      spots: "",
      ctaLink: APP_URLS.FEED.url(user.slug),
      id: _get(subscriptionData, "products.view.id"),
    },
    ask: {
      price: `$${
        Math.round(
          (_get(subscriptionData, "products.ask.cost") + Number.EPSILON) * 10000
        ) / 10000
      }`,
      spots: askSpotsCopy,
      ctaLink: APP_URLS.SIGNUP.url(user.slug),
      id: _get(subscriptionData, "products.ask.id"),
    },
  };

  return (
    <View css={pageStyles.container}>
      <GridSystem.Container css={{ width: "100%" }}>
        <GridSystem.Row justify="center">
          {isNotUpgrade && (
            <GridSystem.Col sm={6}>
              <PricingCard
                title={PRODUCT_DESCRIPTION_BY_KEY.view}
                costLine={priceData.view.price}
                availability={priceData.view.spots}
                css={pageStyles.pricingCard}
                upgradeView={true}
                ctaLink={priceData.view.ctaLink}
                productId={priceData.view.id}
                buttonText="Buy"
                view={true}
              >
                {TYPE_TO_DESC_MAPPING.view}
              </PricingCard>
            </GridSystem.Col>
          )}
          <GridSystem.Col sm={6}>
            <PricingCard
              title={PRODUCT_DESCRIPTION_BY_KEY.ask}
              upgradeView={true}
              costLine={priceData.ask.price}
              availability={priceData.ask.spots}
              css={pageStyles.pricingCard}
              view={false}
              ctaLink={priceData.ask.ctaLink}
              productId={priceData.ask.id}
              subscriptionId={_get(
                subscriptionData,
                "products.view.latestSubscription.id"
              )}
              isNotUpgrade={!isNotUpgrade}
              buttonText={
                totalSpots - numTaken === 0
                  ? "Join waitlist"
                  : `${isNotUpgrade ? "Buy" : "Upgrade"}`
              }
              details={userProfile}
            >
              {TYPE_TO_DESC_MAPPING.ask}
            </PricingCard>
          </GridSystem.Col>
        </GridSystem.Row>
      </GridSystem.Container>
    </View>
  );
};

CurrentPlan.propTypes = {
  user: PropTypes.shape({
    slug: PropTypes.string,
    activeSubscribers: PropTypes.shape({
      numViewers: PropTypes.number,
      numAskers: PropTypes.number,
    }),
    products: PropTypes.shape({
      ask: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
        cost: PropTypes.number.isRequired,
      }).isRequired,
      view: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
        cost: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  isNotUpgrade: PropTypes.bool,
};

CurrentPlan.defaultProps = {
  isNotUpgrade: false,
};
