/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { useRef, Fragment } from "react";

import { View } from "../view";
import styles from "./styles";

export const Modal = ({ children, isOpen, onClose, noButton, style }) => {
  const backGroundElm = useRef(null);

  const onBackgroundClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (backGroundElm.current === e.target) {
      onClose();
    }
  };

  return (
    <View
      css={{
        ...styles.backgroundContainer,
        ...(isOpen ? {} : styles.modalHidden),
      }}
      onClick={onBackgroundClick}
      ref={backGroundElm}
    >
      <View
        style={{
          minHeight: style ? "40%" : window.screen.width < 500 ? "100%" : "64%",
          minWidth: window.screen.width < 500 ? "430px" : "636px",
        }}
        css={styles.modalContentWrapper}
      >
        <View f="none" css={styles.scrollContainer}>
          {noButton ? (
            <Fragment />
          ) : (
            <button type="button" css={styles.closeButton} onClick={onClose}>
              x
            </button>
          )}
          <View className="modal" css={styles.modalContent} data-testid="modal">
            {children}
          </View>
        </View>
      </View>
    </View>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  noButton: PropTypes.bool,
};

Modal.defaultProps = {
  children: [],
  isOpen: false,
  noButton: false,
};

export default Modal;
