/** @jsx jsx */
import { jsx } from "@emotion/react";
import gloriaProfile from "./img/gloriaProfile.png";
import styles from "./styles";
import verified from "../../components/shared/userProfile/img/Verified.svg";
import insta from "./img/instaIcon.svg";
import youtube from "./img/youtubeIcon.svg";
import music from "./img/musicIcon.svg";
import image1 from "./img/random17.svg";
import image2 from "./img/random21.svg";
import image3 from "./img/random25.svg";
import image4 from "./img/random26.svg";
import image5 from "./img/random18.jpg";
import image6 from "./img/random19.jpg";
import image7 from "./img/random7.svg";
import image8 from "./img/random28.svg";
import image9 from "./img/random30.svg";
import image10 from "./img/random20.jpg";
import image11 from "./img/random22.svg";
import image12 from "./img/random23.svg";
import image13 from "./img/random24.svg";
import image14 from "./img/random27.svg";
import image15 from "./img/random29.svg";
import image16 from "./img/random31.svg";
import bookmark from "./img/bookmark.svg";
import video1 from "./img/6.mp4";
import video2 from "./img/7.mp4";

export const Gloriaglows = () => {
  return (
    <div css={styles.deanProfileDiv}>
      <img
        css={styles.profileImg}
        style={{
          border: "3px solid rgb(53, 104, 89)",
          marginTop: "35px",
          marginBottom: 15,
        }}
        src={gloriaProfile}
        alt="profile"
      />
      <label style={{ fontSize: 34 }} css={styles.deanTitle}>
        gloriaglows
        <img style={{ marginLeft: 10 }} src={verified} alt="verified" />
      </label>
      <label
        style={{
          fontSize: 20,
          lineHeight: "30px",
          letterSpacing: "0.15px",
          textAlign: "center",
        }}
        css={styles.deanCategories}
      >
        Gloria Day <br />
        Beauty Vlogger + Healthy Skinthusiast
        <br />
        Founder - Inner Glow Skin
      </label>
      <span>
        <img style={{ margin: "10px 15px" }} src={youtube} />
        <img style={{ margin: "10px 15px" }} src={music} />
      </span>
      <span>
        <label css={styles.metricsLabel}>
          <b>300</b> Answers
        </label>
        <label css={styles.metricsLabel}>
          <b>146</b> Posts
        </label>
        <label css={styles.metricsLabel}>
          <b>12k</b> Members
        </label>
        <div css={styles.borderDiv}></div>
      </span>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image1} />
              <label css={styles.name}>danika_m</label>
              <label css={styles.timeLabel}>5 min ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            What can I do to tell if my skin is cool or warm toned? My veins
            don't lean blue or green, and I honestly can't tell if gold or
            silver looks best. I wear them both. Am I blind??? Feeling stupid
            that I can't figure this out.
          </label>
          <div style={{ display: "flex" }}>
            <img
              style={{
                marginRight: 2,
                marginBottom: 15,
                width: window.screen.width < 624 ? 300 : 423,
                height: window.screen.width < 624 ? 160 : 285,
              }}
              src={image5}
            />
            <div>
              <img
                style={{
                  marginBottom: 1,
                  width: window.screen.width < 624 ? 135 : 285,
                  height: window.screen.width < 624 ? 80 : 142,
                }}
                src={image6}
              />
              <img
                style={{
                  marginBottom: 15,
                  width: window.screen.width < 624 ? 135 : 285,
                  height: window.screen.width < 624 ? 80 : 142,
                }}
                src={image10}
              />
            </div>
          </div>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={gloriaProfile} />
              <label css={styles.name}>gloriaglows</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 7 min ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ paddingLeft: 58, paddingRight: 12 }}
            css={styles.content}
          >
            There's nothing wrong with your eyesight! Though it's harder to tell
            from a photo or video than in person, it looks and sounds like you
            fall into the neutral territory. Some argue that everyone leans
            either cool or warm, which may be true, but if your skin tone is
            pretty close to center some of these tests can feel inconclusive.
            The good news is that you have more color options to choose from as
            a neutral baby!
          </label>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image2} />
              <label css={styles.name}>rubyroo</label>
              <label css={styles.timeLabel}>8 min ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Do you know any good dupes for Clinique's Black Honey? It's all over
            my tiktok feed and I'm dying to try it, but it's literally sold out
            everywhere.
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px " }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={gloriaProfile} />
              <label css={styles.name}>gloriaglows</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 8 min ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ paddingLeft: 58, paddingRight: 12 }}
            css={styles.content}
          >
            OH yeah, that one's been selling out like hotcakes thanks to tiktok.
            For good reason, though - how they make a color that looks good on
            everybody I'll never know. Luckily there are a few good runner-ups
            to try until you get your hands on one! Clinique's Chubby Stick in
            Richer Raison gives off a similar light berry hue, but it'll be
            slightly more pigmented. Burt's Bee's Tinted lip balm in Red Dahlia
            is close, though a bit more sheer.
            <br /> But, THE best dupe, imo, is the e.l.f. Sheer Lipstick in
            Black Cherry. Honestly I swear it's the same color and only costs
            like $5.
          </label>
        </div>
        <div style={{ display: "flex" }}>
          <img style={{ marginRight: 2, marginBottom: 15 }} src={image11} />
          <div>
            <img src={image12} />
            <img style={{ marginBottom: 15 }} src={image13} />
          </div>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={gloriaProfile} />
              <label css={styles.name}>gloriaglows</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}>1 day ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Just share the current lip + cheek + eye combo that I can't stop
            using: Nudestix in Picante. Oh how I love a good multi-purpose
            stick. As in I've literally worn it every day this week. I've even
            got a discount code, PICANTE15, if you want to join me in the
            #lazymorningmakeup crew :)
          </label>
          <img
            style={{ width: window.screen.width < 624 ? 300 : {} }}
            src={image3}
            alt="icon"
          />
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image4} />
              <label css={styles.name}>kali_t</label>
              <label css={styles.timeLabel}>2 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            I LOVED the no-makeup makeup look video you shared the other day.
            Your skin looked flawless. I tried following your steps with some
            products I already own, but can't seem to get the under-eye
            concealer to go on smoothly without cracking/separating. What am I
            doing wrong????
          </label>
          <img
            style={{
              marginBottom: 20,
              width: window.screen.width < 624 ? 300 : {},
            }}
            src={image14}
            alt="icon"
          />
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={gloriaProfile} />
              <label css={styles.name}>gloriaglows</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 2 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ paddingLeft: 58, marginBottom: 20, paddingRight: 12 }}
            css={styles.content}
          >
            The most common mistake I find in this scenario is mixing water and
            oil-based products. Stick to one or the other. And give your
            concealer plenty of time to dry, like 10 minutes or so, before
            applying any setting powder. <br />
            <br />
            Let me know if this helps!
          </label>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image8} />
              <label css={styles.name}>karissa.g</label>
              <label css={styles.timeLabel}>3 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Hi! I'm getting married in a few weeks and have no idea what
            lipstick to wear. Something long-lasting that doesn't have to be
            wiped off to reapply, and that won't be a mess every time I kiss the
            groom. Any suggestions?? Thanks!!
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ margin: "15px 0px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={gloriaProfile} />
              <label css={styles.name}>gloriaglows</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 3 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: window.screen.width < 624 ? "column" : "row",
            }}
          >
            <video
              style={{
                marginRight: 2,
                marginBottom: window.screen.width < 624 ? 40 : {},
                marginTop: window.screen.width < 624 ? -60 : {},
              }}
              width="285"
              height="286"
              controls
            >
              <source
                style={{ height: 286 }}
                preload="metadata"
                src={video1 + "#t=0.001"}
                type="video/mp4"
              />
            </video>
            <div>
              <img style={{ marginBottom: 15 }} src={image15} />
            </div>
          </div>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image9} />
              <label css={styles.name}>karissa.g</label>
              <label css={styles.timeLabel}>5 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Question on skincare: I have a similar skin type to you, dry,
            sensitive, prone to dehydration, the works. I've tried a number of
            your recs and loved them (inky list retinol has done wonders for
            me)! But I just can't come to terms with shelling out $100+ for
            those SkinCeuticals products you always mention. Any budget-friendly
            favorites for a pm face cream?
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={gloriaProfile} />
              <label css={styles.name}>gloriaglows</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 5 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: window.screen.width < 624 ? "column" : "row",
            }}
          >
            <video
              style={{
                marginRight: 2,
                marginBottom: window.screen.width < 624 ? 40 : {},
                marginTop: window.screen.width < 624 ? -35 : {},
              }}
              width="295"
              height="266"
              controls
            >
              <source
                style={{ height: 266 }}
                preload="metadata"
                src={video2 + "#t=0.001"}
                type="video/mp4"
              />
            </video>
            <div>
              <img style={{ marginBottom: 15 }} src={image16} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gloriaglows;
