const component = {
  materialShadow: {
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  materialShadowHover: {
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
  transistion: (prop = 'all') => ({
    transition: `${prop} 280ms cubic-bezier(0.4, 0, 0.2, 1)`,
  }),
  shadowBoxBG: {
    borderRadius: 30,
    background: 'linear-gradient(180deg, rgba(228,228,228,1) 0%, rgba(255,255,255,0) 120%)',
  },
};

export default component;
