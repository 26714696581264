import React, { Fragment } from "react";
import "normalize.css";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  useParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import "./App.css";
import { APP_URLS, BASE_URL } from "./constants";

import {
  ConnectedRemainingQuestionsComponent,
  Header,
  LoggedOutNav,
} from "./components/shared";
import FavoritesPage from "./screens/favorites";
import DashboardPage from "./screens/dashboard";
import FeedPage from "./screens/feed";
import LoginPage from "./screens/loginPage";
import LogoutPage from "./screens/logoutPage";
import ProfilePage from "./screens/profilePage";
import SalesPage from "./screens/salesPage";
import DeananywhereProfile from "./screens/salesPage/deanAnywhere";
import OllieomProfile from "./screens/salesPage/ollieom";
import GloriaglowsProfile from "./screens/salesPage/gloriaglows";
import AutoLogin from "./components/shared/autoLoginService";
import SignUpPageWrapper from "./screens/signupPage";
import SettingsPage from "./screens/settings";
import AdminCreator from "./screens/admin.min";
import UserService from "./services/api/user/index";
import CreatorSignup from "./screens/creatorSignup/index";
import Privacy from "./screens/Privacy_Terms/privacyPolicy";
import Terms from "./screens/Privacy_Terms/terms";

const Admin = AdminCreator.default(`${BASE_URL}/api`, "nt-theme");

const AdminRoute = ({ component: Component, setToken, ...rest }) => {
  const userObj = UserService.loadFromLocalStorage();
  const { path } = useRouteMatch();
  let redirect = "";
  if (!userObj?.token) redirect = "/";
  setToken(userObj?.token);
  if (redirect) {
    return (
      <Route
        {...rest}
        path={`${path}/${APP_URLS.LOGIN.path}`}
        component={LoginPage}
      />
      //   {/* <Header>
      //     <LoggedOutNav />
      //   </Header>
      //   <SalesPage /> */}
      // {/* </Route> */}
    );
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

AdminRoute.propTypes = {
  component: PropTypes.element.isRequired,
  setToken: PropTypes.string.isRequired,
};

const LoggedInApp = () => {
  const slug = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  // TODO, we might want to check that the user is actually logged in!
  return (
    <Fragment>
      <Header>
        <ConnectedRemainingQuestionsComponent />
      </Header>
      <Switch>
        <Route
          path={`${path}/${APP_URLS.SIGNUP.path}`}
          component={SignUpPageWrapper}
        />
        <Route path={`${path}/${APP_URLS.LOGIN.path}`} component={LoginPage} />
        <Route
          path={
            user === null && user?.userRoleId !== 2
              ? `${path}`
              : `${path}/${APP_URLS.FEED.path}/:id?`
          }
          component={FeedPage}
        />
        <Route
          path={`${path}/${APP_URLS.FAVORITES.path}`}
          component={FavoritesPage}
        />
        <Route
          path={`${path}/${APP_URLS.DASHBOARD.path}`}
          component={DashboardPage}
        />
        <Route
          path={`${path}/${APP_URLS.PROFILE.path}`}
          component={ProfilePage}
        />
        <Route
          path={`${path}/${APP_URLS.SETTINGS.path}`}
          component={SettingsPage}
        />
        {/* <Route
          path={`${path}/${APP_URLS.CREATORSIGNUP.path}`}
          component={creatorSignup}
        /> */}
        <Route>404, not found</Route>
      </Switch>
    </Fragment>
  );
};

function App() {
  const dispatch = useDispatch();
  return (
    <Router>
      <AutoLogin dispatch={dispatch} />
      <Switch>
        <AdminRoute
          path="/admin"
          component={Admin.AdminComponent}
          setToken={Admin.setToken}
        />
        <Route path={APP_URLS.LOGOUT.path} exact component={LogoutPage} />
        <Route path={APP_URLS.CREATORSIGNUP.path} exact>
          <Header>
            <LoggedOutNav />
          </Header>
          <CreatorSignup />
        </Route>
        <Route path={APP_URLS.HOME.path} exact>
          <Header>
            <LoggedOutNav />
          </Header>
          <SalesPage />
        </Route>
        <Route path={"/deananywhere"} exact>
          <Header>
            <LoggedOutNav />
          </Header>
          <DeananywhereProfile />
        </Route>
        <Route path={"/ollieom"} exact>
          <Header>
            <LoggedOutNav />
          </Header>
          <OllieomProfile />
        </Route>
        <Route path={"/gloriaglows"} exact>
          <Header>
            <LoggedOutNav />
          </Header>
          <GloriaglowsProfile />
        </Route>
        <Route path={APP_URLS.TERMS.path} component={Terms}>
          <Header>
            <LoggedOutNav />
          </Header>
          <Terms />
        </Route>
        <Route path={APP_URLS.POLICY.path} component={Privacy}>
          <Header>
            <LoggedOutNav />
          </Header>
          <Privacy />
        </Route>
        <Route path="/:slug">
          <LoggedInApp />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
