/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Component } from "react";
import _get from "lodash/get";
import styles from "./styles";
import TransactionsTable from "./transactions.jsx";
import EarningsTable from "./earnings";
import MembersTable from "./members";
import { Question } from "../../components/shared";
import { favoriteQuestion as favoriteQuestionAction } from "../../services/state/actionCreators";
import { View } from "../../components/core";
import MobileUI from "./mobileDashboard";

// TODO, maybe we should convert to a stateless-func.
// eslint-disable-next-line react/prefer-stateless-function
class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Earnings: true,
      Members: false,
      Transactions: false,
    };
  }
  render() {
    return (
      <div>
        {window.screen.width < 500 ? (
          <MobileUI />
        ) : (
          <div css={styles.root}>
            <div css={styles.navDiv}>
              <div
                css={
                  this.state.Earnings === true
                    ? styles.activeLabel
                    : styles.inActiveLabel
                }
                onClick={() => {
                  this.setState({
                    Earnings: true,
                    Transactions: false,
                    Members: false,
                  });
                }}
              >
                Earnings
              </div>
              <div
                onClick={() => {
                  this.setState({
                    Earnings: false,
                    Transactions: false,
                    Members: true,
                  });
                }}
                css={
                  this.state.Members === true
                    ? styles.activeLabel
                    : styles.inActiveLabel
                }
              >
                Members
              </div>
              <div
                css={
                  this.state.Transactions === true
                    ? styles.activeLabel
                    : styles.inActiveLabel
                }
                onClick={() => {
                  this.setState({
                    Earnings: false,
                    Transactions: true,
                    Members: false,
                  });
                }}
              >
                Transactions
              </div>
            </div>
            {this.state.Transactions && <TransactionsTable />}
            {this.state.Members && <MembersTable />}
            {this.state.Earnings && <EarningsTable />}
          </div>
        )}
      </div>
    );
  }
}

export default DashboardPage;
