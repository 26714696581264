import { APP_URLS } from '../../constants';

export const redirectUser = (slug) => {
  if (!slug || APP_URLS.LOGIN.url(slug).includes(window.location.pathname)) {
    // return false to signal need to logout.
    return false;
  }
  window.location.href = `${APP_URLS.LOGIN.url(slug)}?redirect=${window.location.pathname}`;
  return true;
};

export default {};
