/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import styles from "./styles";
import DefaultAvatar from "./img/default.png";

export const Avatar = ({ url, css, alt, ...rest }) => (
  <img
    src={url}
    css={{ ...styles, ...css }}
    alt={alt}
    data-testid="user-avatar"
    {...rest}
  />
);

Avatar.propTypes = {
  url: (props, propName, componentName) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const _ = new URL(props[propName]);
      return null;
    } catch (error) {
      if (error instanceof TypeError) {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. Not a valid URL failed.`
        );
      }
      return error;
    }
  },
  // eslint-disable-next-line react/forbid-prop-types
  css: PropTypes.object,
  alt: PropTypes.string,
};

Avatar.defaultProps = {
  url: DefaultAvatar,
  css: {},
  alt: "User Avatar",
};

export default Avatar;
