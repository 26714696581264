/** @jsx jsx */
import { jsx } from '@emotion/react';
import { forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { View } from '../view';

const InputRaw = ({
  children,
  css,
  multiline,
  forwardedRef,
  label,
  id,
  name,
  ...rest
}) => {
  const Component = multiline ? 'textarea' : 'input';
  const Container = label ? View : Fragment;
  const containerProps = label ? { fd: 'column' } : {};
  return (
    <Container {...containerProps}>
      {
        label && (
          <label css={{ marginBottom: 6, textAlign: 'center' }} htmlFor={id || name}>
            {label}
          </label>
        )
      }
      <Component
        placeholder={children}
        type="text"
        {...rest}
        ref={forwardedRef}
        css={{
          ...styles,
          ...css,
        }}
        id={id}
        name={name}
      />
    </Container>
  );
};

InputRaw.propTypes = {
  children: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  css: PropTypes.object,
  multiline: PropTypes.bool,
  forwardedRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
};

InputRaw.defaultProps = {
  children: 'Enter Text',
  multiline: false,
  css: {},
  forwardedRef: null,
  label: undefined,
  id: undefined,
  name: undefined,
};

export const Input = forwardRef((props, ref) => <InputRaw {...props} forwardedRef={ref} />);

export default Input;
