import axios from 'axios';
import rateLimit from 'axios-rate-limit';

import {
  USER_ROLE,
  BASE_URL,
} from '../../constants';

export class ApplicationClient {
  URL = `${BASE_URL}/api`;

  userObject = {};

  constructor() {
    this.networkClient = rateLimit(
      axios.create({
        baseURL: this.URL,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      {
        maxRequests: 2,
        perMilliseconds: 1000,
      },
    );
  }

  get userToken() {
    return this.userObject.token;
  }

  authenticate(id, token, userRoleId) {
    this.userObject = {
      userId: id,
      token,
      userRoleId,
    };
    this.networkClient.defaults.headers = {
      'X-Auth-Token': token,
      'Content-Type': 'application/json',
    };
  }

  setToken(userData) {
    this.authenticate(userData.userId, userData.token, userData.userRoleId);
  }

  isLoggedIn() {
    return !!this.userObject.token;
  }

  isPublisher() {
    return this.isLoggedIn() && this.userObject.userRoleId === USER_ROLE.PUBLISHER;
  }
}

export default ApplicationClient;
