/** @jsx jsx */
import { jsx } from "@emotion/react";

import { View, Text } from "../../components/core";

import partner from "../../components/shared/feature/img/partner.svg";
import checkCircle from "../../components/shared/feature/img/check-circle.svg";
import intersect1 from "../../components/shared/feature/img/Intersect1.svg";
import intersect2 from "../../components/shared/feature/img/Intersect2.svg";
import intersect3 from "../../components/shared/feature/img/Intersect3.svg";
import styles from "./styles";

export const Partnership = () => (
  <View css={styles.partnership} f="none">
    <img
      src={intersect1}
      alt="circle"
      style={{
        width: window.screen.width < 624 ? "105.78px" : "199.31px",
        height: window.screen.width < 624 ? "104.71px" : "197.29px",
        position: "absolute",
        right: window.screen.width < 624 ? "292px" : "42px",
        marginTop: window.screen.width < 624 ? "200px" : {},
      }}
    ></img>
    <img
      src={intersect2}
      alt="circle"
      style={{
        width: "108.87px",
        height: "107.77px",
        marginLeft: window.screen.width < 624 ? "285px" : "143px",
        marginTop: window.screen.width < 624 ? 0 : 155,
      }}
    ></img>
    <img
      src={intersect3}
      alt="circle"
      css={styles.intersect3}
      style={{
        width: window.screen.width < 624 ? "106.07px" : "152.84px",
        height: window.screen.width < 624 ? "105px" : "151.29px",
      }}
    ></img>
    <img src={partner} css={styles.handshakeIcon} alt="handshake icon" />
    <Text css={styles.title} centered mb={35}>
      Partnership
    </Text>
    <Text css={styles.subHead} centered mb={35}>
      Receive regular <b style={{ color: "#356859" }}>monthly payouts</b> based
      on 80% of your revenue
    </Text>
    {/* <Text
      css={styles.subHead}
      style={{ fontSize: "16px", fontWeight: "400" }}
      centered
      mb={40}
    >
      GuideReply fee: 30% monthly revenue
    </Text> */}
    <div style={{ marginBottom: 15 }} css={styles.partnershipDiv}>
      <img
        src={checkCircle}
        alt="circle"
        style={{ width: "30px", height: "30px", marginRight: "20px" }}
      ></img>
      <Text
        style={{
          maxWidth: "430px",
          fontSize: "16px",
          fontWeight: "400",
          width: window.screen.width < 624 ? "100%" : {},
          paddingRight: window.screen.width < 624 ? "35px" : {},
        }}
        css={styles.subHead}
        mb={window.screen.width < 624 ? 0 : 20}
      >
        We handle <b>billing, failed payments,</b> and <b>fraud protection</b>{" "}
        for your members worldwide
      </Text>
    </div>
    <div
      style={{
        marginRight: window.screen.width < 624 ? 0 : 160,
        marginBottom: 15,
      }}
      css={styles.partnershipDiv}
    >
      <img
        src={checkCircle}
        alt="circle"
        style={{ width: "30px", height: "30px", marginRight: "20px" }}
      ></img>
      <Text
        style={{
          maxWidth: "430px",
          fontSize: "16px",
          fontWeight: "400",
          width: window.screen.width < 624 ? "100%" : {},
          paddingRight: window.screen.width < 624 ? "35px" : {},
        }}
        css={styles.subHead}
        mb={window.screen.width < 624 ? 0 : 20}
      >
        We address all <b>customer service</b> inquiries
      </Text>
    </div>
    <div
      style={{ marginBottom: window.screen.width < 624 ? 108 : {} }}
      css={styles.partnershipDiv}
    >
      <img
        src={checkCircle}
        alt="circle"
        style={{ width: "30px", height: "30px", marginRight: "20px" }}
      ></img>
      <Text
        style={{
          maxWidth: "430px",
          fontSize: "16px",
          fontWeight: "400",
          width: window.screen.width < 624 ? "100%" : {},
          paddingRight: window.screen.width < 624 ? "35px" : {},
        }}
        css={styles.subHead}
        mb={window.screen.width < 624 ? 0 : 60}
      >
        <b>All transactions, members, and earnings </b>per day, week, and month
        are accessible via your easy-to-use dashboard
      </Text>
    </div>
  </View>
);

export default Partnership;
