import globalStyles from "../../../styles";

export default {
  root: {
    alignItems: "center",
  },
  avatar: {
    width: 35,
    height: 35,
    flexBasis: 35,
    marginRight: 10,
  },
  userName: {
    fontFamily: "IBM Plex Sans",
    fontSize: 14,
    letterSpacing: "0.25px",
    lineHeight: "21px",
    color: "#000000",
  },
  verificationIcon: {
    width: 16,
    height: 16,
    marginLeft: 5,
  },
  timeAgo: {
    marginLeft: 10,
    color: "#737373",
    fontWeight: 300,
    fontFamily: "IBM Plex Sans",
    fontSize: 14,
  },
};
