/** @jsx jsx */
import { jsx } from "@emotion/react";
import { forwardRef } from "react";
import PropTypes from "prop-types";

import {
  acceptShortcutProps,
  shortcutPropTypes,
  defaultShortcutProps,
} from "../utils";

export const View = forwardRef(({ children, ...rest }, ref) => {
  const [finalStyles, otherProps] = acceptShortcutProps(rest);

  return (
    <div {...otherProps} css={finalStyles} ref={ref}>
      {children}
    </div>
  );
});

View.propTypes = {
  children: PropTypes.node,
  /* Styles */
  // eslint-disable-next-line react/forbid-prop-types
  css: PropTypes.object,
  ...shortcutPropTypes,
};

View.defaultProps = {
  children: [],
  css: {},
  ...defaultShortcutProps,
};

export default View;
