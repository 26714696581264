import globalStyles from "../../../styles";

export default {
  root: {
    position: "relative",
  },
  loadingBlock: {
    background: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    top: -1,
    left: -1,
    right: -1,
    bottom: "-32%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  targetWrapper: {
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  uploadHelptext: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "IBM Plex Sans",
    width: "100%",
    paddingBottom: 0,
    paddingTop: 0,
    color: "#356859",
    paddingLeft: "10px",
    letterSpacing: "0.25px",
    lineHeight: "21px",
    textAlign: "left",
  },
  uploadedImagesContainer: {
    width: "100%",
    maxHeight: "170px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  dropTarget: {
    display: "flex",
    boxSizing: "border-box",
    flex: "0 0 48%",
    background: "#F8F8F8",
    borderRadius: "8px",
    border: "0.5px solid #356859",
    height: "53px",
    cursor: "pointer",
  },
  uploadIcon: {
    width: "24px",
    marginLeft: "55px",
  },
  uploadDiv: {
    display: "flex",
    alignItems: "center",
  },
};
