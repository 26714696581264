import React, { useEffect, useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/react";
import UserService from "../../services/api/user";
import { DateComponent } from "../../components/core";
import styles from "./styles";
import arrowUp from "./images/arrow-up-circle.svg";
import arrowDown from "./images/arrow-down-circle.svg";
import moment from "moment";

const pageStyles = styles.transactionHistory;

const EarningsTable = () => {
  const [earningslist, setEarningsList] = useState();
  useEffect(() => {
    fetchTransactions();
  }, []);
  const fetchTransactions = async () => {
    const userDetails = JSON.parse(sessionStorage.getItem("user"));
    const apiData = await UserService.getRecentearnings(userDetails.userId);
    setEarningsList(apiData.data);
  };
  return (
    <div css={pageStyles.root}>
      <div css={pageStyles.statsDiv}>
        <div css={pageStyles.statssubDiv}>
          <label css={pageStyles.statslabel1}>Today</label>
          <br />
          <label css={pageStyles.statslabel2}>
            $ {earningslist && earningslist.today.stats.earnings}
          </label>
          <br />
          <label
            style={{
              color:
                earningslist && earningslist.today.stats.delta < 1
                  ? "#C0001B"
                  : "#43A046",
            }}
            css={pageStyles.statslabel3}
          >
            <img
              css={pageStyles.arrowIcon}
              src={
                earningslist && earningslist.today.stats.delta < 1
                  ? arrowDown
                  : arrowUp
              }
            />{" "}
            &nbsp;
            {earningslist && earningslist.today.stats.delta}% than yesterday
          </label>
          <br />
        </div>
        <div css={pageStyles.borderDiv}></div>
        <div css={pageStyles.statssubDiv}>
          <label css={pageStyles.statslabel1}>Week</label>
          <br />
          <label css={pageStyles.statslabel2}>
            {" "}
            $ {earningslist && earningslist.thisWeek.stats.earnings}
          </label>
          <br />
          <label
            style={{
              color:
                earningslist && earningslist.thisWeek.stats.delta < 1
                  ? "#C0001B"
                  : "#43A046",
            }}
            css={pageStyles.statslabel3}
          >
            {" "}
            <img
              css={pageStyles.arrowIcon}
              src={
                earningslist && earningslist.thisWeek.stats.delta < 1
                  ? arrowDown
                  : arrowUp
              }
            />{" "}
            &nbsp;
            {earningslist && earningslist.thisWeek.stats.delta}% than last week
          </label>
          <br />
        </div>
        <div css={pageStyles.borderDiv}></div>
        <div css={pageStyles.statssubDiv}>
          {" "}
          <label css={pageStyles.statslabel1}>Month</label>
          <br />
          <label css={pageStyles.statslabel2}>
            {" "}
            $ {earningslist && earningslist.thisMonth.stats.earnings}
          </label>
          <br />
          <label
            style={{
              color:
                earningslist && earningslist.thisMonth.stats.delta < 1
                  ? "#C0001B"
                  : "#43A046",
            }}
            css={pageStyles.statslabel3}
          >
            <img
              css={pageStyles.arrowIcon}
              src={
                earningslist && earningslist.thisMonth.stats.delta < 1
                  ? arrowDown
                  : arrowUp
              }
            />
            &nbsp;
            {earningslist && earningslist.thisMonth.stats.delta}% than last
            month
          </label>
          <br />
        </div>
      </div>
      <table css={pageStyles.table}>
        <thead>
          <tr>
            <th>Month</th>
            <th>Earnings</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {earningslist &&
            Object.keys(earningslist.pastMonths).map((earning, idx) => (
              <tr
                // key={`row-${transaction}`}
                css={idx % 2 !== 0 ? pageStyles.evenRow : {}}
              >
                <td>{moment(earning).format("MM-YYYY")}</td>
                <td>{earningslist.pastMonths[earning].stats.earnings}</td>
                <td
                  style={{
                    color:
                      earningslist.pastMonths[earning].stats.paid.allPaid ===
                      false
                        ? "#C0001B"
                        : "#43A046",
                  }}
                >
                  {earningslist.pastMonths[earning].stats.paid.allPaid === false
                    ? "Not paid"
                    : "Paid"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default EarningsTable;
