/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { GridSystem } from "../../components/core";
import { PricingCard } from "../../components/shared";
import {
  APP_URLS,
  TYPE_TO_DESC_MAPPING,
  PRODUCT_DESCRIPTION_BY_KEY,
} from "../../constants";
import styles from "./styles";

export const SignUpCTA = ({ children, priceData, user }) =>
  !user.token ? (
    <GridSystem.Container css={{ width: "100%" }}>
      <GridSystem.Row justify="center" css={{ marginBottom: 50 }}>
        {children}
      </GridSystem.Row>
      <GridSystem.Row justify="center">
        <GridSystem.Col
          sm={6}
          css={{ marginBottom: window.screen.width < 500 ? 30 : 0 }}
        >
          <PricingCard
            title={PRODUCT_DESCRIPTION_BY_KEY.view}
            costLine={priceData.view.price}
            availability={priceData.view.spots}
            css={styles.pricingCard}
            ctaLink={priceData.view.ctaLink}
            buttonText="Subscribe"
            view={true}
            // productId={priceData.view.id}
          >
            {TYPE_TO_DESC_MAPPING.view}
          </PricingCard>
        </GridSystem.Col>
        <GridSystem.Col
          sm={6}
          css={{ marginBottom: window.screen.width < 500 ? 30 : 0 }}
        >
          <PricingCard
            title={PRODUCT_DESCRIPTION_BY_KEY.ask}
            costLine={priceData.ask.price}
            availability={priceData.ask.spots}
            css={styles.pricingCard}
            ctaLink={priceData.ask.ctaLink}
            buttonText={
              priceData.ask.spots === "Sold out" ? "Join waitlist" : "Subscribe"
            }
            view={false}
            id={priceData.ask.id}
            // productId={priceData.ask.id}
          >
            {TYPE_TO_DESC_MAPPING.ask}
          </PricingCard>
        </GridSystem.Col>
      </GridSystem.Row>
    </GridSystem.Container>
  ) : (
    <GridSystem.Row justify="center" css={{ marginBottom: 24 }}>
      {children}
    </GridSystem.Row>
  );

SignUpCTA.propTypes = {
  children: PropTypes.node,
  priceData: PropTypes.shape({
    ask: PropTypes.shape({
      price: PropTypes.string,
      spots: PropTypes.string,
      ctaLink: PropTypes.string,
      id: PropTypes.number,
    }),
    view: PropTypes.shape({
      price: PropTypes.string,
      spots: PropTypes.string,
      ctaLink: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
  user: PropTypes.shape({
    userId: PropTypes.number,
    token: PropTypes.string,
  }),
};

SignUpCTA.defaultProps = {
  children: [],
  priceData: {
    view: {
      price: "$10000/month",
      spots: "Sold out",
      ctaLink: APP_URLS.LOGIN.url(""),
    },
    ask: {
      price: "$149000/month",
      spots: "1/50 Spots Left!",
      ctaLink: APP_URLS.LOGIN.url(""),
    },
  },
  user: {},
};

export default SignUpCTA;
