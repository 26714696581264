/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import parse from "date-fns/parse";
import format from "date-fns/format";
import _get from "lodash/get";
import PropTypes from "prop-types";
import moment from "moment";

import { Text, View, Button, Pill } from "../../components/core";

import UserService from "../../services/api/user";
import canAskQuestion from "../../services/utils/user-can-ask-question";
import { SUBSCRIPTION_STATUS } from "../../constants";
import CurrentPlan from "./currentPlan";
import styles from "./styles";
import { determinePlanString } from "../../services/utils/plan-from-caps";

const pageStyles = styles.membershipTable;

const SubscriptionStatus = ({ status }) => {
  switch (status) {
    case SUBSCRIPTION_STATUS.ACTIVE:
      return <div css={pageStyles.activeText}>Active</div>;
    case SUBSCRIPTION_STATUS.OVERDUE:
      return (
        <div
          css={pageStyles.activeText}
          style={{ color: "#9D3007", background: "#FFF2DF" }}
        >
          Payment Due
        </div>
      );
    case SUBSCRIPTION_STATUS.PENDING_CANCELLATION:
      return (
        <div
          style={{ color: "#9D3007", background: "#FFF2DF" }}
          css={pageStyles.activeText}
        >
          Pending cancellation
        </div>
      );
    case SUBSCRIPTION_STATUS.ENDED:
      return (
        <div
          style={{ color: "#001ABA", background: "#E9EBFD" }}
          css={pageStyles.activeText}
        >
          Ended
        </div>
      );
    default:
      return <div>Unknown</div>;
  }
};

SubscriptionStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(SUBSCRIPTION_STATUS)).isRequired,
};

const formatNextDate = (dateRaw) => {
  return moment(dateRaw).format("MM-DD-YYYY");
};

export const MembershipTable = () => {
  const slug = JSON.parse(sessionStorage.getItem("subscription"));
  const allSubscriptions = useSelector((store) =>
    _get(store, "user.subscriptionData.subscribedPublishers", [])
  );
  const subscriptions =
    allSubscriptions && allSubscriptions.length > 0
      ? allSubscriptions.filter((data) => {
          return data.name === slug;
        })
      : allSubscriptions;
  const user = useSelector((store) => _get(store, "user", {}));
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [isNotUpgrade, setIsUpgradeOrBuy] = useState(false);
  const hasSubscriptions =
    subscriptions.length > 0 &&
    (_get(
      subscriptions[0],
      "products.view.latestSubscription.subscriptionStatusId"
    ) ||
      _get(
        subscriptions[0],
        "products.ask.latestSubscription.subscriptionStatusId"
      ));

  const toggleUpgradePlan = (subscription) => {
    if (selectedMembership != null) {
      setSelectedMembership(null);
    } else {
      setSelectedMembership(subscription.id);
    }
  };

  const cancelSubscriptionPlan = () => {
    UserService.cancelSubscription().then((response) => {
      if (response) {
        sessionStorage.setItem("membership", true);
        window.location = window.location.href;
      }
    });
  };

  const reenableSubscriptionPlan = () => {
    UserService.reenableSubscription().then((response) => {
      if (response) {
        sessionStorage.setItem("membership", true);
        window.location = window.location.href;
      }
    });
  };

  return (
    <View fd="column" mb={36}>
      <Text css={pageStyles.titleMembership} mb={50}>
        Membership information
      </Text>
      <View f="none" css={pageStyles.tableContainer}>
        {hasSubscriptions ? (
          <table>
            <tbody>
              {subscriptions.map((subscription) => {
                let CurrentSubscriptionStatus = null;
                let latestSubscription = null;
                let viewStatus = _get(
                  subscription,
                  "products.view.latestSubscription"
                );
                let askStatus = _get(
                  subscription,
                  "products.ask.latestSubscription"
                );
                if (
                  askStatus === null ||
                  (askStatus.subscriptionStatusId === 4 && viewStatus !== null)
                ) {
                  CurrentSubscriptionStatus = _get(
                    subscription,
                    "products.view.latestSubscription.subscriptionStatusId"
                  );
                  latestSubscription = _get(subscription, "products.view");
                  sessionStorage.setItem(
                    "subscriptionId",
                    latestSubscription.latestSubscription.id
                  );
                } else {
                  CurrentSubscriptionStatus = _get(
                    subscription,
                    "products.ask.latestSubscription.subscriptionStatusId"
                  );
                  latestSubscription = _get(subscription, "products.ask");
                  sessionStorage.setItem(
                    "subscriptionId",
                    latestSubscription.latestSubscription.id
                  );
                }
                return (
                  <div>
                    <table css={pageStyles.table}>
                      <thead>
                        <tr>
                          <th>Current plan</th>
                          <th>Status</th>
                          <th>Next payment</th>
                          <th>
                            {" "}
                            {CurrentSubscriptionStatus ===
                              SUBSCRIPTION_STATUS.PENDING_CANCELLATION ||
                            CurrentSubscriptionStatus ===
                              SUBSCRIPTION_STATUS.ENDED
                              ? "Cancellation Date"
                              : "Renewal date"}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {determinePlanString(
                              _get(latestSubscription, "capIds", [])
                            )}
                          </td>
                          <td>
                            <SubscriptionStatus
                              status={CurrentSubscriptionStatus}
                            />
                          </td>
                          <td>{`$${_get(latestSubscription, "cost")}`}</td>
                          <td>
                            {" "}
                            {formatNextDate(
                              _get(
                                latestSubscription,
                                "latestSubscription.nextRenewal",
                                ""
                              )
                            )}
                          </td>
                          <td>
                            <View>
                              {viewStatus !== null &&
                                viewStatus.subscriptionStatusId === 1 && (
                                  <label
                                    css={pageStyles.actionLabel}
                                    onClick={() => {
                                      toggleUpgradePlan(subscription);
                                      setIsUpgradeOrBuy(true);
                                    }}
                                  >
                                    Upgrade Plan
                                  </label>
                                )}
                              {CurrentSubscriptionStatus !==
                                SUBSCRIPTION_STATUS.PENDING_CANCELLATION &&
                                CurrentSubscriptionStatus !==
                                  SUBSCRIPTION_STATUS.ENDED && (
                                  <label
                                    css={pageStyles.actionLabel}
                                    onClick={() => {
                                      cancelSubscriptionPlan();
                                    }}
                                  >
                                    Cancel Plan
                                  </label>
                                )}
                              {CurrentSubscriptionStatus ===
                                SUBSCRIPTION_STATUS.PENDING_CANCELLATION && (
                                <label
                                  onClick={() => {
                                    reenableSubscriptionPlan();
                                  }}
                                  css={pageStyles.actionLabel}
                                >
                                  Don’t Cancel
                                </label>
                              )}
                              {CurrentSubscriptionStatus ===
                                SUBSCRIPTION_STATUS.ENDED && (
                                <label
                                  onClick={() => {
                                    toggleUpgradePlan(subscription);
                                    setIsUpgradeOrBuy(false);
                                  }}
                                  css={pageStyles.actionLabel}
                                >
                                  Buy Plan
                                </label>
                              )}
                            </View>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <tr key={`table-row-subscription-${subscription.id}`}>
                      <td>
                        <View css={pageStyles.activePlan}>
                          <View fd="column" mr={12}>
                            <Text color="light">Current Plan</Text>
                            <Text bold>
                              {determinePlanString(
                                _get(latestSubscription, "capIds", [])
                              )}
                            </Text>
                          </View>
                          <SubscriptionStatus
                            status={CurrentSubscriptionStatus}
                          />
                        </View>
                      </td>
                      <td>
                        <View fd="column">
                          <Text>Next Payment</Text>
                          <Text bold>
                            {`$${_get(latestSubscription, "cost")}`}
                          </Text>
                        </View>
                      </td>
                      <td>
                        <View fd="column">
                          <Text>
                            {CurrentSubscriptionStatus ===
                              SUBSCRIPTION_STATUS.PENDING_CANCELLATION ||
                            CurrentSubscriptionStatus ===
                              SUBSCRIPTION_STATUS.ENDED
                              ? "Cancellation Date"
                              : "Next Renewal Date"}
                          </Text>
                          <Text bold>
                            {formatNextDate(
                              _get(
                                latestSubscription,
                                "latestSubscription.nextRenewal",
                                ""
                              )
                            )}
                          </Text>
                        </View>
                      </td>
                      <td>
                        <View>
                          {viewStatus !== null &&
                            viewStatus.subscriptionStatusId === 1 && (
                              <Button
                                onClick={() => {
                                  toggleUpgradePlan(subscription);
                                  setIsUpgradeOrBuy(true);
                                }}
                              >
                                Upgrade Plan
                              </Button>
                            )}
                          {CurrentSubscriptionStatus !==
                            SUBSCRIPTION_STATUS.PENDING_CANCELLATION &&
                            CurrentSubscriptionStatus !==
                              SUBSCRIPTION_STATUS.ENDED && (
                              <Button
                                onClick={() => {
                                  cancelSubscriptionPlan();
                                }}
                                css={styles.membershipTable.cancelButton}
                              >
                                Cancel Plan
                              </Button>
                            )}
                          {CurrentSubscriptionStatus ===
                            SUBSCRIPTION_STATUS.PENDING_CANCELLATION && (
                            <Button
                              onClick={() => {
                                reenableSubscriptionPlan();
                              }}
                              css={styles.membershipTable.cancelButton}
                            >
                              Don’t Cancel
                            </Button>
                          )}
                          {CurrentSubscriptionStatus ===
                            SUBSCRIPTION_STATUS.ENDED && (
                            <Button
                              onClick={() => {
                                toggleUpgradePlan(subscription);
                                setIsUpgradeOrBuy(false);
                              }}
                              css={styles.membershipTable.cancelButton}
                            >
                              Buy Plan
                            </Button>
                          )}
                        </View>
                      </td>
                    </tr> */}
                  </div>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Text centered>
            No Subscriptions yet, please consider subscribing.
          </Text>
        )}
      </View>
      {!!selectedMembership && (
        <CurrentPlan
          publisherId={selectedMembership}
          isNotUpgrade={!isNotUpgrade}
          upgradeView={true}
        />
      )}
    </View>
  );
};

export default MembershipTable;
