export const POSTS_ROOT = 'POSTS';

export const ADD_POST_ATTEMPT = `${POSTS_ROOT}/ADD_POST_ATTEMPT`;
export const ADD_POST_FAILED = `${POSTS_ROOT}/ADD_POST_FAILED`;
export const ADD_POST_SUCCESS = `${POSTS_ROOT}/ADD_POST_SUCCESS`;

export const LOAD_POSTS_ATTEMPT = `${POSTS_ROOT}/LOAD_POSTS_ATTEMPT`;
export const LOAD_POSTS_FAILED = `${POSTS_ROOT}/LOAD_POSTS_FAILED`;
export const LOAD_POSTS_SUCCESS = `${POSTS_ROOT}/LOAD_POSTS_SUCCESS`;

export const LOAD_POST_ATTEMPT = `${POSTS_ROOT}/LOAD_POST_ATTEMPT`;
export const LOAD_POST_FAILED = `${POSTS_ROOT}/LOAD_POST_FAILED`;
export const LOAD_POST_SUCCESS = `${POSTS_ROOT}/LOAD_POST_SUCCESS`;

// For testing reasons
export const CLEAR_POSTS = `${POSTS_ROOT}/CLEAR_POSTS`;
