export default {
  root: {
    width: "100%",
    maxHeight: "170px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  imageMedia: {
    position: "relative",
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "31%",
    marginRight: "2%",
  },
  image: {
    width: "100%",
    cursor: "zoom-in",
  },
  imageInModal: {
    width: "100%",
  },
  buttonStyles: {
    padding: 0,
    margin: 0,
    border: "0px none",
    appearance: "none",
  },
};
