/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Component } from "react";
import PropTypes from "prop-types";

import { Loading } from "../../components/core";

import { userParamSlug } from "../../services/schemas/commonProps";
import UserClient from "../../services/api/user";
import SignupPage from "./SignupFormPage";

export class SignUpPageWrapper extends Component {
  static propTypes = {
    match: PropTypes.shape(userParamSlug).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      subscriptionData: {},
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { slug: userSlug },
      },
    } = this.props;
    this.fetchPublisherData(userSlug);
  }

  fetchPublisherData = async (userSlug) => {
    this.setState({
      loading: true,
    });
    // fetch the publisher subscription data.
    const userData = await UserClient.fetchPublisherBySlug(userSlug);
    const { id } = userData;
    const response = await UserClient.fetchPublisherStats(id);
    this.setState({
      loading: false,
      subscriptionData: response,
    });
  };

  render() {
    const { loading, subscriptionData } = this.state;
    if (loading || !subscriptionData) {
      return <Loading />;
    }
    return <SignupPage subscriptionData={subscriptionData} />;
  }
}

export default SignUpPageWrapper;
