/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Avatar from "../avatar";
import { View, Text } from "../../core";
import verified from "./img/Verified.svg";

import styles from "./styles";
import mediaStyles from "../../core/mediaModal/styles";

/**
 * User
 * this is an inline component. Small and used before content to indicate who shared the content.
 */
export const UserProfile = ({ name, bio, IntroVideo, avatarUrl, ...rest }) => {
  const [play, setPlay] = useState(false);
  const playIntoVideo = () => {
    if (
      IntroVideo !== undefined &&
      (IntroVideo !== null && IntroVideo) !== false
    ) {
      setPlay(true);
    }
  };
  return (
    <View
      style={{ marginBottom: 0 }}
      css={styles.root}
      fd="column"
      data-testid="user-profile"
      {...rest}
    >
      <div
        onClick={() => {
          playIntoVideo();
        }}
      >
        <Avatar
          style={{
            border:
              IntroVideo === null ||
              IntroVideo === undefined ||
              IntroVideo === false
                ? "none"
                : "3px solid #356859",
            cursor:
              IntroVideo === null ||
              IntroVideo === undefined ||
              IntroVideo === false
                ? "none"
                : "pointer",
          }}
          url={avatarUrl}
          alt={name}
          css={styles.avatar}
        />
      </div>
      {name === false ? (
        ""
      ) : (
        <div>
          <Text type="h2" mb={12} css={styles.userName}>
            {name}
            <img css={styles.verifiedImg} src={verified} />
          </Text>
          <Text type="h2" px={24} css={styles.bio}>
            {bio}
          </Text>
        </div>
      )}
      {play === true && (
        <View
          css={{
            ...mediaStyles.backgroundContainer,
          }}
        >
          <button
            type="button"
            css={{ ...mediaStyles.closeButton, ...mediaStyles.noStyleButton }}
            onClick={() => {
              setPlay(false);
            }}
          >
            x
          </button>
          <View css={mediaStyles.modalContentWrapper}>
            <View
            // className="modal"
            // css={mediaStyles.modalContent}
            // data-testid="modal"
            ></View>
            <div
              css={mediaStyles.enlargedVideo}
              dangerouslySetInnerHTML={{
                __html: IntroVideo && IntroVideo.embedCode,
              }}
            />
          </View>
        </View>
      )}
    </View>
  );
};

UserProfile.propTypes = {
  name: PropTypes.string,
  avatarUrl: PropTypes.any,
  bio: PropTypes.string,
};

UserProfile.defaultProps = {
  name: "user",
  avatarUrl: undefined,
  bio: undefined,
};

export default UserProfile;
