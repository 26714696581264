import globalStyles from "../../../styles";

const styles = {
  root: {
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    padding: "20px 52px 20px 52px",
    height: 88,
    [globalStyles.layout.maxScreenQueries.md]: {
      padding: "20px 15px",
      flexWrap: "wrap",
    },
  },
  logoContainer: {
    alignItems: "center",
    cursor: "pointer",
  },
  logotype: {
    ...globalStyles.typography.h3,
  },
  logo: {
    width: "48.94px",
    height: "39.86px",
    borderRadius: 3,
  },
};

export default styles;
