export const USER_ROOT = 'USER';

export const ATTEMPT_LOGIN = `${USER_ROOT}/ATTEMPT_LOGIN`;
export const LOGIN_SUCCESS = `${USER_ROOT}/LOGIN_SUCCESS`;
export const LOGIN_FAILED = `${USER_ROOT}/LOGIN_FAILED`;
export const LOGOUT = `${USER_ROOT}/LOGOUT`;

export const ATTEMPT_LOAD_SUBSCRIPTIONS = `${USER_ROOT}/ATTEMPT_LOAD_SUBSCRIPTIONS`;
export const LOAD_SUBSCRIPTIONS_SUCCESS = `${USER_ROOT}/LOAD_SUBSCRIPTIONS_SUCCESS`;
export const LOAD_SUBSCRIPTIONS_FAILED = `${USER_ROOT}/LOAD_SUBSCRIPTIONS_FAILED`;

export const SIGN_UP_ATTEMPT = `${USER_ROOT}/SIGN_UP_ATTEMPT`;
export const SIGN_UP_SUCCESS = `${USER_ROOT}/SIGN_UP_SUCCESS`;
export const SIGN_UP_FAILED = `${USER_ROOT}/SIGN_UP_FAILED`;

export const USER_UPLOAD_AVATAR_ATTEMPT = `${USER_ROOT}/USER_UPLOAD_AVATAR_ATTEMPT`;
export const USER_UPLOAD_AVATAR_SUCCESS = `${USER_ROOT}/USER_UPLOAD_AVATAR_SUCCESS`;
export const USER_UPLOAD_AVATAR_FAILED = `${USER_ROOT}/USER_UPLOAD_AVATAR_FAILED`;
