/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useFetchRemoteResource } from "../../services/utils/custom-hooks";
import { PAYMENT_OPTIONS } from "../../constants";
import { Text, View, Placeholder, DateComponent } from "../../components/core";
import UserService from "../../services/api/user";
import moment from "moment";
import { useSelector } from "react-redux";
import styles from "./styles";
import _get from "lodash/get";

const pageStyles = styles.paymentHistory;

// export const PaymentHistory = async () => {
// const { loading, requested, data } = useFetchRemoteResource(
//   UserService.fetchPaymentHistory().bind(UserService)
// );

// if (loading || !requested) {
//   return (
//     <View css={{ maxWidth: 800, margin: "0 auto" }}>
//       <Placeholder />
//     </View>
//   );
// }

//   if (!data) {
//     return (
//       <Text color="error" centered mb={16}>
//         Some error occurred while loading payment history. Please try again or
//         contact support.
//       </Text>
//     );
//   }

//   return <PaymentHistoryContent transactions={data} />;
// };

const stringForTransactionId = (id) =>
  ["Unknown", "Credit Card", "Bitcoin", "Complimentary", "Manual Entry"][id];

export const PaymentHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const slug = JSON.parse(sessionStorage.getItem("subscription"));
  const allSubscriptions = useSelector((store) =>
    _get(store, "user.subscriptionData.subscribedPublishers", [])
  );
  const subscriptions =
    allSubscriptions && allSubscriptions.length > 0
      ? allSubscriptions.filter((data) => {
          return data.name === slug;
        })
      : allSubscriptions;

  useEffect(() => {
    fetchTransactions(subscriptions);
  }, []);

  const fetchTransactions = async (subscriptions) => {
    const apiData = await UserService.fetchPaymentHistory(
      subscriptions && subscriptions[0].products
    );
    setTransactions(apiData);
  };

  return (
    <View f="none" css={pageStyles.root}>
      <Text f="1 1 auto" type="h5" css={pageStyles.titlelabel} mb={14}>
        Transactions history
      </Text>
      <div css={pageStyles.paymentContainer}>
        <table css={pageStyles.table}>
          <thead>
            <tr>
              <th>Product</th>
              <th>Amount</th>
              <th>Method</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, idx) => (
              <tr
                key={`row-${transaction.date}`}
                css={idx % 2 === 0 ? pageStyles.evenRow : {}}
              >
                <td>
                  {transaction.product.capIds.length > 1
                    ? transaction.product.capIds.map((data) => {
                        if (data === 4) {
                          return "Ask";
                        }
                      })
                    : transaction.product.capIds[0] === 3
                    ? "View"
                    : transaction.product.capIds[0] === 4
                    ? "Ask"
                    : ""}
                </td>
                <td>{`$${
                  transaction.amount || transaction.refundedAmount
                }`}</td>
                <td>{stringForTransactionId(transaction.paymentMethodId)}</td>
                <td>{moment(transaction.date).format("MM-DD-YYYY")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </View>
  );
};

PaymentHistory.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      transactionStatusId: PropTypes.number,
      amount: PropTypes.number,
      refundedAmount: PropTypes.number,
      paymentMethodId: PropTypes.oneOf(Object.values(PAYMENT_OPTIONS)),
      date: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        cost: PropTypes.number,
      }),
    })
  ),
};

PaymentHistory.defaultProps = {
  transactions: [],
};

export default PaymentHistory;
