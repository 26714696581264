/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";
import _pick from "lodash/pick";
import UserService from "../../services/api/user";
import { loadUserById } from "../../services/state/users/actionCreators";
import NotificationService from "../../components/shared/Notification";

import { Text, View, Input, Button, Modal } from "../../components/core";
import styles from "./styles";
import VideoIcon from "../../components/shared/uploadMediaTarget/img/settingsVideo.svg";
import { PostMedia } from "../../components/shared/postMedia";
import { LOAD_USER_DATA_SUCCESS } from "../../services/state/users/actions";
import { UserProfile } from "../../components/shared";
import UploadMediaTarget from "../../components/shared/uploadMediaTarget";
import Modalstyles from "../../components/shared/createQuestion/styles";

const pageStyles = styles.userSettings;

const isValidEmail = (mail) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    mail
  );

export const ChangePasswordForm = ({
  loadUser,
  accountDetails,
  planDetails,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const userProfile = useSelector((store) =>
    _get(store, `users.users[${user.userId}]`)
  );
  const openMembership =
    userProfile &&
    userProfile.products &&
    userProfile.products.ask.maxActiveSubscriptions -
      userProfile.activeSubscribers.numAskers;
  const [pendingRequest, setPendingRequest] = useState(false);
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [imageData, setImagedata] = useState();
  const [videoData, setvideoData] = useState();
  const [isOpen, setOpen] = useState(false);
  const [intro, setIntro] = useState(false);

  const userProfileData = _pick(userProfile, ["name", "email", "password"]);
  const [formData, setFormData] = useState({
    name: userProfile ? userProfile.name : "",
    email: userProfile ? userProfile.email : "",
    password: "",
    password2: "",
    bio: userProfile?.bio ? userProfile.bio : "",
    bankRoutingNumber: userProfile?.payoutDetails
      ? userProfile.payoutDetails.bankRoutingNumber
      : "",
    bankAccountNumber: userProfile?.payoutDetails
      ? userProfile.payoutDetails.bankAccountNumber
      : "",
    businessName: userProfile?.taxes ? userProfile.taxes.businessName : "",
    businessAddress: userProfile?.taxes
      ? userProfile.taxes.businessAddress
      : "",
    socialSecurityNumber: userProfile?.taxes
      ? userProfile.taxes.socialSecurityNumber
      : "",
    viewCost: "",
    askCost: "",
    askmaxMembers: "",
    askBilling: "",
    viewBilling: "",
  });

  useEffect(() => {
    if (userProfile.specificProductIds?.length > 0) {
      UserService.getProductDetails(userProfile.specificProductIds).then(
        (resp) => {
          if (resp) {
            const newFormState = {
              ...formData,
              ...{
                askCost: resp.data[0].name.includes("Ask")
                  ? resp.data[0].cost
                  : resp.data[1].name.includes("Ask")
                  ? resp.data[1].cost
                  : "",
                viewCost: resp.data[0].name.includes("View")
                  ? resp.data[0].cost
                  : resp.data[1].name.includes("View")
                  ? resp.data[1].cost
                  : "",
                askmaxMembers: resp.data[0]?.name.includes("Ask")
                  ? resp.data[0].maxActiveSubscriptions
                  : resp.data[1].name.includes("Ask")
                  ? resp.data[1].maxActiveSubscriptions
                  : "",
                askBilling: resp.data[0].name.includes("Ask")
                  ? resp.data[0].billingCycle
                  : resp.data[1].name.includes("Ask")
                  ? resp.data[1].billingCycle
                  : "",
                viewBilling: resp.data[0].name.includes("View")
                  ? resp.data[0].billingCycle
                  : resp.data[1].name.includes("View")
                  ? resp.data[1].billingCycle
                  : "",
              },
            };
            setFormData(newFormState);
          }
        }
      );
    }
  }, []);
  const updateField = (key, value) => {
    const newFormState = {
      ...formData,
      ...{
        [key]: value,
      },
    };
    setFormData(newFormState);
  };

  const validateForm = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const newErrors = [];
    if (formData.password !== "" && formData.password !== formData.password2) {
      newErrors.push("Passwords must match");
    }
    if (formData.email !== "" && !isValidEmail(formData.email)) {
      newErrors.push("Email must be valid");
    }
    if (
      formData.email === "" &&
      formData.password === "" &&
      formData.name === ""
    ) {
      newErrors.push(
        'You haven\'t changed anything. Did you mean to hit "Save"?'
      );
    }
    if (formData.name.match(/[A-Z\s/[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]+/)) {
      newErrors.push(
        "Empty space, capital letter, or any other symbol than these: “.” and “_” are not allowed for username"
      );
    }

    if (newErrors.length === 0 && userProfile.userRoleId === 3) {
      setPendingRequest(true);
      const obj = {
        name: formData.name,
        email: formData.email,
        bio: formData.bio,
        slug: formData.name,
        payoutDetails: {
          bankRoutingNumber: formData.bankRoutingNumber,
          bankAccountNumber: formData.bankAccountNumber,
        },
        taxes: {
          businessName: formData.businessName,
          businessAddress: formData.businessAddress,
          socialSecurityNumber: formData.socialSecurityNumber,
        },
        productSettings: {
          questions: {
            view: {
              cost: formData.viewCost,
              billingCycle: formData.viewBilling,
            },
            ask: {
              cost: formData.askCost,
              maxActiveSubscriptions: formData.askmaxMembers,
              billingCycle: formData.askBilling,
            },
            applyCostToExistingSubscriptions: true,
          },
        },
      };
      if (formData.password !== "") {
        obj.password = formData.password;
      }
      setPendingRequest(false);
      UserService.creatorprofileUpdate(userProfile.id, obj).then((data) => {
        if (data) {
          setFormData({});
          dispatch(loadUserById(userProfile.id));
          NotificationService.success("Updated sucessfully");
          setOpen(false);
        }
      });
    } else if (newErrors.length === 0 && userProfile.userRoleId === 2) {
      const obj = {
        userRoleId: 2,
        name: formData.name,
        email: formData.email,
      };
      if (formData.password !== "") {
        obj.password = formData.password;
      }
      UserService.userprofileUpdate(userProfile.id, obj).then((data) => {
        if (data) {
          dispatch(loadUserById(userProfile.id));
          NotificationService.success("Updated sucessfully");
          setOpen(false);
        }
      });
    }
    setErrors(newErrors);
  };
  const handleFileChange = (fileData) => {
    if (intro) {
      const obj = {
        mediaId: fileData[0].mediaId,
        embedCode: fileData[0].embedCode,
      };
      setvideoData(obj);
    } else {
      setImagedata(fileData);
    }
  };

  const handleUpdateProfile = () => {
    if (intro) {
      UserService.uploadIntroVideo(userProfile.id, videoData).then((resp) => {
        if (resp) {
          dispatch(loadUserById(userProfile.id));
          NotificationService.success("Updated sucessfully");
          setOpen(false);
          setIntro(false);
        }
      });
    } else {
      const data = {
        cropProperties: {
          x: 100,
          y: 100,
          width: 200,
          height: 200,
        },
        original: imageData[0].data,
        medium: imageData[0].data,
        small: imageData[0].data,
      };
      UserService.updateProfilePic(userProfile.id, data).then((data) => {
        if (data) {
          dispatch(loadUserById(userProfile.id));
          NotificationService.success("Updated sucessfully");
          setOpen(false);
        }
      });
    }
  };
  return (
    <form onSubmit={validateForm}>
      <View f="none" style={{ maxWidth: "100%" }} css={pageStyles.root}>
        {/* <Text type="h5" centered style={{ fontSize: "22px" }}>
          Account
        </Text> */}
        {accountDetails === true && planDetails !== true && (
          <div>
            <View
              style={{
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "25px",
              }}
            >
              {userProfile && userProfile.avatar ? (
                <div>
                  <UserProfile
                    name={false}
                    avatarUrl={userProfile.avatar.medium}
                    style={{
                      position: window.screen.width < 500 ? {} : "absolute",
                      marginTop: 30,
                      marginLeft: window.screen.width < 500 ? {} : 230,
                      left: 0,
                    }}
                  />
                  <label
                    onClick={() => {
                      setOpen(true);
                    }}
                    css={pageStyles.avatarLabel}
                  >
                    Edit Photo
                  </label>
                </div>
              ) : (
                ""
              )}
            </View>
            <Text style={{ marginRight: 248 }} css={styles.title}>
              Username <b style={{ color: "red" }}>*</b>
            </Text>
            <View css={pageStyles.inputContainer}>
              <Input
                id="name"
                name="name"
                disabled={pendingRequest ? "disabled" : undefined}
                defaultValue={userProfile && userProfile.name}
                onChange={(e) => updateField("name", e.target.value)}
                css={pageStyles.input}
              >
                User Name
              </Input>
            </View>
            <div>
              {userProfile && userProfile.userRoleId === 3 && (
                <div>
                  <Text style={{ marginRight: 283 }} css={styles.title}>
                    Bio <b style={{ color: "red" }}>*</b>
                  </Text>
                  <View css={pageStyles.inputContainer}>
                    <Input
                      type="text"
                      css={pageStyles.input}
                      defaultValue={userProfile && userProfile.bio}
                      onChange={(e) => updateField("bio", e.target.value)}
                      multiline
                    ></Input>
                  </View>
                </div>
              )}
            </div>
            <Text style={{ marginRight: 265 }} css={styles.title}>
              Email <b style={{ color: "red" }}>*</b>
            </Text>
            <View css={pageStyles.inputContainer}>
              <Input
                id="changeEmail"
                name="changeEmail"
                disabled={pendingRequest ? "disabled" : undefined}
                defaultValue={userProfile && userProfile.email}
                onChange={(e) => updateField("email", e.target.value)}
                css={pageStyles.input}
              >
                Email
              </Input>
            </View>
            {userProfile && userProfile.userRoleId === 3 && (
              <Text style={{ marginRight: 206 }} css={styles.title}>
                Marketing URL <b style={{ color: "red" }}>*</b>
              </Text>
            )}
            {userProfile && userProfile.userRoleId === 3 && (
              <View css={pageStyles.inputContainer}>
                <Input
                  disabled={"disabled"}
                  value={`${window.location.origin.split("/")[2]}/${
                    userProfile.slug
                  }`}
                  onChange={(e) => updateField("password2", e.target.value)}
                  css={pageStyles.input}
                >
                  Marketing URL
                </Input>
              </View>
            )}
            <Text style={{ marginRight: 246 }} css={styles.title}>
              Password <b style={{ color: "red" }}>*</b>
            </Text>
            <View css={pageStyles.inputContainer}>
              <Input
                disabled={pendingRequest ? "disabled" : undefined}
                value={formData.password}
                type="password"
                onChange={(e) => updateField("password", e.target.value)}
                css={pageStyles.input}
              >
                New Password
              </Input>
            </View>{" "}
            <Text style={{ marginRight: 192 }} css={styles.title}>
              Confirm password <b style={{ color: "red" }}>*</b>
            </Text>
            <View css={pageStyles.inputContainer}>
              <Input
                disabled={pendingRequest ? "disabled" : undefined}
                value={formData.password2}
                type="password2"
                onChange={(e) => updateField("password2", e.target.value)}
                css={pageStyles.input}
              >
                Confirm Password
              </Input>
            </View>
            {/* <View css={pageStyles.inputContainer}> */}
            {userProfile &&
              userProfile.userRoleId === 3 &&
              (userProfile.featuredVideo === null ? (
                <div css={pageStyles.videoIconDiv}>
                  <Text
                    style={{ marginRight: 50, width: "100%" }}
                    css={styles.title}
                  >
                    Video Intro
                  </Text>
                  <img
                    css={pageStyles.uploadIcon}
                    src={VideoIcon}
                    style={{
                      marginLeft: window.screen.width > 500 ? "-44px" : "7px",
                      cursor: "pointer",
                    }}
                    alt="video upload icon"
                    onClick={() => {
                      setOpen(true);
                      setIntro(true);
                    }}
                  />
                  <Text
                    css={pageStyles.uploadText}
                    onClick={() => {
                      setOpen(true);
                      setIntro(true);
                    }}
                    type="small"
                    style={{
                      marginLeft: window.screen.width < 500 ? 15 : -40,
                    }}
                  >
                    Upload video
                  </Text>
                </div>
              ) : (
                <div
                  style={{
                    marginLeft: window.screen.width < 500 ? 10 : 215,
                    width: "120px",
                  }}
                >
                  <PostMedia
                    attachments={[
                      {
                        attachmentTypeId: 2,
                        thumbnailUrl: userProfile.avatar?.medium,
                        mediaId: userProfile.featuredVideo?.mediaId,
                        embedCode: userProfile.featuredVideo?.embedCode,
                      },
                    ]}
                  />
                  <Text
                    css={pageStyles.editVideoText}
                    type="small"
                    onClick={() => {
                      setOpen(true);
                      setIntro(true);
                    }}
                  >
                    Edit video
                  </Text>
                </div>
              ))}
          </div>
        )}
        {/* </View> */}
        {userProfile &&
          userProfile.userRoleId === 3 &&
          accountDetails !== true && (
            <div>
              <Text type="h5" css={pageStyles.paymentLabel}>
                Payment details
              </Text>
              <Text
                style={{ marginRight: window.screen.width < 500 ? {} : 360 }}
                css={styles.title}
              >
                Bank account number
              </Text>
              <View
                style={{
                  marginLeft:
                    accountDetails !== true && window.screen.width > 500
                      ? 20
                      : window.screen.width < 500
                      ? {}
                      : 200,
                  justifyContent: accountDetails !== true ? "unset" : "center",
                }}
                css={pageStyles.inputContainer}
              >
                <Input
                  disabled={pendingRequest ? "disabled" : undefined}
                  value={
                    userProfile &&
                    userProfile.payoutDetails &&
                    userProfile.payoutDetails.bankAccountNumber
                  }
                  type="number"
                  onChange={(e) =>
                    updateField("bankAccountNumber", e.target.value)
                  }
                  style={{ margin: window.screen.width < 500 ? 0 : "0 12px" }}
                  css={pageStyles.input}
                ></Input>
              </View>
              <Text
                style={{ marginRight: window.screen.width < 500 ? {} : 360 }}
                css={styles.title}
              >
                Bank routing number
              </Text>
              <View
                style={{
                  marginLeft:
                    accountDetails !== true && window.screen.width > 500
                      ? 20
                      : window.screen.width < 500
                      ? {}
                      : 200,
                  justifyContent: accountDetails !== true ? "unset" : "center",
                }}
                css={pageStyles.inputContainer}
              >
                <Input
                  disabled={pendingRequest ? "disabled" : undefined}
                  value={
                    userProfile &&
                    userProfile.payoutDetails &&
                    userProfile.payoutDetails.bankRoutingNumber
                  }
                  type="number"
                  onChange={(e) =>
                    updateField("bankRoutingNumber", e.target.value)
                  }
                  css={pageStyles.input}
                  style={{ margin: window.screen.width < 500 ? 0 : "0 12px" }}
                ></Input>
              </View>
            </div>
          )}
        {userProfile &&
          userProfile.userRoleId === 3 &&
          accountDetails !== true && (
            <div>
              <Text type="h5" css={pageStyles.paymentLabel}>
                Taxes/W9
              </Text>
              <Text
                style={{ marginRight: window.screen.width < 500 ? {} : 398 }}
                css={styles.title}
              >
                Bussiness name
              </Text>
              <View
                style={{
                  marginLeft:
                    accountDetails !== true && window.screen.width > 500
                      ? 20
                      : window.screen.width < 500
                      ? {}
                      : 200,
                  justifyContent: accountDetails !== true ? "unset" : "center",
                }}
                css={pageStyles.inputContainer}
              >
                <Input
                  disabled={pendingRequest ? "disabled" : undefined}
                  value={
                    userProfile &&
                    userProfile.taxes &&
                    userProfile.taxes.businessName
                  }
                  type="text"
                  onChange={(e) => updateField("businessName", e.target.value)}
                  css={pageStyles.input}
                  style={{ margin: window.screen.width < 500 ? 0 : "0 12px" }}
                ></Input>
              </View>{" "}
              <Text
                style={{ marginRight: window.screen.width < 500 ? {} : 348 }}
                css={styles.title}
              >
                Social security number
              </Text>
              <View
                style={{
                  marginLeft:
                    accountDetails !== true && window.screen.width > 500
                      ? 20
                      : window.screen.width < 500
                      ? {}
                      : 200,
                  justifyContent: accountDetails !== true ? "unset" : "center",
                }}
                css={pageStyles.inputContainer}
              >
                <Input
                  disabled={pendingRequest ? "disabled" : undefined}
                  value={
                    userProfile &&
                    userProfile.taxes &&
                    userProfile.taxes.socialSecurityNumber
                  }
                  type="number"
                  onChange={(e) =>
                    updateField("socialSecurityNumber", e.target.value)
                  }
                  css={pageStyles.input}
                  style={{ margin: window.screen.width < 500 ? 0 : "0 12px" }}
                ></Input>
              </View>
              <Text
                style={{ marginRight: window.screen.width < 500 ? {} : 380 }}
                css={styles.title}
              >
                Bussiness address
              </Text>
              <View
                style={{
                  marginLeft:
                    accountDetails !== true && window.screen.width > 500
                      ? 20
                      : window.screen.width < 500
                      ? {}
                      : 200,
                  justifyContent: accountDetails !== true ? "unset" : "center",
                }}
                css={pageStyles.inputContainer}
              >
                <Input
                  type="text"
                  value={
                    userProfile &&
                    userProfile.taxes &&
                    userProfile.taxes.businessAddress
                  }
                  onChange={(e) =>
                    updateField("businessAddress", e.target.value)
                  }
                  css={pageStyles.input}
                  style={{ margin: window.screen.width < 500 ? 0 : "0 12px" }}
                  multiline
                ></Input>
              </View>
            </div>
          )}
        {userProfile && userProfile.userRoleId === 3 && planDetails === true && (
          <div>
            <Text
              css={pageStyles.paymentLabel}
              style={{ margin: "15px 0px 0px 32px" }}
              type="h5"
              left
            >
              View subscription
            </Text>
            <label css={pageStyles.planSubheader}>
              Members view questions you’ve answered
            </label>
            <Text
              style={{ textAlign: "left", marginLeft: 35 }}
              left
              css={styles.title}
            >
              Price
            </Text>
            <View
              style={{
                marginLeft: planDetails === true ? 20 : 200,
                justifyContent: planDetails === true ? "unset" : "center",
              }}
              css={pageStyles.inputContainer}
            >
              <Input
                disabled={pendingRequest ? "disabled" : undefined}
                value={formData.viewCost}
                style={{ minWidth: window.screen.width < 500 ? "90%" : {} }}
                type="number"
                onChange={(e) => updateField("viewCost", e.target.value)}
                css={pageStyles.input}
              ></Input>
            </View>{" "}
            <Text
              style={{ textAlign: "left", marginLeft: 35 }}
              css={styles.title}
            >
              Max members
            </Text>
            <View
              style={{
                marginLeft: planDetails === true ? 20 : 200,
                justifyContent: planDetails === true ? "unset" : "center",
              }}
              css={pageStyles.inputContainer}
            >
              <Text
                style={{
                  textAlign: "left",
                  marginTop: 5,
                  marginLeft: 17,
                  color: "#556670",
                  fontWeight: 400,
                }}
                css={styles.title}
              >
                Unlimited
              </Text>
            </View>
            <Text
              style={{ textAlign: "left", marginLeft: 35 }}
              css={styles.title}
            >
              Time
            </Text>
            <View
              style={{
                marginLeft: planDetails === true ? 20 : 200,
                justifyContent: planDetails === true ? "unset" : "center",
              }}
              css={pageStyles.inputContainer}
            >
              <Text css={pageStyles.directText}>30 days</Text>
              <Input
                style={{ visibility: "hidden" }}
                css={pageStyles.input}
              ></Input>
            </View>
          </div>
        )}
        {userProfile && userProfile.userRoleId === 3 && planDetails === true && (
          <div>
            <Text
              css={pageStyles.paymentLabel}
              style={{ margin: "15px 0px 0px 32px" }}
              type="h5"
              left
            >
              Questions
            </Text>
            <label css={pageStyles.planSubheader}>
              Members view questions you’ve answered and ask you one question
              per week
            </label>
            <Text
              style={{ textAlign: "left", marginLeft: 35 }}
              left
              css={styles.title}
            >
              Price
            </Text>
            <View
              style={{
                marginLeft: planDetails === true ? 20 : 200,
                justifyContent: planDetails === true ? "unset" : "center",
              }}
              css={pageStyles.inputContainer}
            >
              <Input
                disabled={pendingRequest ? "disabled" : undefined}
                value={formData.askCost}
                type="number"
                onChange={(e) => updateField("askCost", e.target.value)}
                css={pageStyles.input}
                style={{ minWidth: window.screen.width < 500 ? "90%" : {} }}
              ></Input>
            </View>
            <Text
              style={{ textAlign: "left", marginLeft: 35 }}
              left
              css={styles.title}
            >
              Max members
            </Text>
            <View
              style={{
                marginLeft: planDetails === true ? 20 : 200,
                justifyContent: planDetails === true ? "unset" : "center",
              }}
              css={pageStyles.inputContainer}
            >
              <Input
                value={
                  formData.askmaxMembers === null ? 0 : formData.askmaxMembers
                }
                type="number"
                onChange={(e) => updateField("askmaxMembers", e.target.value)}
                css={pageStyles.input}
                style={{ minWidth: window.screen.width < 500 ? "90%" : {} }}
              ></Input>
            </View>
            <Text
              style={{ textAlign: "left", marginLeft: 35 }}
              css={styles.title}
            >
              Time
            </Text>
            <View
              style={{
                marginLeft: planDetails === true ? 20 : 200,
                justifyContent: planDetails === true ? "unset" : "center",
              }}
              css={pageStyles.inputContainer}
            >
              <Text css={pageStyles.directText}>30 days</Text>
            </View>
            <Text
              style={{ textAlign: "left", marginLeft: 35 }}
              css={styles.title}
            >
              Open memberships
            </Text>
            <View
              style={{
                marginLeft: planDetails === true ? 20 : 200,
                justifyContent: planDetails === true ? "unset" : "center",
              }}
              css={pageStyles.inputContainer}
            >
              <Text css={pageStyles.directText}>{openMembership}</Text>
            </View>
          </div>
        )}
        <View
          css={pageStyles.inputButtonDiv}
          style={{
            marginRight:
              accountDetails !== true && window.screen.width > 500
                ? 345
                : planDetails === true && window.screen.width > 500
                ? 410
                : window.screen.width < 500
                ? 0
                : 165,
          }}
        >
          <Button onClick={validateForm} css={pageStyles.inputButton}>
            Save changes
          </Button>
        </View>
        <View fd="column">
          {errors &&
            errors.map((error) => (
              <Text
                color="error"
                centered
                key={`error-${error.replace(" ", "")}`}
              >
                {error}
              </Text>
            ))}
          {messages &&
            messages.map((message) => (
              <Text
                color="primary"
                centered
                key={`error-${message.replace(" ", "")}`}
              >
                {message}
              </Text>
            ))}
        </View>
      </View>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)} noButton>
        <View fd="column" px={42} py={42} style={{ width: "100%" }}>
          <Text
            type="h5"
            css={Modalstyles.titleContent}
            centered
            style={{ fontSize: "22px", margin: "12%" }}
          >
            {!intro ? "Edit Profile Pic" : "Upload Intro Video"}
          </Text>
          <View
            f="none"
            style={{
              paddingLeft: window.screen.width < 500 ? "0%" : "35%",
              position: window.screen.width < 500 ? "absolute" : "inherit",
              top: window.screen.width < 500 ? "150px" : "0px",
              left: window.screen.width < 500 ? "120px" : "0px",
            }}
            fg={1}
            css={Modalstyles.inputContainer}
          >
            <UploadMediaTarget
              settings={true}
              video={intro}
              onChange={handleFileChange}
            ></UploadMediaTarget>
          </View>
          <View css={Modalstyles.actionRow} mt={24}>
            <Button
              css={pageStyles.cancelButton}
              onClick={() => {
                setOpen(false);
                setIntro(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleUpdateProfile()}
              css={pageStyles.sendButton}
            >
              Send
            </Button>
          </View>
        </View>
      </Modal>
    </form>
  );
};

export default ChangePasswordForm;
