/** @jsx jsx */
import { jsx } from '@emotion/react';
import {
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Avatar from '../avatar';
import {
  View,
  Text,
  Input,
  Button,
} from '../../core';

import styles from './styles';
import ImageCropper from './ImageCropper';

/**
 * User
 * this is an inline component. Small and used before content to indicate who shared the content.
*/
export const EditableUserProfile = ({
  name,
  bio,
  avatarUrl,
  onEditAvatar,
  onEditBio,
  ...rest
}) => {
  const [editingBio, setEditingBio] = useState(false);
  const [currentBio, setCurruentBio] = useState(bio);
  const fileInputRef = useRef();

  const onSave = () => {
    onEditBio(currentBio);
    setEditingBio(false);
  };

  const onClickedAvatar = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <View css={styles.root} fd="column" {...rest}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div css={styles.editableOverlay} onClick={onClickedAvatar} role="button" tabIndex={0}>
        <Avatar
          url={avatarUrl}
          alt={name}
          css={styles.avatar}
        />
        <ImageCropper ref={fileInputRef} onEditAvatar={onEditAvatar} />
      </div>
      <Text type="h2" mb={12} css={styles.userName}>
        {name}
      </Text>
      {
        editingBio ? (
          <View css={styles.editingWidthConstraint} f="none">
            <Input
              css={styles.editingWidthConstraint}
              multiline
              value={currentBio}
              onChange={(e) => setCurruentBio(e.target.value)}
            />
            <View css={styles.editingButtons} my={12}>
              <Button flat onClick={onSave}>
                Save
              </Button>
              <Button flat ghost onClick={() => setEditingBio(false)}>
                Cancel
              </Button>
            </View>
          </View>
        ) : (
          <Text type="h2" px={24} css={styles.bio} onClick={() => { setEditingBio(!editingBio); }}>
            {bio}
            <span>
              edit
            </span>
          </Text>
        )
      }
    </View>
  );
};

EditableUserProfile.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  avatarUrl: PropTypes.any,
  bio: PropTypes.string,
  onEditAvatar: PropTypes.func,
  onEditBio: PropTypes.func,
};

EditableUserProfile.defaultProps = {
  name: 'user',
  avatarUrl: undefined,
  bio: undefined,
  onEditAvatar: () => { throw new Error('user trying to edit profile, but no listner attached'); },
  onEditBio: () => { throw new Error('user trying to edit bio, but no listner attached'); },
};

export default EditableUserProfile;
