export default {
  root: {
    background: "#FFFFFF",
    display: "flex",
    boxShadow:
      "0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    marginRight: 50,
    marginLeft: 50,
    height: "100vh",
    overflowY: "auto",
    marginBottom: 20,
    marginTop: 35,
  },
  navDiv: {
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #CFCFCF",
    paddingRight: "30px",
    "@media(max-width: 500px)": {
      borderRight: "none",
      paddingRight: 0,
    },
  },
  inActiveLabel: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: 16,
    color: "#737373",
    LineHeight: "20px",
    display: "flex",
    alignItems: "center",
    height: "50px",
    letterSpacing: "0.15px",
    cursor: "pointer",
    paddingLeft: 20,
    "@media(max-width: 500px)": {
      borderBottom: "1.0807px solid #EEEEEE",
      fontWeight: 600,
      fontFamily: "Inter",
      fontSize: 17.2912,
    },
  },
  titleNavLabel: {
    display: "flex",
    justifyContent: "center",
    fontWeight: 700,
    fontSize: 14,
    alignItems: "center",
    color: "#000000",
    height: "50px",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.08)",
  },
  arrowIcon: {
    position: "absolute",
    right: 15,
  },
  arrowLeftIcon: {
    position: "absolute",
    left: 10,
  },
  activeLabel: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 600,
    fontSize: 16,
    paddingLeft: 15,
    color: "#356859",
    display: "flex",
    alignItems: "center",
    LineHeight: "24px",
    height: "50px",
    letterSpacing: "0.15px",
    borderLeft: "3px solid #356859",
  },
  transactionHistory: {
    root: {},
    statsDiv: {
      background: "#ffffff",
      margin: "50px 0px 20px 20px",
      width: "49vw",
      display: "flex",
      height: "104px",
      borderRadius: "8px",
      boxShadow:
        "0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
      "@media(max-width: 500px)": {
        width: "90vw",
        height: "auto",
      },
    },
    downloadButton: {
      float: "right",
      marginTop: 25,
      marginRight: 20,
      borderRadius: "8px",
      boxShadow:
        " 0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
      color: "#356859",
      background: "#ffffff",
      border: "2px solid #356859",
      height: "48px",
    },
    downloadIcon: {
      width: "24px",
    },
    statssubDiv: {
      margin: "5px 25px",
    },
    statslabel1: {
      fontWeight: 400,
      fontSize: 16,
      fontFamily: "IBM Plex Sans",
      letterSpacing: "0.15px",
      LineHeight: "20px",
      color: "#737373",
      marginBottom: 0,
    },
    statslabel2: {
      fontWeight: 700,
      fontSize: 20,
      fontFamily: "IBM Plex Sans",
      letterSpacing: "0.15px",
      LineHeight: "30px",
      color: "#000000",
      marginBottom: 0,
    },
    statslabel3: {
      fontWeight: 400,
      fontSize: 12,
      fontFamily: "IBM Plex Sans",
      letterSpacing: "0.15px",
      LineHeight: "20px",
      color: "#000000",
      marginBottom: 0,
    },
    arrowIcon: {
      width: "16px",
      height: "16px",
      marginBottom: "5px",
    },
    borderDiv: {
      margin: "25px 0px",
      borderRight: "1px solid #CFCFCF",
    },
    tableContainer: {
      "@media(max-width: 500px)": {
        width: "100%",
        margin: 0,
        overflow: "auto",
      },
    },
    table: {
      marginRight: 20,
      marginLeft: 20,
      padding: 0,
      // border: "1px solid #737373",
      borderSpacing: 0,
      overflow: "hidden",
      borderRadius: 6,
      " tr:last-child td": {
        borderBottom: "none",
      },
      " td": {
        padding: 20,
        textAlign: "left",
        fontSize: 14,
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        color: "#263238",
        letterSpacing: "0.25px",
      },
      " th": {
        borderBottom: "1px solid #888888",
        padding: 20,
        fontSize: 14,
        fontFamily: "IBM Plex Sans",
        fontWeight: 700,
        color: "#263238",
        textAlign: "left",
        letterSpacing: "0.25px",
        width: "100vw",
      },
    },
    evenRow: {
      " td": {
        backgroundColor: "#eef9f6",
      },
    },
  },
};
