import { nominalTypeHack } from "prop-types";
import globalStyles from "../../../styles";

const styles = {
  root: {
    justifyContent: "space-between",
    alignItems: "center",
    [globalStyles.layout.maxScreenQueries.md]: {
      padding: "20px 0",
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  logoContainer: {
    alignItems: "center",
  },
  logotype: {
    ...globalStyles.typography.h3,
  },
  logoLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    userSelect: "none",
  },
  logo: {
    width: "28px",
    height: "28px",
    borderRadius: 3,
  },
  navigationContainer: {
    alignItems: "center",
    textAlign: "right",
    [globalStyles.layout.maxScreenQueries.md]: {},
  },
  navLinks: {
    textDecoration: "none",
    color: globalStyles.colors.neutral.neutral3,
    "&:hover": {
      textDecoration: "underline",
    },
    "&.active": {
      color: globalStyles.colors.primary.accent,
    },
    "&.active > p": {
      color: globalStyles.colors.primary.accent,
    },
  },
  navLogoContainer: {
    alignSelf: "flex-start",
  },
  askButton: {
    marginRight: 12,
  },
  iconStyles: {
    margin: "0 10px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.75,
    },
  },
  cogIcon: {
    width: 25,
    height: 25,
  },
  homeIcon: {
    width: 25,
    height: 25,
  },
  profileIcon: {
    width: 25,
    height: 25,
  },
  bookmarkIcon: {
    width: 16,
    height: 23,
  },
  loggedInNavContainer: {
    alignItems: "flex-end",
  },
  loggedInNavContainerActions: {
    alignItems: "center",
  },
  profileImage: {
    width: "48px",
    height: "48px",
    borderRadius: "40px",
    cursor: "pointer",
    marginLeft: 30,
  },
  dropdownStyle: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    right: "45px",
    top: "100px",
    background: "#ffffff",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    border: "0.5px solid #f3f3f3",
    width: "240px",
    borderRadius: "4px",
    textAlign: "left",
    span: {
      borderBottom: "1px solid #EEEEEE",
      padding: "10px 0px 10px 26px",
      cursor: "pointer",
    },
    label: {
      marginBottom: "0px",
      cursor: "pointer",
      paddingLeft: "10px",
      fontWeight: 600,
      fontSize: 16,
      color: "#737373",
      fontFamily: "Inter",
    },
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
  },
  remainingQuestionsText: {
    fontWeight: 400,
  },
  gagueContainer: {
    alignItems: "center",
  },
  logout: {
    marginLeft: 10,
  },
  centerHome: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gaugeIcon: {
    width: 25,
  },
  loginButton: {
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
    background: "#356859",
    borderRadius: 8,
    color: "#fff",
    width: 143.5,
    height: 48,
    fontFamily: "Inter",
    fontWeight: 700,
    borderColor: "#356859",
    fontSize: 16,
    "&:hover": {
      background: "#356859",
      color: "#fff",
      boxShadow:
        "0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
    },
    "@media(max-width: 500px)": {
      width: "120px",
    },
  },
  signUpButton: {
    fontFamily: "Inter",
    fontWeight: 700,
    width: 143.5,
    height: 48,
    fontSize: 16,
    letterSpacing: "0.25px",
    border: "none",
    background: "none",
    boxShadow: "none",
    color: "#356859",
    "&:hover": {
      border: "none",
      background: "none",
      boxShadow: "none",
    },
    "@media(max-width: 500px)": {
      width: "120px",
    },
  },
};

export default styles;
