import globalStyles from "../../../styles";

export default {
  root: {
    alignItems: "flex-start",
    borderTop: "1px solid #EEEEEE",
    width: "472px",
    paddingTop: "20px",
    paddingLeft: 0,
    "@media(max-width: 500px)": {
      width: "auto",
    },
  },
};
