/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { jsx } from "@emotion/react";
import _get from "lodash/get";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import dashboardStyles from "../dashboard/styles";

import { Text, View } from "../../components/core";

import ChangePasswordForm from "./updateUserData";
import NotificationSettings from "./notificationSettings";
import MembershipTable from "./membershipTable";
import PaymentMethod from "./cards";
import PaymentHistory from "./paymentHistory";

import styles from "./styles";
import { EditableUserProfile } from "../../components/shared";
import {
  setUserBio,
  setUserAvatar,
  loadUserBySlug,
} from "../../services/state/actionCreators";
import { usePublisherParam } from "../../components/shared/hooks";
import MobileUI from "./mobileSettings";
// eslint-disable-next-line react/prefer-stateless-function
export const SettingsPage = ({ user, editBio, uploadNewAvatar, loadUser }) => {
  // const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const slug = usePublisherParam();
  const [nav, setNav] = useState({
    myAccount: true,
    notifications: false,
    payout: false,
    subscriptions: false,
    membership: false,
    transactions: false,
    payment: false,
  });
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("membership"));
    if (data !== null && data === true) {
      setNav({
        myAccount: false,
        notifications: false,
        payout: false,
        subscriptions: false,
        membership: true,
        transactions: false,
        payment: false,
      });
      sessionStorage.removeItem("membership");
    }
  });

  useEffect(() => {
    if (!user?.id) {
      dispatch(loadUserBySlug(slug));
    }
  }, []);

  return (
    // <View f="none" pb={36} css={styles.page}>
    //   <Text f="1 1 auto" type="h3" centered style={{ marginBottom: "30px" }}>
    //     Settings
    //   </Text>
    //   {/* <EditableUserProfile
    //   name={user.name}
    //   avatarUrl={_get(user.avatar, 'medium')}
    //   bio={user.bio ? user.bio : 'No Bio, Please update'}
    //   mb={42}
    //   onEditBio={(newBio) => { editBio(newBio, user); }}
    //   onEditAvatar={(data) => uploadNewAvatar(data)}
    // /> */}
    //   {user && (
    //     <React.Fragment>
    //       <ChangePasswordForm />
    //       {/* TODO, waiting on Victor */}
    //       <NotificationSettings data={user} />
    //       {user.userRoleId !== 3 && <MembershipTable />}
    //       {user.userRoleId !== 3 && <PaymentMethod />}
    //       {user.userRoleId !== 3 && <PaymentHistory />}
    //     </React.Fragment>
    //   )}
    // </View>
    <div>
      {window.screen.width < 500 ? (
        <MobileUI user={user} />
      ) : (
        <div css={dashboardStyles.root}>
          <div css={dashboardStyles.navDiv}>
            <div
              css={
                nav.myAccount === true
                  ? dashboardStyles.activeLabel
                  : dashboardStyles.inActiveLabel
              }
              onClick={() => {
                setNav({
                  myAccount: true,
                  notifications: false,
                  payout: false,
                  subscriptions: false,
                });
              }}
            >
              My account
            </div>
            <div
              onClick={() => {
                setNav({
                  myAccount: false,
                  notifications: true,
                  payout: false,
                  subscriptions: false,
                });
              }}
              css={
                nav.notifications === true
                  ? dashboardStyles.activeLabel
                  : dashboardStyles.inActiveLabel
              }
            >
              Notifications
            </div>
            {user && user.userRoleId === 3 && (
              <div
                css={
                  nav.payout === true
                    ? dashboardStyles.activeLabel
                    : dashboardStyles.inActiveLabel
                }
                onClick={() => {
                  setNav({
                    myAccount: false,
                    notifications: false,
                    payout: true,
                    subscriptions: false,
                  });
                }}
              >
                Payout
              </div>
            )}
            {user && user.userRoleId === 3 && (
              <div
                css={
                  nav.subscriptions === true
                    ? dashboardStyles.activeLabel
                    : dashboardStyles.inActiveLabel
                }
                onClick={() => {
                  setNav({
                    myAccount: false,
                    notifications: false,
                    payout: false,
                    subscriptions: true,
                  });
                }}
              >
                Subscriptions
              </div>
            )}
            {user && user.userRoleId !== 3 && (
              <div>
                <div
                  css={
                    nav.membership === true
                      ? dashboardStyles.activeLabel
                      : dashboardStyles.inActiveLabel
                  }
                  onClick={() => {
                    setNav({
                      myAccount: false,
                      notifications: false,
                      payout: false,
                      subscriptions: false,
                      membership: true,
                      transactions: false,
                      payment: false,
                    });
                  }}
                >
                  Membership
                </div>
                <div
                  css={
                    nav.transactions === true
                      ? dashboardStyles.activeLabel
                      : dashboardStyles.inActiveLabel
                  }
                  onClick={() => {
                    setNav({
                      myAccount: false,
                      notifications: false,
                      payout: false,
                      subscriptions: false,
                      membership: false,
                      transactions: true,
                      payment: false,
                    });
                  }}
                >
                  Transactions
                </div>
                <div
                  css={
                    nav.payment === true
                      ? dashboardStyles.activeLabel
                      : dashboardStyles.inActiveLabel
                  }
                  onClick={() => {
                    setNav({
                      myAccount: false,
                      notifications: false,
                      payout: false,
                      subscriptions: false,
                      membership: false,
                      transactions: false,
                      payment: true,
                    });
                  }}
                >
                  Payment
                </div>
              </div>
            )}
          </div>
          {user && (
            <React.Fragment>
              {(nav.myAccount === true ||
                nav.payout === true ||
                nav.subscriptions === true) && (
                <ChangePasswordForm
                  accountDetails={nav.payout === true ? false : true}
                  planDetails={nav.subscriptions === true ? true : false}
                />
              )}
              {nav.notifications === true && (
                <NotificationSettings data={user} />
              )}
              {user.userRoleId !== 3 && nav.membership === true && (
                <MembershipTable />
              )}
              {user.userRoleId !== 3 && nav.payment === true && (
                <PaymentMethod />
              )}
              {user.userRoleId !== 3 && nav.transactions === true && (
                <PaymentHistory />
              )}
            </React.Fragment>
          )}
          {/* {this.state.Transactions && <TransactionsTable />}
      {this.state.Members && <MembersTable />}
      {this.state.Earnings && <EarningsTable />} */}
        </div>
      )}
    </div>
  );
};

SettingsPage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    // twitterTag,
    numTwitterFollowers: PropTypes.number,
    // featuredVideo,
    bio: PropTypes.string,
    id: PropTypes.number,
    token: PropTypes.string,
    slug: PropTypes.string,
    activeSubscribers: PropTypes.shape({
      numViewers: PropTypes.number,
      numAskers: PropTypes.number,
    }),
    products: PropTypes.shape({
      ask: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
        cost: PropTypes.number.isRequired,
      }).isRequired,
      view: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
        cost: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  editBio: PropTypes.func.isRequired,
  uploadNewAvatar: PropTypes.func.isRequired,
};

// SettingsPage.defaultProps = {
//   user: undefined,
// };

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { slug: userSlug },
    },
  } = props;
  const userIdForSlug = _get(state, `users.slugs.${userSlug}`);
  let user = _get(state, `users.users.${userIdForSlug}`, null);
  if (!user) {
    return {
      user: null,
    };
  }

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editBio: (newBio, userData) => {
    dispatch(setUserBio(newBio, userData));
  },
  uploadNewAvatar: (data) => {
    dispatch(setUserAvatar(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
// export default SettingsPage;
