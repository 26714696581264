/** @jsx jsx */
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';

import { flexMap, marginMap, paddingMap } from './constants';
import styles from './styles';

/**
 * This file contains utils used across all shared components
*/

export const flexStyles = ({ f, ...rest }, _styles) => {
  const output = { ..._styles };
  if (f === 'none') {
    output.display = 'block';
    delete output.flex;
  } else if (f) {
    output.flex = f;
  }
  Object.keys(flexMap).forEach((item) => {
    output[flexMap[item]] = rest[item];
  });

  return output;
};

export const marginStyles = ({ mx, my, ...rest }, _styles) => {
  const output = { ..._styles };
  if (my !== undefined) {
    output.marginTop = my;
    output.marginBottom = my;
  }

  if (mx !== undefined) {
    output.marginRight = mx;
    output.marginLeft = mx;
  }

  Object.keys(marginMap).forEach((item) => {
    if (rest[item]) {
      output[marginMap[item]] = rest[item];
    }
  });
  return output;
};

export const paddingStyles = ({ px, py, ...rest }, _styles) => {
  const output = { ..._styles };
  if (py !== undefined) {
    output.paddingTop = py;
    output.paddingBottom = py;
  }

  if (px !== undefined) {
    output.paddingRight = px;
    output.paddingLeft = px;
  }

  Object.keys(paddingMap).forEach((item) => {
    if (rest[item]) {
      output[paddingMap[item]] = rest[item];
    }
  });
  return output;
};

/**
 * acceptShortcutProps
 * Peels off the flex, margin, and padding props,
 * sets the styles on them, and returns the rest of the props.
*/
export const acceptShortcutProps = ({ css, ...rest }, defaultStyles = styles) => {
  let finalStyles = { ...defaultStyles, ...css };
  // update after mapping flex styles.
  finalStyles = flexStyles(rest, finalStyles);
  // update after mapping padding
  finalStyles = marginStyles(rest, finalStyles);
  // update after mapping padding
  finalStyles = paddingStyles(rest, finalStyles);
  // Remove the other flex props.
  const otherProps = _omit(
    rest,
    'f',
    Object.keys(flexMap),
    Object.keys(marginMap),
    Object.keys(paddingMap),
  );
  return [finalStyles, otherProps];
};

const validMarginPaddingProps = () => PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

/**
 * Default PropTypes for anything adopting acceptShortcutProps
*/
export const shortcutPropTypes = {
  /* Set as short hand for 'flex' (see: https://developer.mozilla.org/en-US/docs/Web/CSS/flex), or "none", in which case this view will not flex. */
  f: PropTypes.string,
  /* If set, defines flex-grow */
  fg: PropTypes.number,
  /* If set, defines flex-shrink */
  fs: PropTypes.number,
  /* If set, defines flex-basis */
  fb: PropTypes.string,
  /* Flex Direction */
  fd: PropTypes.oneOf([undefined, 'row', 'row-reverse', 'column', 'column-reverse']),
  /* Margin, all sides */
  m: validMarginPaddingProps(),
  /* Margin, x-axis */
  mx: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
  /* Margin, y-axis */
  my: PropTypes.number,
  /* Margin, right */
  mr: PropTypes.number,
  /* Margin, left */
  ml: PropTypes.number,
  /* Margin, top */
  mt: PropTypes.number,
  /* Margin, bottom */
  mb: PropTypes.number,
  /* Padding, all sides */
  p: validMarginPaddingProps(),
  /* Padding, x-axis */
  px: PropTypes.number,
  /* Padding, y-axis */
  py: PropTypes.number,
  /* Padding, right */
  pr: PropTypes.number,
  /* Padding, left */
  pl: PropTypes.number,
  /* Padding, top */
  pt: PropTypes.number,
  /* Padding, bottom */
  pb: PropTypes.number,
};

// Since above proptypes are dynamically evaulated, storybook skips them.
// see https://github.com/storybookjs/storybook/issues/10536
export const storybookArgTypes = {
  f: {
    name: 'flex',
    type: { name: 'string', required: false },
    defaultValue: '1 1 auto',
    description: 'flexbox definition (grow, shrink, basis)',
  },
  fg: {
    name: 'fg',
    type: { name: 'string', required: false },
    defaultValue: undefined,
    description: 'Flex Grow',
  },
  fs: {
    name: 'fs',
    type: { name: 'string', required: false },
    defaultValue: undefined,
    description: 'Flex shrink',
  },
  fb: {
    name: 'fb',
    type: { name: 'string', required: false },
    defaultValue: undefined,
    description: 'Flex Basis',
  },
  fd: {
    name: 'fd',
    type: { name: 'string', required: false },
    defaultValue: undefined,
    description: 'Flex Direction',
  },
  m: {
    name: 'm',
    type: { name: 'string', required: false },
    defaultValue: undefined,
    description: 'Margin, all sides',
  },
  mx: {
    name: 'mx',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Margin, x-axis',
  },
  my: {
    name: 'my',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Margin, y-axis',
  },
  mr: {
    name: 'mr',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Margin, right',
  },
  ml: {
    name: 'ml',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Margin, left',
  },
  mt: {
    name: 'mt',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Margin, top',
  },
  mb: {
    name: 'mb',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Margin, bottom',
  },
  p: {
    name: 'p',
    type: { name: 'string', required: false },
    defaultValue: undefined,
    description: 'Padding, all sides',
  },
  px: {
    name: 'px',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Padding, x-axis',
  },
  py: {
    name: 'py',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Padding, y-axis',
  },
  pr: {
    name: 'pr',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Padding, right',
  },
  pl: {
    name: 'pl',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Padding, left',
  },
  pt: {
    name: 'pt',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Padding, top',
  },
  pb: {
    name: 'pb',
    type: { name: 'number', required: false },
    defaultValue: undefined,
    description: 'Padding, bottom',
  },
};

export const defaultShortcutProps = {
  f: '0 0 auto',
  fg: undefined,
  fs: undefined,
  fb: undefined,
  fd: undefined,
  m: undefined,
  mx: undefined,
  my: undefined,
  mr: undefined,
  ml: undefined,
  mt: undefined,
  mb: undefined,
  p: undefined,
  px: undefined,
  py: undefined,
  pr: undefined,
  pl: undefined,
  pt: undefined,
  pb: undefined,
};

export default acceptShortcutProps;
