import PropTypes from 'prop-types';

export const cardsPropTypes = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.string,
  brand: PropTypes.string,
  expirationDate: PropTypes.string,
  last4: PropTypes.string,
  holderName: PropTypes.string,
}));

export default {};
