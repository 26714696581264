import _get from 'lodash/get';
import UserService from '../../api/user';
import * as USERS_ACTIONS from './actions';
import { redirectUser } from '../../utils/redirect-user';
import { USER_ROLE } from '../../../constants';

export const loadUserSucceeded = (userData) => (dispatch) => {
  dispatch({
    type: USERS_ACTIONS.LOAD_USER_DATA_SUCCESS,
    payload: {
      id: userData.id,
      slug: userData.slug,
      user: userData,
    },
  });
};

export const loadUserFailed = (id, failureMessage) => ({
  type: USERS_ACTIONS.LOAD_USER_DATA_FAILED,
  payload: {
    id,
    error: failureMessage,
  },
});

const attemptToLoadAllPublihserData = async (id) => {
  // Combine with the publisher data, if is a publisher.
  // TODO: We do not know if the other user is a publisher or not, but
  // we can try to load their publisher data either way.
  let pubData = {};
  let subData = {};
  try {
    pubData = await UserService.fetchPublisherById(id);
  } catch (error) {
    // nothing.
  }
  try {
    subData = await UserService.fetchPublisherStats(id);
  } catch (error) {
    // nothing
  }
  return {
    ...pubData,
    ...subData,
  };
};

export const loadUserById = (id) => async (dispatch) => {
  // need to load publisher data if it's a publisher.
  if (!UserService.userToken) {
    if (!redirectUser('')) {
      UserService.logout();
    }
    return;
  }
  dispatch({
    type: USERS_ACTIONS.LOAD_USER_DATA,
    payload: {
      id,
      pendingRequest: true,
    },
  });
  let userData = {};
  try {
    userData = await UserService.fetchUserData(id);
  } catch (error) {
    dispatch(loadUserFailed(id, error));
  }
  let extraData = {};
  // If we don't know the role we'll go ahead and try as a publisher,
  // so we default the role to publisher.
  if (
    USER_ROLE.PUBLISHER === _get(userData, 'userRoleId', USER_ROLE.PUBLISHER)
  ) {
    extraData = await attemptToLoadAllPublihserData(id);
  }
  const fullUserData = {
    ...userData,
    ...extraData,
  };
  dispatch(loadUserSucceeded(fullUserData));
};

export const loadCurrentUserDetails = () => async (dispatch, getState) => {
  const state = getState();
  const userId = _get(state, 'user.userId');
  if (userId) {
    dispatch(loadUserById(userId));
  }
};

export const loadUserBySlug = (slug) => async (dispatch) => {
  // we know this is a publisher.
  dispatch({
    type: USERS_ACTIONS.LOAD_USER_DATA,
    payload: {
      slug,
      pendingRequest: true,
    },
  });

  let error;

  let result = {};
  try {
    const publisherData = await UserService.fetchPublisherBySlug(slug);
    result = { ...publisherData };
  } catch (_error) {
    error = _error;
    dispatch(loadUserFailed(slug, _error));
    return;
  }

  const userId = _get(result, 'id', 0);
  try {
    const user = await UserService.fetchUserData(userId);
    result = {
      ...result,
      ...user,
    };
  } catch (_error) {
    error = _error;
  }
  try {
    const subData = await UserService.fetchPublisherStats(userId);
    result = {
      ...result,
      ...subData,
    };
  } catch (_error) {
    error = _error;
  }

  if (error && Object.keys(result).length === 0) {
    dispatch(loadUserFailed(slug, error));
    return;
  }
  dispatch(loadUserSucceeded(result));
};

export const setUserBioSucceeded = (userData) => (dispatch) => {
  dispatch({
    type: USERS_ACTIONS.SET_USER_BIO_SUCCESS,
    payload: {
      ...userData,
    },
  });
};

export const setUserBioFailed = (error) => (dispatch) => {
  dispatch({
    type: USERS_ACTIONS.SET_USER_BIO_FAILED,
    payload: {
      error,
    },
  });
};

export const setUserBio = (bio, userData) => async (dispatch) => {
  // dispatch({
  //   type: USERS_ACTIONS.SET_USER_BIO_ATTEMPT,
  //   payload: {
  //     bio,
  //   },
  // });
  const data = {
    bio,
  };

  let response;
  try {
    response = await UserService.setPublisherProfile(data, {});
    dispatch(setUserBioSucceeded({ ...userData, ...response, ...{ bio } }));
  } catch (error) {
    dispatch(setUserBioFailed(error));
  }
};
