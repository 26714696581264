/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import _get from "lodash/get";

import UserService from "../../services/api/user";
import { View } from "../../components/core";
import { UserProfile } from "../../components/shared";

import LoginForm from "./LoginForm";
import styles from "./styles";

export class LoginPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        slug: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    match: {
      params: {
        slug: PropTypes.string,
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      username: undefined,
      avatarUrl: undefined,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { slug: userSlug },
      },
    } = this.props;
    if (!userSlug) {
      return;
    }
    UserService.fetchPublisherBySlug(userSlug).then((data) => {
      this.setState({
        username: _get(data, "name", "unknown name"),
        avatarUrl: _get(data, "avatar.medium", ""),
      });
    });
  }

  render() {
    const { username, avatarUrl } = this.state;

    return (
      <View fd="column" css={styles.root} f="none">
        {/* <View css={styles.pageContent} m="0 auto" fd="column" pt={42} mb={28}>
          {username && avatarUrl ? (
            <UserProfile name={username} avatarUrl={avatarUrl} mb={42} />
          ) : (
            <Fragment />
          )}
        </View> */}
        <View f="none">
          <LoginForm
            data={this.props.location.state && this.props.location.state}
          />
        </View>
      </View>
    );
  }
}

export default withRouter(LoginPage);
