export default {
  removeBtn: {
    position: 'absolute',
    padding: '9px 11px',
    right: '-7px',
    top: '-5px',
    fontSize: '16px',
  },
  root: {
    position: 'relative',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '31%',
    marginRight: '2%',
  },
  image: {
    width: '100%',
  },
};
