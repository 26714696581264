/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import styles from './styles';
import globalStyles from '../../../styles';

export const Loading = ({ color }) => {
  const finalStyles = styles(color);
  return (
    <div css={finalStyles.ldsEllipsis} data-testid="loading">
      <div css={finalStyles.child} />
      <div css={finalStyles.child} />
      <div css={finalStyles.child} />
      <div css={finalStyles.child} />
    </div>
  );
};

Loading.propTypes = {
  color: PropTypes.string,
};

Loading.defaultProps = {
  color: globalStyles.colors.primary.secondary,
};

export default Loading;
