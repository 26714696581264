export const styles = {
  rootDiv: {
    width: "85vw",
    paddingLeft: "15vw",
    textAlign: "justify",
    marginTop: "25px",
    label: {
      fontFamily: "IBM Plex Sans",
      fontSize: 18,
      color: "#535353",
      letterSpacing: "0.25px",
      fontWeight: 400,
    },
    h1: {
      fontFamily: "IBM Plex Sans",
      color: "#000000",
      fontSize: "2.2rem",
      fontWeight: 700,
      color: "#356859",
      "@media(max-width: 500px)": {
        fontSize: "2rem",
      },
    },
    h4: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 600,
      color: "#356859",
    },
    h5: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 600,
      color: "#356859",
    },
    b: {
      fontWeight: 600,
      color: "#356859",
    },
    li: {
      fontFamily: "IBM Plex Sans",
      fontSize: 18,
      color: "#535353",
      letterSpacing: "0.25px",
      fontWeight: 400,
      lineHeight: "30px",
    },
  },
};
export default styles;
