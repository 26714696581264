export default {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    img: { height: "51px", width: "51px" },
    label: {
      color: "#000000",
      fontFamily: "Source Serif Pro",
      fontWeight: 600,
      fontSize: 34,
      lineHeight: "42px",
    },
  },
  emptyText: {
    fontFamily: "IBM Plex Sans",
    color: "#535353",
    lineHeight: "32px",
    fontSize: 20,
  },
  borderDiv: {
    width: "45%",
    margin: "25px auto",
    borderBottom: "1px solid #B2B2B2",
    "@media(max-width: 500px)": {
      width: "350px",
    },
  },
};
