/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState } from "react";
import PropTypes from "prop-types";
import logo from "../loginPage/images/logo.svg";
import { View, Text, GridSystem } from "../../components/core";
import { PricingCard } from "../../components/shared";
import { withRouter } from "react-router-dom";
import SignupForm from "./SignupForm";
import styles from "./styles";
import {
  PRODUCT_TYPE_TO_SUB_MAPPING,
  PRODUCT_TYPE_TO_PLAN_MAPPING,
  PLANS,
} from "./constants";
import {
  PRODUCT_DESCRIPTION_BY_KEY,
  TYPE_TO_DESC_MAPPING,
} from "../../constants";

export const SignupPage = ({ subscriptionData }) => {
  const { products, activeSubscribers } = subscriptionData;
  let productId = window.location.search.split("=")[1];
  productId = productId ? parseInt(productId, 10) : null;
  const selectedParamPlan =
    productId && productId === 2 ? PLANS.ASK_AND_VIEW : PLANS.VIEW_ONLY;
  const [selectedPlan, setSelectedPlan] = useState(
    productId
      ? [selectedParamPlan, productId]
      : [PLANS.VIEW_ONLY, products.view.id]
  );
  const [test, setTest] = useState(products ? products.view.id : "");
  return (
    <div>
      {/* <div css={styles.logoDiv}>
        <img src={logo} />
      </div> */}
      <View fd="column" css={styles.root} f="none">
        <View css={styles.pageContent} m="0 auto" fd="column" pt={42} mb={28}>
          <Text css={styles.title} type="h3" centered>
            Join GuideReply
          </Text>
          {/* <GridSystem.Container css={styles.pricingContainer}>
          <GridSystem.Row justify="center">
            {Object.entries(products).map(([key, product]) => {
              const numSubscribed =
                activeSubscribers[PRODUCT_TYPE_TO_SUB_MAPPING[key]];
              const numAvailable = product.maxActiveSubscriptions;
              let availabilityString = `${
                numAvailable - numSubscribed
              }/${numAvailable} SPOTS LEFT!`;
              if (parseInt(numSubscribed, 10) >= parseInt(numAvailable, 10)) {
                availabilityString = "Sold out";
              }
              const isSpaceLimited = !!product.maxActiveSubscriptions;
              return (
                <GridSystem.Col sm={6} key={product.id}>
                  <PricingCard
                    title={PRODUCT_DESCRIPTION_BY_KEY[key]}
                    view={
                      PRODUCT_DESCRIPTION_BY_KEY[key] === "View" ? true : false
                    }
                    costLine={`$${product.cost}`}
                    availability={
                      isSpaceLimited ? availabilityString : undefined
                    }
                    onClick={() => {
                      setTest(product.id);
                      setSelectedPlan([
                        PRODUCT_TYPE_TO_PLAN_MAPPING[key],
                        product.id,
                      ]);
                    }}
                    selectable
                    selected={
                      selectedPlan[0] === PRODUCT_TYPE_TO_PLAN_MAPPING[key]
                    }
                    css={styles.pricingCard}
                  >
                    {TYPE_TO_DESC_MAPPING[key]}
                  </PricingCard>
                </GridSystem.Col>
              );
            })}
          </GridSystem.Row>
        </GridSystem.Container> */}
        </View>
        <View>
          <SignupForm
            subscriptionData={subscriptionData}
            selectedPlan={test}
            selectedId={selectedPlan[1]}
          />
        </View>
      </View>
    </div>
  );
};

SignupPage.propTypes = {
  subscriptionData: PropTypes.shape({
    activeSubscribers: PropTypes.shape({
      numAskers: PropTypes.number.isRequired,
      numViewers: PropTypes.number.isRequired,
    }).isRequired,
    products: PropTypes.shape({
      ask: PropTypes.shape({
        id: PropTypes.number.isRequired,
        cost: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
      }).isRequired,
      view: PropTypes.shape({
        id: PropTypes.number.isRequired,
        cost: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

SignupPage.defaultProps = {};

export default withRouter(SignupPage);
