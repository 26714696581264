export default {
  root: {},
  questionTextArea: {
    display: "block",
    width: "100%",
    borderRadius: "8px",
    marginBottom: "40px",
    minHeight: "120px",
  },
  replyButtonContainer: {
    justifyContent: "flex-end",
  },
  replyButton: {
    background: "#356859",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  cancelButton: {
    background: "none",
    color: "#356859",
    borderColor: "none",
    border: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
};
