/** @jsx jsx */
import { jsx } from "@emotion/react";
import { css } from "styled-components";
import { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import { View, Input, Button, Text } from "../../components/core";
import globalStyles from "../../styles";
import { userMutationSchema } from "../../services/schemas";
import { ServerSideErrors, OnLoginRedirect } from "../../components/shared";
import { login as loginAction } from "../../services/state/actionCreators";
import styles from "./styles";
import { PAYMENT_OPTIONS } from "../../constants";
import NotificationService from "../../components/shared/Notification";
import UserClient from "../../services/api/user/index";

const StyledCCInput = forwardRef((props, ref) => (
  <input {...props} css={styles.ccInput} ref={ref} />
));

const SignUpForm = ({
  login,
  user: { error: serverSideError, ...user },
  selectedPlan,
  selectedId,
  subscriptionData,
}) => {
  const [pendingRequest, setpendingRequest] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [apierror, setApierror] = useState(false);
  const [planId, setPlanId] = useState("");
  const [btcPaymentUrl, setbtcPaymentUrl] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    holderName: "",
    number: "",
    expirationDate: "",
    cvv: "",
  });
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const updateField = (key, value) => {
    setFormData({
      ...formData,
      ...{ [key]: value },
    });
    setFormErrors([]);
    setApierror(false);
  };

  useEffect(() => {
    const defaultPlan = JSON.parse(localStorage.getItem("view"));
    if (defaultPlan !== null && defaultPlan === true) {
      setPlanId({
        value: subscriptionData.products.view.id,
        label: `View -$${subscriptionData.products.view.cost} a month`,
      });
    } else if (defaultPlan !== null && defaultPlan === false) {
      setPlanId({
        value: subscriptionData.products.ask.id,
        label: `Ask -$${subscriptionData.products.ask.cost} a month`,
      });
    }
  }, []);
  const onSubscribeClicked = async () => {
    const ccParts = formData.expirationDate.split("/");
    if (ccParts.length !== 2) {
      NotificationService.error("CreditCard is required");
      return;
    } else if (
      formData.name.match(/[A-Z\s/[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]+/)
    ) {
      NotificationService.error(
        "Empty space, capital letter, or any other symbol than these: “.” and “_” are not allowed for username"
      );
    } else {
      setApierror(true);
      const [expyMonth, expyYear] = ccParts;
      const formatExpDate = `20${expyYear.trim()}-${expyMonth.trim()}`; // 02 / 23
      const formattedData = {
        name: formData.name, // yup.string().required(),
        email: formData.email, // yup.string().email().required(),
        password: formData.password, // yup.string().required(),
        // affiliateId: selectedPlan, // yup.number()
        // marketingSource: ,// yup.string(),
        productId: planId.value, // TODO: Not sure where selectedPlan goes, so it's here for now,
        paymentMethodId: PAYMENT_OPTIONS.CREDITCARD,
        creditCard: {
          holderName: "formData.holderName",
          number: formData.number.split(" ").join(""),
          expirationDate: formatExpDate,
          cvc: formData.cvv,
        }, // creditCardMustationSchema.required(),
        // couponName: ,// yup.string(),
      };
      // try {
      //   userMutationSchema.validateSync(formattedData);
      // } catch (error) {
      //   setFormErrors(_get(error, "errors", ["Invalid schema"]));
      //   return;
      // }
      setpendingRequest(true);
      setFormErrors([]); // No more errors
      const apiData = await UserClient.register_User(formattedData);
      if (apiData) {
        const getUrl = await UserClient.get_invoice_status(
          apiData.data.paymentKey
        );
        if (getUrl.data.redirectUrl) {
          setbtcPaymentUrl(
            getUrl.data.redirectUrl ? getUrl.data.redirectUrl : null
          );
        }
        const intervalId = setInterval(async () => {
          const invoiceStatus = await UserClient.get_invoice_status(
            apiData.data.paymentKey
          );
          if (invoiceStatus && invoiceStatus.data.paymentStatusId === 5) {
            login(formData.email, formData.password);
            setbtcPaymentUrl(null);
            setpendingRequest(false);
            clearInterval(intervalId);
          } else if (
            invoiceStatus &&
            invoiceStatus.data.paymentStatusId === 4
          ) {
            clearInterval(intervalId);
            setpendingRequest(false);
            NotificationService.error(invoiceStatus.data.error);
            setbtcPaymentUrl(null);
          }
        }, 3000);
      }
      // signUp(formattedData);
    }
  };
  const options = [
    {
      value: subscriptionData.products.view.id,
      label: `View - $${subscriptionData.products.view.cost}/month`,
    },
    {
      value: subscriptionData.products.ask.id,
      label: `Ask - $${subscriptionData.products.ask.cost}/month`,
    },
  ];
  const selectedOption = (e) => {
    setPlanId(e);
  };
  return (
    <View m="0 auto 67px" fd="column" css={styles.inputContainer}>
      <OnLoginRedirect user={user} firstAttempt={true} />
      <Text css={styles.title1}>
        Username <b style={{ color: "red" }}>*</b>
      </Text>
      <Input
        disabled={pendingRequest ? "disabled" : undefined}
        value={formData.name}
        onChange={(e) => updateField("name", e.target.value)}
      >
        Username
      </Input>
      <Text css={styles.title1}>
        Email <b style={{ color: "red" }}>*</b>
      </Text>
      <Input
        disabled={pendingRequest ? "disabled" : undefined}
        value={formData.email}
        onChange={(e) => updateField("email", e.target.value)}
      >
        Email
      </Input>
      <Text css={styles.title1}>
        Password <b style={{ color: "red" }}>*</b>
      </Text>
      <Input
        disabled={pendingRequest ? "disabled" : undefined}
        value={formData.password}
        type="password"
        onChange={(e) => updateField("password", e.target.value)}
      >
        Password
      </Input>
      <div style={{ marginBottom: "20px" }}>
        <Text css={styles.title1}>
          Choose a plan <b style={{ color: "red" }}>*</b>
        </Text>
        <Dropdown
          css={styles.paymentInput}
          options={options}
          onChange={selectedOption}
          value={planId}
          // placeholder="Select an option"
        />
      </div>
      <Text css={styles.title1}>
        Card details <b style={{ color: "red" }}>*</b>
      </Text>
      {/* <Input
        disabled={pendingRequest ? 'disabled' : undefined}
        value={formData.holderName}
        onChange={(e) => updateField('holderName', e.target.value)}
      >
        Name on Card
      </Input> */}
      <input type="hidden" name="productId" value={selectedId} />
      <PaymentInputsWrapper
        css={styles.paymentInput}
        {...wrapperProps}
        styles={{
          errorText: {
            base: css`
              ${globalStyles.typography.body};
              color: ${globalStyles.colors.primary.accent};
              text-align: "left";
            `,
          },
        }}
      >
        <svg {...getCardImageProps({ images })} />
        <StyledCCInput
          {...getCardNumberProps({
            onChange: (e) => updateField("number", e.target.value),
          })}
          value={formData.number}
        />
        <StyledCCInput
          {...getExpiryDateProps({
            onChange: (e) => updateField("expirationDate", e.target.value),
          })}
          value={formData.expirationDate}
        />
        <StyledCCInput
          {...getCVCProps({
            onChange: (e) => updateField("cvv", e.target.value),
          })}
          value={formData.cvv}
        />
      </PaymentInputsWrapper>
      <div css={styles.borderDiv}></div>
      <div css={styles.dueDiv}>
        <Text css={styles.title1}>Total due:</Text>
        <Text style={{ marginLeft: "43%" }} css={styles.title1}>
          {" "}
          {planId !== " " ? planId.label : ""}
        </Text>
      </div>
      {btcPaymentUrl !== null && (
        <div>
          <iframe
            style={{
              height: 405,
              width: "100%",
              border: "none",
              marginTop: 20,
            }}
            src={btcPaymentUrl}
            title="paymentUrl"
          ></iframe>
        </div>
      )}
      <Button
        css={styles.ctaButton}
        disabled={pendingRequest}
        onClick={onSubscribeClicked}
      >
        Subscribe
      </Button>
      {/* {formErrors.length
        ? formErrors.map((errorMessage) => (
            <Text
              key={errorMessage}
              css={styles.errorMessage}
              mt={22}
              mx="auto"
            >
              {errorMessage}
            </Text>
          ))
        : null} */}
      {/* {serverSideError && apierror ? (
        <ServerSideErrors errors={serverSideError} />
      ) : null} */}
      {/* <Text css={styles.otherOptions} pt={50}>
        By registering you agree to out Terms of Use, our Privacy Policy and our
        Information collection notice.
      </Text> */}
    </View>
  );
};

SignUpForm.propTypes = {
  login: PropTypes.func.isRequired,
  user: PropTypes.shape({
    token: PropTypes.string,
    pendingRequest: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    id: PropTypes.number,
  }),
  selectedPlan: PropTypes.number.isRequired,
  selectedId: PropTypes.number.isRequired,
};

SignUpForm.defaultProps = {
  user: {
    pendingRequest: false,
    error: null,
    id: null,
  },
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => {
    dispatch(loginAction({ email, password }));
  },
});

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpForm);

export default LoginForm;
