/** @jsx jsx */
import { jsx } from "@emotion/react";
import { PureComponent } from "react";
import _get from "lodash/get";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { View, Text, Input, Button } from "../../components/core";
import UserService from "../../services/api/user";
import { login as loginAction } from "../../services/state/actionCreators";
import { ServerSideErrors, OnLoginRedirect } from "../../components/shared";
import NotificationService from "../../components/shared/Notification/index";
import signUpstyles from "../signupPage/styles";
import logo from "../loginPage/images/logo.svg";

import styles from "../salesPage/styles";

const Doodad = () => <View css={styles.doodad} />;

class CreatorSignup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      isSubmitting: false,
      success: false,
    };
  }

  submitForm = async () => {
    const { name, email, password } = this.state;

    const formData = {
      name: name,
      email: email,
      password: password,
    };
    if (name.match(/[A-Z\s/[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]+/)) {
      this.setState({
        error:
          "Empty space, capital letter, or any other symbol than these: “.” and “_” are not allowed for username",
      });
    } else if (password !== this.state.confirmPassword) {
      this.setState({
        error: "Passwords does not match",
      });
    } else {
      UserService.creatorSignUp(formData).then((data) => {
        if (data && data.status === 200) {
          NotificationService.success("Registered Successfully");
          this.props.history.push({
            pathname: `${data.data.slug}/login`,
            state: {
              email: formData.email,
              password: formData.password,
            },
          });
          this.props.login(formData.email, formData.password);
        }
      });
    }

    // this.setState({
    //   error: null,
    //   name: '',
    //   email: '',
    //   password: '',
    //   confirmPassword: '',
    //   isSubmitting: false,
    //   success: true,
    // });
  };

  updateField(which, value) {
    this.setState({ [which]: value, error: null });
  }

  render() {
    const { name, email, ig, yt, twitter, isSubmitting, error, success } =
      this.state;

    if (success) {
      return (
        <View f="none" mb={64} mx="auto" css={styles.thankYou} py={48}>
          <Text type="h3" centered mb={48}>
            Thank you! :)
          </Text>
          <Text type="body" centered>
            Our team will be in touch soon.
          </Text>
        </View>
      );
    }

    return (
      <div>
        {/* <div css={signUpstyles.logoDiv}>
          <img src={logo} />
        </div> */}
        <View fd="column" css={signUpstyles.root}>
          <OnLoginRedirect user={this.props.user} />
          <Text
            style={{ margin: "40px 0px" }}
            css={signUpstyles.title}
            type="h3"
            centered
          >
            Join GuideReply
          </Text>
          <View
            m="0px 125px 40px 125px"
            fd="column"
            css={styles.inputContainer}
          >
            <Text css={signUpstyles.title1}>
              Username <b style={{ color: "red" }}>*</b>
            </Text>
            <Input
              disabled={isSubmitting ? "disabled" : undefined}
              value={name}
              onChange={(e) => this.updateField("name", e.target.value)}
            >
              Username
            </Input>
            <Text css={signUpstyles.title1}>
              Email <b style={{ color: "red" }}>*</b>
            </Text>
            <Input
              disabled={isSubmitting ? "disabled" : undefined}
              value={email}
              onChange={(e) => this.updateField("email", e.target.value)}
            >
              Email
            </Input>
            <Text css={signUpstyles.title1}>
              Password <b style={{ color: "red" }}>*</b>
            </Text>
            <Input
              disabled={isSubmitting ? "disabled" : undefined}
              value={ig}
              type="password"
              onChange={(e) => this.updateField("password", e.target.value)}
            >
              Password
            </Input>
            <Text css={signUpstyles.title1}>
              Confirm Password <b style={{ color: "red" }}>*</b>
            </Text>
            <Input
              disabled={isSubmitting ? "disabled" : undefined}
              value={twitter}
              type="password"
              onChange={(e) =>
                this.updateField("confirmPassword", e.target.value)
              }
            >
              Confirm Password
            </Input>
            <Button
              css={styles.ctaButton}
              disabled={isSubmitting}
              onClick={this.submitForm}
            >
              Submit
            </Button>
            <Text
              style={{ maxWidth: "250px", textAlign: "justify" }}
              color="error"
            >
              {error}
            </Text>
          </View>
          {/* <Doodad /> */}
        </View>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => {
    dispatch(loginAction({ email, password }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatorSignup)
);
