import { defaultStyles } from "../../core";

export default {
  root: {
    maxWidth: 300,
  },
  icon: {
    width: 67,
    height: 67,
  },
  iconWrapper: {
    background: "#D8F0E9",
    borderColor: "#D8F0E9",
    borderRadius: "50px",
    borderStyle: "solid",
    borderWidth: 1,
    width: 100,
    height: 100,
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
  },
  text: {
    textAlign: "center",
  },
};
