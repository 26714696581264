import * as USER_ACTIONS from "../actions";
import UserClient from "../../../api/user";
import { SUBSCRIPTION_STATUS } from "../../../../constants";

const loadSubscriptionsSuccess = (subscriptions) => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.LOAD_SUBSCRIPTIONS_SUCCESS,
    payload: {
      subscriptions,
      pendingRequest: false,
    },
  });
};

const loadSubscriptionsFailure = (error) => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.LOAD_SUBSCRIPTIONS_FAILED,
    payload: {
      error,
    },
  });
};

export const loadSubscriptions = () => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.ATTEMPT_LOAD_SUBSCRIPTIONS,
    payload: {
      pendingRequest: true,
    },
  });

  let response;
  try {
    response = await UserClient.fetchSubscription();
    dispatch(loadSubscriptionsSuccess(response));
    let latestSubscription =
      response.subscribedPublishers[0].products.view?.latestSubscription;
    if (
      latestSubscription?.subscriptionStatusId === SUBSCRIPTION_STATUS.ACTIVE
    ) {
      let latestPrice = await UserClient.fetchLatestAskPriceForCurrentUser(
        latestSubscription.id
      );
      response.subscribedPublishers[0].products.ask.cost = latestPrice;
    }
  } catch (error) {
    dispatch(loadSubscriptionsFailure(error));
  }
};

export default {};
