/** @jsx jsx */
import { jsx } from "@emotion/react";
import _get from "lodash/get";
import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./styles";
import { View, Text, Loading } from "../../components/core";
import { Question } from "../../components/shared";
import {
  postReply as postReplyAction,
  getQuestionsByPublisher as getQuestionsByPublisherAction,
} from "../../services/state/actionCreators";
import { questionPropType } from "../../services/schemas/commonProps";
import { sortQuestionsByupdatedDate } from "../../services/utils/date-utils";
import { getPostsByPublisher } from "../../services/state/posts/actionCreators";
import QuestionService from "../../services/api/question";
import user from "../../services/api/user";

export class Questions extends Component {
  static propTypes = {
    publisherId: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    currentUserId: PropTypes.object.isRequired,
    // From Redux
    postReply: PropTypes.func.isRequired,
    questions: PropTypes.arrayOf(questionPropType).isRequired,
    loading: PropTypes.bool,
    onOwnFeed: PropTypes.bool,
    noQuestions: PropTypes.bool,
    loadQuestions: PropTypes.func.isRequired,
    loadPosts: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: true,
    onOwnFeed: false,
    noQuestions: false,
  };
  constructor(props) {
    super(props);
    this.state = { allData: "" };
  }

  fetchAll = async (publisherId) => {
    var allData = await QuestionService.fetchPostsQuestions(publisherId);
    this.setState({ allData: allData });
  };

  componentDidMount() {
    const { loadQuestions, loadPosts, publisherId, currentUserId } = this.props;
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData && userData.userRoleId === 2) {
      this.fetchAll(publisherId);
    }
    setTimeout(() => {
      if (currentUserId) {
        loadPosts(publisherId);
      }
      loadQuestions(publisherId);
    }, 0);
  }

  render() {
    const { text, noQuestions, loading, questions, postReply, onOwnFeed } =
      this.props;
    let filterdata = [];
    let allFilteredData = [];
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (text && userData && userData.userRoleId === 2) {
      allFilteredData =
        this.state.allData &&
        this.state.allData.map((data) => {
          if (data && data.contentAvailabilityId === 1) {
            return null;
          }
          return data;
        });
    }
    if (text && userData.userRoleId !== 2) {
      filterdata = questions.map((data) => {
        if (data && data.contentAvailabilityId === 1) {
          return null;
        }
        return data;
      });
    }
    filterdata.filter((data) => data !== null);
    allFilteredData.length>0 && allFilteredData.filter((data) => data !== null);
    if (noQuestions) {
      return (
        <View f="none" my={64} data-testid="questions-empty">
          <Text css={styles.emptyText} centered>
            Hm, nothing to see yet...
            <br />
            {sessionStorage.getItem("user") === null
              ? "Questions from fans will display here in the future!"
              : "Answers and posts from the expert will display here!"}
          </Text>
        </View>
      );
    }

    if (loading) {
      return (
        <View f="none" my={64} data-testid="questions">
          <Loading />
        </View>
      );
    }

    return (
      <View f="none" my={64} data-testid="questions-list">
        {userData && userData.userRoleId === 2 && !text
          ? this.state.allData &&
            this.state.allData
              .filter((num, i) => this.state.allData.indexOf(num) === i)
              .map((question) => (
                <Question
                  question={question}
                  key={question.id}
                  onReply={onOwnFeed ? postReply : undefined}
                />
              ))
          : userData && userData.userRoleId === 2 && text
          ? allFilteredData
              .filter((data) => data !== null)
              .map((question) => (
                <Question
                  question={question}
                  key={question.id}
                  onReply={onOwnFeed ? postReply : undefined}
                />
              ))
          : text && userData && userData.userRoleId !== 2
          ? filterdata
              .filter((data) => data !== null)
              .map((question) => (
                <Question
                  question={question}
                  key={question.id}
                  onReply={onOwnFeed ? postReply : undefined}
                />
              ))
          : questions
              .filter((num, i) => questions.indexOf(num) === i)
              .map((question) => (
                <Question
                  question={question}
                  key={question.id}
                  onReply={onOwnFeed ? postReply : undefined}
                />
              ))}
      </View>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { publisherId } = props;
  // Figure out if this user is on their own feed page.
  const loggedInUserId = _get(state, "user.userId");
  const userPlanInformation = _get(
    state,
    "user.subscriptionData.subscribedPublishers[0].products"
  );
  const viewDetails =
    userPlanInformation && userPlanInformation.view.latestSubscription;
  const askDetails =
    userPlanInformation && userPlanInformation.ask.latestSubscription;
  const text =
    (viewDetails === null ||
      (viewDetails && viewDetails.subscriptionStatusId === 4)) &&
    (askDetails === null ||
      (askDetails && askDetails.subscriptionStatusId === 4))
      ? true
      : false;
  let onOwnFeed = false;
  if (loggedInUserId !== null && loggedInUserId === publisherId) {
    onOwnFeed = true;
  }

  const idsFromPublisher = _get(
    state,
    `questions.idsByPublisherId[${publisherId}]`,
    []
  );
  const postIdsFromPublisher = _get(
    state,
    `posts.idsByPublisherId[${publisherId}]`
  );
  const postsFromPublisher = _get(state, "posts.idsByPublisherId");
  const loading = _get(state, "questions.pendingRequest");
  const allPosts = _get(state, "posts.postsById");
  const allQuestions = _get(state, "questions.questionsById", {});

  if (
    (postsFromPublisher === null && loggedInUserId) ||
    !idsFromPublisher ||
    !allQuestions
  ) {
    return {
      currentUserId: loggedInUserId,
      questions: [],
      loading,
      onOwnFeed,
      noQuestions: false,
      userPlanInformation,
    };
  }

  const questions = idsFromPublisher
    .map((questionId) => allQuestions[questionId])
    .filter((q) => {
      const numReplies =
        _get(q, "numReplies", 0) || _get(q, "replies.length", 0);
      return numReplies !== 0;
    });
  let allFeed = [...questions];
  if (loggedInUserId !== null || !text) {
    const posts =
      postsFromPublisher === null ||
      postsFromPublisher === undefined ||
      Object.keys(postsFromPublisher).length <= 0
        ? []
        : postIdsFromPublisher.map((postId) => allPosts[postId]);
    allFeed = [...allFeed, ...posts];
  }
  return {
    text,
    questions: sortQuestionsByupdatedDate(allFeed),
    loading,
    onOwnFeed,
    noQuestions: questions.length === 0,
    userPlanInformation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postReply: (questionId, content, attachements) => {
    dispatch(postReplyAction(questionId, content, attachements));
  },
  loadQuestions: (userId) => {
    dispatch(getQuestionsByPublisherAction(userId, true));
  },
  loadPosts: (userId) => {
    dispatch(getPostsByPublisher(userId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
