import _get from "lodash/get";
import { USER_ROLE } from "../../../constants";

import QuestionService from "../../api/question";
import * as QUESTION_ACTIONS from "./actions";

export const postQuestion =
  (questionContent, publisherId, files) => async (dispatch, getState) => {
    const { userId, token, userRoleId } = _get(getState(), "user");
    QuestionService.authenticate(userId, token, userRoleId);
    dispatch({
      type: QUESTION_ACTIONS.POST_QUESTION_ATTEMPT,
      payload: questionContent,
    });
    let postedQuestion;
    try {
      postedQuestion = await QuestionService.addQuestion({
        publisherId,
        title: "Title is not yet in the UI",
        content: questionContent,
        attachments: files,
      });
    } catch (error) {
      dispatch({
        type: QUESTION_ACTIONS.POST_QUESTION_FAILED,
        payload: error,
      });
      return;
    }
    dispatch({
      type: QUESTION_ACTIONS.POST_QUESTION_SUCCESS,
      payload: postedQuestion,
    });
  };

export const postQuestionSuccess = () => (dispatch) => {
  dispatch({
    type: QUESTION_ACTIONS.POST_QUESTION_SUCCESS,
    payload: {},
  });
};

export const postQuestionFailed = () => (dispatch) => {
  dispatch({
    type: QUESTION_ACTIONS.POST_QUESTION_FAILED,
    payload: {},
  });
};

export const getQuestionsByPublisher =
  (publisherId, isPublisherProfile) => async (dispatch, getState) => {
    const { userId, token, userRoleId } = _get(getState(), "user");
    if (userId && token) {
      QuestionService.authenticate(userId, token, userRoleId);
    }
    dispatch({
      type: QUESTION_ACTIONS.LOAD_QUESTIONS_ATTEMPT,
      payload: {
        id: publisherId,
        pendingRequest: true,
      },
    });
    let questions;
    try {
      questions = await QuestionService.fetchQuestionsByPublisher(
        publisherId,
        isPublisherProfile,
        0
      );
    } catch (error) {
      dispatch({
        type: QUESTION_ACTIONS.LOAD_QUESTIONS_FAILED,
        payload: error,
      });
      return;
    }
    dispatch({
      type: QUESTION_ACTIONS.LOAD_QUESTIONS_SUCCESS,
      payload: questions,
    });
  };

export const postReply =
  (questionId, content, attachments) => async (dispatch, getState) => {
    const { userId, token, userRoleId } = _get(getState(), "user");
    if (!userId || !token || userRoleId !== USER_ROLE.PUBLISHER) {
      throw new Error("You must be logged in to reply to a question");
    }
    QuestionService.authenticate(userId, token, userRoleId);

    dispatch({
      type: QUESTION_ACTIONS.POST_REPLY_ATTEMPT,
      payload: {
        pendingRequest: true,
        questionId,
        content,
        attachments,
      },
    });

    let question;
    try {
      question = await QuestionService.submitAnAnswer(
        questionId,
        content,
        attachments
      );
    } catch (error) {
      const errorMessage = _get(error, "message", error);
      dispatch({
        type: QUESTION_ACTIONS.POST_REPLY_FAILED,
        payload: errorMessage,
      });
      return;
    }
    dispatch({
      type: QUESTION_ACTIONS.POST_REPLY_SUCCESS,
      payload: question,
    });
  };

// Toggles favoriteQuestion for a question.
export const favoriteQuestion =
  (questionId, isPost) => async (dispatch, getState) => {
    dispatch({
      type: QUESTION_ACTIONS.FAV_QUESTION_ATTEMPT,
      payload: { questionId },
    });

    const favQuestions = _get(getState(), "questions.favoriteQuestionIds", []);
    let response;
    try {
      if (favQuestions.includes(questionId)) {
        // Already favorited, so unfavorite it.
        response = await QuestionService.unfavoriteQuestion(questionId, isPost);
      } else {
        // Not favorited, so favorite it.
        response = await QuestionService.favoriteQuestion(questionId, isPost);
      }
    } catch (error) {
      dispatch({
        type: QUESTION_ACTIONS.FAV_QUESTION_FAILED,
        payload: {
          questionId,
          error,
        },
      });
      return;
    }
    if (response === true) {
      dispatch({
        type: QUESTION_ACTIONS.FAV_QUESTION_SUCCESS,
        payload: { questionId },
      });
      return;
    }
    dispatch({
      type: QUESTION_ACTIONS.FAV_QUESTION_FAILED,
      payload: { questionId },
    });
  };

export const loadFavorites = () => async (dispatch) => {
  dispatch({
    type: QUESTION_ACTIONS.LOAD_FAVS_ATTEMPT,
  });

  try {
    const [favIds, allQuestions] = await QuestionService.loadFavorites();

    if (favIds && Array.isArray(favIds)) {
      dispatch({
        type: QUESTION_ACTIONS.LOAD_FAVS_SUCCESS,
        payload: {
          questions: allQuestions.items,
          favIds,
        },
      });
    } else {
      dispatch({
        type: QUESTION_ACTIONS.LOAD_FAVS_FAILED,
      });
    }
  } catch (error) {
    dispatch({
      type: QUESTION_ACTIONS.LOAD_FAVS_FAILED,
    });
  }
};

export const loadQuestionById = (id) => async (dispatch) => {
  dispatch({
    type: QUESTION_ACTIONS.LOAD_QUESTION_ATTEMPT,
  });
  try {
    const question = await QuestionService.fetchQuestionById(id);
    if (question) {
      dispatch({
        type: QUESTION_ACTIONS.LOAD_QUESTION_SUCCESS,
        payload: { question },
      });
      return;
    }
    dispatch({
      type: QUESTION_ACTIONS.LOAD_QUESTION_FAILED,
      payload: { error: "Unknown error", question },
    });
    return;
  } catch (error) {
    dispatch({
      type: QUESTION_ACTIONS.LOAD_QUESTION_FAILED,
      payload: { error: `${error}` },
    });
  }
};

export default {};
