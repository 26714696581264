export const USER_ROOT = 'USERS';

export const LOAD_USER_DATA = `${USER_ROOT}/LOAD_USER_DATA`;
export const LOAD_USER_DATA_SUCCESS = `${USER_ROOT}/LOAD_USER_DATA_SUCCESS`;
export const LOAD_USER_DATA_FAILED = `${USER_ROOT}/LOAD_USER_DATA_FAILED`;

export const SET_USER_BIO_ATTEMPT = `${USER_ROOT}/SET_BIO_ATTEMPT`;
export const SET_USER_BIO_SUCCESS = `${USER_ROOT}/SET_BIO_SUCCESS`;
export const SET_USER_BIO_FAILED = `${USER_ROOT}/SET_BIO_FAILED`;

export const UPDATE_USER_PROFILE_DATA = `${USER_ROOT}/UPDATE_USER_PROFILE_DATA`;
