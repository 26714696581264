/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";
import { withRouter } from "react-router-dom";
import {
  loadUserBySlug,
  loadQuestionById,
} from "../../services/state/actionCreators";
import { View, Loading } from "../../components/core";
import { Feed } from "./FeedPage";
import ConnectedQuestions, { Questions } from "./Questions";
import { APP_URLS, USER_ROLE } from "../../constants";

export class FeedPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        slug: PropTypes.string,
        // deeplink directly to a single question with id.
        id: PropTypes.string,
      }),
    }).isRequired,
    loadUser: PropTypes.func.isRequired,
    loadQuestion: PropTypes.func.isRequired,
    userLoading: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    question: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    currentUser: PropTypes.object,
  };

  static defaultProps = {
    currentUser: undefined,
    question: undefined,
  };

  componentDidMount() {
    const {
      user,
      loadUser,
      loadQuestion,
      match: {
        params: { slug: userSlug, id: questionId },
      },
    } = this.props;
    // const userDetails = JSON.parse(localStorage.getItem("user"));
    // if (
    //   userDetails !== null &&
    //   userDetails.userRoleId === 2 &&
    //   userSlug &&
    //   userSlug != localStorage.getItem("subscription")
    // ) {
    //   // console.log("enetrr");
    //   this.props.history.push({
    //     pathname: `${APP_URLS.LOGIN.path}`,
    //   });
    // }

    if (userSlug)
      if (!questionId && !user) {
        loadUser(userSlug);
        return;
      }
    if (questionId) {
      // explicitly load the question incase it's not already loaded.
      loadUser(userSlug);
      loadQuestion(questionId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      user,
      loadUser,
      loadQuestion,
      userLoading,
      match: {
        params: { slug: userSlug, id: questionId },
      },
    } = this.props;
    if (
      (!userLoading && user === null) ||
      userSlug !== prevProps.match.params.slug
    ) {
      loadUser(userSlug);
    }
    if (questionId && questionId !== prevProps.match.params.id) {
      // explicitly load the question incase it's not already loaded.
      loadUser(userSlug);
      loadQuestion(questionId);
    }
  }

  render() {
    const {
      user,
      currentUser,
      question,
      match: {
        params: { id: questionId },
      },
    } = this.props;
    if (
      // We're not loading a question, just a user, and that user is still loading.
      (!questionId && (!user || Object.keys(user).length < 1)) ||
      // Or, we're trying to show a question but the question hasn't loaded.
      (questionId && !question)
    ) {
      return (
        <View data-testid="loading" f="none" pt={64}>
          <Loading />
        </View>
      );
    }
    // show single question.
    if (questionId && question) {
      return (
        <Feed user={user} currentUser={currentUser}>
          <Questions
            publisherId={_get(user, "id")}
            // TODO, these props should come from elsewhere.
            postReply={() => {}}
            questions={[question]}
            loading={false}
            onOwnFeed={false}
            noQuestions={false}
            loadQuestions={() => {}}
            favoriteQuestion={() => {}}
          />
        </Feed>
      );
    }
    return (
      <Feed user={user} currentUser={currentUser}>
        <ConnectedQuestions publisherId={user.id} />
      </Feed>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { slug: userSlug, id: questionId },
    },
  } = props;
  const userIdForSlug = _get(state, `users.slugs.${userSlug}`);
  return {
    user: _get(state, `users.users.${userIdForSlug}`, false),
    userLoading: _get(state, "users.pendingRequest", false),
    currentUser: _get(state, "user", undefined),
    question: _get(
      state,
      `questions.questionsById[${parseInt(questionId, 10)}]`
    ),
  };
};
const mapDispatchToProps = (dispatch) => ({
  loadUser: (slug) => {
    dispatch(loadUserBySlug(slug));
  },
  loadQuestion: (id) => {
    dispatch(loadQuestionById(id));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedPage)
);
