import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { APP_URLS } from "../../constants";
import { logout as logoutAction } from "../../services/state/actionCreators";

class LogoutPage extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { logout } = this.props;
    logout();
  }

  render() {
    return <Redirect to={APP_URLS.LOGOUT} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logoutAction());
  },
});

export default connect(null, mapDispatchToProps)(LogoutPage);
