import PropTypes from 'prop-types';

import { MEDIA_TYPES } from '../../constants';

export const userParamSlug = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  }),
};

export const toBeUploadedAttachmentProps = PropTypes.shape({
  attachmentTypeId: PropTypes.oneOf(Object.values(MEDIA_TYPES)),
  title: PropTypes.string,
  data: PropTypes.string,
  mediaId: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  embedCode: PropTypes.string,
});

export const attachementPropType = PropTypes.shape({
  attachmentTypeId: PropTypes.oneOf(Object.values(MEDIA_TYPES)),
  title: PropTypes.string,
  id: PropTypes.number.isRequired,
  extension: PropTypes.string,
  url: PropTypes.string,
});

export const replyPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(attachementPropType),
});

export const questionPropType = PropTypes.shape({
  id: PropTypes.number,
  createdAt: PropTypes.string,
  content: PropTypes.string,
  author: PropTypes.shape({
    name: PropTypes.string,
  }),
  replies: PropTypes.arrayOf(replyPropType),
  numReplies: PropTypes.number,
  attachements: PropTypes.arrayOf(attachementPropType),
});

export default {};
