/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";
import bookmark from "../../Images/bookmark.svg";
import { Question } from "../../components/shared";
import { favoriteQuestion as favoriteQuestionAction } from "../../services/state/actionCreators";
import { View, Loading, Text } from "../../components/core";
import styles from "./styles";

// TODO, maybe we should convert to a stateless-func.
// eslint-disable-next-line react/prefer-stateless-function
export class FavoritesPage extends Component {
  static propTypes = {
    questions: PropTypes.arrayOf(PropTypes.object),
    favoriteQuestion: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    questions: [],
    loading: true,
  };

  render() {
    const { questions, favoriteQuestion, loading } = this.props;
    const subscription = sessionStorage.getItem("subscription");
    if (loading) {
      return (
        <View data-testid="loading" f="none" pt={64}>
          <Loading />
        </View>
      );
    }
    if (!loading && questions.length === 0) {
      return (
        <View data-testid="loading" f="none" pt={64}>
          <div css={styles.root}>
            <img src={bookmark} alt="bookmarkIcon" />
            <label>Bookmarks</label>
          </div>
          <div css={styles.borderDiv}></div>
          <Text css={styles.emptyText} centered>
            {"Post and answers you favorite in the feed display here"}
          </Text>
        </View>
      );
    }

    return (
      <View f="none" my={24} data-testid="questions-list">
        <div css={styles.root}>
          <img src={bookmark} alt="bookmarkIcon" />
          <label>Bookmarks</label>
        </div>
        <div css={styles.borderDiv}></div>
        {questions.map((question) => (
          <Question
            question={question.item}
            key={question.item.id}
            onFav={() =>
              favoriteQuestion(
                question.item.id,
                subscription === question.item.author.name ? true : false
              )
            }
          />
        ))}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  // const { match: { params: { slug: userSlug } } } = props;
  // const userIdForSlug = _get(state, `users.slugs.${userSlug}`);
  let loading = true;
  const favoritesByIds = _get(state, "questions.favoriteQuestionIds", []);
  const questionsByIds = _get(state, "questions.questionsById");
  const missingQuestions = [];
  const questions = favoritesByIds
    .map((id) => {
      const question = _get(questionsByIds, id, undefined);
      if (question) {
        return {
          favorite: true,
          ...question,
        };
      }
      missingQuestions.push(id);
      return undefined;
    })
    .filter((q) => q !== undefined);
  // TODO: Need to load all these individual questions. Test by refrshing page on just the favs.
  // if (missingQuestions.length > 0) {
  //   // not sure how this can happen since the same query that loads what the IDs of favorite
  //   // questions are, also loads the actual questions themselves.
  //   throw new Error("missing questions", missingQuestions);
  // } else {
  loading = false;
  // }

  return {
    questions,
    loading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  favoriteQuestion: (questionId, isPost) =>
    dispatch(favoriteQuestionAction(questionId, isPost)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesPage);
