/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import UserClient from "../../../services/api/user";
import { View, Text, Modal } from "../../../components/core";

import { cardsPropTypes } from "./types";
import CCSelector from "./ccSelector";
import AddNewCardForm from "./addNewCardForm";
import styles from "./styles";
import moment from "moment";

export const PaymentMethodSelector = ({
  cards: propCards,
  defaultCardId: defaultCardIdProp,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [defaultCardId, setdefaultCardId] = useState(
    defaultCardIdProp === null ? "" : defaultCardIdProp
  );
  const [currentPaymentMethod, setPaymentMethod] = useState(defaultCardId);
  const [cards, setCards] = useState(propCards);
  // manage modal's open state.
  useEffect(() => {
    if (!modalOpen && currentPaymentMethod === "other") {
      setModalOpen(true);
    }
  }, [currentPaymentMethod, modalOpen, setModalOpen]);

  // Handler for setting new card.
  const setNewDefaultCard = (id) => {
    setPaymentMethod(id);
    if (id && id !== "other") {
      UserClient.setDefaultCard(id);
    }
  };

  // handler for when a card is successfully added.
  const onAddCard = (card) => {
    // TODO, if multiple cards are allowed, enable this next line.
    // setCards([...cards, card]);
    // If multiple cards are not allowed, enable this line.
    UserClient.fetchCreditCardData().then((data) => {
      setCards(data.cards);
    });
    setPaymentMethod(card.id);
  };

  const deleteCard = (data) => {
    UserClient.deleteCard(data.id).then((resp) => {
      if (resp) {
        UserClient.fetchCreditCardData().then((data) => {
          setCards(data.cards);
        });
      }
    });
  };

  const setNewDefault = (data) => {
    UserClient.setDefaultCard(data.id).then((resp) => {
      if (resp) {
        UserClient.fetchCreditCardData().then((data) => {
          setdefaultCardId(data.defaultCardId);
        });
      }
    });
  };
  // TODO: handler for changing default card.
  // https://github.com/bobpol/BLF_docs/blob/master/API_payment.md#paymentuseriddefault-cardcardid-post

  return (
    <View f="none" mb={62}>
      <Text f="1 1 auto" type="h5" css={styles.titleLabel} mb={50}>
        Payment method
      </Text>
      <div css={styles.tableContainer}>
        <table css={styles.table}>
          <thead>
            <tr>
              <th>Default</th>
              <th>Card information</th>
              <th>Card number</th>
              <th>Exp. Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cards.map((data, idx) => (
              <tr css={idx % 2 === 0 ? styles.evenRow : {}}>
                <td>
                  <input
                    style={{ cursor: "pointer" }}
                    type="radio"
                    checked={defaultCardId === data.id ? true : false}
                    onChange={() => setNewDefault(data)}
                  />
                </td>
                <td>Credit</td>
                <td>****{data.last4}</td>
                <td>{moment(data.expirationDate).format("MM-YYYY")}</td>
                <td
                  onClick={() => deleteCard(data)}
                  style={{
                    fontWeight: 700,
                    color: "#356859",
                    cursor: "pointer",
                  }}
                >
                  Delete card
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Text
        f="1 1 auto"
        type="h5"
        onClick={() => {
          setModalOpen(true);
        }}
        css={styles.addnewCard}
        mb={50}
      >
        + Add new card
      </Text>
      {/* <CCSelector
        cards={cards}
        onSelected={setNewDefaultCard}
        currentPaymentMethod={currentPaymentMethod}
      /> */}
      <Modal isOpen={modalOpen} noButton>
        <AddNewCardForm
          onClose={() => {
            setModalOpen(false);
            setPaymentMethod(defaultCardId);
          }}
          addCard={onAddCard}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </View>
  );
};

PaymentMethodSelector.propTypes = {
  cards: cardsPropTypes,
  defaultCardId: PropTypes.string,
};

PaymentMethodSelector.defaultProps = {
  cards: [],
  defaultCardId: undefined,
};

export default PaymentMethodSelector;
