import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { isDev } from '../../constants';
import defaultState from './defaultState';
import rootReducer from './reducer';

const enhancers = () => {
  // only adds redux tools if on dev.
  if (isDev) {
    return composeWithDevTools(
      { trace: true },
    )(applyMiddleware(thunk));
  }
  return compose(applyMiddleware(thunk));
};

const store = createStore(
  rootReducer,
  defaultState,
  enhancers(),
);

export default store;
