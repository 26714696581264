import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _last from "lodash/last";
import getUnixTime from "date-fns/getUnixTime";
import parse from "date-fns/parse";

/**
 * @typedef {object} Reply
 * @property {number} id
 * @property {date} createdAt
 * @property {string} content
 */

/**
 * @typedef {object} Question
 * @property {number} id
 * @property {date} createdAt
 * @property {Reply[]} replies
 */

const unixTimeForDate = (date) => {
  const parsed = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
  return getUnixTime(parsed);
};

/**
 * sortQuestionsByDate
 *
 * @param {Question[]} questions: a list of question objects
 *
 * sorts questions by most recent reply, OR by most recently
 * created Question, if no replies.
 */
export const sortQuestionsByDate = (questions) => {
  const uniqueQuestions = _uniqBy(questions, "id");
  const sortedByUnix = _sortBy(uniqueQuestions, (q) => {
    const hasReplies = _get(q, "replies", []).length > 0;
    if (hasReplies) {
      const sortedReplies = _sortBy(q.replies, "createdAt");
      return unixTimeForDate(_get(_last(sortedReplies), "createdAt"));
    }
    return unixTimeForDate(q.createdAt ? q.createdAt : q.publishedAt);
  });
  return _reverse(sortedByUnix);
};

export const sortQuestionsByOnlyDate = (questions) => {
  // const uniqueQuestions = _uniqBy(questions, 'id');
  const sortedByUnix = _sortBy(questions, (q) =>
    unixTimeForDate(q.createdAt ? q.createdAt : q.publishedAt)
  );
  return _reverse(sortedByUnix);
};
export const sortQuestionsByupdatedDate = (questions) => {
  // const uniqueQuestions = _uniqBy(questions, 'id');
  const sortedByUnix = _sortBy(questions, (q) =>
    unixTimeForDate(q.updatedAt ? q.updatedAt : q.createdAt)
  );
  return _reverse(sortedByUnix);
};

export default {};
