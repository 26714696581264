/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState } from "react";
import PropTypes from "prop-types";

import { View, Input, Button } from "../../core";
import UploadMediaTarget from "../uploadMediaTarget";
import styles from "./styles";

export const Reply = ({ onCancel, onSubmit }) => {
  const [replyMode, setReplyMode] = useState(false);
  const [content, setContent] = useState("");
  // TODO: this is repeated code.
  // https://github.com/bobpol/Agage_frontend/pull/41/files#diff-971ee0f341d5c4176b620f43a9ff5fb25fa76a0722d0b7dcfc91e9dbbb0c5a5aR20
  // https://github.com/bobpol/Agage_frontend/pull/41/files#diff-3db20b2d2813af46e868a6b8283c02c1430a7db119381dd8067d6c1bbbfcbb2eR26
  const [attachementData, setAttachmentData] = useState([]);

  const handleCancelClicked = () => {
    onCancel(content);
    setReplyMode(!replyMode);
  };

  const handleReplyClicked = () => {
    onSubmit(content, attachementData);
  };

  const handleFileChange = (fileData) => {
    setAttachmentData(fileData);
  };

  return (
    <View fd="column" data-testid="reply-buttons" css={styles.root}>
      <UploadMediaTarget onChange={handleFileChange}>
        <Input
          multiline
          css={styles.questionTextArea}
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          data-testid="add-reply"
        />
      </UploadMediaTarget>
      <View css={styles.replyButtonContainer} mb={12} mt={12}>
        <Button
          css={styles.cancelButton}
          ghost
          flat
          onClick={handleCancelClicked}
        >
          Cancel
        </Button>
        <Button
          css={styles.replyButton}
          secondary
          flat
          onClick={handleReplyClicked}
        >
          Reply
        </Button>
      </View>
    </View>
  );
};

Reply.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Reply;
