import { keyframes } from '@emotion/react';

import globalStyles from '../../../styles';

const placeHolderShimmer = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`;

export default {
  animationDuration: '1.25s',
  animationFillMode: 'forwards',
  animationIterationCount: 'infinite',
  animationName: placeHolderShimmer,
  animationTimingFunction: 'linear',
  background: 'linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%)',
  backgroundSize: '800px 104px',
  height: globalStyles.typography.body.fontSize,
  position: 'relative',
};
