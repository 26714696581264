/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import { Text, View } from "../../components/core";
import CheckmarkIcon from "./img/check.png";
import styles from "./styles";
import _get from "lodash/get";
import _find from "lodash/find";
import { usePublisherParam } from "../../components/shared/hooks";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import UserService from "../../services/api/user/index";

const pageStyles = styles.notificationSettings;

const platforms = {
  sms: 3,
  email: 2,
  platform: 1,
};

const labelStyles = {
  fontFamily: "IBM Plex Sans",
  fontSize: 16,
  letterSpacing: "0.1px",
  fontWeight: 600,
  color: "#303030",
  paddingTop: 5,
  paddingLeft: 5,
};
export const NotificationSettings = (user) => {
  const Id = JSON.parse(sessionStorage.getItem("user"));
  const [newQuestion, setnewQuestion] = useState(null);
  const [newRegistration, setnewRegistration] = useState(null);
  const [preferences, setPreferences] = useState([]);
  const [post, setPost] = useState(null);
  const [replyMember, setreplyMember] = useState(null);
  const [replyMemberOwn, setreplyMemberOwn] = useState(null);
  const fetchNotificationPreference = async () => {
    const preferencesRes = await UserService.fetchNotification(Id.userId);
    const filterPost = preferencesRes.posts.filter(
      (data) => data.publisherId === user.data.id
    );
    if (Id.userRoleId === 3) {
      if (preferencesRes.special.newQuestion.length > 0) {
        setnewQuestion(true);
      } else {
        setnewQuestion(false);
      }
      if (
        preferencesRes.special.newSubscriber &&
        preferencesRes.special.newSubscriber.length > 0
      ) {
        setnewRegistration(true);
      } else {
        setnewRegistration(false);
      }
    }
    setPost(filterPost.length > 0 ? true : false);
    const filterreplyMember = preferencesRes.questionRepliesMember.filter(
      (data) => data.publisherId === user.data.id
    );
    setreplyMember(filterreplyMember.length > 0 ? true : false);
    const filterreplyMemberOwn = preferencesRes.questionRepliesOwn.filter(
      (data) => data.publisherId === user.data.id
    );
    setreplyMemberOwn(filterreplyMemberOwn.length > 0 ? true : false);
    setPreferences(preferencesRes);
  };
  useEffect(() => {
    fetchNotificationPreference();
  }, []);
  const userData = useSelector((state) => state.user);
  const userSlug = usePublisherParam();
  const subScriptions = _get(userData, "subscriptionData.subscribedPublishers");
  const subscription = _find(subScriptions, (s) => s.slug === userSlug);
  let viewStatus = _get(subscription, "products.view.latestSubscription");
  let askStatus = _get(subscription, "products.ask.latestSubscription");

  const toggleReplyOwn = () => {
    const allPreference = preferences;
    if (replyMemberOwn === true) {
      const all = allPreference.questionRepliesOwn.filter(
        (data) => data.publisherId !== user.data.id
      );
      allPreference.questionRepliesOwn = all;
      UserService.setNotificationPreference(Id.userId, allPreference);
      setreplyMemberOwn(false);
      setPreferences(allPreference);
    } else {
      let newstate1 = { publisherId: user.data.id, notificationTypeId: 1 };
      let newstate2 = { publisherId: user.data.id, notificationTypeId: 2 };
      allPreference.questionRepliesOwn.push(newstate1, newstate2);
      UserService.setNotificationPreference(Id.userId, allPreference);
      setreplyMemberOwn(true);
      setPreferences(allPreference);
    }
  };
  const toggleReplyMember = () => {
    const allPreference = preferences;
    if (replyMember === true) {
      const all = allPreference.questionRepliesMember.filter(
        (data) => data.publisherId !== user.data.id
      );
      allPreference.questionRepliesMember = all;
      UserService.setNotificationPreference(Id.userId, allPreference);
      setreplyMember(false);
      setPreferences(allPreference);
    } else {
      let newstate1 = { publisherId: user.data.id, notificationTypeId: 1 };
      let newstate2 = { publisherId: user.data.id, notificationTypeId: 2 };
      allPreference.questionRepliesMember.push(newstate1, newstate2);
      UserService.setNotificationPreference(Id.userId, allPreference);
      setreplyMember(true);
      setPreferences(allPreference);
    }
  };

  const togglePost = () => {
    const allPreference = preferences;
    if (post === true) {
      const all = allPreference.posts.filter(
        (data) => data.publisherId !== user.data.id
      );
      allPreference.posts = all;
      UserService.setNotificationPreference(Id.userId, allPreference);
      setPost(false);
      setPreferences(allPreference);
    } else {
      let newstate1 = { publisherId: user.data.id, notificationTypeId: 1 };
      let newstate2 = { publisherId: user.data.id, notificationTypeId: 2 };

      allPreference.posts.push(newstate1, newstate2);
      UserService.setNotificationPreference(Id.userId, allPreference);
      setPost(true);
      setPreferences(allPreference);
    }
  };
  const toggleNewSubscriber = () => {
    const allPreference = preferences;
    if (newRegistration === true) {
      let all = allPreference.special.newSubscriber;
      all = [];
      allPreference.special.newSubscriber = all;
      UserService.setNotificationPreference(Id.userId, allPreference);
      setnewRegistration(false);
      setPreferences(allPreference);
    } else {
      let newstate1 = { notificationTypeId: 2 };
      allPreference.special.newSubscriber.push(newstate1);
      UserService.setNotificationPreference(Id.userId, allPreference);
      setnewRegistration(true);
      setPreferences(allPreference);
    }
  };
  const toggleNewQuestion = () => {
    const allPreference = preferences;
    if (newQuestion === true) {
      let all = allPreference.special.newQuestion;
      all = [];
      allPreference.special.newQuestion = all;
      UserService.setNotificationPreference(Id.userId, allPreference);
      setnewQuestion(false);
      setPreferences(allPreference);
    } else {
      let newstate1 = { notificationTypeId: 1 };
      allPreference.special.newQuestion.push(newstate1);
      UserService.setNotificationPreference(Id.userId, allPreference);
      setnewQuestion(true);
      setPreferences(allPreference);
    }
  };
  return (
    <View fd="column" css={pageStyles.root} py={35} mb={10}>
      <View f="none" css={pageStyles.content}>
        <Text f="1 1 auto" css={pageStyles.header} type="h5" centered mb={20}>
          Notifications
        </Text>
        {user.data.userRoleId === 3 ? (
          <div style={{ marginLeft: "32px" }}>
            <div class="custom-control form-control-lg custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customCheck1"
                checked={newRegistration}
                onChange={() => toggleNewSubscriber()}
              />
              <label
                class="custom-control-label"
                style={labelStyles}
                for="customCheck1"
              >
                New member registration.
              </label>
            </div>
            <div class="custom-control form-control-lg custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customCheck2"
                checked={newQuestion}
                onChange={() => toggleNewQuestion()}
              />
              <label
                class="custom-control-label"
                style={labelStyles}
                for="customCheck2"
              >
                New member question.
              </label>
            </div>

            {/* <View fd="column">
              {[
                // 'Creator replied to your question',
                "New member registration.",
                "New member question.",
              ].map((notif, idx) => (
                <View
                  fd="row"
                  key={notif.replace(" ", "-").trim().toLocaleLowerCase()}
                  styles={pageStyles.notificationSetting}
                  mb={24}
                >
                  <img
                    src={CheckmarkIcon}
                    alt="enabled"
                    css={{
                      ...pageStyles.notificationIcon,
                      ...(idx === 0 ? pageStyles.notificationIconDisabled : {}),
                    }}
                  />
                  <Text type="h6">{notif}</Text>
                </View>
              ))}
            </View> */}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              left: window.screen.width < 500 ? "20px" : "50px",
            }}
          >
            <div class="custom-control form-control-lg custom-checkbox">
              <input
                onChange={() => {
                  toggleReplyMember();
                }}
                type="checkbox"
                class="custom-control-input"
                id="1"
                checked={replyMember}
              />
              <label class="custom-control-label" style={labelStyles} for="1">
                Expert replied to a member's question
              </label>
            </div>
            <div class="custom-control form-control-lg custom-checkbox">
              <input
                onChange={() => togglePost()}
                checked={post}
                type="checkbox"
                class="custom-control-input"
                id="2"
              />
              <label class="custom-control-label" for="2" style={labelStyles}>
                Expert shared a post
              </label>
            </div>
            {viewStatus === null || viewStatus?.subscriptionStatusId === 4 ? (
              <div class="custom-control form-control-lg custom-checkbox">
                <input
                  onChange={() => toggleReplyOwn()}
                  checked={replyMemberOwn}
                  type="checkbox"
                  class="custom-control-input"
                  id="3"
                />
                <label class="custom-control-label" for="3" style={labelStyles}>
                  Expert replied to your question
                </label>
              </div>
            ) : (
              ""
            )}
          </div>
          // <View fd="column">
          //   {[
          //     // 'Creator replied to your question',
          //     "Expert replied to a member's question",
          //     "Expert shared a post",
          //     viewStatus === null || viewStatus?.subscriptionStatusId === 4
          //       ? "Expert replied to your question"
          //       : "",
          //   ].map((notif, idx) => (
          //     <View
          //       fd="row"
          //       key={notif.replace(" ", "-").trim().toLocaleLowerCase()}
          //       styles={pageStyles.notificationSetting}
          //       mb={24}
          //     >
          //       {notif !== "" ? (
          //         <img
          //           src={CheckmarkIcon}
          //           alt="enabled"
          //           css={{
          //             ...pageStyles.notificationIcon,
          //             ...(idx === 0 ? pageStyles.notificationIconDisabled : {}),
          //           }}
          //         />
          //       ) : (
          //         ""
          //       )}
          //       <Text type="h6">{notif}</Text>
          //     </View>
          //   ))}
          // </View>
        )}
      </View>
    </View>
  );
};

export default NotificationSettings;
