/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment } from "react";
import greenTick from "../src/Images/greentick.svg";
import whiteTick from "../src/Images/whitetick.svg";

export const environment =
  process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV;

export const isProd = environment === "production";
export const isDev = environment === "development";

export const BASE_URL = {
  dev: "https://dev.api.guidereply.com",
  development: "https://dev.api.guidereply.com", // 'https://dev.api.agage.io', // local
  qa: "https://qa.api.guidereply.com",
  production: "https://api.guidereply.com",
}[environment];

export const APP_URLS = {
  FEED: {
    path: "feed",
    example: "/brian_hawkins",
    url: (slug) => `/${slug}`,
  },
  LOGIN: {
    path: "login",
    example: "/brian-hawkins/login",
    url: (slug) => `/${slug}/login`,
  },
  LOGOUT: {
    path: "/logout",
    example: "/logout",
    url: () => "/logout",
  },
  PROFILE: {
    path: "profile",
    example: "/brian-hawkins/profile",
    url: (slug) => `/${slug}/profile`,
  },
  SIGNUP: {
    // TODO: verify this is working with new URL structure.
    path: "signup",
    example: "brian-hawkins/signup",
    url: (slug) => `/${slug}/signup`,
    params: (slug, productId) => `/${slug}/signup?productId=${productId}`,
  },
  HOME: {
    path: "/",
    example: "/",
    url: () => "/",
  },
  SETTINGS: {
    path: "settings",
    example: "brian-hawkins/settings",
    url: (slug) => `/${slug}/settings`,
  },
  FAVORITES: {
    path: "favs",
    example: "brian-hawkins/favs",
    url: (slug) => `/${slug}/favs`,
  },
  DASHBOARD: {
    path: "creatorDashboard",
    url: (slug) => `/${slug}/creatorDashboard`,
  },
  CREATORSIGNUP: {
    path: "/expert-sign-up",
    example: "/expert-sign-up",
    url: "/expert-sign-up",
  },
  POLICY: {
    path: "/policy",
    example: "/policy",
    url: "/policy",
  },
  TERMS: {
    path: "/terms",
    example: "/terms",
    url: "/terms",
  },
};

export const PAYMENT_OPTIONS = {
  CREDITCARD: 1,
  BITCOIN: 2,
  COMPLIMENTARY: 3,
  MANUAL: 4,
};

export const USER_ROLE = {
  ADMIN: 1,
  SUBSCRIBER: 2,
  PUBLISHER: 3,
  ASSISTANT: 4,
};

export const MEDIA_TYPES = {
  IMAGE: 1,
  VIDEO: 2,
};

export const CONTENT_ACCESS_PERMISSIONS = {
  VIEW_PREMIUM: 1,
  VIEW_ALERTS: 2,
  VIEW_QUESTION_REPLIES: 3,
  ASK_QUESTIONS: 4,
  JOIN_WEBINARS: 5,
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 1,
  OVERDUE: 2,
  PENDING_CANCELLATION: 3,
  ENDED: 4,
};

export const reverseEnum = (enumeration, val) => {
  let result;
  Object.entries(enumeration).forEach(([key, value]) => {
    if (val === value) {
      result = key;
    }
  });
  return result;
};

export const PRODUCT_DESCRIPTION_BY_KEY = {
  ask: "Ask",
  view: "View",
};

export const CONTENT_ACCESS_PERMISSION = {
  VIEW_PREMIUM_POSTS: 1,
  VIEW_ALERTS: 2,
  VIEW_QUESTION_REPLIES: 3,
  ASK_QUESTIONS: 4,
  JOIN_WEBINARS: 5,
};

export const TYPE_TO_DESC_MAPPING = {
  view: (
    <Fragment>
      <img
        style={{ width: "24px", height: "24px", marginRight: 10 }}
        src={greenTick}
      />
      <label
        style={{
          fontFamily: "IBM Plex Sans",
          fontSize: 20,
          color: "#737373",
          fontWeight: 400,
          letterSpacing: "0.15px",
          lineHeight: "24px",
        }}
      >
        View all questions answered by the expert
      </label>
    </Fragment>
  ),
  ask: (
    <Fragment>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        <li style={{ display: "flex" }}>
          <img
            style={{ width: "24px", height: "24px", marginRight: 10 }}
            src={whiteTick}
          />
          <label
            style={{
              fontFamily: "IBM Plex Sans",
              fontSize: 20,
              color: "#fff",
              fontWeight: 400,
              letterSpacing: "0.15px",
              lineHeight: "24px",
            }}
          >
            View all questions answered by the expert
          </label>
        </li>
        <li style={{ display: "flex" }}>
          <img
            style={{ width: "24px", height: "24px", marginRight: 10 }}
            src={whiteTick}
          />
          <label
            style={{
              fontFamily: "IBM Plex Sans",
              fontSize: 20,
              color: "#fff",
              fontWeight: 400,
              letterSpacing: "0.15px",
              lineHeight: "24px",
            }}
          >
            Ask the expert your own personal question
          </label>
        </li>
      </ul>
    </Fragment>
  ),
};
