/** @jsx jsx */
import { Component } from "react";
import { connect } from "react-redux";
import { jsx } from "@emotion/react";
import _first from "lodash/first";
import _get from "lodash/get";
import _last from "lodash/last";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";

import { MEDIA_TYPES } from "../../../constants";
import { View, Text, Loading } from "../../core";
import DisplayMedia from "./displayMedia";
import styles from "./styles";
import NotificationService from "../Notification";
import uploadVideo from "../../../services/api/video";
import popupStyles from "../createQuestion/styles";
import VideoIcon from "./img/play-circle.png";
import ImageIcon from "./img/image.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const maxUploadedItems = 100;
export class UploadMediaTarget extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    user: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: undefined,
    children: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      attachementData: [],
      pendingVideoUploads: [],
      uploadingPercent: 0,
    };
    this.attachFileListener();
  }

  onDroppedImage = (files) => {
    const { attachementData } = this.state;
    if (this.props.isPublisher === false && attachementData.length > 2) {
      NotificationService.error("Total of 3 photos/videos allowed maximum");
    } else if (files.length > 0 && attachementData.length < maxUploadedItems) {
      this.reader.readAsDataURL(files[0]);
    }
    // TODO: support multiple file uploads.
    // files.forEach((file) => {
    //   this.reader.readAsDataURL(file);
    // });
  };

  onDroppedVideo = async (files) => {
    if (
      this.props.isPublisher === false &&
      this.state.attachementData.length > 2
    ) {
      NotificationService.error("Total of 3 photos/videos allowed maximum");
    } else {
      const { attachementData, pendingVideoUploads } = this.state;
      const { user } = this.props;
      // Pluck some user attributes for the file meta data.
      const userName = _get(user, "name", "Unknown User");
      const userId = _get(user, "id", "Unknown ID");

      const newPendingUploads = [...pendingVideoUploads];
      if (files.length > 0 && attachementData.length < maxUploadedItems) {
        files.forEach((file) => {
          newPendingUploads.push(file);
        });
      }
      this.setState({
        pendingVideoUploads: newPendingUploads,
      });
      const nextToUpload = _first(newPendingUploads);
      const finalVideoData = await uploadVideo(
        nextToUpload,
        {
          fileName: `Question video uploaded by ${userName}`,
          fileDescription: `A question ${userName} asked on ${
            window.location.hostname
          }.

Uploaded by user with ID:${userId} on ${new Date()}.
        `,
          rating: "safe",
        },
        (percent) => this.setState({ uploadingPercent: percent })
      );

      const thumb = _last(_get(finalVideoData, "pictures.sizes", []));
      const vimeoId = _last(_get(finalVideoData, "uri", "").split("/"));
      const fileData = {
        name: files[0].name, // todo, this is only able to handle one file.
        attachmentTypeId: MEDIA_TYPES.VIDEO,
        mediaId: vimeoId || _get(finalVideoData, "uri"),
        thumbnailUrl: _get(thumb, "link", ""),
        embedCode: _get(finalVideoData, "embed.html"),
        title: `Question video uploaded by ${userName}`,
      };
      this.onVideoUploaded(fileData);
    }
  };

  onVideoUploaded = (newVideoData) => {
    this.setState(
      (state) => {
        // Should remove the just uploaded from from state.pendingVideoUploads.
        const newPendingVideoUploads = state.pendingVideoUploads.filter(
          (f) => f.name !== newVideoData.name
        );
        return {
          ...state,
          attachementData: [...state.attachementData, newVideoData],
          pendingVideoUploads: newPendingVideoUploads,
        };
      },
      () => {
        const { onChange } = this.props;
        const { attachementData } = this.state;
        onChange(attachementData);
      }
    );
  };

  onRemoveFile = (fileUrl) => {
    const { onChange } = this.props;
    const newFiles = [];
    const { attachementData } = this.state;
    attachementData.filter((file) => {
      if (file.key !== fileUrl.key) {
        newFiles.push(file);
      }
    });
    this.setState({
      attachementData: newFiles,
    });
    onChange(newFiles);
  };

  onLoadedCallback() {
    this.setState((state, _props) => {
      const nextState = {
        ...state,
        attachementData: [
          ...state.attachementData,
          {
            attachmentTypeId: MEDIA_TYPES.IMAGE,
            title: "User Uploaded Image",
            data: this.reader.result,
            key: new Date().getTime(),
          },
        ],
      };
      const { onChange } = _props;
      const { attachementData } = nextState;
      onChange(attachementData);
      return nextState;
    });
  }

  attachFileListener() {
    if (this.reader) {
      this.reader.removeEventListener("load", this.boundLoadListener);
      this.boundLoadListener = undefined;
      this.reader = undefined;
    }

    this.reader = new FileReader();
    // TODO: Might have to reattach the listener if onChange prop changes.
    this.boundLoadListener = this.onLoadedCallback.bind(this);
    this.reader.addEventListener("load", this.boundLoadListener);
  }

  render() {
    const { children } = this.props;
    const { attachementData, pendingVideoUploads, uploadingPercent } =
      this.state;
    const uploading = pendingVideoUploads.length > 0;

    return (
      <View f="none" css={styles.root}>
        {children}
        {uploading && (
          <View
            style={{
              marginRight:
                this.props.video && this.props.video !== undefined
                  ? "86px"
                  : {},
            }}
            css={styles.loadingBlock}
          >
            <Text>
              {uploadingPercent < 100
                ? `Video Uploading: ${uploadingPercent}%`
                : "Finalizing Video..."}
            </Text>
            <Loading />
          </View>
        )}
        <View css={styles.targetWrapper} my={12}>
          {this.props.video !== undefined && this.props.video ? (
            ""
          ) : (
            <View css={{ ...styles.dropTarget, ...styles.imageDropTarget }}>
              <Dropzone
                onDrop={this.onDroppedImage}
                data-testid="dropzone"
                multiple={false}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div css={styles.uploadDiv} {...getRootProps()}>
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img
                      css={styles.uploadIcon}
                      src={ImageIcon}
                      alt="image upload icon"
                      style={{
                        marginLeft:
                          this.props.settings === true ||
                          window.screen.width < 500
                            ? 25
                            : 55,
                      }}
                    />
                    <Text
                      centered
                      type="small"
                      py={6}
                      css={styles.uploadHelptext}
                    >
                      {isDragActive && "Drop image here."}
                      Upload image
                    </Text>
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </View>
          )}
          {this.props.video !== undefined && !this.props.video ? (
            ""
          ) : (
            <View css={{ ...styles.dropTarget, ...styles.videoDropTarget }}>
              <Dropzone
                onDrop={this.onDroppedVideo}
                data-testid="dropzone-video"
                multiple={false}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div css={styles.uploadDiv} {...getRootProps()}>
                    <img
                      css={styles.uploadIcon}
                      src={VideoIcon}
                      alt="video upload icon"
                      style={{
                        marginLeft:
                          this.props.settings === true ||
                          window.screen.width < 500
                            ? 20
                            : 55,
                      }}
                    />
                    <Text
                      centered
                      type="small"
                      py={6}
                      css={styles.uploadHelptext}
                    >
                      {isDragActive && "Drop video here."}
                      Upload a video
                    </Text>
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </View>
          )}
        </View>
        {!this.props.isPublisher && (
          <div
            css={popupStyles.questionLimitArea}
          >{`${this.state.attachementData.length}/3`}</div>
        )}
        <DragDropContext
          onDragEnd={(param) => {
            let source = param.source.index;
            let destination = param.destination?.index;
            if (destination || destination === 0) {
              let attachements = [...this.state.attachementData];
              attachements.splice(
                destination,
                0,
                attachements.splice(source, 1)[0]
              );
              this.setState({
                attachementData: attachements,
              });
            }
          }}
        >
          <Droppable droppableId="droppable-1" direction="horizontal">
            {(provided) => (
              <div style={{ display: "flex" }}>
                <View
                  data-testid="uploaded files"
                  css={styles.uploadedImagesContainer}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {attachementData.map((mediaData, index) => (
                    <DisplayMedia
                      key={mediaData.data}
                      index={index}
                      {...mediaData}
                      removeFile={() => {
                        this.onRemoveFile(mediaData);
                      }}
                    />
                  ))}
                  {provided.placeholder}
                </View>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const userId = _get(state, "user.userId", undefined);
  return {
    user: {
      ..._get(state, "user", {}),
      ..._get(state, `users.users[${userId}]`, {}),
    },
  };
};

export default connect(mapStateToProps)(UploadMediaTarget);
