import _get from "lodash/get";

import ApplicationClient from "../core";
import NotificationService from "../../../components/shared/Notification";
import { postMutationSchema, postSchema, postsSchema } from "../../schemas";

export class PostClient extends ApplicationClient {
  canCreatorPost() {
    if (!this.isLoggedIn()) {
      throw new Error("User must be logged in.");
    }
    if (!this.isPublisher()) {
      throw new Error("Only publishers can do this action.");
    }
    return true;
  }

  addPost(postData) {
    if (!this.canCreatorPost()) {
      return new Promise().reject();
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .put("post", postData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            const question = response.data;
            if (!question) {
              throw new Error("Problem with API, question didn't match schema");
            }
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchPostById(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`post/${id}`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            const question = response.data;
            if (!postSchema.validateSync(question)) {
              throw new Error("Problem with API, question didn't match schema");
            }
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchPostBySlug(slug) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`post/by-slug/${slug}`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { data: question } = response;
            if (!postSchema.validateSync(question)) {
              throw new Error("Problem with API, question didn't match schema");
            }
            resolve(question);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchPostsByPublisher(publisherId, pageIndex = 0) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`post/mixed/${publisherId}/100/${pageIndex}`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { data: questions } = response;
            resolve(questions);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  favoriteQuestion(questionId) {
    if (!Number.isInteger(questionId)) {
      throw new Error(
        `QuestionID must be an integier, but was a ${typeof questionId}`
      );
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(`favourites/posts/${questionId}`, {})
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(true);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  unfavoriteQuestion(questionId) {
    if (!Number.isInteger(questionId)) {
      throw new Error(
        `QuestionID must be an integier, but was a ${typeof questionId}`
      );
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .delete(`favourites/posts/${questionId}`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(true);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  loadFavorites() {
    const { userObject } = this;
    if (!userObject || !userObject.token || !userObject.userId) {
      throw new Error("You must be logged in to get your subscription status");
    }

    return new Promise((resolve, reject) => {
      this.networkClient
        .get("favourites/posts", { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { data: questions } = response;
            if (!postsSchema.validateSync(questions)) {
              throw new Error("Problem with API, question didn't match schema");
            }
            const favQuestionIds = questions.map((q) => q.id);
            resolve([favQuestionIds, questions]);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
}

export default new PostClient();
