/** @jsx jsx */
import { jsx } from '@emotion/react';

import { useFetchRemoteResource } from '../../../services/utils/custom-hooks';
import UserService from '../../../services/api/user';
import PaymentMethodSelector from './paymentMethodSelector';
import {
  View,
  Text,
  Loading,
} from '../../../components/core';

/*
Right now, in theory this component loads the user's credit card info.
No users exist yet with CC info saved on their models.
The component is not yet complete, as the selections are hard coded, yet
you could fill in the dynamic data given that the schema is well-defined.
*/
const PaymentMethodLoader = () => {
  const {
    loading,
    requested,
    data,
  } = useFetchRemoteResource(UserService.fetchCreditCardData.bind(UserService));

  if (loading || !requested) {
    return (
      <View css={{ maxWidth: 300, margin: '0 auto' }}>
        <Loading />
      </View>
    );
  }

  if (!loading && requested && !data) {
    return (
      <Text color="error" centered mb={16}>
        Some error occurred while loading payment options.
        Please try again or contact support.
      </Text>
    );
  }

  return <PaymentMethodSelector {...data} />;
};

export default PaymentMethodLoader;
