import * as USER_ACTIONS from '../actions';
import UserClient from '../../../api/user';

export const setUserAvatarSuccess = (responseData) => (dispatch) => {
  dispatch({
    type: USER_ACTIONS.USER_UPLOAD_AVATAR_SUCCESS,
    payload: {
      user: responseData,
    },
  });
};

export const setUserAvatarFailed = (error) => (dispatch) => {
  dispatch({
    type: USER_ACTIONS.USER_UPLOAD_AVATAR_FAILED,
    payload: {
      error,
    },
  });
};

export const setUserAvatar = (avatarData) => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.USER_UPLOAD_AVATAR_ATTEMPT,
    payload: {
      ...avatarData,
    },
  });

  try {
    const response = await UserClient.uploadAvatar(avatarData);
    dispatch(setUserAvatarSuccess(response));
    // throw new Error('Invalid user data returned');
    // TODO: Now the page reloads when user succesfully uploads, since the response
    // doen't include the uploaded image.
  } catch (error) {
    dispatch(setUserAvatarFailed(error));
  }
};

export default {};
