/** @jsx jsx */
import _get from "lodash/get";
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { favoriteQuestion } from "../../../services/state/question/actionCreators";
import { User } from "../user";
import { View, Button, Link } from "../../core";
import { APP_URLS } from "../../../constants";

import styles from "./styles";
import BookmarkIcon from "./img/bookmarkNew.svg";
import BookmarkIconFilled from "./img/bookmark-filled.svg";

export const Post = ({
  children,
  userName,
  postTime,
  cta,
  noBackground,
  isFav,
  onFav,
  avatarUrl,
  isUserLoggedIn,
  ...rest
}) => (
  <View
    css={{ ...styles.root, ...(noBackground ? {} : styles.withBackground) }}
    fd="column"
    p="20px 50px"
    data-testid="post"
    {...rest}
  >
    <View css={styles.heading} mb={18}>
      <User avatarUrl={avatarUrl} name={userName} postTime={postTime} />
      {isUserLoggedIn && (
        <View css={styles.actions}>
          <button type="button" css={styles.button} onClick={onFav}>
            <img
              src={isFav ? BookmarkIconFilled : BookmarkIcon}
              alt="bookmark post"
              css={styles.bookmarkIcon}
            />
          </button>
        </View>
      )}
    </View>
    <View css={styles.content} mx={12} fd="column">
      {children}
    </View>
    <View css={styles.callToAction} pt={16} f="none">
      {cta}
    </View>
  </View>
);

Post.propTypes = {
  children: PropTypes.node,
  userName: PropTypes.string,
  avatarUrl: PropTypes.string,
  postTime: PropTypes.string,
  cta: PropTypes.node,
  noBackground: PropTypes.bool,
  onFav: PropTypes.func,
  isFav: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
};

Post.defaultProps = {
  children: null,
  userName: "Unknown User",
  avatarUrl: null,
  postTime: null,
  isFav: false,
  isUserLoggedIn: false,
  cta: (
    <Link to={APP_URLS.LOGIN.url()} css={{ marginLeft: 5 }}>
      <Button ghost flat>
        Join Now
      </Button>
    </Link>
  ),
  noBackground: false,
  onFav: () => {
    // eslint-disable-next-line no-console
    console.log("user tried to favorite post, but no handler existed");
  },
};

export const ConnectedPost = (props) => {
  const { questionId, ...restProps } = props;
  const favQuestions = useSelector((store) =>
    _get(store, "questions.favoriteQuestionIds", [])
  );
  const userLoggedInId = useSelector((store) =>
    _get(store, "user.userId", null)
  );
  const isUserLoggedIn =
    userLoggedInId !== null && userLoggedInId !== undefined;
  const dispatch = useDispatch();
  let isFav = false;
  if (questionId && Array.isArray(favQuestions)) {
    isFav = favQuestions.includes(parseInt(questionId, 10));
  }

  const onFav = (data) => {
    const subscription = JSON.parse(sessionStorage.getItem("subscription"));
    if (subscription === data.userName) {
      dispatch(favoriteQuestion(questionId, true));
    } else {
      dispatch(favoriteQuestion(questionId, false));
    }
  };
  return (
    <Post
      onFav={() => {
        onFav(restProps);
      }}
      isFav={isFav}
      {...restProps}
      isUserLoggedIn={isUserLoggedIn}
    />
  );
};

ConnectedPost.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConnectedPost.defaultProps = {
  questionId: undefined,
};

export default Post;
