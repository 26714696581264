import colors from './colors';
import typography from './typography';
import spacing from './spacing';
import component from './component';
import layout from './layout';

export default {
  colors,
  component,
  typography,
  spacing,
  layout,
};
