import React, { useEffect, useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/react";
import UserService from "../../services/api/user";
import { DateComponent, Button } from "../../components/core";
import styles from "./styles";
import downloadIcon from "./images/download.svg";
import moment from "moment";

const pageStyles = styles.transactionHistory;

const TransactionsTable = () => {
  const [transactionlist, setTransactionList] = useState([]);
  useEffect(() => {
    fetchTransactions();
  }, []);
  const fetchTransactions = async () => {
    const userDetails = JSON.parse(sessionStorage.getItem("user"));
    const apiData = await UserService.gettransactionDetails(userDetails.userId);
    setTransactionList(apiData.data);
  };
  const downloadCSV = async () => {
    const userDetails = JSON.parse(sessionStorage.getItem("user"));
    const apiData = await UserService.downloadCsv(userDetails.userId);
    const url = window.URL.createObjectURL(new Blob([apiData.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `transaction.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div css={pageStyles.root}>
      <Button css={pageStyles.downloadButton} onClick={() => downloadCSV()}>
        <img css={pageStyles.downloadIcon} src={downloadIcon} alt="icon" />{" "}
        Export CSV
      </Button>
      <div css={pageStyles.tableContainer}>
        <table style={{ marginTop: 60 }} css={pageStyles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Product</th>
              <th>Type</th>
              <th>Price</th>
              <th>Earnings</th>
            </tr>
          </thead>
          <tbody>
            {transactionlist.map((transaction, idx) => (
              <tr
                key={`row-${transaction.date}`}
                css={idx % 2 !== 0 ? pageStyles.evenRow : {}}
              >
                <td>{moment(transaction.dateTime).format("MM-DD-YYYY")}</td>
                <td>{transaction.isAskProduct === true ? "Ask" : "View"}</td>
                <td>
                  {transaction.transactionTypeId === 2
                    ? "Renewal"
                    : transaction.transactionTypeId === 1
                    ? "New"
                    : transaction.transactionTypeId === 3
                    ? "Subscription change"
                    : "Comeback"}
                </td>
                <td>{transaction.amount}</td>
                <td>{transaction.earnings}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TransactionsTable;
