/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { MEDIA_TYPES } from "../../../constants";
import { attachementPropType } from "../../../services/schemas/commonProps";
import { View, MediaModal } from "../../core";
import styles from "./styles";

/**
 * PostMedia {Component}
 *
 * Handles the display of attachments (media) on
 * Questions and Replies.
 *
 * TODO; do video media
 * props:
 * @param {[Object]} attachments: Attachements to display.
 */
export const PostMedia = ({ attachments }) => (
  <View data-testid="post-files" css={styles.root}>
    <MediaModal
      onClose={() => {}}
      media={attachments
        .map((m) => {
          if (m.attachmentTypeId === MEDIA_TYPES.VIDEO) return m.thumbnailUrl;
          return m.url;
        })
        .filter((s) => !!s)}
      attachements={attachments}
    />
  </View>
);

PostMedia.propTypes = {
  attachments: PropTypes.arrayOf(attachementPropType),
};

PostMedia.defaultProps = {
  attachments: [],
};

export default PostMedia;
