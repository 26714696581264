import globalStyles from "../../../styles";

export default {
  noStyleButton: {
    appearance: "none",
    border: "none",
    background: "transparent",
    display: "block",
    cursor: "pointer",
  },
  mediaButton: {
    padding: 0,
    margin: 0,
    marginBottom: 5,
    flexBasis: "50%",
    flexShrink: 0,
    flexGrow: 1,
  },
  mediaButtonBackgroundImage: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    borderRadius: 5,
    margin: "0 0 0 5px",
  },
  mediaImage: {
    display: "block",
    width: "100%",
  },
  mediaContainer: {
    width: "100%",
    maxWidth: 500,
    alignItems: "stretch",
    minHeight: 140,
    marginLeft: -5,
    flexWrap: "wrap",
  },
  backgroundContainer: {
    background: `${globalStyles.colors.neutral.neutral4}cc`,
    position: "fixed",
    left: 0,
    right: 0,
    height: "100%",
    top: 0,
    zIndex: 999,
  },
  prevButton: {
    transform: "rotate(180deg)",
  },
  nextButton: {},
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  enlargedImage: {
    maxHeight: "90vh",
    width: "80vw",
  },
  enlargedVideo: {
    "> iframe": {
      width: "90vw",
      height: "90vh",
    },
  },
  modalContentWrapper: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    overflow: "hidden",
    padding: 0,
  },
  arrowContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 60,
    alignItems: "center",
  },
  arrowContainerRight: {
    right: 0,
  },
  arrowIcon: {
    width: "100%",
  },
  modalContent: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  closeButton: {
    ...globalStyles.typography.body,
    color: globalStyles.colors.neutral.neutral1,
    fontWeight: "bold",
    fontSize: 24,
    position: "absolute",
    top: 20,
    right: 25,
    zIndex: 9999,
    ":hover": {
      opacity: 0.75,
    },
  },
  modalHidden: {
    display: "none",
  },
  keylistener: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: "none",
  },
};
