/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Component } from "react";
import _get from "lodash/get";
import styles from "./styles";
import TransactionsTable from "./transactions.jsx";
import EarningsTable from "./earnings";
import MembersTable from "./members";
import { Question } from "../../components/shared";
import { favoriteQuestion as favoriteQuestionAction } from "../../services/state/actionCreators";
import { View } from "../../components/core";
import rightArrow from "../settings/img/rightArrow.svg";
import leftArrow from "../settings/img/arrowLeft.svg";

// TODO, maybe we should convert to a stateless-func.
// eslint-disable-next-line react/prefer-stateless-function
class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Earnings: false,
      Members: false,
      Transactions: false,
    };
  }
  render() {
    return (
      <div>
        {this.state.Earnings === false &&
          this.state.Members === false &&
          this.state.Transactions === false && (
            <div css={styles.navDiv}>
              <div
                css={styles.inActiveLabel}
                onClick={() => {
                  this.setState({
                    Earnings: true,
                    Transactions: false,
                    Members: false,
                  });
                }}
              >
                Earnings
                <img css={styles.arrowIcon} src={rightArrow} />
              </div>
              <div
                onClick={() => {
                  this.setState({
                    Earnings: false,
                    Transactions: false,
                    Members: true,
                  });
                }}
                css={styles.inActiveLabel}
              >
                Members
                <img css={styles.arrowIcon} src={rightArrow} />
              </div>
              <div
                css={styles.inActiveLabel}
                onClick={() => {
                  this.setState({
                    Earnings: false,
                    Transactions: true,
                    Members: false,
                  });
                }}
              >
                Transactions
                <img css={styles.arrowIcon} src={rightArrow} />
              </div>
            </div>
          )}
        {(this.state.Earnings === true ||
          this.state.Members === true ||
          this.state.Transactions === true) && (
          <div
            onClick={() => {
              this.setState({
                Earnings: false,
                Members: false,
                Transactions: false,
              });
            }}
            css={styles.titleNavLabel}
          >
            {this.state.Earnings === true
              ? "Earnings"
              : this.state.Members === true
              ? "Members"
              : this.state.Transactions === true
              ? "Transactions"
              : ""}
            <img css={styles.arrowLeftIcon} src={leftArrow} />
          </div>
        )}
        {this.state.Transactions && <TransactionsTable />}
        {this.state.Members && <MembersTable />}
        {this.state.Earnings && <EarningsTable />}
      </div>
    );
  }
}

export default DashboardPage;
