/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { Fragment, useRef, useState } from "react";

import { useGetThumbnailForVideo } from "../../shared/hooks";
import { View } from "../view";
import styles from "./styles";
import ArrowIcon from "./img/arrow_white.png";
import playButtom from "./img/Play.svg";
import { MEDIA_TYPES } from "../../../constants";

const MediaImage = ({ url, mediaId, mediaType }) => {
  const isVideo = mediaType === MEDIA_TYPES.VIDEO;
  const rawData = useGetThumbnailForVideo(isVideo ? mediaId : undefined);
  const loadedUrl = _get(rawData, "data", undefined);
  return (
    <div
      css={{
        ...styles.mediaButtonBackgroundImage,
        backgroundImage: `url(${loadedUrl || url})`,
        position: "relative",
      }}
    >
      {isVideo && <img src={playButtom} alt="play" css={styles.playButton} />}
    </div>
  );
};

MediaImage.propTypes = {
  url: PropTypes.string,
  mediaType: PropTypes.number,
  mediaId: PropTypes.number,
};

MediaImage.defaultProps = {
  url: undefined,
  mediaType: undefined,
  mediaId: undefined,
};

export const MediaModal = ({ onClose, media, attachements }) => {
  const [isOpen, setIsOpen] = useState(false);
  const backGroundElm = useRef(null);

  const closeModal = () => {
    onClose();
    setIsOpen(false);
  };

  const onBackgroundClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (backGroundElm.current === e.target) {
      closeModal();
    }
  };

  let nextMedia = isOpen + 1;
  if (nextMedia >= media.length) {
    nextMedia = 0;
  }
  let prevMedia = isOpen - 1;
  if (prevMedia < 0) {
    prevMedia = media.length - 1;
  }

  // const handleKeyPressed = (whichKey) => {
  //   switch (whichKey) {
  //     case 'ArrowRight':
  //     case 'ArrowDown':
  //     case 'd':
  //       setIsOpen(nextMedia);
  //       break;
  //     case 'ArrowLeft':
  //     case 'ArrowUp':
  //     case 'a':
  //       setIsOpen(prevMedia);
  //       break;
  //     case 'Escape':
  //       closeModal();
  //       break;
  //     default:
  //       break;
  //   }
  // };

  if (media.length === 0) {
    return <Fragment />;
  }

  // const containerHeight = media.length === 3 ? 280 : 140;
  return (
    <Fragment>
      <View
        css={{
          ...styles.mediaContainer,
          // height: containerHeight,
        }}
      >
        {media.map((url, idx) => (
          <button
            css={{ ...styles.mediaButton, ...styles.noStyleButton }}
            key={`media-${url}`}
            type="button"
            onClick={() => setIsOpen(idx)}
          >
            <MediaImage
              url={url}
              mediaId={_get(attachements, `[${idx}].mediaId`)}
              mediaType={_get(attachements, `[${idx}].attachmentTypeId`)}
            />
          </button>
        ))}
      </View>
      {typeof isOpen === "number" && (
        <View
          css={{
            ...styles.backgroundContainer,
            ...(isOpen !== "number" ? {} : styles.modalHidden),
          }}
          onClick={onBackgroundClick}
          ref={backGroundElm}
        >
          <button
            type="button"
            css={{ ...styles.closeButton, ...styles.noStyleButton }}
            onClick={closeModal}
          >
            x
          </button>
          {media.length !== 1 && (
            <Fragment>
              <View css={styles.arrowContainer}>
                <button
                  onClick={() => {
                    setIsOpen(prevMedia);
                  }}
                  type="button"
                  css={{ ...styles.noStyleButton, ...styles.prevButton }}
                >
                  <img src={ArrowIcon} alt="next" css={styles.arrowIcon} />
                </button>
              </View>
              <View
                css={{
                  ...styles.arrowContainer,
                  ...styles.arrowContainerRight,
                }}
              >
                <button
                  onClick={() => {
                    setIsOpen(nextMedia);
                  }}
                  type="button"
                  css={{ ...styles.noStyleButton, ...styles.nextButton }}
                >
                  <img src={ArrowIcon} alt="next" css={styles.arrowIcon} />
                </button>
              </View>
            </Fragment>
          )}
          <View css={styles.modalContentWrapper}>
            <View
              // className="modal"
              css={styles.modalContent}
              data-testid="modal"
            >
              {attachements[isOpen].attachmentTypeId === MEDIA_TYPES.VIDEO ? (
                <Fragment>
                  {/* eslint-disable react/no-danger */}
                  <div
                    css={styles.enlargedVideo}
                    dangerouslySetInnerHTML={{
                      __html: attachements[isOpen].embedCode,
                    }}
                  />
                  {/* eslint-enable react/no-danger */}
                </Fragment>
              ) : (
                <img
                  src={media[isOpen] || ""}
                  alt="enlarged-uploaded-media"
                  css={styles.enlargedImage}
                />
              )}
            </View>
          </View>
          {/* <KeyboardIntractionManager onKeyPressed={handleKeyPressed} /> */}
        </View>
      )}
    </Fragment>
  );
};

MediaModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  media: PropTypes.arrayOf(PropTypes.string),
  attachements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      embedCode: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      attachmentTypeId: PropTypes.oneOf(Object.values(MEDIA_TYPES)),
    })
  ),
};

MediaModal.defaultProps = {
  media: [],
  attachements: [],
};

export default MediaModal;
