import globalStyles from "../../styles";
import PartnershipBackgroundImage from "./img/partnership.png";

export const styles = {
  pageRoot: {
    minHeight: "100vh",
    justifyContent: "space-between",
    [globalStyles.layout.maxScreenQueries.sm]: {
      overflow: "hidden",
    },
  },
  titleText: {
    color: "#000000",
    fontSize: 48,
    marginTop: "125px",
    fontWeight: 600,
    lineHeight: "56px",
    fontFamily: "Source Serif Pro",
    marginBottom: 15,
  },
  gettingStarted: {
    backgroundColor: globalStyles.colors.primary.accent,
  },
  highlight: {
    position: "relative",
    display: "inline-block",
    marginRight: "8px",
    color: "#356859",
  },
  featureContainer: {
    maxWidth: 1032,
    width: "100%",
    margin: "50px auto 100px",
    justifyContent: "space-around",
    "> div": {
      minWidth: 190,
      maxWidth: 250,
    },
    [globalStyles.layout.maxScreenQueries.sm]: {
      display: "block",
      "> div": {
        margin: "0 auto 20px",
      },
    },
  },
  featureContainerLabel: {
    fontWeight: 400,
    color: "#000000",
    fontSize: "20px",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "0.15px",
    lineHeight: "30px",
    marginTop: 10,
  },
  title: {
    fontSize: "48px",
    fontWeight: 600,
    fontFamily: "Source Serif Pro",
    color: "#000000",
    [globalStyles.layout.maxScreenQueries.sm]: {
      fontSize: 34,
    },
  },
  subHead: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 700,
    fontSize: 20,
    color: "#000000",
    zIndex: 1,
    [globalStyles.layout.maxScreenQueries.sm]: {
      padding: "0px 15px",
    },
  },
  partnership: {
    background: "#D8F0E9",
    display: "flex",
    flexDirection: "column",
    marginTop: "50px",
  },
  partnershipDiv: {
    display: "flex",
    marginLeft: "70px",
    justifyContent: "center",
    [globalStyles.layout.maxScreenQueries.sm]: {
      alignItems: "center",
      marginBottom: "25px",
      marginLeft: 40,
    },
  },
  handshakeIcon: {
    display: "block",
    margin: "0 auto",
    maxWidth: 70,
    maxHeight: 52,
    marginTop: -330,
    marginBottom: 45,
    [globalStyles.layout.maxScreenQueries.sm]: {
      marginTop: 0,
    },
  },
  intersect3: {
    width: "152.84px",
    height: "151.29px",
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "106.07px",
      height: "105px",
      position: "absolute",
      right: "-5px",
      marginTop: "535px",
    },
  },
  inputContainer: {
    margin: "0px 125px 40px 125px",
    "@media(max-width: 500px)": {
      margin: "0px 60px 40px 60px",
    },
    "> input": {
      marginBottom: 16,
    },
  },
  ctaButton: {
    width: 375,
    minHeight: 48,
    margin: "30px 0px",
    "@media(max-width: 500px)": {
      width: 275,
    },
    "@media(max-width: 375px)": {
      width: 225,
    },
  },
  demoContainer: {
    position: "relative",
    overflow: "hidden",
    [globalStyles.layout.maxScreenQueries.md]: {
      br: {
        display: "none",
      },
    },
  },
  doodad: {
    position: "absolute",
    backgroundColor: globalStyles.colors.blue.blue1,
    width: 225,
    height: 225,
    borderRadius: 225,
    bottom: 0,
    left: 0,
    transform: "translateX(-50%) translateY(50%)",
    zIndex: "-1",
  },
  thankYou: {
    maxWidth: 500,
    width: "100%",
  },
  rootAbout: {
    width: "100%",
    display: "flex",
    maxWidth: 1200,
    margin: "50px auto 100px",
    justifyContent: "space-around",
    alignItems: "center",
    [globalStyles.layout.maxScreenQueries.sm]: {
      flexDirection: "column",
      alignItems: "inherit",
    },
  },
  examplesDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rootAbout1: {
    width: "100%",
    display: "flex",
  },
  examplelabel1: {
    color: "#000000",
    fontFamily: "Source Serif Pro",
    fontWeight: 600,
    fontSize: 48,
    lineHeight: "56px",
    "@media(max-width: 500px)": {
      fontSize: 34,
    },
  },
  examplelabel2: {
    fontWeight: 400,
    fontSize: 20,
    color: "#000000",
    fontFamily: "IBM Plex Sans",
    lineHeight: "30px",
    letterSpacing: "0.15px",
    "@media(max-width: 500px)": {
      fontSize: 16,
      textAlign: "center",
    },
  },
  exampleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 40px",
    "@media(max-width: 500px)": {
      flexDirection: "column",
    },
  },
  userImg: {
    width: 406,
    height: 247.29,
    marginBottom: 30,
    "@media(max-width: 500px)": {
      width: 340,
      height: 208.29,
    },
  },
  deanDiv: {
    display: "flex",
    flexDirection: "column",
    width: 406,
    height: 404,
    cursor: "pointer",
    boxShadow:
      "0px 1px 3px rgba(6, 19, 36, 0.16), 0px 1px 2px rgba(6, 19, 36, 0.12)",
    borderRadius: "8px",
    margin: "40px 10px 35px 10px",
    "@media(max-width: 500px)": {
      width: 340,
      height: 350,
    },
  },
  deanTitle: {
    fontFamily: "Source Serif Pro",
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "0.18px",
  },
  deanDemoTitle: {
    border: "0.673077px solid #356859",
    borderRadius: "36px",
    width: 63,
    height: 34,
    color: "#356859",
    padding: "8px 12px 8px 12px",
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "18px",
    marginLeft: 10,
  },
  deanCategories: {
    fontFamily: "IBM Plex Sans",
    fontSize: 14,
    color: "#737373",
    fontWeight: 400,
    letterSpacing: "0.25px",
    lineHeight: "21px",
  },
  deanProfileDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileImg: {
    border: "3px solid rgb(53, 104, 89)",
    borderRadius: "130px",
    width: 176,
    padding: "2px",
    height: 176,
  },
  borderDiv: {
    border: "1px solid #EEEEEE",
  },
  metricsLabel: {
    margin: "10px 25px",
    fontSize: 20,
    fontWeight: 400,
    color: "#535353",
    fontFamily: "IBM Plex Sans",
  },
  content: {
    color: "#535353",
    fontFamily: "IBM Plex Sans",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0.15px",
  },
  cardDiv: {
    width: 636,
    margin: "20px",
    padding: 20,
    borderRadius: "8px",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    "@media(max-width: 500px)": {
      width: 350,
    },
  },
  linkStyle: {
    textDecoration: "underline",
  },
  name: {
    fontWeight: 700,
    fontFamily: "IBM Plex Sans",
    letterSpacing: "0.25px",
    fontSize: 14,
    margin: "0px 10px",
    color: "#000000",
  },
  timeLabel: {
    fontWeight: 300,
    fontFamily: "IBM Plex Sans",
    letterSpacing: "0.25px",
    fontSize: 14,
    color: "rgb(115, 115, 115)",
  },
  rootAbout2: {
    width: "100%",
    display: "flex",
    [globalStyles.layout.maxScreenQueries.sm]: {
      marginTop: "75px",
      marginLeft: "15px",
    },
  },
  about: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "100%",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  aboutStyles: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "100%",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  whylabel: {
    color: "#737373",
    fontSize: "50px",
    lineHeight: "61px",
    fontFamily: "Source Serif Pro",
    fontWeight: 600,
    textTransform: "uppercase",
    paddingRight: 15,
    "@media(max-width: 500px)": {
      width: 25,
    },
  },
  titleAbout: {
    color: "#356859",
    fontSize: "54px",
    fontWeight: 600,
    fontFamily: "sans-serif",
  },
  aboutdescription: {
    fontSize: "20px",
    letterSpacing: "0.15px",
    fontFamily: "IBM Plex Sans",
    lineHeight: "30px",
    color: "#000000",
  },
  aboutImg: {
    width: "70px",
    height: "70px",
    marginRight: "20px",
  },
  secondFeaturesLabel1: {
    color: "#fff",
    fontWeight: 600,
    fontFamily: "Source Serif Pro",
    fontSize: 48,
    lineHeight: "56px",
    "@media(max-width: 500px)": {
      fontSize: 34,
    },
  },
  secondFeaturesLabel2: {
    color: "#fff",
    fontWeight: 400,
    fontFamily: "IBM Plex Sans",
    fontSize: 20,
    letterSpacing: 0.15,
  },
  listStyle: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "240px",
    columnGap: "50px",
    [globalStyles.layout.maxScreenQueries.sm]: {
      maxHeight: "320px",
    },
  },
  topTitle: {
    fontSize: 54,
    fontWeight: 600,
    lineHeight: "64px",
    fontFamily: "Source Serif Pro",
    color: "#000000",
    [globalStyles.layout.maxScreenQueries.sm]: {
      fontSize: 34,
      lineHeight: "42px",
    },
  },
  topSubTitle: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "30px",
    fontFamily: "IBM Plex Sans",
    color: "#000000",
    letterSpacing: "0.15px",
  },
  topIntersect1: {
    left: "116px",
    width: "80.97px",
    height: "80.14px",
    position: "relative",
    bottom: "103px",
    zIndex: -1,
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "56.5px",
      height: "55.93px",
      left: "-75px",
      bottom: "76px",
    },
  },
  topEllipse1: {
    width: "364px",
    height: "364px",
    position: "relative",
    right: "422px",
    top: -14,
    zIndex: -1,
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "254.02px",
      height: "254.02px",
      right: "274px",
      top: "-14px",
    },
  },
  topIntersect2: {
    width: "80.97px",
    height: "80.14px",
    position: "relative",
    right: "875px",
    top: -16,
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "56.5px",
      height: "55.93px",
      right: "595px",
    },
  },
  homeButton: {
    width: "327px",
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "250px",
    },
  },
  card1: {
    width: "229px",
    height: "299px",
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "152.42px",
      height: "200.98px",
    },
  },
  card2: {
    width: "297.44px",
    height: "164px",
    position: "relative",
    right: "38px",
    top: "112px",
    [globalStyles.layout.maxScreenQueries.sm]: {
      width: "199.58px",
      height: "108.86px",
      right: "31px",
      top: "76px",
    },
  },
  Ellipse1_Expert: {
    width: "342px",
    height: "342px",
    position: "absolute",
    zIndex: -1,
    left: -78,
    [globalStyles.layout.maxScreenQueries.sm]: {
      left: -90,
      marginTop: "75px",
    },
  },
  Ellipse2_Expert: {
    width: "347px",
    height: "347px",
    position: "absolute",
    left: -100,
    marginTop: "-8px",
    [globalStyles.layout.maxScreenQueries.sm]: {
      marginTop: "70px",
    },
  },
  launchButton: {
    color: "#356859",
    background: "#fff",
    marginTop: "20px",
    width: "327px",
    "&:hover": {
      background: "#fff",
    },
    [globalStyles.layout.maxScreenQueries.sm]: {
      marginBottom: "50px",
    },
  },
};

export default styles;
