import { useState, useEffect } from 'react';

export const useFetchRemoteResource = (asyncFetcher) => {
  const [state, setState] = useState({
    loading: false,
    requested: false,
    data: undefined,
  });

  useEffect(() => {
    const { loading, requested, data } = state;
    if (!requested && !loading && !data) {
      // initial state.
      setState({
        loading: true,
        requested: true,
        data: undefined,
      });

      // Callbacks with requests.
      asyncFetcher().then((paymentData) => {
        setState({
          loading: false,
          requested: true,
          data: paymentData,
        });
      }).catch((error) => {
        // Intentional, safe, production error logging.
        // eslint-disable-next-line no-console
        if (console && console.error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        setState({
          loading: false,
          requested: true,
          data: undefined,
        });
      });
    }
  }, [state, setState, asyncFetcher]);

  return state;
};

export const useTriggerableFetchRemoteResource = (asyncFetcher, params = []) => {
  const [state, setState] = useState({
    triggered: false,
    loading: false,
    requested: false,
    data: undefined,
    error: undefined,
    params,
  });

  const triggerRequest = (...args) => {
    setState({
      ...state,
      triggered: true,
      params: args,
    });
  };

  useEffect(() => {
    const {
      loading,
      requested,
      data,
      triggered,
      params: paramsFromState,
    } = state;
    if (triggered && !requested && !loading && !data) {
      // initial state.
      setState({
        loading: true,
        requested: true,
        data: undefined,
        triggered: true,
      });

      // Callbacks with requests.
      asyncFetcher(...paramsFromState).then((paymentData) => {
        setState({
          loading: false,
          requested: true,
          data: paymentData,
        });
      }).catch((error) => {
        // Intentional, safe, production error logging.
        // eslint-disable-next-line no-console
        if (console && console.error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        setState({
          error,
          loading: false,
          requested: true,
          data: undefined,
        });
      });
    }
  }, [state, setState, asyncFetcher, params]);

  return [state, triggerRequest];
};

export default {};
