import React, { useState } from "react";
import { View, Input, Modal, Button } from "../../../components/core";
import UserService from "../../../services/api/user";
import NotificationService from "../Notification";

const WaitlistModal = ({ open, onclose, id }) => {
  const [email, setEmail] = useState();
  const [pendingRequest, setpendingRequest] = useState(false);

  const submit = async () => {
    setpendingRequest(true);
    const data = {
      email: email,
      productId: id,
    };
    const apiData = await UserService.storeEmailWaitlist(data).then((resp) => {
      if ((resp.status = 200 && resp.data.message)) {
        setpendingRequest(false);
        NotificationService.error(resp.data.message);
      } else if ((resp.status = 200)) {
        NotificationService.success(
          "Got it! You'll be notified when a membership opens up."
        );
        setEmail("");
        onclose();
      }
      setpendingRequest(false);
    });
  };
  return (
    <Modal isOpen={open} onClose={onclose} noButton>
      <div>
        <div style={{ position: "absolute", right: "30px", marginTop: 20 }}>
          <label
            style={{
              fontWeight: "bolder",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={onclose}
          >
            X
          </label>
        </div>
        <div
          style={{
            padding: 50,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label
            style={{
              fontFamily: "IBM Plex Sans",
              fontSize: "24px",
              letterSpacing: "0.1px",
              textAlign: "center",
              fontWeight: 600,
              marginBottom: 35,
              marginTop: 35,
            }}
          >
            Please provide your email to be notified when an Ask spot opens up
          </label>
          <Input
            disabled={pendingRequest === true ? "disabled" : undefined}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: "340px", marginBottom: 25 }}
          >
            Enter your email...
          </Input>
          <Button
            disabled={pendingRequest === true ? "disabled" : undefined}
            style={{
              color: "#fff",
              background: "#356859",
              marginTop: "20px",
              width: "327px",
            }}
            onClick={() => submit()}
          >
            Notify
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WaitlistModal;
