/** @jsx jsx */
import { jsx } from "@emotion/react";
import _get from "lodash/get";
import _find from "lodash/find";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { USER_ROLE } from "../../../constants";
import { CreateQuestion } from "../createQuestion";
import {
  usePublisherDataParam,
  usePublisherParam,
  useCurrentUser,
  useUserData,
} from "../hooks";
import { postQuestion as postQuestionAction } from "../../../services/state/actionCreators";
// import canAskQuestion from '../../../services/utils/user-can-ask-question';
import { Button, Modal, Text, View } from "../../core";
import {
  addCreatorPost,
  getPostsByPublisher,
} from "../../../services/state/posts/actionCreators";

export const AskButton = ({ canUserAskQuestion }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const publisher = usePublisherDataParam();
  const [networkErrors, setNetworkError] = useState(false);
  const questionNetworkError = useSelector((store) =>
    _get(store, "questions.error", false)
  );
  const loggedInUser = useCurrentUser();
  const user = useUserData();
  const { userRoleId } = user;
  const isPublisher = userRoleId === USER_ROLE.PUBLISHER;
  const userData = useSelector((state) => state.user);
  const userSlug = usePublisherParam();
  const subScriptions = _get(userData, "subscriptionData.subscribedPublishers");
  const subscription = _find(subScriptions, (s) => s.slug === userSlug);
  let subscribedProduct;
  let viewStatus = _get(subscription, "products.view.latestSubscription");
  let askStatus = _get(subscription, "products.ask.latestSubscription");
  if (viewStatus?.id > askStatus?.id) {
    subscribedProduct = _get(subscription, "products.view");
  } else {
    subscribedProduct = _get(subscription, "products.ask");
  }
  const remainingQuestions = _get(
    subscribedProduct,
    "questionsStatistic.numQuestionsRemainingUntilNextRenewal",
    0
  );
  useEffect(() => {
    const errorMessage = _get(questionNetworkError, "message");
    if (!networkErrors && questionNetworkError && errorMessage) {
      setNetworkError(errorMessage);
    }
  }, [questionNetworkError, networkErrors]);

  if (canUserAskQuestion || isPublisher) {
    return (
      <Fragment>
        <Button
          style={{
            background: "#356859",
            color: "#fff",
            borderColor: "#356859",
            minWidth: "100px",
          }}
          ghost
          flat
          disabled={!isPublisher && remainingQuestions <= 0}
          onClick={
            !isPublisher && remainingQuestions <= 0
              ? () => {}
              : () => setOpen(true)
          }
        >
          {isPublisher ? "Post" : "Ask"}
        </Button>
        <Modal isOpen={isOpen} onClose={() => setOpen(false)} noButton>
          <View fd="column">
            <CreateQuestion
              isPublisher={isPublisher}
              noBackground
              userProps={{
                name: _get(loggedInUser, "name"),
                avatarUrl: _get(loggedInUser, "avatar.small"),
              }}
              onCancel={() => {
                setOpen(false);
              }}
              onSend={(content, attachmentData) => {
                if (isPublisher) {
                  dispatch(
                    addCreatorPost(
                      content,
                      _get(publisher, "id"),
                      attachmentData
                    )
                  );
                  setOpen(false);
                  setTimeout(() => {
                    dispatch(getPostsByPublisher(_get(publisher, "id")));
                  }, 3000);
                } else {
                  dispatch(
                    postQuestionAction(
                      content,
                      _get(publisher, "id"),
                      attachmentData
                    )
                  );
                  setOpen(false);
                }
              }}
              css={{
                minWidth: "630px",
                margin: "0 auto",
              }}
            />
            {networkErrors && (
              <Text color="error" centered>
                {networkErrors}
              </Text>
            )}
          </View>
        </Modal>
      </Fragment>
    );
  }
  return <Fragment />;
};

AskButton.propTypes = {
  canUserAskQuestion: PropTypes.bool,
};

AskButton.defaultProps = {
  canUserAskQuestion: false,
};

export default {};
