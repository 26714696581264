/** @jsx jsx */
import { jsx } from '@emotion/react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import { View } from '../../../components/core';
import styles from './styles';
import { cardsPropTypes } from './types';

export const CCSelector = ({
  onSelected,
  currentPaymentMethod,
  cards,
}) => {
  const formattedCards = cards.map((card) => [
    _get(card, 'id'),
    `${_get(card, 'brand')} (${_get(card, 'last4')})`,
  ]);
  return (
    <View css={styles.ccInputWrapper} mb={16}>
      <select
        onChange={(e) => { onSelected(e.target.value); }}
        value={currentPaymentMethod}
      >
        <option value="" disabled>
          Select
        </option>
        {
          [...formattedCards, ['other', 'Add Card']].map(([value, label]) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))
        }
      </select>
    </View>
  );
};

CCSelector.propTypes = {
  onSelected: PropTypes.func.isRequired,
  currentPaymentMethod: PropTypes.string,
  cards: cardsPropTypes,
};

CCSelector.defaultProps = {
  currentPaymentMethod: undefined,
  cards: [],
};

export default CCSelector;
