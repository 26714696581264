import { toast } from "react-toastify";

const NotificationService = {
  success: (message) => {
    toast.success(message);
  },

  error: (message) => {
    if (message.length > 0 && message.length < 3) {
      message.map((data) => {
        toast.error(data);
      });
    } else {
      toast.error(message);
    }
  },

  warn: (message) => {
    toast.warn(message);
  },

  info: (message) => {
    toast.info(message);
  },
};

export default NotificationService;
