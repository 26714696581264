/** @jsx jsx */
import { jsx } from "@emotion/react";
import _get from "lodash/get";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Component } from "react";
import styles from "./styles";

import { NAV_POINTS_ENUM } from "./constants";
import { View, Text, Loading } from "../../components/core";
import { Question } from "../../components/shared";
import {
  postReply as postReplyAction,
  getQuestionsByPublisher as getQuestionsByPublisherAction,
} from "../../services/state/actionCreators";
import { questionPropType } from "../../services/schemas/commonProps";
import { sortQuestionsByDate } from "../../services/utils/date-utils";
import { getPostsByPublisher } from "../../services/state/posts/actionCreators";
import { USER_ROLE } from "../../constants";
import UserService from "../../services/api/user";

class Questions extends Component {
  static propTypes = {
    publisherId: PropTypes.number.isRequired,
    postReply: PropTypes.func.isRequired,
    // From Redux
    questions: PropTypes.arrayOf(questionPropType).isRequired,
    loading: PropTypes.bool,
    anyError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
      PropTypes.string,
    ]),
    onOwnFeed: PropTypes.bool,
    loadQuestions: PropTypes.func.isRequired,
    creatorSlug: PropTypes.string,
    loadPosts: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: true,
    onOwnFeed: false,
    anyError: false,
    creatorSlug: null,
  };

  componentDidMount() {
    const {
      questions,
      publisherId,
      loading,
      loadQuestions,
      loadPosts,
      onOwnFeed,
      creatorSlug,
    } = this.props;
    if (creatorSlug && questions.length === 0 && publisherId && !loading) {
      UserService.fetchPublisherBySlug(creatorSlug).then((data) => {
        loadPosts(null);
        loadQuestions(data.id, onOwnFeed);
      });
    } else if (questions.length === 0 && publisherId && !loading) {
      loadPosts(publisherId);
      loadQuestions(publisherId, onOwnFeed);
    }
  }

  render() {
    const {
      questions,
      // posts,
      loading,
      postReply,
      anyError,
    } = this.props;

    if ((!questions || questions.length === 0) && loading) {
      return (
        <View f="none" my={64} data-testid="Questions">
          <Loading />
        </View>
      );
    }
    if (questions.length > 0) {
      return (
        <View f="none" my={64} data-testid="Questions">
          {questions.map((question) => (
            <Question
              question={question}
              key={question.id}
              onReply={postReply}
              lastError={anyError}
            />
          ))}
        </View>
      );
    }
    return (
      <View f="none" my={64} data-testid="Questions">
        {this.props.mode === NAV_POINTS_ENUM.ANSWERED &&
        this.props.loggedInUser &&
        this.props.loggedInUser.userRoleId === 2 ? (
          <Text css={styles.emptyText} centered>
            Your questions that have been answered by the expert display here
          </Text>
        ) : this.props.mode === NAV_POINTS_ENUM.OUTSTANDING &&
          this.props.loggedInUser &&
          this.props.loggedInUser.userRoleId === 2 ? (
          <Text css={styles.emptyText} centered>
            Your questions that are waiting to be answered display here
          </Text>
        ) : this.props.mode === NAV_POINTS_ENUM.ANSWERED &&
          this.props.loggedInUser &&
          this.props.loggedInUser.userRoleId === 3 ? (
          <Text css={styles.emptyText} centered>
            All questions that you've answered display here
          </Text>
        ) : this.props.mode === NAV_POINTS_ENUM.OUTSTANDING &&
          this.props.loggedInUser &&
          this.props.loggedInUser.userRoleId === 3 ? (
          <Text css={styles.emptyText} centered>
            Questions from members awaiting to be answered display here
          </Text>
        ) : this.props.mode === NAV_POINTS_ENUM.POSTS &&
          this.props.loggedInUser &&
          this.props.loggedInUser.userRoleId === 3 ? (
          <Text css={styles.emptyText} centered>
            General posts you share display here
          </Text>
        ) : (
          <Text css={styles.emptyText} centered>
            Hm, nothing to see yet... <br />
            When the expert replies to a member question, you’ll see it here!
          </Text>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { publisherId, mode, setOutstandingQstnsCount } = props;
  // Figure out if this user is on their own feed page.
  const loggedInUser = _get(state, "user");
  let onOwnFeed = false;
  if (
    loggedInUser !== null &&
    loggedInUser.userId === publisherId &&
    loggedInUser.userRoleId === USER_ROLE.PUBLISHER
  ) {
    onOwnFeed = true;
  }
  let idsFromUser;
  if (!onOwnFeed) {
    idsFromUser = _get(state, `questions.idsByUserId[${publisherId}]`);
  } else {
    idsFromUser = _get(state, `questions.idsByPublisherId[${publisherId}]`);
  }
  const postIdsFromPublisher = _get(
    state,
    `posts.idsByPublisherId[${publisherId}]`,
    []
  );
  const postsFromPublisher = _get(state, "posts.idsByPublisherId");
  const loading = _get(state, "questions.pendingRequest");
  const allQuestions = _get(state, "questions.questionsById");
  const allPosts = _get(state, "posts.postsById");
  const anyError = _get(state, "questions.error");

  if (postsFromPublisher === null || loading || !idsFromUser || !allQuestions) {
    return {
      questions: [],
      // posts: [],
      loading,
      onOwnFeed,
      anyError,
      loggedInUser,
    };
  }

  const outstandingquestions = idsFromUser
    .map((questionId) => allQuestions[questionId])
    .filter((q) => {
      const numReplies =
        _get(q, "numReplies", false) || _get(q, "replies.length", false);
      if (numReplies === 0) {
        return true;
      }
      return false;
    })
    .filter(
      (itemId, index, self) =>
        index === self.findIndex((t) => t.id === itemId.id)
    );
  setOutstandingQstnsCount(outstandingquestions.length);
  const posts =
    postsFromPublisher === null ||
    postsFromPublisher === undefined ||
    Object.keys(postsFromPublisher).length <= 0
      ? []
      : postIdsFromPublisher.map((postId) => allPosts[postId]);
  const questions = idsFromUser
    .map((questionId) => allQuestions[questionId])
    .filter((q) => {
      const numReplies =
        _get(q, "numReplies", false) || _get(q, "replies.length", false);
      if (mode === NAV_POINTS_ENUM.OUTSTANDING && numReplies === 0) {
        return true;
      }
      if (mode === NAV_POINTS_ENUM.ANSWERED && numReplies !== 0) {
        return true;
      }
      return false;
    });

  return {
    questions:
      mode === NAV_POINTS_ENUM.POSTS
        ? sortQuestionsByDate(posts)
        : sortQuestionsByDate(questions),
    loading,
    onOwnFeed,
    anyError,
    loggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postReply: (questionId, content, attachements) => {
    dispatch(postReplyAction(questionId, content, attachements));
  },
  loadQuestions: (userId, isPublisherProfile) => {
    dispatch(getQuestionsByPublisherAction(userId, isPublisherProfile));
  },
  loadPosts: (userId) => {
    dispatch(getPostsByPublisher(userId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
