import globalStyles from "../../styles";

export default {
  root: {
    background: "#fff",
    margin: "2% 27%",
    borderRadius: "4px",
    boxShadow:
      "0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1);",
    "@media(max-width: 500px)": {
      margin: "6% 4%",
    },
  },
  signUpContainer: {
    margin: "0 auto",
  },
  title: {
    color: "#263238",
    fontSize: 34,
    fontFamily: "Source Serif Pro",
    fontWeight: 600,
  },
  inputContainer: {
    "> input": {
      marginBottom: 16,
      minWidth: 256,
    },
  },
  title1: {
    fontFamily: "IBM Plex Sans",
    fontSize: 16,
    lineHeight: "24px",
    color: "#000000",
    fontWeight: 600,
    letterSpacing: "0.1px",
    marginBottom: 7,
  },
  borderDiv: {
    border: "1px solid #EEEEEE",
    marginTop: 40,
  },
  dueDiv: {
    marginTop: "30px",
    display: "flex",
  },
  paymentInput: {
    fontFamily: "IBM Plex Sans",
    borderRadius: 8,
    border: "1px solid #556670",
    "&:focus": {
      outline: "none",
      border: "1.5px solid #356859",
    },
  },
  logoDiv: {
    display: "flex",
    justifyContent: "center",
  },
  ctaButton: {
    minHeight: 48,
    marginTop: 30,
  },
  otherOptions: {
    textAlign: "center",
    color: globalStyles.colors.primary.textLight,
    maxWidth: 300,
  },
  pricingContainer: {
    maxWidth: 800,
    margin: "0 auto",
  },
  pricingCard: {
    height: "100%",
  },
  ccInput: {
    ...globalStyles.typography.body,
  },
  errorMessage: {
    ...globalStyles.typography.body,
    color: globalStyles.colors.primary.accent,
    maxWidth: 200,
    textAlign: "left",
  },
  ccFocused: {
    boxShadow: `${globalStyles.colors.primary.secondary} 0px 0px 0px 1px'`,
    borderColor: globalStyles.colors.primary.secondary,
  },
};
