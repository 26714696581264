export const PLANS = {
  VIEW_ONLY: 1,
  ASK_AND_VIEW: 2,
};

export const PRODUCT_TYPE_TO_SUB_MAPPING = {
  ask: 'numAskers',
  view: 'numViewers',
};

export const PRODUCT_TYPE_TO_PLAN_MAPPING = {
  ask: PLANS.ASK_AND_VIEW,
  view: PLANS.VIEW_ONLY,
};
