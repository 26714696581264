/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { View, Text } from "../../core";
import styles from "./styles";
import SocialMedia from "../socialMedia";

const socials = [
  // {
  //   icon: "instagram",
  //   url: "https://www.instagram.com/guidereply/",
  // },
  // {
  //   icon: "twitter",
  //   url: "https://twitter.com/guidereply_",
  // },
  {
    icon: "email",
    url: "mailto:chris@guidereply.com",
  },
];

const footerNavLinks = [
  {
    label: "Terms",
    url: "/terms",
  },
  {
    label: "Privacy Policy",
    url: "/policy",
  },
];

export const Footer = ({ children }) => (
  <View css={styles.root} px={21} fb="33%">
    <View f="0 0 33%" css={styles.footerSocialIconsContainer}>
      {socials.map((social) => (
        <View mr={12} f="none" key={`social-${social.url}`}>
          <a href={social.url} target="_blank" rel="noreferrer">
            <SocialMedia icon={social.icon} />
          </a>
        </View>
      ))}
    </View>
    <View f="1 0 33%" my={21}>
      <Text css={styles.children}>{children}</Text>
    </View>
    <View f="0 0 33%" css={styles.footerLinksContainer}>
      {footerNavLinks.map((link) => (
        <Text css={styles.linkStyles} ml={16} key={`link-${link.url}`}>
          <a href={link.url} target="_self" rel="noreferrer">
            {link.label}
          </a>
        </Text>
      ))}
    </View>
  </View>
);

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = {
  children: "© GuideReply LLC 2022",
};

export default Footer;
