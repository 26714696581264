import globalStyles from "../../../styles";

export default {
  backgroundContainer: {
    background: `${globalStyles.colors.neutral.neutral4}99`,
    position: "fixed",
    left: 0,
    right: 0,
    height: "100%",
    top: 0,
    zIndex: 999,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContentWrapper: {
    minWidth: "32%",
    maxWidth: "50%",
    minHeight: "64%",
    maxHeight: "70%",
    background: globalStyles.colors.neutral.neutral1,
    borderRadius: 30,
    position: "absolute",
    // left: '50%',
    // top: '50%',
    // transform: 'translateX(-50%) translateY(-50%)',
    overflow: "hidden",
    padding: 24,
    paddingTop: 64,
  },
  scrollContainer: {
    width: "100%",
    // position: 'relative',
  },
  arrowContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 60,
    background:
      "linear-gradient(96deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)",
  },
  modalContent: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    position: "absolute",
  },
  closeButton: {
    ...globalStyles.typography.body,
    fontWeight: "bold",
    fontSize: 24,
    position: "absolute",
    top: 20,
    right: 25,
    appearance: "none",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    ":hover": {
      opacity: 0.75,
    },
  },
  modalHidden: {
    display: "none",
  },
};
