import globalStyles from "../../../styles";

export default {
  rootBackground: {
    ...globalStyles.component.shadowBoxBG,
  },
  root: {
    maxWidth: 360,
    "@media(max-width: 500px)": {
      minWidth: "430px",
    },
  },
  titleContent: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 700,
    color: "black",
    fontSize: 20,
  },
  actionRow: {
    justifyContent: "flex-end",
    "@media(max-width: 500px)": {
      marginTop: "100%",
    },
  },
  contentRow: {
    alignItems: "flex-start",
  },
  charactersLeftContainer: {},
  charactersLeft: {
    color: globalStyles.colors.neutral.neutral1,
    fontWeight: "normal",
  },
  progressBar: {
    backgroundColor: globalStyles.colors.neutral.neutral3,
    borderRadius: 100,
  },
  questionTextArea: {
    display: "block",
    width: "100%",
    borderRadius: "8px",
    minHeight: "120px",
  },
  questionLimitArea: {
    marginBottom: 30,
    fontFamily: "IBM Plex Sans",
    fontSize: 14,
    color: "#737373",
    letterSpacing: "0.25px",
  },
  inputContainer: {
    width: "100%",
  },
};
