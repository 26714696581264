import * as yup from "yup";
import { USER_ROLE, PAYMENT_OPTIONS } from "../../constants";

const avatarSchema = yup
  .object()
  .shape({
    medium: yup.string(),
    small: yup.string(),
  })
  .required();

export const basicUserSchema = yup
  .object()
  .shape({
    userId: yup.number().integer().required(),
    userRoleId: yup.number().oneOf(Object.values(USER_ROLE)),
    token: yup.string().required(),
  })
  .noUnknown(true)
  .strict()
  .required();

export const userSchema = yup
  .object()
  .shape({
    id: yup.number().positive().integer().required(),
    name: yup.string().required(),
    avatar: avatarSchema,
  })
  .required();

const creditCardMustationSchema = yup.object().shape({
  holderName: yup.string(),
  number: yup.string().min(12).max(19).required(),
  expirationDate: yup.string().matches(/\d\d\d\d-\d\d/),
  cvc: yup.string().min(3).max(4).required(),
});

export const userMutationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
  phone: yup.string(),
  affiliateId: yup.string(),
  marketingSourceKey: yup.string(),
  productId: yup.number().integer().required(),
  paymentMethodId: yup.number().oneOf(Object.values(PAYMENT_OPTIONS)),
  creditCard: creditCardMustationSchema.required(),
  couponName: yup.string(),
});

const publisherUserSchema = yup
  .object()
  .shape({
    slug: yup.string().required(),
    isFeaturedContributor: yup.bool().required(),
  })
  .required()
  .concat(userSchema);

export const userProfileSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string(),
    phone: yup.string(),
  })
  .noUnknown(true)
  .strict()
  .required();

export const addUserCardSchema = yup.object().shape({
  // holderName: yup.string(),
  number: yup.string().required(),
  expirationDate: yup.string().required(),
  cvc: yup.string().required(),
});

export const publisherProfileSchema = yup
  .object()
  .shape({
    twitterTag: yup.string(),
    numTwitterFollowers: yup.number().positive().integer(),
    bio: yup.string().required(),
  })
  .noUnknown(true)
  .strict()
  .required();

const attachmentSchema = yup
  .object()
  .shape({
    attachmentTypeId: yup.number().positive().integer().required(),
    title: yup.string(),
    name: yup.string(),
    data: yup.string(),
    mediaId: yup.string(),
    thumbnailUrl: yup.string(),
    embedCode: yup.string(),
  })
  .noUnknown(true)
  .strict()
  .required();

const repliesSchema = yup.object().shape({
  id: yup.number().positive().integer().required(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
  content: yup.string().required(),
  attachments: yup.array().of(attachmentSchema),
});

export const questionMutationSchema = yup
  .object()
  .shape({
    publisherId: yup.number().positive().integer(),
    title: yup.string(),
    content: yup.string().required(),
    attachments: yup.array().of(attachmentSchema),
  })
  .noUnknown(true)
  .strict()
  .required();

export const postVideoAttachmentSchema = yup
  .object()
  .shape({
    mediaId: yup.string(),
    thumbnailUrl: yup.string(),
    embedCode: yup.string(),
  })
  .noUnknown(true)
  // .strict()
  .nullable();

export const postMutationSchema = yup
  .object()
  .shape({
    authorId: yup.number().positive().integer().required(),
    contentAvailabilityId: yup.number().positive().integer().required(),
    isHidden: yup.bool().required(),
    postContentTypeId: yup.number().positive().integer().required(),
    isDraft: yup.bool().required(),
    title: yup.string().required(),
    slug: yup.string(),
    excerpt: yup.string(),
    content: yup.string().required(),
    embeddedVideo: postVideoAttachmentSchema,
    isCommunity: yup.bool().required(),
    image: yup.string(),
    tags: yup.array().of(yup.mixed()),
    suggestedPostIds: yup.array().of(yup.mixed()),
    educationTopicIds: yup.array().of(yup.mixed()),
  })
  .noUnknown()
  .strict()
  .required();

export const questionSchema = yup.object().shape({
  id: yup.number().positive().integer().required(),
  author: userSchema,
  publisher: publisherUserSchema,
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
  slug: yup.string().required(),
  title: yup.string().required(),
  content: yup.string().required(),
  attachments: yup.array().of(attachmentSchema),
  replies: yup.array().of(repliesSchema),
});

export const postSchema = yup
  .object()
  .shape({
    id: yup.number().positive().integer().required(),
    author: userSchema,
    contentTypeId: yup.number().positive().integer().required(),
    contentAvailabilityId: yup.number().positive().integer().required(),
    isCommunity: yup.bool().required(),
    publishedAt: yup.date(),
    title: yup.string().required(),
    slug: yup.string().required(),
    url: yup.string().required(),
    image: yup.object().shape({
      small: yup.string(),
      original: yup.string(),
    }),
    stripTags: yup.bool(),
    isDraft: yup.bool(),
    isHidden: yup.bool(),
    isFavourite: yup.bool(),
    views: yup.object().shape({
      total: yup.number().integer().required(),
      seenByCurrentUser: yup.bool(),
    }),
    likes: yup.object().shape({
      total: yup.number().integer().required(),
      likedByCurrentUser: yup.bool(),
    }),
    embeddedVideo: postVideoAttachmentSchema,
    tags: yup.array().of(yup.mixed()),
    educationTopics: yup.array().of(yup.mixed()),
    suggestedPostIds: yup.array().of(yup.mixed()),
    excerpt: yup.string().nullable(),
    content: yup.string(),
  })
  .noUnknown()
  .strict()
  .required();

export const questionsSchema = yup.array().of(questionSchema);

export const postsSchema = yup.array().of(postSchema);

export const replyMutationSchema = yup
  .object()
  .shape({
    questionId: yup.number().positive().integer().required(),
    content: yup.string().required(),
    attachments: yup.array().of(attachmentSchema),
  })
  .noUnknown(true)
  .strict()
  .required();

// const contentValues = Object.values(CONTENT_ACCESS_PERMISSIONS).reduce((sum, val) => ({
//   ...sum,
//   ...{
//     [val]: yup.mixed(),
//   },
// }), {});

export const subscriptionResponseSchema = yup.object().shape({
  invoiceId: yup.number().required(),
  url: yup.string().required(),
  expirationTime: yup.string().required(),
});

const latestSubscriptionSchema = yup.object().shape({
  id: yup.number().positive().integer().required(),
  nextRenewal: yup.string().required(),
  renewalPaymentMethodId: yup.number().positive().integer().required(),
  subscriptionStatusId: yup.number().positive().integer().required(),
});

export const subscribedPublishersProductSchema = yup.object().shape({
  ask: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    cost: yup.number().required(),
    billingCycle: yup.number().required(),
    capIds: yup.array().of(yup.number()),
    isPublisherSpecific: yup.bool().required(),
    maxActiveSubscriptions: yup.number().required(),
    maxQuestionsPerAskerPerCycle: yup.number().required(),
    latestSubscription: latestSubscriptionSchema,
    questionsStatistic: yup.object().shape({
      numQuestionsRemainingUntilNextRenewal: yup.number().integer(),
      numQuestionsAsked: yup.number().integer(),
    }),
  }),
  view: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    cost: yup.number().required(),
    billingCycle: yup.number().required(),
    capIds: yup.array().of(yup.number()),
    isPublisherSpecific: yup.bool().required(),
    latestSubscription: latestSubscriptionSchema,
    maxActiveSubscriptions: yup.number().nullable(true),
  }),
});

const subscribedPublishersSchema = yup.object().shape({
  id: yup.number().positive().integer().required(),
  name: yup.string().required(),
  slug: yup.string().required(),
  ownAffiliateKey: yup.string().required(),
  isFeaturedContributor: yup.bool().required(),
  products: subscribedPublishersProductSchema,
});

export const subscriptionSchema = yup
  .object()
  .shape({
    // numQuestionsAsked: yup.number().integer(),
    numQuestionsAskedToAllPublishers: yup.number().integer(),
    subscribedPublishers: yup.array().of(subscribedPublishersSchema),
  })
  .noUnknown(true)
  .strict()
  .required();

export const paymentHistorySchema = yup.array().of(
  yup
    .object()
    .shape({
      product: yup
        .object()
        .shape({
          id: yup.number().positive().integer().required(),
          name: yup.string().required(),
          cost: yup.number().required(),
        })
        .noUnknown(true)
        .strict()
        .required(),
      transactionStatusId: yup.number().positive().integer().required(),
      paymentMethodId: yup.mixed().oneOf(Object.values(PAYMENT_OPTIONS)),
      amount: yup.number(),
      date: yup.date().required(),
      refundedAmount: yup.number(),
    })
    .noUnknown(true)
    .strict()
    .required()
);

export const allUserCardsSchema = yup
  .object()
  .shape({
    defaultCardId: yup.string().nullable(),
    cards: yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        brand: yup.string().required(),
        expirationDate: yup.string().required(),
        last4: yup.string().required(),
        // holderName: yup.string().required(),
      })
    ),
  })
  .noUnknown(true)
  .strict()
  .required();

/*
contentAccessPermissions defines what permissions the user has.
{
  "contentAccessPermissions": {
    "1": false,
    "2": false,
    "3": true,
    "4": true,
    "5": false
  }
}
*/

export const fileUploadResponseSchema = yup
  .object()
  .shape({
    url: yup.string().required(),
  })
  .noUnknown(true)
  .strict()
  .required();

export const avatarUploaderSchema = yup
  .object()
  .shape({
    cropProperties: yup.object().shape({
      x: yup.number().required(),
      y: yup.number().required(),
      width: yup.number().required(),
      height: yup.number().required(),
    }),
    original: yup.string().required(),
    medium: yup.string().required(),
    small: yup.string().required(),
  })
  .noUnknown(true)
  .strict()
  .required();

export const productSchema = {
  ask: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    cost: yup.number().required(),
    billingCycle: yup.number().required(),
    capIds: yup.array().of(yup.number()),
    isPublisherSpecific: yup.bool().required(),
    maxActiveSubscriptions: yup.number().required(),
    maxQuestionsPerAskerPerCycle: yup.number().required(),
  }),
  view: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    cost: yup.number().required(),
    billingCycle: yup.number().required(),
    capIds: yup.array().of(yup.number()),
    isPublisherSpecific: yup.bool().required(),
    maxActiveSubscriptions: yup.number().nullable(true),
  }),
};

export const publiserStats = yup.object().shape({
  activeSubscribers: yup.object().shape({
    numViewers: yup.string().required(),
    numAskers: yup.string().required(),
  }),
  products: yup.object().shape(productSchema),
  questions: yup.object().shape({
    averageResponseTimeInHours: yup.number().required(),
    numTotal: yup.number().required(),
    numAnswered: yup.number().required(),
  }),
});

export default {};
