import {
  ATTEMPT_LOAD_SUBSCRIPTIONS,
  ATTEMPT_LOGIN,
  LOAD_SUBSCRIPTIONS_FAILED,
  LOAD_SUBSCRIPTIONS_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGN_UP_ATTEMPT,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
} from './actions';
import defaultState from './defaultState';

/**
 * userReducer
 *
 * Standard reducer. Note that we copy all of the payload
 * items into the redux store.
 */
const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ATTEMPT_LOGIN:
    case SIGN_UP_ATTEMPT:
    case ATTEMPT_LOAD_SUBSCRIPTIONS:
      return {
        ...state,
        ...{
          email: action.payload.email,
          pendingRequest: action.payload.pendingRequest,
          error: null,
        },
      };
    case LOGIN_SUCCESS:
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case LOGIN_FAILED:
    case SIGN_UP_FAILED:
      return {
        ...defaultState.user,
        ...{ error: action.payload.error },
      };
    case LOAD_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        ...{
          error: action.payload.error,
          pendingRequest: action.payload.pendingRequest,
        },
      };
    case LOAD_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionData: action.payload.subscriptions,
      };
    case LOGOUT:
      return { ...defaultState.user };
    default:
      return state;
  }
};

export default userReducer;
