import color from "color";

import styles from "../../../styles";

export const buttonStyles = {
  button: {
    ...styles.typography.small,
    color: "#fff",
    background: "#356859",
    borderRadius: "10px",
    padding: "9px 16px",
    border: "0px none",
    borderColor: "#356859",
    outline: "none",
    textShadow: "0px 1px #00000024",
    ...styles.component.transistion(),
    ...styles.component.materialShadow,
    "&:hover": {
      ...styles.component.materialShadowHover,
      textShadow: "0px 1px #00000066",
      background: color("#356859").lighten(0.1).hex(),
      cursor: "pointer",
    },
    "&:active": {
      background: color("#356859").darken(0.2).hex(),
    },
    "&:disabled": {
      cursor: "wait",
      background: color("#356859").lighten(0.5).hex(),
      ...styles.component.materialShadow,
    },
  },
  secondary: {
    backgroundColor: styles.colors.primary.secondary,
    "&:hover": {
      ...styles.component.materialShadowHover,
      textShadow: "0px 1px #00000066",
      background: color(styles.colors.primary.secondary).lighten(0.1).hex(),
      cursor: "pointer",
    },
    "&:active": {
      background: color(styles.colors.primary.secondary).darken(0.2).hex(),
    },
    "&:disabled": {
      cursor: "wait",
      background: color(styles.colors.primary.secondary).lighten(0.5).hex(),
      ...styles.component.materialShadow,
    },
  },
  ghost: {
    backgroundColor: styles.colors.neutral.neutral1,
    color: styles.colors.neutral.neutral4,
    "&:hover": {
      ...styles.component.materialShadowHover,
      textShadow: "0px 1px #00000011",
      background: color(styles.colors.neutral.neutral1).lighten(0.1).hex(),
      cursor: "pointer",
    },
    "&:active": {
      background: color(styles.colors.neutral.neutral1).darken(0.2).hex(),
    },
    "&:disabled": {
      cursor: "wait",
      background: color(styles.colors.neutral.neutral3).lighten(0.2).hex(),
      border: "1px solid",
      borderColor: color(styles.colors.neutral.neutral2).darken(0.4).hex(),
      color: color(styles.colors.neutral.neutral3).darken(0.5).hex(),
      ...styles.component.materialShadow,
    },
  },
  ghostFlat: {
    border: "1px solid",
    borderColor: styles.colors.neutral.neutral4,
  },
  flat: {
    boxShadow: "none",
    textShadow: "none",
  },
};

export default buttonStyles;
