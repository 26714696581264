import _get from "lodash/get";

import {
  ADD_POST_ATTEMPT,
  ADD_POST_FAILED,
  ADD_POST_SUCCESS,
  LOAD_POSTS_ATTEMPT,
  LOAD_POSTS_FAILED,
  LOAD_POSTS_SUCCESS,
  LOAD_POST_ATTEMPT,
  LOAD_POST_FAILED,
  LOAD_POST_SUCCESS,
  CLEAR_POSTS,
} from "./actions";
import defaultState from "./defaultState";

const postsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_POST_ATTEMPT:
    case LOAD_POSTS_ATTEMPT:
    case ADD_POST_ATTEMPT:
      return {
        ...state,
        ...{
          pendingRequest: true,
        },
      };
    case LOAD_POSTS_FAILED:
    case ADD_POST_FAILED:
    case LOAD_POST_FAILED:
      return {
        ...state,
        ...{
          pendingRequest: false,
          error: action.payload,
        },
      };
    case LOAD_POST_SUCCESS: {
      const { post } = action.payload;
      const byPublisher = { ...state.idsByPublisherId };
      const byID = {};

      const publisherID = _get(post, "publisher.id");
      const postID = post.id;
      byPublisher[publisherID] = [postID]
        .concat(byPublisher[publisherID])
        .filter((q) => !!q);
      byID[postID] = post;

      return {
        ...state,
        ...{
          idsByPublisherId: byPublisher,
          postsById: { ...state.postsById, ...byID },
          pendingRequest: false,
        },
      };
    }
    case LOAD_POSTS_SUCCESS: {
      const posts = action.payload;
      const byPublisher = { ...state.idsByPublisherId };
      const byID = {};
      if (!Array.isArray(posts)) {
        throw new Error("posts is not an array");
      }
      posts.forEach((post) => {
        const publisherID = _get(post, "author.id");
        const postID = post.id;
        byPublisher[publisherID] = [postID]
          .concat(byPublisher[publisherID])
          .filter((q) => !!q);
        byID[postID] = post;
      });
      return {
        ...state,
        ...{
          idsByPublisherId: byPublisher,
          postsById: { ...state.postsById, ...byID },
          pendingRequest: false,
          postsBypublisher: posts,
        },
      };
    }
    case ADD_POST_SUCCESS: {
      const post = action.payload;
      const publisherID = _get(post, "publisher.id");
      const postID = post.id;

      const byPublisher = { ...state.idsByPublisherId };

      const byID = {};
      byPublisher[publisherID] = [postID]
        .concat(byPublisher[publisherID])
        .filter((q) => !!q);
      byID[postID] = post;

      return {
        ...state,
        ...{
          idsByPublisherId: byPublisher,
          postsById: { ...state.postsById, ...byID },
          pendingRequest: false,
        },
      };
    }
    case CLEAR_POSTS: {
      return {
        ...state,
        ...{
          idsByPublisherId: {},
          postsById: {},
          pendingRequest: false,
        },
      };
    }
    default:
      return state;
  }
};

export default postsReducer;
