import _get from "lodash/get";
import _set from "lodash/set";
import _pick from "lodash/pick";
import _last from "lodash/last";
import _isEmpty from "lodash/isEmpty";
import NotificationService from "../../../components/shared/Notification";
import ApplicationClient from "../core";
import {
  allUserCardsSchema,
  addUserCardSchema,
  avatarUploaderSchema,
  basicUserSchema,
  fileUploadResponseSchema,
  paymentHistorySchema,
  publiserStats,
  publisherProfileSchema,
  subscriptionSchema,
  userMutationSchema,
  userProfileSchema,
  subscriptionResponseSchema,
} from "../../schemas";
import { USER_ROLE, PAYMENT_OPTIONS } from "../../../constants";
import { redirectUser } from "../../utils/redirect-user";

/*
 * TODO, consider storing the axios.create outside of this,
 * and passing it to any other services we make.
 * */
export class UserClient extends ApplicationClient {
  LOGIN_URL = "/user/login";

  REGISTER_URL = "user/register";

  CREATOR_REGISTER_URL = "publisher/register";

  loadFromLocalStorage() {
    const rawUserData = window.sessionStorage.getItem("user");
    if (!rawUserData) {
      return null;
    }
    let userData;
    try {
      userData = JSON.parse(rawUserData);
    } catch (error) {
      // Log into the console, but ignore.
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
    this.userObject = userData;
    return userData;
  }

  storeInLocalStorage() {
    const { userObject } = this;
    const seralizedData = JSON.stringify(userObject);
    window.sessionStorage.setItem("user", seralizedData);
  }

  login(email, password) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .post(this.LOGIN_URL, { email, password })
        .then((response) => {
          if (response.status === 200) {
            this.setToken(response.data);
            this.storeInLocalStorage();
          }
          resolve(response);
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  register_User(userData) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(this.REGISTER_URL, userData)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Server responded with a non 200 status");
          }
          resolve(response);
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  get_invoice_status(invoiceId) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`payment/status/${invoiceId}`, {
          data: null,
        })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Server responded with a non 200 status");
          }
          resolve(response);
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  signUp(userData) {
    if (!userMutationSchema.validateSync(userData)) {
      throw new Error("Invalid schema for user");
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(this.REGISTER_URL, userData)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Server responded with a non 200 status");
          }
          const { data: user } = response;

          if (!basicUserSchema.validateSync(user)) {
            throw new Error("Server responded invalid user type");
          }
          this.setToken(user);
          this.storeInLocalStorage();
          resolve(response);
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  creatorSignUp(userData) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(this.CREATOR_REGISTER_URL, userData)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  creatorprofileUpdate(id, data) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .patch(`publisher/${id}`, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  userprofileUpdate(id, data) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .patch(`user/${id}`, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
  updateProfilePic(id, data) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(`user/avatar/${id}`, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  uploadIntroVideo(id, data) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(`user/featured-video/${id}`, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  getProductDetails(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`product/list/by-ids/${id}`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  logout() {
    this.userObject = {};
    window.sessionStorage.clear();
    this.networkClient.defaults.headers = {
      "Content-Type": "application/json",
    };
  }

  fetchUserData(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`user/${id}`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            sessionStorage.setItem("name", response.data.name);
            resolve(response.data);
          } else {
            const prettyErrorMessage = _get(
              response,
              "data.validationErrors",
              _get(response, "data.error", "Unknown Error")
            );
            reject(prettyErrorMessage);
          }
        })
        .catch((error) => {
          if (_get(error, "response.status", "") === 401) {
            this.logout();
            // Attempt to extract the slug.
            const pathname = _get(window, "location.pathname", "");
            const slug = _last(pathname.split("/"));
            if (!redirectUser(slug)) {
              this.logout();
            }
          }
          reject(error);
        });
    });
  }

  setUserData(data, currentProfile) {
    if (!userProfileSchema.validateSync(data)) {
      throw new Error("Invalid profile setting shape detected");
    }
    if (!this.userObject || !this.userObject.token || !this.userObject.userId) {
      throw new Error("You must be logged in to edit your profile");
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .patch(`user/${this.userObject.userId}`, {
          data: {
            // Need to start with user's current settings.
            ..._pick(currentProfile, ["name", "email", "password"]),
            ...data,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve({
              ...response.data,
              ...data,
            });
            // TODO: the server doesn't send back the data.
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  setPublisherProfile(data, currentProfile) {
    if (!publisherProfileSchema.validateSync(data)) {
      throw new Error("invalid profile setting shape detected");
    }
    if (
      !this.userObject ||
      !this.userObject.token ||
      !this.userObject.userId ||
      this.userObject.userRoleId !== USER_ROLE.PUBLISHER
    ) {
      throw new Error(
        "You must be logged in and a publisher to edit your publisher profile"
      );
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .patch(`publisher/${this.userObject.userId}`, {
          ..._pick(currentProfile, [
            "twitterTag",
            "numTwitterFollowers",
            "bio",
          ]),
          ...data,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  setDefaultCard(cardId) {
    if (!this.userObject || !this.userObject.token || !this.userObject.userId) {
      throw new Error("You must be logged in to add a new card");
    }

    return new Promise((resolve, reject) => {
      this.networkClient
        .post(`payment/${this.userObject.userId}/card/${cardId}/default`, {})
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchLatestAskPriceForCurrentUser(subscriptionId) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`subscription/upgrade/view-to-ask/cost/${subscriptionId}`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          } else {
            const prettyErrorMessage = _get(
              response,
              "data.validationErrors",
              _get(response, "data.error", "Unknown Error")
            );
            reject(prettyErrorMessage);
          }
        })
        .catch((error) => {
          if (_get(error, "response.status", "") === 401) {
            this.logout();
            // Attempt to extract the slug.
            const pathname = _get(window, "location.pathname", "");
            const slug = _last(pathname.split("/"));
            if (!redirectUser(slug)) {
              this.logout();
            }
          }
          reject(error);
        });
    });
  }

  addUserCard(data) {
    if (!addUserCardSchema.validateSync(data)) {
      throw new Error("invalid credit card information");
    }
    if (!this.userObject || !this.userObject.token || !this.userObject.userId) {
      throw new Error("You must be logged in to add a new card");
    }
    const fieldToHuman = {
      // holderName: 'Cardholder Name',
      number: "Credit Card Number",
      expirationDate: "Expiration Data",
      cvc: "Card Security Number",
    };

    Object.keys(fieldToHuman).forEach((field) => {
      if (!_get(data, field, false)) {
        throw new Error(`${fieldToHuman[field]} cannot be blank`);
      }
    });

    return new Promise((resolve, reject) => {
      this.networkClient
        .put(`payment/${this.userObject.userId}/card`, data)
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchPublisherBySlug(slug) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`publisher/by-slug/${slug}/`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchPublisherById(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`publisher/${id}/`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchNotification(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`notification-preference/${id}/all`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
  setNotificationPreference(id, data) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(`notification-preference/${id}/all`, data)
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchPublisherStats(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`question/publisher/stats/general/${id}`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchSubscription() {
    const slug =
      window.location.pathname &&
      window.location.pathname.substring(
        1,
        window.location.pathname.lastIndexOf("/") + 0
      );
    return new Promise((resolve, reject) => {
      const { userObject } = this;
      if (!userObject || !userObject.token || !userObject.userId) {
        throw new Error(
          "You must be logged in to get your subscription status"
        );
      }

      this.networkClient
        .get(`question/subscriber-stats/${userObject.userId}`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            let status =
              response.data.subscribedPublishers &&
              response.data.subscribedPublishers.length > 0
                ? response.data.subscribedPublishers.filter(
                    (data) => data.name === slug
                  )
                : [];
            sessionStorage.setItem(
              "subscription",
              JSON.stringify(status[0] && status[0].name)
            );
            resolve(response.data);
            return;
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchPaymentHistory(data) {
    const { userObject } = this;
    const viewID = data.view.id ? data.view.id : null;
    const askID = data.ask.id ? data.ask.id : null;
    const url =
      viewID === null
        ? `payment/history/${userObject.userId}/${askID}`
        : askID === null
        ? `payment/history/${userObject.userId}/${viewID}`
        : `payment/history/${userObject.userId}/${viewID},${askID}`;
    return new Promise((resolve, reject) => {
      if (!userObject || !userObject.token || !userObject.userId) {
        throw new Error(
          "You must be logged in to get your subscription status"
        );
      }

      this.networkClient
        .get(url, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
            return;
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  deleteCard(cardId) {
    return new Promise((resolve, reject) => {
      const { userObject } = this;
      this.networkClient
        .delete(`payment/${userObject.userId}/card/${cardId}`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(true);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchCreditCardData() {
    return new Promise((resolve, reject) => {
      const { userObject } = this;
      if (!userObject || !userObject.token || !userObject.userId) {
        throw new Error(
          "You must be logged in to get your subscription status"
        );
      }
      this.networkClient
        .get(`payment/${userObject.userId}/card/all`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
            return;
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  uploadImage(files) {
    return new Promise((resolve, reject) => {
      // Validate everything first.
      const { userObject } = this;
      if (!userObject || !userObject.token || !userObject.userId) {
        throw new Error(
          "You must be logged in to get your subscription status"
        );
      }
      if (!files || files.length === 0) {
        throw new Error("No file detected");
      }
      // Construct multipart form.
      const form = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        form.append(`files[${i}]`, files.item(i));
      }
      this.networkClient
        .post("media/image", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            if (fileUploadResponseSchema.validateSync(response.data)) {
              resolve(response.data);
              return;
            }
            reject(new Error("Issue with parsing the response data"));
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  uploadAvatar(data) {
    return new Promise((resolve, reject) => {
      // Validate everything first.
      const { userObject } = this;
      if (!userObject || !userObject.token || !userObject.userId) {
        throw new Error(
          "You must be logged in to get your subscription status"
        );
      }
      if (!avatarUploaderSchema.validateSync(data)) {
        throw new Error("Data malformed");
      }

      this.networkClient
        .put(`user/${userObject.userId}/avatar/`, data)
        .then((response) => {
          if (response.status === 200 && response.data) {
            // Add random string after avatar URL, since server doesn't
            // change avatar URL when user uploads a new one, we must
            // force the browser to think it's a new avatar.
            ["medium", "original", "small"].forEach((size) => {
              const org = _get(response.data, `avatar.${size}`);
              _set(response.data, `avatar.${size}`, `${org}&${Math.random()}`);
            });
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  renewSubscription(productId) {
    const { userObject } = this;
    if (!productId) {
      throw new Error("Missing product ID");
    }
    if (!userObject || !userObject.token || !userObject.userId) {
      throw new Error("You must be logged in to get your subscription status");
    }
    const data = {
      targetUserId:userObject.userId,
      productId:productId,
      paymentMethodId: PAYMENT_OPTIONS.CREDITCARD, // CC hardcoded, only option.
      // couponName: 'coupon 1', // We don't have coupons
    };
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(`subscription/buy`, data)
        .then((response) => {
          if (response.status === 200 && response.data) {
            // window.location = window.location.href;
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  renewSubscriptionWithUpdatedCost(productId) {
    const { userObject } = this;
    if (!productId) {
      throw new Error("Missing product ID");
    }
    if (!userObject || !userObject.token || !userObject.userId) {
      throw new Error("You must be logged in to get your subscription status");
    }
    const data = {
      productId,
      paymentMethodId: PAYMENT_OPTIONS.CREDITCARD, // CC hardcoded, only option.
      // couponName: 'coupon 1', // We don't have coupons
    };
    return new Promise((resolve, reject) => {
      this.networkClient
        .post(`subscription/upgrade/view-to-ask/${productId}`, data)
        .then((response) => {
          if (response.status === 200 && response.data) {
            // if (_isEmpty(response.data)) {
            //   window.location = window.location.href;
            //   resolve(response.data);
            // }
            // if (subscriptionResponseSchema.validateSync(response.data)) {
            // window.location = window.location.href;
            resolve(response.data);
            // }
            // reject(new Error('Issue with parsing the response data'));
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  reenableSubscription() {
    const { userObject } = this;
    const subscriptionId = sessionStorage.getItem("subscriptionId");
    if (!userObject || !userObject.token || !userObject.userId) {
      throw new Error("You must be logged in to get your subscription status");
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .patch(`subscription/re-enable/${subscriptionId}`, {})
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  cancelSubscription() {
    const { userObject } = this;
    const subscriptionId = sessionStorage.getItem("subscriptionId");
    if (!userObject || !userObject.token || !userObject.userId) {
      throw new Error("You must be logged in to get your subscription status");
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .patch(`subscription/cancel/${subscriptionId}`, {})
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  gettransactionDetails(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`payment/transaction/list/${id}/300/0`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  getmemberStats(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`question/publisher/stats/members/${id}`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
  getRecentearnings(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`payout/${id}/recent-earnings`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
  downloadCsv(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`payment/transaction/list/${id}/csv`, {
          data: null,
          responseType: "blob",
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
  storeEmail(data) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(`presale/email`, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
  storeEmailWaitlist(data) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(`subscription/waitlist/add-email`, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  resetpassword(data) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`user/reset-password-request/${data.email}`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
}
export default new UserClient();
