import globalStyles from '../../../styles';

export default {
  root: {
    borderRadius: 100,
    display: 'inline-block',
    padding: '4px 24px',
  },
  text: {
    ...globalStyles.typography.body,
    fontSize: 12,
    fontWeight: 'bold',
  },
  variants: {
    primary: {
      root: {
        backgroundColor: globalStyles.colors.primary.accent,
      },
      text: {
        color: globalStyles.colors.neutral.neutral1,
      },
    },
    success: {
      root: {
        backgroundColor: '#5fdc94',
      },
      text: {
        color: globalStyles.colors.neutral.neutral1,
      },
    },
    warning: {
      root: {
        backgroundColor: '#ffc424',
      },
      text: {
        color: globalStyles.colors.neutral.neutral1,
      },
    },
    error: {
      root: {
        backgroundColor: '#fe3c3c',
      },
      text: {
        color: globalStyles.colors.neutral.neutral1,
      },
    },
  },
};
