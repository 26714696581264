/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { View, Text, Button, Link } from "../../core";
import { ServerSideErrors } from "../serverSideError";
import { APP_URLS } from "../../../constants";
import styles from "./styles";
import CheckBlack from "./img/check_black.svg";
import CheckWhite from "./img/check_white.svg";
import intersect1 from "./img/intersect1.svg";
import intersect2 from "./img/intersect2.svg";
import infoIcon from "./img/info.svg";
import UserService from "../../../services/api/user";
import { useTriggerableFetchRemoteResource } from "../../../services/utils/custom-hooks";
import { useEffect } from "react";
import { useState } from "react";
import WaitlistModal from "./waitlistModal";
import NotificationService from "../Notification";
import UserClient  from "../../../services/api/user";

const CTAButton = ({
  ctaLink,
  prodId,
  buttonText,
  customCostUpgradation,
  subscriptionId,
  view,
  id,
  details,
}) => {
  const [open, setOpen] = useState(false);
  const [btcPaymentUrl, setbtcPaymentUrl] = useState(null);
  const [disable,setDisable] = useState(false)
  // const [{ loading1, data1, requested1, error1 }, triggerCall1] =
  //   useTriggerableFetchRemoteResource(
  //     UserService.renewSubscription.bind(UserService),
  //     prodId
  //   );

    const triggerCall1 =async()=>{
      setDisable(true)
      const apiData = await UserService.renewSubscription(prodId)
      if (apiData) {
        const getUrl = await UserClient.get_invoice_status(
          apiData.paymentKey
        );
        if (getUrl.data.redirectUrl) {
          setbtcPaymentUrl(
            getUrl.data.redirectUrl ? getUrl.data.redirectUrl : null
          );
        }
        const intervalId = setInterval(async () => {
          const invoiceStatus = await UserClient.get_invoice_status(
            apiData.paymentKey
          );
          if (invoiceStatus && invoiceStatus.data.paymentStatusId === 5) {
            setDisable(false)
            NotificationService.success("Success");
            setbtcPaymentUrl(null)
            clearInterval(intervalId);
            sessionStorage.setItem("membership", true);
            window.location = window.location.href;
          } else if (
            invoiceStatus &&
            invoiceStatus.data.paymentStatusId === 4
          ) {
            setDisable(false)
            NotificationService.error(invoiceStatus.data.error);
            setbtcPaymentUrl(null);
            clearInterval(intervalId);
          }
        }, 3000);
      }
    }

  // const [{ loading2, data2, requested2, error2 }, triggerCall2] =
  //   useTriggerableFetchRemoteResource(
  //     UserService.renewSubscriptionWithUpdatedCost.bind(UserService),
  //     subscriptionId
  //   );

    const triggerCall2 =async()=>{
      setDisable(true)
    const apiData = await UserService.renewSubscriptionWithUpdatedCost(subscriptionId)
    if (apiData) {
      const getUrl = await UserClient.get_invoice_status(
        apiData.paymentKey
      );
      if (getUrl.data.redirectUrl) {
        setbtcPaymentUrl(
          getUrl.data.redirectUrl ? getUrl.data.redirectUrl : null
        );
      }
      const intervalId = setInterval(async () => {
        const invoiceStatus = await UserClient.get_invoice_status(
          apiData.paymentKey
        );
        if (invoiceStatus && invoiceStatus.data.paymentStatusId === 5) {
          setDisable(false)
          NotificationService.success("Success");
          setbtcPaymentUrl(null)
          clearInterval(intervalId);
          sessionStorage.setItem("membership", true);
          window.location = window.location.href;
        } else if (
          invoiceStatus &&
          invoiceStatus.data.paymentStatusId === 4
        ) {
          setDisable(false)
          NotificationService.error(invoiceStatus.data.error);
          setbtcPaymentUrl(null);
          clearInterval(intervalId);
        }
      }, 3000);
    }
    }

 
 


  const triggerNotify = async (id) => {
    const data = {
      email: details.email,
      productId: id,
    };
    const apiData = await UserService.storeEmailWaitlist(data).then((resp) => {
      if ((resp.status = 200 && resp.data.message)) {
        NotificationService.error(resp.data.message);
      } else if ((resp.status = 200)) {
        NotificationService.success(
          "Got it! You'll be notified when a membership opens up."
        );
      }
    });
  };

  if (prodId) {
    return (
      <View f="none">
         {btcPaymentUrl !== null && (
        <div>
          <iframe
            style={{
              height: 405,
              width:"100%",
              border: "none",
            }}
            src={btcPaymentUrl}
            title="paymentUrl"
          ></iframe>
        </div>
      )}
        <Button
          style={{
            color: view ? "#fff" : "#356859",
            background: view ? "#356859" : "#fff",
          }}
          disabled={ disable}
          css={styles.button}
          onClick={() => {
            if (prodId && buttonText !== "Join waitlist") {
            const fetchData=customCostUpgradation
                ? triggerCall2()
                : triggerCall1()
            } else {
              triggerNotify(prodId);
            }
          }}
        >
          {buttonText}
        </Button>
      </View>
    );
  }
  return buttonText !== "Join waitlist" ? (
    <Link to={ctaLink}>
      <Button
        style={{
          color: view ? "#fff" : "#356859",
          background: view ? "#356859" : "#fff",
        }}
        disabled={disable}
        css={styles.button}
        onClick={() => {
          localStorage.setItem("view", view);
        }}
      >
        {buttonText}
      </Button>
    </Link>
  ) : (
    <div>
      <Button
        style={{
          color: view ? "#fff" : "#356859",
          background: view ? "#356859" : "#fff",
        }}
        disabled={disable}
        css={styles.button}
        onClick={() => {
          localStorage.setItem("view", view);
          setOpen(true);
        }}
      >
        {buttonText}
      </Button>
      <WaitlistModal open={open} id={id} onclose={() => setOpen(false)} />;
    </div>
  );
};

CTAButton.propTypes = {
  ctaLink: PropTypes.string,
  prodId: PropTypes.number,
  buttonText: PropTypes.string,
  customCostUpgradation: PropTypes.bool,
  subscriptionId: PropTypes.number,
};

CTAButton.defaultProps = {
  ctaLink: APP_URLS.LOGIN.url(""),
  prodId: undefined,
  buttonText: "Join",
  customCostUpgradation: false,
  subscriptionId: null,
};

const SelectableButton = ({ selected, onClick }) => (
  <button
    type="button"
    css={{
      ...styles.selectedButton,
      ...(selected ? styles.selectedButtonSelected : {}),
    }}
    onClick={onClick}
  >
    <img src={selected ? CheckWhite : CheckBlack} alt="selected icon" />
  </button>
);

SelectableButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const PricingCard = ({
  title,
  costLine,
  children,
  availability,
  onClick,
  selectable,
  selected,
  ctaLink,
  productId,
  buttonText,
  isNotUpgrade,
  subscriptionId,
  upgradeView,
  view,
  id,
  details,
  ...rest
}) => (
  <View
    style={{ background: view === true ? "#fff" : "#356859" }}
    css={styles.root}
    fd="column"
    {...rest}
    onClick={onClick}
  >
    {view === false ? (
      <img
        alt="icon"
        style={{ position: "absolute", right: "15px" }}
        src={intersect1}
      />
    ) : (
      ""
    )}
    <View>
      <Text
        type="h3"
        style={{ color: view === false ? "#fff" : "#356859" }}
        css={styles.title}
      >
        {title}
      </Text>
    </View>
    <View fg={1} css={styles.content} fd="column">
      <View mb={16} mt={12}>
        <Text
          style={{ color: view === false ? "#fff" : "#535353" }}
          css={styles.costLine}
        >
          {costLine}
          <label
            style={{
              fontSize: 20,
              color: view === false ? "#fff" : "#737373",
              lineHeight: "20px",
            }}
          >
            /month
          </label>
        </Text>
      </View>
      <View css={styles.description} mb={12}>
        {children}
      </View>
      <View data-testid="spacer" fg={1} />
      <Text css={styles.availability} mb={12}>
        <img style={{ height: "24px", marginBottom: 4 }} src={infoIcon} />{" "}
        {availability}
      </Text>
      {view === false ? (
        <img
          alt="icon"
          style={{
            position: "absolute",
            right: upgradeView === true ? "70px" : "0px",
            bottom: "0px",
          }}
          src={intersect2}
        />
      ) : (
        ""
      )}
      <View mb={8}>
        {selectable ? (
          <SelectableButton onClick={onClick} selected={selected} />
        ) : (
          <CTAButton
            ctaLink={ctaLink}
            prodId={productId}
            customCostUpgradation={isNotUpgrade}
            subscriptionId={subscriptionId}
            buttonText={buttonText}
            view={view}
            id={id}
            details={details}
          />
        )}
      </View>
    </View>
  </View>
);

PricingCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  costLine: PropTypes.string,
  availability: PropTypes.string,
  onClick: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  ctaLink: PropTypes.string,
  productId: PropTypes.number,
  buttonText: PropTypes.string,
  isNotUpgrade: PropTypes.bool,
  subscriptionId: PropTypes.number,
};

PricingCard.defaultProps = {
  children: "",
  title: "",
  costLine: "",
  availability: "",
  onClick: () => {},
  selectable: false,
  selected: false,
  ctaLink: "",
  productId: undefined,
  buttonText: "Join",
  isNotUpgrade: false,
  subscriptionId: null,
};

export default PricingCard;
