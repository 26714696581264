/** @jsx jsx */
import { jsx } from '@emotion/react';
import {
  Component,
  createRef,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
  Modal,
  View,
  Text,
  Button,
} from '../../core';

import styles from './styles';

const getCroppedImg = (image, crop) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve, reject) => {
    try {
      const base64Image = canvas.toDataURL('image/jpeg');
      resolve(base64Image);
    } catch (error) {
      reject(error);
    }
  });
};

class ImageCropper extends Component {
  static propTypes = {
    onEditAvatar: PropTypes.func.isRequired,
    forwardedRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
  }

  constructor(props) {
    super(props);
    this.inputRef = createRef();
    this.imageRef = createRef();
    this.state = {
      fileData: undefined,
      crop: { aspect: 1 },
      croppedImageUrl: undefined,
    };
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ fileData: reader.result }));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    // You could also use 2nd arg percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  uploadAvatar = () => {
    const { onEditAvatar } = this.props;
    const { crop, croppedImageUrl, fileData } = this.state;
    const imageData = croppedImageUrl || fileData;
    const data = {
      cropProperties: {
        x: Math.round(crop.x),
        y: Math.round(crop.y),
        width: Math.round(crop.width) || 200,
        height: Math.round(crop.height) || 200,
      },
      original: imageData,
      medium: imageData,
      small: imageData,
    };
    onEditAvatar(data);
  };

  confirmCrop = () => {
    this.uploadAvatar();
    this.setState({
      fileData: undefined,
    });
  }

  cancelCrop = () => {
    this.setState({ fileData: undefined });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg',
      );
      this.setState({ croppedImageUrl });
    }
  }

  render() {
    const { forwardedRef } = this.props;
    const { fileData, crop } = this.state;
    if (fileData) {
      return (
        <Modal isOpen onClose={this.cancelCrop}>
          <View fd="column" pb={12} css={styles.croppingModalContent}>
            <Text type="h3" centered>
              Crop your avatar
            </Text>
            <ReactCrop
              src={fileData}
              crop={crop}
              circularCrop
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              style={{ margin: '0 auto 12px' }}
            />
            <View css={styles.croppingModalActions}>
              <Button flat onClick={this.confirmCrop}>
                Upload
              </Button>
              <Button ghost flat onClick={this.cancelCrop}>
                Cancel
              </Button>
            </View>
          </View>
        </Modal>
      );
    }
    return (
      <input
        ref={forwardedRef}
        type="file"
        id="fileElem"
        accept="image/*"
        css={styles.visuallyHidden}
        onChange={this.onSelectFile}
      />
    );
  }
}

export default forwardRef((props, ref) => <ImageCropper {...props} forwardedRef={ref} />);
