/**
 * default Users state.
 *
 * @param {number[]} ids - is a flat list of the loaded user IDs,
 * @param {object} users- is an object of user profiles loaded, hashed by id
 * @param {boolean} pendingRequest - true if any requests are loading.
 * @param {object} error - the last error encountered
*/
export default {
  ids: [],
  slugs: {},
  users: {},
  pendingRequest: false,
  error: null,
};
