/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { useSelector } from "react-redux";
import _find from "lodash/find";
import { usePublisherParam } from "../../components/shared/hooks";
import parse from "date-fns/parse";
import format from "date-fns/format";

import { Divider, GridSystem, Text, View } from "../../components/core";
import { UserProfile, EditableUserProfile } from "../../components/shared";
import { USER_ROLE } from "../../constants";

import styles from "./styles";
import { NAV_POINTS_ENUM } from "./constants";
import { ProfileNav } from "./ProfileNav";
import Questions from "./Questions";
import data from "../fixture";
import clockIcon from "../../Images/clock.svg";

export const ProfilePage = ({
  name,
  avatar,
  bio,
  id,
  isOwnProfile,
  numTwitterFollowers,
  onEditBio,
  onEditAvatar,
  userRoleId,
  slug,
  creatorSlug,
  user,
}) => {
  const [activeNav, setActiveNav] = useState(NAV_POINTS_ENUM.OUTSTANDING);
  const [outstandingCount, setOutstandingCount] = useState(0);
  const UserProfileComponent = UserProfile;
  const userIsPublisher = userRoleId !== USER_ROLE.SUBSCRIBER && !!slug;
  const userData = useSelector((state) => state.user);
  const userId = _get(userData, "userId");
  const userSlug = usePublisherParam();
  const subScriptions = _get(userData, "subscriptionData.subscribedPublishers");
  // TODO: Problem is, when on my own profile, or settings page, the slug is not defined.
  const subscription = _find(subScriptions, (s) => s.slug === userSlug);
  let viewStatus = _get(subscription, "products.view.latestSubscription");
  let askStatus = _get(subscription, "products.ask.latestSubscription");
  const Membership =
    viewStatus !== null && viewStatus?.subscriptionStatusId !== 4
      ? viewStatus
      : askStatus;
  const canUserAskQuestion =
    askStatus !== null && askStatus && askStatus.subscriptionStatusId === 1
      ? true
      : false;
  let subscribedProduct;
  if (viewStatus?.id > askStatus?.id) {
    subscribedProduct = _get(subscription, "products.view");
  } else {
    subscribedProduct = _get(subscription, "products.ask");
  }
  const remainingQuestions = _get(
    subscribedProduct,
    "questionsStatistic.numQuestionsRemainingUntilNextRenewal",
    false
  );
  const nextRenewalDateRaw = _get(
    subscribedProduct,
    "latestSubscription.nextRenewal"
  );
  let nextRenewalDate;
  if (nextRenewalDateRaw) {
    try {
      const parsed = parse(
        nextRenewalDateRaw,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );
      nextRenewalDate = `ask by ${format(parsed, "MMM d")}`;
      if (parseInt(remainingQuestions, 10) < 1) {
        nextRenewalDate = `Questions reset on ${format(parsed, "MMM d")}.`;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }
  const canUserAsk =
    canUserAskQuestion && (remainingQuestions || nextRenewalDate);
  return (
    <View fd="column" css={styles.root} f="none" mx="auto">
      <View css={styles.pageContent} m="0 auto" fd="column" pt={42} mb={28}>
        {userIsPublisher === false ? (
          <div css={styles.activityDiv}>
            <img css={styles.clockImage} src={clockIcon} alt="Icon" />
            <Text css={styles.activityTitle}>My activity</Text>
            {canUserAsk && Membership.subscriptionStatusId !== 4 && (
              <View mt={18} css={styles.gagueContainer}>
                <Text type="small" css={styles.remainingQuestionsText} ml={8}>
                  {remainingQuestions !== false &&
                    `${remainingQuestions} question${
                      remainingQuestions > 1 ? "s" : ""
                    } left - ${nextRenewalDate}`}
                </Text>
              </View>
            )}
          </div>
        ) : (
          <UserProfileComponent
            name={name}
            avatarUrl={_get(avatar, "medium")}
            bio={bio}
            mb={42}
            onEditBio={onEditBio}
            onEditAvatar={onEditAvatar}
          />
        )}
      </View>
      <View f="none" mb={48}>
        {userIsPublisher ? (
          <GridSystem.Container css={{ width: "100%" }}>
            <GridSystem.Row justify="center" css={{ marginBottom: 24 }}>
              <div>
                <label css={styles.metrics}>
                  <b css={styles.metricsFont}>
                    {user.questions && user.questions.numAnswered}
                  </b>
                  {window.screen.width < 500 ? <br /> : ""}
                  Answers
                </label>
                <label css={styles.metrics}>
                  <b css={styles.metricsFont}>
                    {user.posts && user.posts.numTotal}
                  </b>
                  {window.screen.width < 500 ? <br /> : ""}
                  Posts
                </label>
                <label css={styles.metrics}>
                  <b css={styles.metricsFont}>
                    {user.activeSubscribers &&
                      user.activeSubscribers.numAskers +
                        user.activeSubscribers.numViewers}
                  </b>
                  {window.screen.width < 500 ? <br /> : ""}
                  Members
                </label>
              </div>
            </GridSystem.Row>
          </GridSystem.Container>
        ) : (
          <Fragment />
        )}
        <Fragment>
          <ProfileNav
            activeNav={activeNav}
            setNav={setActiveNav}
            outstandingCount={outstandingCount}
            isUserPublisher={userIsPublisher}
          />
        </Fragment>
      </View>
      {/* {userIsPublisher ? ( */}
      <View f="none">
        <Questions
          mode={activeNav}
          publisherId={id}
          setOutstandingQstnsCount={setOutstandingCount}
          creatorSlug={creatorSlug}
        />
      </View>
      {/* ) : (
        <Fragment />
      )} */}
    </View>
  );
};

ProfilePage.propTypes = {
  id: PropTypes.number.isRequired, // 3,
  isOwnProfile: PropTypes.bool,
  name: PropTypes.string.isRequired, // 'Christos Admin',
  numTwitterFollowers: PropTypes.number, // 'Christos Admin',
  bio: PropTypes.string, // 'Christos Admin',
  // phone: PropTypes.string, // null,
  // ownAffiliateId: PropTypes.string.isRequired, // '400',
  avatar: PropTypes.shape({
    // original: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
    small: PropTypes.string.isRequired,
  }),
  onEditAvatar: PropTypes.func,
  onEditBio: PropTypes.func,
  userRoleId: PropTypes.number,
  slug: PropTypes.string,
  creatorSlug: PropTypes.string,
};

ProfilePage.defaultProps = {
  // phone: null,
  isOwnProfile: false,
  avatar: {},
  bio: "",
  numTwitterFollowers: 0,
  onEditAvatar: () => {},
  onEditBio: () => {},
  userRoleId: undefined,
  slug: undefined,
  creatorSlug: "",
};

export default ProfilePage;
