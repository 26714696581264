import globalStyles from "../../styles";

export default {
  root: {
    maxWidth: 630,
    overflow: "hidden",
  },
  nav: {
    justifyContent: "space-around",
    maxWidth: 637,
  },
  clockImage: {
    width: 51,
    height: 51,
  },
  activityDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  activityTitle: {
    color: "#000000",
    fontSize: 34,
    fontFamily: "Source Serif Pro",
    fontWeight: 600,
    padding: "20px 0px",
  },
  remainingQuestionsText: {
    fontSize: 20,
    fontWeight: 400,
    color: "#737373",
    letterSpacing: "0.15px",
    fontFamily: "IBM Plex Sans",
    paddingBottom: "15px",
  },
  metrics: {
    paddingLeft: "25px",
    paddingRight: "25px",
    fontFamily: "IBM Plex Sans",
    fontSize: "20px",
    color: "#535353",
  },
  metricsFont: {
    marginRight: 5,
  },
  activeNav: {
    color: "#356859",
    borderBottom: "2px solid #356859",
    fontWeight: 700,
    cursor: "pointer",
    fontSize: 14,
    letterSpacing: "0.25px",
    fontFamily: "IBM Plex Sans",
    width: "212px",
    textAlign: "center",
    paddingBottom: "10px",
  },
  inactiveNav: {
    color: "#737373",
    cursor: "pointer",
    fontSize: 14,
    textAlign: "center",
    width: "211.96px",
    letterSpacing: "0.25px",
    fontFamily: "IBM Plex Sans",
  },
  emptyText: {
    fontFamily: "IBM Plex Sans",
    color: "#535353",
    lineHeight: "32px",
    fontSize: 20,
  },
};
