import globalStyles from "../../styles";

export default {
  rootDiv: {
    width: "100%",
    display: "flex",
    height: "108vh",
    overflowY: "hidden",
    "@media(max-width: 1000px)": {
      height: "auto",
      marginTop: 20,
    },
  },
  subDiv1: {
    width: "50%",
    background: "#356859",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  subDiv2: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 1000px)": {
      width: "100%",
    },
  },
  label1: {
    color: "#FFFFFF",
    fontFamily: "Source Serif Pro",
    fontWeight: 300,
    fontSize: "32.752px",
    lineHeight: "0px",
    marginBottom: 0,
    marginRight: 130,
    textAlign: "center",
  },
  label2: {
    color: "#FFFFFF",
    textAlign: "center",
    fontFamily: "Source Serif Pro",
    fontWeight: 600,
    fontSize: "100.037px",
    lineHeight: "103px",
    marginBottom: 0,
  },
  iconOrnaments: {
    width: "180px",
    height: "180px",
    position: "absolute",
    top: "105px",
    marginLeft: 70,
  },
  iconOrnaments2: {
    width: "180px",
    height: "180px",
    position: "absolute",
    marginLeft: -45,
    bottom: "-120px",
  },
  intersect1: {
    position: "absolute",
    width: "115px",
    height: "115px",
    top: "88px",
    left: 0,
  },
  intersect2: {
    position: "absolute",
    width: "115px",
    height: "115px",
    right: "50%",
    bottom: -140,
  },
  Logo: {
    width: "142px",
    height: "27.61px",
    marginBottom: 25,
    "@media(max-width: 1000px)": {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  title: {
    fontFamily: "Source Serif Pro",
    fontWeight: 600,
    lineHeight: "42px",
    fontSize: 34,
    color: "#263238",
    marginBottom: 30,
    "@media(max-width: 1000px)": {
      textAlign: "center",
    },
  },
  title1: {
    fontFamily: "IBM Plex Sans",
    fontSize: 16,
    lineHeight: "24px",
    color: "#000000",
    fontWeight: 600,
    letterSpacing: "0.1px",
  },
  inputContainer: {
    "> input": {
      marginBottom: 16,
    },
  },
  ctaButton: {
    width: 416,
    minHeight: 48,
    marginTop: 24,
    boxShadow:
      " 0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
    "@media(max-width: 1000px)": {
      width: "341px",
    },
  },
  otherOptions: {
    textAlign: "center",
    color: "#000000",
    fontFamily: "IBM Plex Sans",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: "0.15px",
  },
};
