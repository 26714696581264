export default {
  root: {
    marginTop: 30,
  },
  pageContent: {
    maxWidth: 630,
    justifyContent: "center",
  },
  pricingCard: {
    margin: "0 auto",
  },
  metrics: {
    textAlign: "center",
  },
  centerButton: {
    justifyContent: "center",
  },
  metricsDiv: {
    borderBottom: "1px solid #B2B2B2",
    paddingBottom: "10px",
  },
  buttonDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    Button: {
      borderRadius: "8px",
      width: "327px",
      marginBottom: "20px",
      boxShadow:
        "0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
    },
  },
  emptyText: {
    fontFamily: "IBM Plex Sans",
    color: "#535353",
    lineHeight: "32px",
    fontSize: 20,
  },
};
