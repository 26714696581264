const colors = {
  pink: {
    pink1: '#fe3c72',
  },
  blue: {
    blue1: '#38dff6',
    blue2: '#1d293f',
    blue3: '#3897f1',
    blue4: '#368fe5',
  },
  neutral: {
    neutral1: '#ffffff',
    neutral2: '#f3f4f6',
    neutral3: '#a9b0be',
    neutral4: '#000000',
  },
};

colors.primary = {
  primary: colors.blue.blue2,
  accent: colors.pink.pink1,
  secondary: colors.blue.blue3,
  text: colors.blue.blue2,
  textLight: colors.neutral.neutral3,
};

export default colors;
