/** @jsx jsx */
import { jsx } from "@emotion/react";

import { View, Text } from "../../components/core";
import { Footer } from "../../components/shared";

import styles from "./styles";
import {
  FirstFeatures,
  SecondFeatures,
  FeedExamples,
  About,
  ExpertType,
  LaunchText,
  TopContent,
} from "./features";
import Partnership from "./partnership";

export const SalesPage = () => (
  <View fd="column" css={styles.pageRoot}>
    <TopContent />
    <About />
    <Text css={styles.titleText} type="h3" centered>
      How does it work?
    </Text>
    <FirstFeatures />
    <FeedExamples />
    <SecondFeatures />
    <ExpertType />
    <Partnership />
    <LaunchText />
    <Footer />
  </View>
);

export default SalesPage;
