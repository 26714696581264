/** @jsx jsx */
import { jsx } from "@emotion/react";
import ollieomprofile from "./img/ollieomProfile.png";
import styles from "./styles";
import verified from "../../components/shared/userProfile/img/Verified.svg";
import youtube from "./img/youtubeIcon.svg";
import twitterIcon from "./img/twitterIcon.svg";
import image1 from "./img/random10.svg";
import image2 from "./img/random11.svg";
import image3 from "./img/random3.svg";
import image4 from "./img/random12.svg";
import image5 from "./img/random14.svg";
import image6 from "./img/random15.svg";
import image7 from "./img/random7.svg";
import image8 from "./img/random13.svg";
import image9 from "./img/random16.svg";
import bookmark from "./img/bookmark.svg";
import video1 from "./img/3.mp4";
import video2 from "./img/4.mp4";
import video3 from "./img/5.mp4";

export const Ollieom = () => {
  return (
    <div css={styles.deanProfileDiv}>
      <img
        css={styles.profileImg}
        style={{
          border: "3px solid rgb(53, 104, 89)",
          marginTop: "35px",
          marginBottom: 15,
        }}
        src={ollieomprofile}
        alt="profile"
      />
      <label style={{ fontSize: 34 }} css={styles.deanTitle}>
        ollieom
        <img style={{ marginLeft: 10 }} src={verified} alt="verified" />
      </label>
      <label
        style={{
          fontSize: 20,
          lineHeight: "30px",
          letterSpacing: "0.15px",
          textAlign: "center",
        }}
        css={styles.deanCategories}
      >
        Olive Dresden <br />
        scaling the collective vibration
        <br />
        mindfulness educator | yoga teacher | healing facilitator
        <br />
        author of Raise Your Vibration
      </label>
      <span>
        <img style={{ margin: "10px 15px" }} src={youtube} />
        <img style={{ margin: "10px 15px" }} src={twitterIcon} />
      </span>
      <span>
        <label css={styles.metricsLabel}>
          <b>245</b> Answers
        </label>
        <label css={styles.metricsLabel}>
          <b>300</b> Posts
        </label>
        <label css={styles.metricsLabel}>
          <b>3.5k</b> Members
        </label>
        <div css={styles.borderDiv}></div>
      </span>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image1} />
              <label css={styles.name}>jaz_b</label>
              <label css={styles.timeLabel}>1 min ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            I'm pretty stable in my headstand, but I'm working on entering from
            a wide-legged forward fold. Problem is, every time I try I can't
            seem to keep myself from tipping over backward. What am I doing
            wrong with my weight balance?
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={ollieomprofile} />
              <label css={styles.name}>ollieom</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 2 min ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <span>
            <video
              style={{
                marginTop: 10,
                width: window.screen.width < 624 ? 310 : 590,
              }}
              width="590"
              controls
              preload="metadata"
            >
              <source
                preload="metadata"
                src={video2 + "#t=0.001"}
                type="video/mp4"
              />
            </video>
          </span>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image2} />
              <label css={styles.name}>joshuamac</label>
              <label css={styles.timeLabel}>3 hours ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            What's the difference between palo santo or sage? I have them both
            but never know when I should be using one over the other.
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px " }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={ollieomprofile} />
              <label css={styles.name}>ollieom</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 3 hours ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <span>
            <video
              style={{
                marginTop: 10,
                width: window.screen.width < 624 ? 310 : 590,
              }}
              width="590"
              controls
              preload="metadata"
            >
              <source
                preload="metadata"
                src={video3 + "#t=0.001"}
                type="video/mp4"
              />
            </video>
          </span>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={ollieomprofile} />
              <label css={styles.name}>ollieom</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}>10 hours ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Good morning soul siblings ☀️
            <br />
            <br /> We've got a new moon on the horizon this evening, which means
            the energy is ripe to spark new beginnings and plant the seeds for
            manifestation. Tonight or tomorrow night would be the perfect time
            to sit down with a journal and set some new intentions for the
            upcoming month. Maybe light a candle, some palo santo. And remember,
            it's not just about what you want to accomplish or where you want to
            be, but how you want to feel. State your intentions using the
            present tense with phrases like 'I feel' and 'I am'. Wishing you a
            bright and abundant day ✨
          </label>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image4} />
              <label css={styles.name}>miller_y</label>
              <label css={styles.timeLabel}>1 day ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Every time I sit down to meditate, I find it literally impossible to
            stop thinking. In fact, I feel like my overthinking gets worse. Any
            tips on quieting the mind?
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={ollieomprofile} />
              <label css={styles.name}>ollieom</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 1 day ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ paddingLeft: 58, marginBottom: 20, paddingRight: 12 }}
            css={styles.content}
          >
            Ahh, the proverbial 'monkey mind'. It's perfectly normal to find
            your mind racing every which way when you sit down to meditate. Your
            mind is just behaving as it was designed to. The practice of
            meditation is just that - practice. When you notice your mind
            wandering, try not to react with frustration or anger. Simply
            observe. Make the observation that your mind has wandered off, doing
            its thinking thing again. And then gently guide your awareness back
            to your breath. Your goal is simply to pull yourself out of your
            thoughts, to become the observer, and to be present.
          </label>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image8} />
              <label css={styles.name}>marieflies</label>
              <label css={styles.timeLabel}>2 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Any advice on improving back-bend flexibility?? I've been doing a
            lot of back strengthening and flexibility exercises over the past
            few months, but I'm barely seeing any change. I know progress can be
            slow, but I wonder if I'm doing something wrong.
          </label>
          <img
            style={{
              marginBottom: 20,
              width: window.screen.width < 624 ? 300 : {},
            }}
            src={image5}
            alt="icon"
          />
          <div css={styles.borderDiv}></div>
          <div style={{ margin: "15px 0px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={ollieomprofile} />
              <label css={styles.name}>ollieom</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 2 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{
              paddingLeft: 58,
              marginBottom: 20,
              paddingRight: 12,
              marginTop: -25,
            }}
            css={styles.content}
          >
            It's true that progress in yoga, as with anything in life, can take
            time. That being said, a lot of the more advanced expressions, like
            King Dancer or Anjaneyasana backbend, also require a lot of shoulder
            flexibility. A lot of us are super tight in the shoulders and pecks,
            and spending so much time hunched over
          </label>
          <img
            style={{
              marginTop: 10,
              width: window.screen.width < 624 ? 300 : {},
            }}
            src={image6}
            alt="icon"
          />
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image9} />
              <label css={styles.name}>sel_jones</label>
              <label css={styles.timeLabel}>4 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            I get a lot of anxiety when flying. Honestly, I find the whole
            travel process stressful, but it's the flying part, especially
            turbulence, that sends my anxiety spiraling. I'd really like to find
            a way to cope other than popping a xanax so I don't have to dread
            traveling so much. Do you have any go-to meditations or breathing
            techniques that could help? Thx xx
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={ollieomprofile} />
              <label css={styles.name}>ollieom</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 4 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
        </div>
        <span>
          <video
            style={{
              marginTop: 10,
              width: window.screen.width < 624 ? 310 : 590,
            }}
            width="590"
            controls
            preload="metadata"
          >
            <source
              preload="metadata"
              src={video1 + "#t=0.001"}
              type="video/mp4"
            />
          </video>
        </span>
      </div>
    </div>
  );
};

export default Ollieom;
