/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import format from "date-fns/format";

const displayDate = (date, mode) => {
  const rawDate = new Date(`${date}Z`);
  const isoDate = rawDate.toISOString();
  const parsed = parseISO(isoDate);
  switch (mode) {
    case "human":
      return format(parsed, "yyyy-MM-dd");
    case "long":
      return format(parsed, "MMM dd yyyy hh:mm b");
    default:
      return formatDistanceToNow(parsed, {
        includeSeconds: true,
        addSuffix: true,
      });
  }
};

export const DateComponent = ({ children, mode }) => (
  <span>{displayDate(children, mode)}</span>
);

DateComponent.propTypes = {
  children: (props, propName, componentName) => {
    const { children } = props;
    try {
      displayDate(children);
      return null;
    } catch (error) {
      return new Error(
        `Invalid prop \`${props[propName]}\` supplied to` +
          ` \`${componentName}\`. Validation failed.`
      );
    }
  },
  mode: PropTypes.oneOf(["timeago", "human", "long"]),
};

DateComponent.defaultProps = {
  children: "",
  mode: "timeago",
};

export default DateComponent;
