/** @jsx jsx */
import _get from "lodash/get";
import { jsx, css } from "@emotion/react";
import { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";

import UserClient from "../../../services/api/user";
import globalStyles from "../../../styles";
import { View, Text, Input, Button } from "../../../components/core";

import styles from "./styles";

const StyledCCInput = forwardRef((props, ref) => (
  <input {...props} css={styles.ccInput} ref={ref} />
));

/*
The form that adds the new card.
Need to wire up the button onclick,
Need to have some callback from a connected component that pushes the post,
Need to update 'currentPaymentMethod` on the parent if this is all done successfully.
*/
export const AddNewCardForm = ({ addCard, setModalOpen, onClose }) => {
  const [pendingNetworkRequest, setPending] = useState(false);
  const [formData, setFormData] = useState({
    // name: '',
    number: "",
    expirationDate: "",
    cvv: "",
    error: "",
  });

  const updateField = (key, value) => {
    setFormData({
      ...formData,
      ...{ [key]: value },
    });
  };

  const onSubmit = async () => {
    setPending(true);
    let data;
    try {
      const removeSpaces = (str) => str.split(" ").join("");
      const formatCCExpy = (str) => {
        const raw = removeSpaces(str);
        const [month, year] = raw.split("/");
        return `20${year}-${month}`;
      };
      data = await UserClient.addUserCard({
        holderName: "",
        number: removeSpaces(formData.number),
        expirationDate: formatCCExpy(formData.expirationDate), // 01/22 to 2022/01
        cvc: formData.cvv.trim(),
      });
    } catch (error) {
      const validationErrors = _get(
        error,
        "response.data.validationErrors",
        false
      );
      let errorMessage = error.message;
      if (validationErrors) {
        errorMessage = Object.values(validationErrors)
          .map((errorList) => errorList.join(" "))
          .join(", ");
      }

      setFormData({
        ...formData,
        error: errorMessage,
      });
      return;
    }
    addCard(data);
    setPending(false);
    setModalOpen(false);
  };

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  return (
    <View fd="column" mt={10} px={42} py={42} style={{ width: "100%" }}>
      <div
        style={{
          position: "absolute",
          top: "15px",
          right: "30px",
          marginTop: 20,
        }}
      >
        <label
          style={{
            fontWeight: "bolder",
            fontSize: "20px",
            cursor: "pointer",
            color: "#535353",
          }}
          onClick={onClose}
        >
          X
        </label>
      </div>
      <Text type="h5" css={styles.title} centered mb={18}>
        Add New Card
      </Text>
      <View fd="column" mt={10} css={styles.modalInputContainer}>
        {/* <Input
          value={formData.name}
          onChange={(e) => updateField('name', e.target.value)}
          css={{ ...styles.input, ...styles.nameInput }}
          disabled={pendingNetworkRequest}
        >
          Card Holder Name
        </Input> */}
        <Text css={styles.title1}>
          Card details <b style={{ color: "red" }}>*</b>
        </Text>
        <View f="none" mb={12}>
          <PaymentInputsWrapper
            {...wrapperProps}
            css={styles.paymentInput}
            disabled={pendingNetworkRequest}
            styles={{
              errorText: {
                base: css`
                  ${globalStyles.typography.body};
                  color: ${globalStyles.colors.primary.accent};
                  text-align: "left";
                `,
              },
            }}
          >
            <svg {...getCardImageProps({ images })} />
            <StyledCCInput
              {...getCardNumberProps({
                onChange: (e) => updateField("number", e.target.value),
              })}
              value={formData.number}
            />
            <StyledCCInput
              {...getExpiryDateProps({
                onChange: (e) => updateField("expirationDate", e.target.value),
              })}
              value={formData.expirationDate}
            />
            <StyledCCInput
              {...getCVCProps({
                onChange: (e) => updateField("cvv", e.target.value),
              })}
              value={formData.cvv}
            />
          </PaymentInputsWrapper>
        </View>
        {formData.error && <Text color="error">{formData.error}</Text>}
        <View mt={24}>
          <Button
            css={styles.cancelStyles}
            onClick={onClose}
            disabled={pendingNetworkRequest}
          >
            Cancel
          </Button>
          <Button
            css={styles.confirmStyles}
            onClick={onSubmit}
            disabled={pendingNetworkRequest}
          >
            Confirm
          </Button>
        </View>
      </View>
    </View>
  );
};

AddNewCardForm.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
};

export default AddNewCardForm;
