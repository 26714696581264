/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import View from '../view';
import styles from './styles';

const randomWidth = (_min, _max) => {
  const min = Math.ceil(_min);
  const max = Math.floor(_max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const Placeholder = ({ size }) => {
  const lines = Array.from(Array(size)).map((val, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <View key={`placeholder-${val}-${idx}`} mb={6} css={{ ...styles, ...{ width: `${randomWidth(40, 80)}%` } }} />
  ));

  return (
    <Fragment>
      {lines}
    </Fragment>
  );
};

Placeholder.propTypes = {
  size: PropTypes.number,
};

Placeholder.defaultProps = {
  size: 3,
};

export default Placeholder;
