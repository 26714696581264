import globalStyles from '../../../styles';

const styles = {
  default: {
    margin: 0,
    padding: 0,
    textAlign: 'left',
  },
  ...globalStyles,
};

export default styles;
