// Initial user state with all possible properties set.
const initialUserState = {
  token: null,
  userId: null,
  userRoleId: null,
  pendingRequest: false,
  error: null,
  subscriptionData: {},
};

export default initialUserState;
