/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
import PropTypes from "prop-types";

import { View, Input, Button, Text, Link } from "../../components/core";
import { ServerSideErrors, OnLoginRedirect } from "../../components/shared";
import { login as loginAction } from "../../services/state/actionCreators";
import styles from "./styles";
import { APP_URLS } from "../../constants";
import ornaments from "./images/ornaments.svg";
import intersect1 from "./images/intersect1.svg";
import intersect2 from "./images/intersect2.svg";
import logo from "./images/logo.svg";
import NotificationService from "../../components/shared/Notification";
import ResetModal from "../salesPage/temporaryModal";

const LoginFormComponent = ({ login, user, data }) => {
  const splitPath = window.location.pathname.replace(/\/\s*$/, "").split("/");
  useEffect(() => {
    if (data) {
      setFormData({
        ...formData,
        ...{ email: data.email },
        ...{ password: data.password },
      });
    }
  });
  const { pendingRequest, error: serverSideError } = user;
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const updateField = (key, value) => {
    setFormData({
      ...formData,
      ...{ [key]: value },
    });
  };

  const onLogin = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!formData.email || !formData.password) {
      NotificationService.error("Please enter a valid email and password");
    }
    if (formData.email && formData.password) {
      login(formData.email, formData.password);
    }
  };

  const slug = useParams();
  const userSlug = _get(slug, "slug", false);
  return (
    <div css={styles.rootDiv}>
      {window.screen.width > 1000 ? (
        <div css={styles.subDiv1}>
          <img
            style={{
              top: splitPath && splitPath[1] === "admin" ? "0px" : "88px",
            }}
            css={styles.intersect1}
            src={intersect1}
          />
          <img
            style={{
              top: splitPath && splitPath[1] === "admin" ? "20px" : "105px",
            }}
            css={styles.iconOrnaments}
            src={ornaments}
          />
          <label css={styles.label1}>Advice from</label>
          <label css={styles.label2}>experts</label>
          <img
            style={{
              bottom:
                splitPath && splitPath[1] === "admin" ? "-50px" : "-120px",
            }}
            css={styles.iconOrnaments2}
            src={ornaments}
          />
          <img
            style={{
              bottom:
                splitPath && splitPath[1] === "admin" ? "-53px" : "-140px",
            }}
            css={styles.intersect2}
            src={intersect2}
          />
        </div>
      ) : (
        ""
      )}
      <div css={styles.subDiv2}>
        <form onSubmit={onLogin}>
          <View fd="column" css={styles.inputContainer}>
            {sessionStorage.getItem("subscription") !== null ? (
              <OnLoginRedirect
                user={user}
                redirectToSettings={data ? true : false}
              />
            ) : (
              ""
            )}
            <img css={styles.Logo} src={logo} alt="logo" />
            <Text css={styles.title}>Welcome back!</Text>
            <Text css={styles.title1}>
              Email <b style={{ color: "red" }}>*</b>
            </Text>
            <Input
              disabled={pendingRequest ? "disabled" : undefined}
              value={formData.email}
              onChange={(e) => updateField("email", e.target.value)}
              style={{ width: window.screen.width < 500 ? "340px" : "416px" }}
            >
              Email
            </Input>
            <Text css={styles.title1}>
              Password <b style={{ color: "red" }}>*</b>
            </Text>
            <Input
              disabled={pendingRequest ? "disabled" : undefined}
              value={formData.password}
              type="password"
              style={{ width: window.screen.width < 500 ? "340px" : "416px" }}
              onChange={(e) => updateField("password", e.target.value)}
            >
              Password
            </Input>
            <Text
              style={{ color: "#356859", cursor: "pointer" }}
              css={styles.title1}
              onClick={() => {
                setOpen(true);
              }}
            >
              Forgot password?
            </Text>
            <Button
              css={styles.ctaButton}
              disabled={pendingRequest}
              onClick={onLogin}
              type="submit"
            >
              Login
            </Button>
            {formErrors.length
              ? formErrors.map((errorMessage) => (
                  <Text
                    key={errorMessage}
                    css={styles.errorMessage}
                    mt={22}
                    mx="auto"
                  >
                    {errorMessage}
                  </Text>
                ))
              : null}
            {/* {serverSideError ? (
              <ServerSideErrors errors={serverSideError} />
            ) : null} */}
            <Text css={styles.otherOptions} mt={28}>
              Don't have an account?
              <Link
                to={APP_URLS.SIGNUP.url(userSlug)}
                css={{ marginLeft: 5, color: "#356859" }}
              >
                Sign up
              </Link>
            </Text>
          </View>
        </form>
      </div>
      <ResetModal open={open} onclose={() => setOpen(false)} />
    </div>
  );
};

LoginFormComponent.propTypes = {
  login: PropTypes.func.isRequired,
  user: PropTypes.shape({
    token: PropTypes.string,
    pendingRequest: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    id: PropTypes.number,
  }),
};

LoginFormComponent.defaultProps = {
  user: {
    pendingRequest: false,
    error: null,
    id: null,
  },
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => {
    dispatch(loginAction({ email, password }));
  },
});

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent);

export default LoginForm;
