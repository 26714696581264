import globalStyles from "../../../styles";

export default {
  errorMessage: {
    color: globalStyles.colors.primary.accent,
  },
  replyLabel: {
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "IBM Plex Sans",
    letterSpacing: "0.25px",
    paddingLeft: "7px",
  },
};
