import globalStyles from "../../../styles";

export default {
  ...globalStyles.typography.body,
  fontSize: 14,
  color: "#000000",
  fontFamily: "IBM Plex Sans",
  borderRadius: 8,
  border: "1px solid #556670",
  padding: "12px 21px",
  boxSizing: "border-box",
  ...globalStyles.component.transistion(),
  "&:focus": {
    outline: "none",
    border: "1.5px solid #356859",
  },
  "&:disabled": {
    cursor: "wait",
    backgroundColor: globalStyles.colors.neutral.neutral2,
  },
};
