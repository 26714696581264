import {
  PRODUCT_DESCRIPTION_BY_KEY,
  CONTENT_ACCESS_PERMISSION,
} from '../../constants';

/**
 * determinePlanString
 *
 * @param {number[]} capIds - list of the capabilities by ID.
*/
export const determinePlanString = (capIds) => {
  if (capIds.includes(CONTENT_ACCESS_PERMISSION.ASK_QUESTIONS)) {
    return PRODUCT_DESCRIPTION_BY_KEY.ask;
  }
  return PRODUCT_DESCRIPTION_BY_KEY.view;
};

export default {};
