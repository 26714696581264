/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { View, Text } from "../../core";
import styles from "./styles";

// icons
import Person from "./img/Frame.svg";
import Social from "./img/Frame1.svg";
import Bank from "./img/bank.svg";
import Book from "./img/Frame2.svg";
import Calendar from "./img/calendar.svg";
import Tag from "./img/tag.svg";

const iconMap = {
  person: Person,
  social: Social,
  bank: Bank,
  book: Book,
  calendar: Calendar,
  tag: Tag,
};

export const Feature = ({ children, icon }) => (
  <View css={styles.root} f="none">
    <View css={styles.iconWrapper} m="0 auto 8px">
      <img css={styles.icon} src={iconMap[icon]} alt={icon} />
    </View>
    <Text css={styles.text}>{children}</Text>
  </View>
);

Feature.propTypes = {
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
  children: PropTypes.node,
};

Feature.defaultProps = {
  children: "Feature",
};

export default Feature;
