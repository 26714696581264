import _uniq from 'lodash/uniq';

import {
  LOAD_USER_DATA,
  LOAD_USER_DATA_SUCCESS,
  LOAD_USER_DATA_FAILED,
  SET_USER_BIO_SUCCESS,
  UPDATE_USER_PROFILE_DATA,
} from './actions';
import { USER_UPLOAD_AVATAR_SUCCESS } from '../user/actions';
import defaultState from './defaultState';

/**
 * userReducer
 *
 * Standard reducer. Note that we copy all of the payload
 * items into the redux store.
*/
const usersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_USER_DATA: {
      const ids = action.payload.id ? [...state.ids, action.payload.id] : state.ids;
      return {
        ...state,
        ...{
          ids,
          users: {
            ...state.users,
            ...{
              [action.payload.id]: action.payload.user,
            },
          },
          pendingRequest: true,
          error: null,
        },
      };
    }
    case USER_UPLOAD_AVATAR_SUCCESS:
    case LOAD_USER_DATA_SUCCESS: {
      const { user } = action.payload;
      const ids = (
        user.id ? _uniq([...state.ids, user.id]) : state.ids
      );
      const newSlugs = { ...state.slugs };
      if (user.slug) {
        newSlugs[user.slug] = user.id;
      }
      const newState = {};

      if (user.id) {
        const oldUser = state.users[user.id];
        newState[user.id] = { ...oldUser, ...user };
      }

      return {
        ...state,
        ...{
          ids,
          slugs: newSlugs,
          users: {
            ...state.users,
            ...newState,
          },
          pendingRequest: false,
        },
      };
    }
    case LOAD_USER_DATA_FAILED:
      return {
        ...state,
        ...{
          ids: [...state.ids.filter((id) => id !== action.payload.id)],
          pendingRequest: false,
          error: action.payload.error,
        },
      };
    case SET_USER_BIO_SUCCESS:
      return {
        ...state,
        ...{
          users: {
            ...state.users,
            [action.payload.id]: action.payload,
          },
        },
      };
    case UPDATE_USER_PROFILE_DATA: {
      const newUserData = {
        ...state.users[action.payload.userId],
        ...action.payload.user,
      };
      return {
        ...state,
        ...{
          users: {
            ...state.users,
            [action.payload.id]: newUserData,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default usersReducer;
