import globalStyles from '../../../styles';
import EditIcon from './img/edit.png';

export default {
  root: {
    alignItems: 'center',
  },
  avatar: {
    width: 130,
    height: 130,
    flexBasis: 130,
    marginBottom: 24,
  },
  userName: {
    fontWeight: 700,
    textAlign: 'center',
  },
  bio: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    cursor: 'pointer',
    '> span': {
      display: 'none',
    },
    '&:hover > span': {
      display: 'block',
      textTransform: 'uppercase',
      color: globalStyles.colors.primary.secondary,
    },
  },
  timeAgo: {
    marginLeft: 5,
    color: globalStyles.colors.primary.textLight,
    fontWeight: 400,
  },
  editableOverlay: {
    width: 130,
    height: 130,
    borderRadius: 130,
    marginBottom: 24,
    cursor: 'pointer',
    position: 'relative',
    '&:hover:before': {
      display: 'block',
      content: '""',
      width: 130,
      height: 130,
      backgroundColor: 'gray',
      backgroundImage: `url(${EditIcon})`,
      backgroundPosition: 'center center',
      backgroundSize: '41px 33px',
      backgroundRepeat: 'no-repeat',
      borderRadius: 130,
      position: 'absolute',
      opacity: 0.5,
    },
  },
  croppingModalContent: {
    width: '100%',
    height: '100%',
  },
  croppingModalActions: {
    justifyContent: 'space-between',
  },
  visuallyHidden: {
    display: 'none',
  },
  editingButtons: {
    justifyContent: 'space-between',
  },
  editingWidthConstraint: {
    width: 300,
  },
};
