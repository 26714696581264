import initialUserState from './user/defaultState';
import initialUsersState from './users/defaultState';
import initialQuestionsState from './question/defaultState';
import initialPostsState from './posts/defaultState';

export default {
  user: initialUserState,
  users: initialUsersState,
  questions: initialQuestionsState,
  posts: initialPostsState,
};
