/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import {
  View,
  Button,
} from '../../core';

import { Draggable } from 'react-beautiful-dnd';
import styles from './styles';

/**
 * UploadedImage {Component}
 *
 * Handles the display of pending media uploaded images.
 * iow: Images selected, but not yet uploaded.
 *
 * props:
 * @param {string} url: The local URL to the image
 * @param {func} onRemoveFile: What happens when user clicks remove button
*/
export const UploadedImage = ({ url, onRemoveFile, index, ...rest }) => (
  <View
    key={url}
    css={styles.root}
    {...rest}
  >
    <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        // style={{ ...provided.draggableProps.style }}
        >
          <Button
            flat
            ghost
            css={styles.removeBtn}
            onClick={() => onRemoveFile(url)}
          >
            x
          </Button>
          <img
            css={styles.image}
            src={url}
            alt="user uploaded"
          />
        </div>
      )}
    </Draggable >
  </View>
);

UploadedImage.propTypes = {
  url: PropTypes.string.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default UploadedImage;
