/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { View } from "../../core";
import { User } from "../user";

import styles from "./styles";

export const Answer = ({
  children,
  userName,
  userAvatarUrl,
  postTime,
  noBackground,
  ...rest
}) => (
  <View css={styles.root} fd="column" pl={24} data-testid="answer" {...rest}>
    <User
      answer={true}
      name={userName}
      avatarUrl={userAvatarUrl}
      postTime={postTime}
      mb={12}
    />
    {children}
  </View>
);

Answer.propTypes = {
  children: PropTypes.node,
  userName: PropTypes.string,
  userAvatarUrl: PropTypes.string,
  postTime: PropTypes.string,
  noBackground: PropTypes.bool,
};

Answer.defaultProps = {
  children: [],
  userName: "Unknown User",
  userAvatarUrl: undefined,
  postTime: null,
  noBackground: false,
};

export default Answer;
