/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import PropTypes from 'prop-types';

import { View } from '../view';
import { Text } from '../text';

import styles from './styles';

export const Pill = ({ children, variant }) => {
  const variantStyles = styles.variants[variant];
  return (
    <View css={css(styles.root, variantStyles.root)}>
      <Text css={css(styles.text, variantStyles.text)}>
        {children}
      </Text>
    </View>
  );
};

Pill.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  variant: PropTypes.oneOf(['primary', 'success', 'warning', 'error']),
};

Pill.defaultProps = {
  children: [],
  variant: 'primary',
};

export default Pill;
