import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { appInit } from '../../../services/state/actionCreators';

class AutoLogin extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  render() {
    return <Fragment />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    dispatch(appInit());
  },
});

export default connect(null, mapDispatchToProps)(AutoLogin);
