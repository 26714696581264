import React, { useState } from "react";
/** @jsx jsx */
import _get from "lodash/get";
import _find from "lodash/find";
import { jsx } from "@emotion/react";
import { useParams, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import parse from "date-fns/parse";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { APP_URLS, USER_ROLE } from "../../../constants";
import { Button, Text, View, Modal } from "../../core";
import styles from "./styles";
import {
  usePublisherParam,
  useUserData,
  usePublisherDataParam,
} from "../hooks";
import { AskButton } from "../askButton";
// import canAskQuestion from '../../../services/utils/user-can-ask-question';

import CogIcon from "./img/cog.png";
import HomeIcon from "./img/home.svg";
import ProfileIcon from "./img/profile.png";
import dropIcon from "./img/dropdown.svg";
import BookmarkIcon from "../post/img/bookmark.svg";
import ActivityIcon from "./img/activity.svg";
import DashboardIcon from "./img/dashboard.svg";
import bookmark2 from "./img/bookmark.svg";
import SettingIcon from "./img/settings.svg";
import SignOutIcon from "./img/log-out.svg";
import EmptyGauge from "./img/gauge_empty_gray.png";
import FullGauge from "./img/gauge_full.png";

const navLinks = [
  {
    label: "Home",
    url: APP_URLS.HOME.url(),
    exact: true,
  },
  {
    label: "Contact",
    url: "/contact",
    exact: true,
  },
  // {
  //   label: 'Profile',
  //   url: APP_URLS.PROFILE.example,
  //   exact: true,
  // },
  // Logins only happen from feed pages.
  // {
  //   label: 'Login',
  //   url: APP_URLS.LOGIN.url(''),
  //   exact: true,
  // },
  {
    label: "Feed example",
    url: APP_URLS.FEED.example,
    exact: true,
  },
  {
    label: "Launch Q&A feed",
    url: "/expert-sign-up",
    exact: true,
  },
];

export const LoggedOutNav = () => {
  const [open, setOpen] = useState(false);
  return (
    <View fd="row">
      {/* {navLinks.map((link) => (
      <NavLink
        exact={link.exact}
        to={link.url}
        key={`link-${link.url}`}
        css={styles.navLinks}
      >
        <Text ml={14}>{link.label}</Text>
      </NavLink>
    ))} */}
      <a href={"mailto:chris@guidereply.com"} target="_blank" rel="noreferrer">
        <Button
          // onClick={() => {
          //   setOpen(true);
          // }}
          css={styles.loginButton}
        >
          Contact
        </Button>
      </a>
      <Modal
        style={window.screen.width > 500 ? true : null}
        isOpen={open}
        onClose={() => setOpen(false)}
        noButton
      >
        <div>
          <div style={{ position: "absolute", right: "30px", marginTop: 20 }}>
            <label
              style={{
                fontWeight: "bolder",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              X
            </label>
          </div>
          <div
            style={{
              padding:
                window.screen.width < 500
                  ? "20% 10px 0px 10px"
                  : "20px 20px 0px 40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontFamily: "IBM Plex Sans",
                fontSize: "24px",
                letterSpacing: "0.1px",
                textAlign: "center",
                fontWeight: 600,
                marginBottom: 35,
                marginTop: 35,
              }}
            >
              <label>
                Experts, please contact:{" "}
                <b style={{ color: "#356859", textDecoration: "underline" }}>
                  chris@guidereply.com
                </b>
              </label>
              <br />
              <label style={{ marginTop: "10px", marginBottom: 20 }}>
                For all other inquiries:{" "}
                <b style={{ color: "#356859", textDecoration: "underline" }}>
                  chris@guidereply.com
                </b>
              </label>
              <br />
              Thank you!
            </label>
            {/* <Input
              // disabled={pendingRequest === true ? "disabled" : undefined}
              // value={email}
              // onChange={(e) => setEmail(e.target.value)}
              style={{ width: "340px", marginBottom: 25 }}
            >
              Enter your email...
            </Input> */}
            {/* <Button
              disabled={pendingRequest === true ? "disabled" : undefined}
              style={{
                color: "#fff",
                background: "#356859",
                marginTop: "20px",
                width: "327px",
              }}
              onClick={() => submit()}
            >
              Reset Password
            </Button> */}
          </div>
        </div>
      </Modal>
    </View>
  );
};

const LoggedInNav = ({ children, canUserAskQuestion }) => {
  const [dropdown, setDropdown] = useState(false);
  const { slug } = useParams();
  const { userRoleId } = useUserData();
  const isPublisher = userRoleId === USER_ROLE.PUBLISHER;
  const userData = useSelector((state) => state.user);
  const userProfile = useSelector((store) =>
    _get(store, `users.users[${userData.userId}]`)
  );
  const userSlug = usePublisherParam();
  const subScriptions = _get(userData, "subscriptionData.subscribedPublishers");
  const subscription = _find(subScriptions, (s) => s.slug === userSlug);
  let viewStatus = _get(subscription, "products.view.latestSubscription");
  let askStatus = _get(subscription, "products.ask.latestSubscription");
  const Membership =
    viewStatus !== null && viewStatus?.subscriptionStatusId !== 4
      ? viewStatus
      : askStatus;
  const history = useHistory();
  return (
    <View fd="column" css={styles.loggedInNavContainer}>
      <View css={styles.loggedInNavContainerActions}>
        {((Membership && Membership.subscriptionStatusId !== 4) ||
          isPublisher) && <AskButton canUserAskQuestion={canUserAskQuestion} />}
        <img
          css={styles.profileImage}
          src={userProfile && userProfile.avatar.small}
          onClick={() => {
            setDropdown(!dropdown);
          }}
        />
        {dropdown === true ? (
          <div css={styles.dropdownStyle}>
            <span
              onClick={() => {
                history.push(
                  !isPublisher ? APP_URLS.FEED.path : APP_URLS.PROFILE.url(slug)
                );
                setDropdown(!dropdown);
              }}
            >
              <img alt="Home Icon" src={HomeIcon} css={styles.dropdownIcons} />
              <label>Feed</label>
            </span>
            {userData.userRoleId === 3 && (
              <span
                onClick={() => {
                  history.push(APP_URLS.DASHBOARD.url(slug));
                  setDropdown(!dropdown);
                }}
              >
                <img
                  alt="DashboardIcon"
                  src={DashboardIcon}
                  css={styles.dropdownIcons}
                />
                <label>Dashboard</label>
              </span>
            )}
            {userData.userRoleId === 2 && (
              <span
                onClick={() => {
                  history.push(APP_URLS.PROFILE.url(slug));
                  setDropdown(!dropdown);
                }}
              >
                <img
                  alt="ActivityIcon"
                  src={ActivityIcon}
                  css={styles.dropdownIcons}
                />
                <label>Activity</label>
              </span>
            )}
            <span
              onClick={() => {
                history.push(APP_URLS.FAVORITES.url(slug));
                window.location.reload();
                setDropdown(!dropdown);
              }}
            >
              <img
                alt="bookmarkIcon"
                src={bookmark2}
                css={styles.dropdownIcons}
              />
              <label>Bookmarks</label>
            </span>
            <span
              onClick={() => {
                history.push(APP_URLS.SETTINGS.url(slug));
                setDropdown(!dropdown);
              }}
            >
              <img
                alt="settingIcon"
                src={SettingIcon}
                css={styles.dropdownIcons}
              />
              <label>Settings</label>
            </span>
            <span
              onClick={() => {
                history.push(APP_URLS.FEED.path);
                sessionStorage.clear();
                window.location.reload();
                setDropdown(!dropdown);
              }}
              style={{ border: "none" }}
            >
              <img
                alt="logoutIcon"
                src={SignOutIcon}
                css={styles.dropdownIcons}
              />
              <label>Sign out</label>
            </span>
          </div>
        ) : (
          ""
        )}
        {/* {userData.userRoleId === 3 && (
          <NavLink to={APP_URLS.DASHBOARD.url(slug)} role="link">
            <Button style={{ marginLeft: "10px" }}>Dashboard</Button>
          </NavLink>
        )}
        {!isPublisher && (
          <NavLink to={APP_URLS.FEED.path} role="link">
            <img
              alt="Home Icon"
              src={HomeIcon}
              css={{ ...styles.iconStyles, ...styles.homeIcon }}
            />
          </NavLink>
        )}
        <NavLink to={APP_URLS.PROFILE.url(slug)} role="link">
          <img
            alt="Profile Icon"
            src={ProfileIcon}
            css={{ ...styles.iconStyles, ...styles.profileIcon }}
          />
        </NavLink>
        <NavLink to={APP_URLS.FAVORITES.url(slug)} role="link">
          <img
            alt="Bookmark Icon"
            src={BookmarkIcon}
            css={{ ...styles.iconStyles, ...styles.bookmarkIcon }}
          />
        </NavLink>
        <NavLink to={APP_URLS.SETTINGS.url(slug)} role="link">
          <img
            alt="Cog Icon"
            src={CogIcon}
            css={{ ...styles.iconStyles, ...styles.cogIcon }}
          />
        </NavLink> */}
      </View>
      <View>{children}</View>
    </View>
  );
};

LoggedInNav.propTypes = {
  children: PropTypes.node,
  canUserAskQuestion: PropTypes.bool,
};

LoggedInNav.defaultProps = {
  children: [],
  canUserAskQuestion: false,
};

export const RemainingQuestionsComponent = ({
  remainingQuestions,
  nextRenewalDate,
  canUserAskQuestion,
}) => {
  // const publisher = usePublisherDataParam();
  const user = useUserData();
  const { userId } = user;
  const canUserAsk =
    canUserAskQuestion && (remainingQuestions || nextRenewalDate);
  const { slug } = useParams();
  const userData = useSelector((state) => state.user);
  const userSlug = usePublisherParam();
  const subScriptions = _get(userData, "subscriptionData.subscribedPublishers");
  const subscription = _find(subScriptions, (s) => s.slug === userSlug);
  let viewStatus = _get(subscription, "products.view.latestSubscription");
  let askStatus = _get(subscription, "products.ask.latestSubscription");
  const Membership =
    viewStatus !== null && viewStatus?.subscriptionStatusId !== 4
      ? viewStatus
      : askStatus;
  const splitPath = window.location.pathname.replace(/\/\s*$/, "").split("/");

  if (userId) {
    return (
      <View f="0 0 auto" css={styles.navigationContainer}>
        {" "}
        {sessionStorage.getItem("subscription") !== null ? (
          <LoggedInNav userId={userId} canUserAskQuestion={canUserAsk}>
            {/* {canUserAsk && Membership.subscriptionStatusId !== 4 && (
              <View mt={18} css={styles.gagueContainer}>
                <img
                  src={remainingQuestions === 0 ? EmptyGauge : FullGauge}
                  alt={
                    remainingQuestions === 0
                      ? "No more questions left."
                      : "You still have questions"
                  }
                  css={styles.gaugeIcon}
                />
                <Text type="small" css={styles.remainingQuestionsText} ml={8}>
                  {remainingQuestions !== false &&
                    `You have ${remainingQuestions} question${
                      remainingQuestions > 1 ? "s" : ""
                    } left.`}
                  <br />
                  {nextRenewalDate}
                </Text>
              </View>
            )} */}
            {/* {window.location.pathname.includes(APP_URLS.SETTINGS.url(slug)) && (
              <View mt={18} css={styles.logout}>
                <NavLink to={APP_URLS.LOGOUT.url()}>
                  <Button ghost flat>
                    Logout
                  </Button>
                </NavLink>
              </View>
            )} */}
          </LoggedInNav>
        ) : (
          ""
        )}
      </View>
    );
  }
  return (
    // <LoggedInNav userId={userId}>
    <View mt={0}>
      {splitPath && splitPath[2] === "login" ? (
        ""
      ) : (
        <div>
          <NavLink to={APP_URLS.SIGNUP.url(slug)}>
            <Button css={styles.signUpButton}>Sign Up</Button>
          </NavLink>
          <NavLink to={APP_URLS.LOGIN.url(slug)} css={styles.logout}>
            <Button css={styles.loginButton} ghost flat>
              Log In
            </Button>
          </NavLink>
        </div>
      )}
      {/* <NavLink to={APP_URLS.FEED.url(slug)} role="link" css={styles.logout}>
        <Button ghost flat>
          Feed
        </Button>
      </NavLink> */}
    </View>
    // </LoggedInNav>
  );
};

RemainingQuestionsComponent.propTypes = {
  remainingQuestions: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  nextRenewalDate: PropTypes.string,
  canUserAskQuestion: PropTypes.bool,
};

RemainingQuestionsComponent.defaultProps = {
  remainingQuestions: undefined,
  nextRenewalDate: undefined,
  canUserAskQuestion: false,
};

export const ConnectedRemainingQuestionsComponent = () => {
  const userData = useSelector((state) => state.user);
  const userId = _get(userData, "userId");
  const userSlug = usePublisherParam();
  const subScriptions = _get(userData, "subscriptionData.subscribedPublishers");
  // TODO: Problem is, when on my own profile, or settings page, the slug is not defined.
  const subscription = _find(subScriptions, (s) => s.slug === userSlug);
  let subscribedProduct;
  let viewStatus = _get(subscription, "products.view.latestSubscription");
  let askStatus = _get(subscription, "products.ask.latestSubscription");
  if (viewStatus?.id > askStatus?.id) {
    subscribedProduct = _get(subscription, "products.view");
  } else {
    subscribedProduct = _get(subscription, "products.ask");
  }
  const remainingQuestions = _get(
    subscribedProduct,
    "questionsStatistic.numQuestionsRemainingUntilNextRenewal",
    false
  );
  const nextRenewalDateRaw = _get(
    subscribedProduct,
    "latestSubscription.nextRenewal"
  );
  let nextRenewalDate;
  if (nextRenewalDateRaw) {
    try {
      const parsed = parse(
        nextRenewalDateRaw,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );
      nextRenewalDate = `Ask by ${format(parsed, "MMM d")}.`;
      if (parseInt(remainingQuestions, 10) < 1) {
        nextRenewalDate = `Questions reset on ${format(parsed, "MMM d")}.`;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }

  const props = {
    userId,
    remainingQuestions,
    nextRenewalDate,
  };

  return (
    <RemainingQuestionsComponent
      {...props}
      canUserAskQuestion={
        askStatus !== null && askStatus && askStatus.subscriptionStatusId === 1
          ? true
          : false
      }
    />
  );
};
