import colors from './colors';

/**
 * Montserrat Weights
 * Black: 900
 * Bold: 700
 * SemiBold: 600
 * Medium: 500
 * Regular: 400
 */

const base = {
  fontFamily: 'Montserrat',
  color: colors.primary.text,
  fontWeight: 400,
  wordBreak: 'break-word',
};

const bold = {
  fontWeight: 700,
};

const typography = {
  h1: {
    ...base,
    ...bold,
    fontSize: '36px',
    lineHeight: '48px',
  },
  h2: {
    ...base,
    fontSize: '30px',
    fontWeight: 900,
  },
  h3: {
    ...base,
    ...bold,
    fontSize: '30px',
    lineHeight: '48px',
  },
  h4: {
    ...base,
    fontSize: '15px',
    lineHeight: '26px',
    fontWeight: 500,
  },
  h5: {
    ...base,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  h6: {
    ...base,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  body: {
    ...base,
    fontSize: '15px',
    lineHeight: '22px',
  },
  small: {
    ...base,
    ...bold,
    fontSize: '12px',
    lineHeight: '15px',
  },
  link: {
    textDecoration: 'none',
    color: colors.blue.blue4,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export default typography;
