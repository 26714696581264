import _get from "lodash/get";
import { USER_ROLE } from "../../../constants";
import PostService from "../../api/posts";
import * as POST_ACTIONS from "./actions";

export const postQuestionSuccess = () => (dispatch) => {
  dispatch({
    type: POST_ACTIONS.ADD_POST_SUCCESS,
    payload: {},
  });
};

export const postQuestionFailed = () => (dispatch) => {
  dispatch({
    type: POST_ACTIONS.ADD_POST_FAILED,
    payload: {},
  });
};

export const getPostsByPublisher =
  (publisherId) => async (dispatch, getState) => {
    const { userId, token, userRoleId } = _get(getState(), "user");
    if (publisherId == null) {
      dispatch({
        type: POST_ACTIONS.LOAD_POSTS_SUCCESS,
        payload: [],
      });
    } else {
      if (userId && token) {
        PostService.authenticate(userId, token, userRoleId);
      }
      dispatch({
        type: POST_ACTIONS.LOAD_POSTS_ATTEMPT,
        payload: {
          id: publisherId,
          pendingRequest: true,
        },
      });
      let questions;
      try {
        questions = await PostService.fetchPostsByPublisher(publisherId, 0);
      } catch (error) {
        dispatch({
          type: POST_ACTIONS.LOAD_POSTS_FAILED,
          payload: error,
        });
        return;
      }
      dispatch({
        type: POST_ACTIONS.LOAD_POSTS_SUCCESS,
        payload: questions,
      });
    }
  };

export const loadQuestionById = (id) => async (dispatch) => {
  dispatch({
    type: POST_ACTIONS.LOAD_POST_ATTEMPT,
  });
  try {
    const question = await PostService.fetchPostById(id);
    if (question) {
      dispatch({
        type: POST_ACTIONS.LOAD_POST_SUCCESS,
        payload: { question },
      });
      return;
    }
    dispatch({
      type: POST_ACTIONS.LOAD_POST_FAILED,
      payload: { error: "Unknown error", question },
    });
    return;
  } catch (error) {
    dispatch({
      type: POST_ACTIONS.LOAD_POST_FAILED,
      payload: { error: `${error}` },
    });
  }
};

export const addCreatorPost =
  (postContent, publisherId, files) => async (dispatch, getState) => {
    const { userId, token, userRoleId } = _get(getState(), "user");
    PostService.authenticate(userId, token, userRoleId);
    dispatch({
      type: POST_ACTIONS.ADD_POST_ATTEMPT,
      payload: postContent,
    });
    let postedQuestion;
    try {
      const video = files.filter((_i) => _i.attachmentTypeId === 2)[0];
      const image = files.filter((_i) => _i.attachmentTypeId === 1)[0];
      postedQuestion = await PostService.addPost({
        authorId: publisherId,
        contentAvailabilityId: 1, // 1 - false/public, 2 - true/member
        isHidden: false,
        postContentTypeId: 1, // 1 - false/Blog, 2 - true/Video ////
        isDraft: false,
        title: postContent,
        slug: "",
        excerpt: "",
        content: postContent,
        attachments: files,
        embeddedVideo: null,
        isCommunity: false,
        image: null,
        tags: [],
        suggestedPostIds: [],
        educationTopicIds: [],
      });
    } catch (error) {
      dispatch({
        type: POST_ACTIONS.ADD_POST_FAILED,
        payload: error,
      });
      return;
    }
    getPostsByPublisher(userId);
    // dispatch(getPostsByPublisher(userId));
    dispatch({
      type: POST_ACTIONS.ADD_POST_SUCCESS,
      payload: postedQuestion,
    });
  };

export default {};
