import _get from "lodash/get";

import ApplicationClient from "../core";
import {
  questionMutationSchema,
  questionSchema,
  questionsSchema,
  replyMutationSchema,
} from "../../schemas";
import NotificationService from "../../../components/shared/Notification";

export class QuestionClient extends ApplicationClient {
  canPostQuestion() {
    if (!this.isLoggedIn()) {
      throw new Error("User must be logged in to post a Question");
    }
    return true;
  }

  canAnswerQuestions() {
    if (!this.isLoggedIn()) {
      throw new Error("User must be logged in.");
    }
    if (!this.isPublisher()) {
      throw new Error("Only publishers can do this action.");
    }
    return true;
  }

  addQuestion(questionData) {
    if (!this.canPostQuestion()) {
      return new Promise().reject();
    }

    return new Promise((resolve, reject) => {
      this.networkClient
        .put("question", questionData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            window.location = window.location.href;
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchQuestionById(id) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`question/${id}`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            const question = response.data;
            if (!questionSchema.validateSync(question)) {
              throw new Error("Problem with API, question didn't match schema");
            }
            resolve(response.data);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchQuestionBySlug(slug) {
    return new Promise((resolve, reject) => {
      this.networkClient
        .get(`question/by-slug/${slug}`, { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { data: question } = response;
            if (!questionSchema.validateSync(question)) {
              throw new Error("Problem with API, question didn't match schema");
            }
            resolve(question);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchQuestionsByPublisher(publisherId, isPublisherProfile, pageIndex = 0) {
    return new Promise((resolve, reject) => {
      const profileUrlValue = isPublisherProfile ? "by-publisher" : "own-asked";
      this.networkClient
        .get(
          `question/group/${profileUrlValue}/*/${publisherId}/100/${pageIndex}`,
          { data: null }
        )
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { data: questions } = response;
            resolve(questions);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  fetchPostsQuestions(publisherId, isPublisherProfile, pageIndex = 0) {
    return new Promise((resolve, reject) => {
      const profileUrlValue = isPublisherProfile ? "by-publisher" : "own-asked";
      this.networkClient
        .get(`question/group/with-posts/${publisherId}/100/${pageIndex}`, {
          data: null,
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { data: questions } = response;
            resolve(questions);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  submitAnAnswer(questionId, reply, attachments) {
    if (!this.canAnswerQuestions()) {
      throw new Error("This user cannot submit an answer");
    }
    const replyData = {
      questionId,
      content: reply,
      attachments,
    };
    if (!replyMutationSchema.validateSync(replyData)) {
      throw new Error("Invalid schema for reply");
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .put("question/reply", replyData)
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { data: questionResponse } = response;
            if (!questionSchema.validateSync(questionResponse)) {
              throw new Error("Problem with API, question didn't match schema");
            }
            if (questionResponse.replies.length < 1) {
              throw new Error(
                "The question should have at least one reply now."
              );
            }
            resolve(questionResponse);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  favoriteQuestion(questionId, isPost) {
    if (!Number.isInteger(questionId)) {
      throw new Error(
        `QuestionID must be an integier, but was a ${typeof questionId}`
      );
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .put(
          isPost === true
            ? `favourites/posts/${questionId}`
            : `favourites/questions/${questionId}`,
          {}
        )
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(true);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  unfavoriteQuestion(questionId, isPost) {
    if (!Number.isInteger(questionId)) {
      throw new Error(
        `QuestionID must be an integier, but was a ${typeof questionId}`
      );
    }
    return new Promise((resolve, reject) => {
      this.networkClient
        .delete(
          isPost === true
            ? `favourites/posts/${questionId}`
            : `favourites/questions/${questionId}`,
          { data: null }
        )
        .then((response) => {
          if (response.status === 200 && response.data) {
            resolve(true);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }

  loadFavorites() {
    const { userObject } = this;
    if (!userObject || !userObject.token || !userObject.userId) {
      throw new Error("You must be logged in to get your subscription status");
    }

    return new Promise((resolve, reject) => {
      this.networkClient
        .get("favourites/group/mixed/300/0", { data: null })
        .then((response) => {
          if (response.status === 200 && response.data) {
            const questions = response.data;
            const favQuestionIds = questions.items.map((q) => q.item.id);
            resolve([favQuestionIds, questions]);
          }
        })
        .catch((error) => {
          const prettyErrorMessage = _get(
            error,
            "response.data.validationErrors",
            _get(error, "response.data.error", error.message)
          );
          const validationMessage =
            Object.keys(prettyErrorMessage).length > 0
              ? Object.values(prettyErrorMessage).map((v) => {
                  return v;
                })
              : prettyErrorMessage;
          if (validationMessage.length < 3) {
            NotificationService.error(validationMessage[0]);
          }
          NotificationService.error(prettyErrorMessage);
          reject(prettyErrorMessage);
        });
    });
  }
}

export default new QuestionClient();
