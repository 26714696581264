/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import { acceptShortcutProps, shortcutPropTypes, defaultShortcutProps } from '../utils';
import styles from './styles';
import globalStyles from '../../../styles';

const componentTypes = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  small: 'p',
  body: 'p',
};

const colorMapping = {
  white: globalStyles.colors.neutral.neutral1,
  black: globalStyles.colors.neutral.neutral4,
  light: globalStyles.colors.primary.textLight,
  accent: globalStyles.colors.primary.accent,
  primary: globalStyles.colors.primary.primary,
  error: globalStyles.colors.primary.accent,
};

export const Text = ({
  children,
  type,
  css,
  color,
  centered,
  bold,
  ...rest
}) => {
  const [finalStyles, otherProps] = acceptShortcutProps({ css, ...rest }, {
    ...styles.typography[type],
    ...styles.default,
  });

  if (color) {
    finalStyles.color = colorMapping[color];
  }
  if (centered) {
    finalStyles.textAlign = 'center';
  }
  if (bold) {
    finalStyles.fontWeight = 'bold';
  }

  return jsx(componentTypes[type], {
    css: {
      ...finalStyles,
      ...css,
    },
    'data-testid': 'text',
    ...otherProps,
  }, children);
};

Text.propTypes = {
  type: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'small',
    'body',
  ]),
  children: PropTypes.node,
  ...shortcutPropTypes,
  /* shortcut prop to set font color */
  color: PropTypes.oneOf(Object.keys(colorMapping)),
  /* shortcut to set centered text */
  centered: PropTypes.bool,
};

Text.defaultProps = {
  children: [],
  type: 'body',
  ...defaultShortcutProps,
  color: undefined,
  centered: false,
};

export default Text;
