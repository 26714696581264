import globalStyles from "../../../styles";

export default {
  root: {
    alignItems: "center",
  },
  avatar: {
    width: 130,
    padding: 2,
    border: "3px solid  #356859",
    height: 130,
    flexBasis: 130,
  },
  verifiedImg: {
    width: 24,
    height: 24,
    paddingLeft: 5,
  },
  userName: {
    fontWeight: 600,
    color: "#000000",
    fontFamily: "Source Serif Pro",
    fontSize: 34,
    lineHeight: "42px",
    textAlign: "center",
  },
  bio: {
    fontWeight: 400,
    fontFamily: "IBM Plex Sans",
    fontSize: 20,
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "#737373",
    marginBottom: 15,
  },
  timeAgo: {
    marginLeft: 5,
    color: globalStyles.colors.primary.textLight,
    fontWeight: 400,
  },
};
