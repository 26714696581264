/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { isProd, environment } from "../../../constants";
import { Text, View } from "../../core";
import styles from "./styles";
import { APP_URLS } from "../../../constants";
import Logo from "./img/logo.svg";

const {
  root: rootStyles,
  navLogoContainer: navLogoContainerStyles,
  logoContainer: logoContainerStyles,
  logotype: logotypeStyles,
  logo: logoStyles,
} = styles;

export const Header = ({ children }) => {
  const history = useHistory();
  return (
    <View css={rootStyles}>
      <View f="0 0 auto" css={navLogoContainerStyles}>
        <View fd="column">
          <View
            onClick={() => {
              history.push("/");
            }}
            css={logoContainerStyles}
          >
            <img src={Logo} alt="GuideReply" css={logoStyles} />
            {window.screen.width > 500 ? (
              <Text
                type="h1"
                style={{ color: "#356859" }}
                css={logotypeStyles}
                ml={13}
              >
                GuideReply
              </Text>
            ) : (
              ""
            )}
          </View>
          {/* {!isProd && <Text>{`current environment: ${environment}`}</Text>} */}
        </View>
      </View>
      {children}
    </View>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Header;
