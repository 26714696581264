export const flexMap = {
  fg: 'flexGrow',
  fs: 'flexShrink',
  fb: 'flexBasis',
  fd: 'flexDirection',
};

export const marginMap = {
  m: 'margin',
  mx: 'marginx', // special case
  my: 'marginy', // special case
  mt: 'marginTop',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mr: 'marginRight',
};

export const paddingMap = {
  p: 'padding',
  px: 'paddingx', // special case
  py: 'paddingy', // special case
  pt: 'paddingTop',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  pr: 'paddingRight',
};
