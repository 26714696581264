export const QUESTIONS_ROOT = 'QUESTIONS';

export const POST_QUESTION_ATTEMPT = `${QUESTIONS_ROOT}/POST_QUESTION_ATTEMPT`;
export const POST_QUESTION_FAILED = `${QUESTIONS_ROOT}/POST_QUESTION_FAILED`;
export const POST_QUESTION_SUCCESS = `${QUESTIONS_ROOT}/POST_QUESTION_SUCCESS`;

export const POST_REPLY_ATTEMPT = `${QUESTIONS_ROOT}/POST_REPLY_ATTEMPT`;
export const POST_REPLY_FAILED = `${QUESTIONS_ROOT}/POST_REPLY_FAILED`;
export const POST_REPLY_SUCCESS = `${QUESTIONS_ROOT}/POST_REPLY_SUCCESS`;

export const LOAD_QUESTIONS_ATTEMPT = `${QUESTIONS_ROOT}/LOAD_QUESTIONS_ATTEMPT`;
export const LOAD_QUESTIONS_FAILED = `${QUESTIONS_ROOT}/LOAD_QUESTIONS_FAILED`;
export const LOAD_QUESTIONS_SUCCESS = `${QUESTIONS_ROOT}/LOAD_QUESTIONS_SUCCESS`;

export const LOAD_QUESTION_ATTEMPT = `${QUESTIONS_ROOT}/LOAD_QUESTION_ATTEMPT`;
export const LOAD_QUESTION_FAILED = `${QUESTIONS_ROOT}/LOAD_QUESTION_FAILED`;
export const LOAD_QUESTION_SUCCESS = `${QUESTIONS_ROOT}/LOAD_QUESTION_SUCCESS`;

export const FAV_QUESTION_ATTEMPT = `${QUESTIONS_ROOT}/FAV_QUESTION_ATTEMPT`;
export const FAV_QUESTION_FAILED = `${QUESTIONS_ROOT}/FAV_QUESTION_FAILED`;
export const FAV_QUESTION_SUCCESS = `${QUESTIONS_ROOT}/FAV_QUESTION_SUCCESS`;

export const LOAD_FAVS_ATTEMPT = `${QUESTIONS_ROOT}/LOAD_FAVS_ATTEMPT`;
export const LOAD_FAVS_FAILED = `${QUESTIONS_ROOT}/LOAD_FAVS_FAILED`;
export const LOAD_FAVS_SUCCESS = `${QUESTIONS_ROOT}/LOAD_FAVS_SUCCESS`;

// For testing reasons
export const CLEAR_QUESTIONS = `${QUESTIONS_ROOT}/CLEAR_QUESTIONS`;
