/** @jsx jsx */
import { jsx } from "@emotion/react";
import { connect } from "react-redux";
import { Fragment } from "react";
import { Redirect, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import _last from "lodash/last";
import _get from "lodash/get";

import { APP_URLS, USER_ROLE } from "../../../constants";

export const OnLoginRedirect = ({ user, redirectToSettings, firstAttempt }) => {
  const isLoggedIn = _get(user, "token", false);
  const isPublisher = _get(user, "userRoleId", 0) === USER_ROLE.PUBLISHER;
  const userId = _get(user, "userId", false);
  const slug = useParams();
  if (!isLoggedIn) {
    return <Fragment />;
  }

  if (isPublisher && userId && !redirectToSettings) {
    if (slug.slug === sessionStorage.getItem("name")) {
      return <Redirect to={APP_URLS.PROFILE.url(slug.slug)} />;
    } else {
      alert("This expert doesn't have a access to this feed");
      <Redirect to={APP_URLS.LOGIN.path} />;
      sessionStorage.clear();
      window.location.reload();
    }
  }
  if (redirectToSettings) {
    return <Redirect to={APP_URLS.SETTINGS.url(slug.slug)} />;
  }
  if (user.userRoleId === 2) {
    if (
      JSON.stringify(slug.slug) === sessionStorage.getItem("subscription") ||
      firstAttempt === true
    ) {
      return <Redirect to={APP_URLS.FEED.path} />;
    } else {
      alert("This user doesn't have a membership to this feed");
      <Redirect to={APP_URLS.LOGIN.path} />;
      sessionStorage.clear();
      window.location.reload();
    }
  }
  // Regular user, see if they have a subscription we can direct them to.
  // TODO

  // Regular user, need to try to see if there's a redirect in the URL.
  const params = window.location.href.split("?");
  if (params.length > 1) {
    // We may have a redirect param
    let redirectPath;
    _last(params)
      .split(",")
      .forEach((param) => {
        const [key, value] = param.split("=");
        if (key.includes("redirect")) {
          redirectPath = value;
        }
      });
    if (redirectPath) {
      return <Redirect to={redirectPath} />;
    }
  }

  // Regular user, need to try to see if there's a redirect in the URL.
  const userSlug = _get(slug, "slug", false);
  if (userSlug) {
    return <Redirect to={APP_URLS.FEED.url(userSlug)} />;
  }

  return <Redirect to="/admin" />;

  // throw new Error('No slug to redirect to was entered');
};

OnLoginRedirect.propTypes = {
  user: PropTypes.shape({
    token: PropTypes.string,
    userRoleId: PropTypes.oneOf(Object.values(USER_ROLE)),
    userId: PropTypes.number,
  }),
};

OnLoginRedirect.defaultProps = {
  user: {},
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export const ConnectedOnLoginRedirect =
  connect(mapStateToProps)(OnLoginRedirect);

export default OnLoginRedirect;
