/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import { View, Text, Button, Link } from "../../components/core";
import { UserProfile } from "../../components/shared";
import SignUpCTA from "./SignUpCTA";
import { APP_URLS } from "../../constants";
import profileStyles from "../profilePage/styles";
import styles from "./styles";
import data from "../fixture"; // TODO: Fake data for now.
import UserService from "../../services/api/user/index";
import ReactGA from "react-ga";

export const Feed = ({ user, currentUser, children }) => {
  const [video, setVideo] = useState("");
  useEffect(() => {
    ReactGA.initialize("G-SVZZ7LD0KZ");
    ReactGA.pageview(window.location.pathname + window.location.search);
    const subscription = JSON.parse(sessionStorage.getItem("subscription"));
    if (subscription !== null) {
      fetchPublisher(subscription);
    }
  }, []);
  const fetchPublisher = (subscription) => {
    UserService.fetchPublisherBySlug(subscription).then((resp) => {
      if (resp) {
        setVideo(resp.featuredVideo);
      }
    });
  };
  localStorage.clear();
  const totalSpots = _get(user, "products.ask.maxActiveSubscriptions");
  const numTaken = _get(user, "activeSubscribers.numAskers");
  let askSpotsCopy = `${totalSpots - numTaken}/${totalSpots} spots left!`;
  if (parseInt(numTaken, 10) >= parseInt(totalSpots, 10)) {
    askSpotsCopy = "Sold out";
  }
  const priceData = {
    view: {
      id: _get(user, "products.view.id"),
      price: `$${_get(user, "products.view.cost")}`,
      spots: "",
      ctaLink: APP_URLS.SIGNUP.url(user.slug),
    },
    ask: {
      id: _get(user, "products.ask.id"),
      price: `$${_get(user, "products.ask.cost")}`,
      spots: askSpotsCopy,
      ctaLink: APP_URLS.SIGNUP.url(user.slug),
    },
  };

  return (
    <View fd="column" css={styles.root} f="none" data-testid="feed-page">
      <View css={styles.pageContent} m="0 auto" fd="column">
        <UserProfile
          name={user.name}
          avatarUrl={_get(user, "avatar.medium")}
          bio={user.bio}
          mb={42}
          IntroVideo={video !== "" ? video : false}
        />
        <View fd="column">
          {
            // (!currentUser.token) && (
            <SignUpCTA priceData={priceData} user={currentUser}>
              {currentUser.userId === null ? (
                <div css={styles.buttonDiv}>
                  <Link to={APP_URLS.SIGNUP.url(user.slug)}>
                    <Button>Subscribe for answers</Button>
                  </Link>
                </div>
              ) : (
                ""
              )}
              <div css={styles.metricsDiv}>
                <label css={profileStyles.metrics}>
                  <b css={profileStyles.metricsFont}>
                    {user.questions && user.questions.numAnswered}
                  </b>
                  {window.screen.width < 500 ? <br /> : ""}
                  Answers
                </label>
                <label css={profileStyles.metrics}>
                  <b css={profileStyles.metricsFont}>
                    {user.posts && user.posts.numTotal}
                  </b>
                  {window.screen.width < 500 ? <br /> : ""}
                  Posts
                </label>
                <label css={profileStyles.metrics}>
                  <b css={profileStyles.metricsFont}>
                    {user.activeSubscribers &&
                      user.activeSubscribers.numAskers +
                        user.activeSubscribers.numViewers}
                  </b>
                  {window.screen.width < 500 ? <br /> : ""}
                  Members
                </label>
              </div>
            </SignUpCTA>
            // )
          }
          {children}
        </View>
      </View>
    </View>
  );
};

Feed.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    // twitterTag,
    numTwitterFollowers: PropTypes.number,
    // featuredVideo,
    bio: PropTypes.string,
    id: PropTypes.number,
    token: PropTypes.string,
    slug: PropTypes.string,
    activeSubscribers: PropTypes.shape({
      numViewers: PropTypes.number,
      numAskers: PropTypes.number,
    }),
    products: PropTypes.shape({
      ask: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
        cost: PropTypes.number.isRequired,
      }).isRequired,
      view: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
        cost: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    userId: PropTypes.number,
    token: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Feed.defaultProps = {
  currentUser: undefined,
};

export default Feed;
