import globalStyles from "../../../styles";

export default {
  root: {
    maxWidth: 306,
    minHeight: 375,
    height: "100%",
    borderRadius: "8px",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.08)",
  },
  content: {
    textAlign: "left",
    paddingLeft: 15,
  },
  description: {
    paddingLeft: 10,
    "> *": {
      width: "100%",
    },
    "> p": {
      textAlign: "center",
    },
    "> ul": {
      paddingLeft: 20,
      textAlign: "left",
    },
    "> ul li": {
      marginBottom: 10,
    },
  },
  title: {
    fontSize: 20,
    width: "100%",
    textAlign: "left",
    paddingLeft: 25,
    paddingTop: 10,
    fontFamily: "IBM Plex Sans",
    fontWeight: 600,
    letterSpacing: "0.15 px",
  },
  costLine: {
    fontSize: 48,
    fontWeight: 600,
    fontFamily: "IBM Plex Sans",
  },
  availability: {
    color: "#fff",
    textAlign: "left",
    fontFamily: "IBM Plex Sans",
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: "0.15px",
    paddingLeft: 10,
  },
  button: {
    paddingLeft: 22,
    paddingRight: 22,
    width: "258px",
    borderRadius: "8px",
    marginBottom: "20px",
    zIndex: 1,
    height: "48px",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
  },
  selectedButton: {
    appearance: "none",
    border: `2px solid ${globalStyles.colors.neutral.neutral4}`,
    background: "transparent",
    cursor: "pointer",
    width: 60,
    height: 60,
    padding: 0,
    borderRadius: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "scale(0.75)",
  },
  selectedButtonSelected: {
    border: `2px solid ${globalStyles.colors.primary.secondary}`,
    backgroundColor: globalStyles.colors.primary.secondary,
  },
};
