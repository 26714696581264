export default {
  // hashed list of question IDs, sorted by created date, indexed by creator's ID
  idsByPublisherId: {},
  // hashed list of question IDs, sorted by created date, indexed by logged in user's ID
  idsByUserId: {},
  // the details of each question, hashed by questionId
  questionsById: {},
  // signals if there's a pending request.
  pendingRequest: false,
  // List of the question IDs that the user has favorited.
  favoriteQuestionIds: [],
  // any Error
  error: false,
};
