import UserService from '../../api/user';
import { loginSuccessful } from '../user/actionCreators';

const APP_INIT = 'APP/INIT';

export const appInit = () => (dispatch) => {
  dispatch({
    type: APP_INIT,
  });
  const user = UserService.loadFromLocalStorage();
  if (user) {
    dispatch(loginSuccessful(user, { fromLocalStorage: true }));
  }
};

export default {};
