/** @jsx jsx */
import { jsx } from "@emotion/react";
import deanprofile from "./img/deanProfile.png";
import styles from "./styles";
import verified from "../../components/shared/userProfile/img/Verified.svg";
import insta from "./img/instaIcon.svg";
import youtube from "./img/youtubeIcon.svg";
import music from "./img/musicIcon.svg";
import image1 from "./img/random1.svg";
import image2 from "./img/random2.svg";
import image3 from "./img/random3.svg";
import image4 from "./img/random4.svg";
import image5 from "./img/random5.svg";
import image6 from "./img/random6.svg";
import image7 from "./img/random7.svg";
import image8 from "./img/random8.svg";
import image9 from "./img/random9.svg";
import bookmark from "./img/bookmark.svg";
import video1 from "./img/1.mp4";
import video2 from "./img/2.mp4";

export const Deananywhere = () => {
  return (
    <div css={styles.deanProfileDiv}>
      <img
        css={styles.profileImg}
        style={{
          border: "3px solid rgb(53, 104, 89)",
          marginTop: "35px",
          marginBottom: 15,
        }}
        src={deanprofile}
        alt="profile"
      />
      <label style={{ fontSize: 34 }} css={styles.deanTitle}>
        deananywhere
        <img style={{ marginLeft: 10 }} src={verified} alt="verified" />
      </label>
      <label
        style={{
          fontSize: 20,
          lineHeight: "30px",
          letterSpacing: "0.15px",
          textAlign: "center",
        }}
        css={styles.deanCategories}
      >
        Dean Ellis <br />
        exploring | discovering | growing
        <br />
        presets and editing courses →{" "}
        <a css={styles.linkStyle}>deananywhere.life</a>
      </label>
      <span>
        <img style={{ margin: "10px 15px" }} src={insta} />
        <img style={{ margin: "10px 15px" }} src={youtube} />
        <img style={{ margin: "10px 15px" }} src={music} />
      </span>
      <span>
        <label css={styles.metricsLabel}>
          <b>124</b> Answers
        </label>
        <label css={styles.metricsLabel}>
          <b>234</b> Posts
        </label>
        <label css={styles.metricsLabel}>
          <b>7k</b> Members
        </label>
        <div css={styles.borderDiv}></div>
      </span>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image1} />
              <label css={styles.name}>joeyboy</label>
              <label css={styles.timeLabel}>2 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Hey, I'm heading on a trip to Europe in a few weeks. We've got a
            rental car booked and plan to do a road trip from the south of
            France across northern Italy to the Dolomites. Do you think an
            international driver's license is really necessary, or more of a
            formality that no one really cares about?
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={deanprofile} />
              <label css={styles.name}>deananywhere</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 2 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ paddingLeft: 58, paddingRight: 12 }}
            css={styles.content}
          >
            You might be fine in France, but don't test it in Italy. If you get
            pulled over by the police, they'll ask for it. Random police stops
            to check for drunk driving or whatever else they want to are
            perfectly legal in lots of European countries. Besides, an IDL is
            basically just a translation of your license. It'll only cost you
            like $20 and 20 minutes at AAA. Just give yourself the peace of mind
            and pick one up.
          </label>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image2} />
              <label css={styles.name}>chris_j</label>
              <label css={styles.timeLabel}>3 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Do you have a favorite website for cheap flights? I don’t have dates
            set yet for my next trip and would rather just plan around the
            cheapest flights.
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px " }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={deanprofile} />
              <label css={styles.name}>deananywhere</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 2 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ paddingLeft: 58, paddingRight: 12 }}
            css={styles.content}
          >
            Skyscanner is my tried and true for general searches. You can choose
            an entire month or a whole year to peruse the best deal. As with
            most of those sites, some of the smaller airlines are left out. So
            be sure to check the websites of any local budget airlines.{" "}
            <a css={styles.linkStyle}>Scott's Cheap Flights</a> is also a great
            newsletter for keeping up on general flight deals as they pop up!
          </label>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={deanprofile} />
              <label css={styles.name}>deananywhere</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}>4 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Travel tribe: Fiji Airways JUST published a roundtrip economy class
            flight between SFO and Fiji for $379. I'll say it louder for those
            in the back: $379 TO FLY ALL THE WAY TO FIJI AND BACK. Who cares if
            you don't live in SF. Take a train, take a Spirit flight, walk to
            the airport if you have to. You'll figure it out later, but these
            mistake fares typically get corrected real quick so run don't walk
            over to their website!
            <br />
            <a css={styles.linkStyle}>https://www.fijiairways.com/en-fj/</a>
          </label>
          <img
            src={image3}
            style={{ width: window.screen.width < 624 ? 300 : {} }}
            alt="icon"
          />
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image4} />
              <label css={styles.name}>domdona</label>
              <label css={styles.timeLabel}>5 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            What's the deal with house-sitting websites? Ever tried it? Some of
            these homes seem too good to be true. Like, come live in my giant
            house in Tuscany for free for a month…? Is it scammy? Legit?
          </label>
          <span>
            <img style={{ marginRight: 2, marginBottom: 15 }} src={image5} />
            <img style={{ marginBottom: 15 }} src={image6} />
          </span>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={deanprofile} />
              <label css={styles.name}>deananywhere</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 5 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ paddingLeft: 58, marginBottom: 20, paddingRight: 12 }}
            css={styles.content}
          >
            Legit - as far as I know, at least. I've never done it myself, but a
            friend of mine and her partner spent two months living in a LITERAL
            chateau in a small town a few hours south of Paris a few years ago.
            Bear in mind, though - they did have to take care of a few
            pets...including two horses and multiple chickens. Pets or some kind
            of house/yard maintenance to be taken care of are often the part of
            the deal. Still, not a bad trade if you ask me. Some even allow you
            access to their car! Just stick to the established sites, don't give
            anyone your personal info, you get it.
          </label>
          <span>
            <img
              style={{
                marginRight: 2,
                marginBottom: 15,
                width: window.screen.width < 624 ? 300 : {},
              }}
              src={image7}
            />
          </span>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image8} />
              <label css={styles.name}>austin.o</label>
              <label css={styles.timeLabel}>6 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            Maybe a controversial question, but I have some friends from Spain
            who are planning a trip to Cuba. As an American with no other
            passport, can I go?
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ margin: "15px 0px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={deanprofile} />
              <label css={styles.name}>deananywhere</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 6 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <span>
            <video style={{ marginRight: 2 }} width="285" controls>
              <source
                preload="metadata"
                src={video1 + "#t=0.001"}
                type="video/mp4"
              />
            </video>
            <video width="285" controls>
              <source
                preload="metadata"
                src={video2 + "#t=0.001"}
                type="video/mp4"
              />
            </video>
          </span>
        </div>
      </div>
      <div css={styles.cardDiv}>
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>
              <img src={image9} />
              <label css={styles.name}>jennyj</label>
              <label css={styles.timeLabel}>7 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ marginBottom: 25, paddingRight: 12 }}
            css={styles.content}
          >
            About to buy my first backpack for my first solo travel trip to
            Asia! So excited, but also feel like there are so many options to
            choose from…I don't even know where to start. Help???
          </label>
          <div css={styles.borderDiv}></div>
          <div style={{ marginTop: "15px" }}>
            <span>
              <img style={{ width: 48, height: 48 }} src={deanprofile} />
              <label css={styles.name}>deananywhere</label>
              <img
                style={{ width: 16, height: 16, marginRight: 10 }}
                src={verified}
              />
              <label css={styles.timeLabel}> 7 days ago</label>
              <img style={{ float: "right" }} src={bookmark} />
            </span>
          </div>
          <label
            style={{ paddingLeft: 58, paddingRight: 12 }}
            css={styles.content}
          >
            First off, CONGRATS! There's nothing like wandering out into the
            world on your own for the first time. When it comes to buying a
            pack, you definitely want to make a trip to an REI or similar in
            person. This will give you a better idea of what size (s/m/l) is
            best for you, and what size (40/55/65 liter) you'll want to carry
            with you. <br /> <br /> Think about how long you're going, whether
            you want to be able to carry your bag onto the plane, if you'll need
            any hiking/camping functionality. In my experience, the smaller you
            can get by with, the better. As far as brands, well, my first
            backpack was a Gregory and I’ve been a loyalist ever since.
          </label>
        </div>
      </div>
    </div>
  );
};

export default Deananywhere;
