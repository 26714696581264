import React, { useEffect, useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/react";
import UserService from "../../services/api/user";
import styles from "./styles";
import moment from "moment";

const pageStyles = styles.transactionHistory;

const MembersTable = () => {
  const [memberlist, setMemberList] = useState();
  useEffect(() => {
    fetchTransactions();
  }, []);
  const fetchTransactions = async () => {
    const userDetails = JSON.parse(sessionStorage.getItem("user"));
    const apiData = await UserService.getmemberStats(userDetails.userId);
    setMemberList(apiData.data);
  };
  return (
    <div css={pageStyles.root}>
      <div css={pageStyles.statsDiv}>
        <div css={pageStyles.statssubDiv}>
          <label css={pageStyles.statslabel1}>Today</label>
          <br />
          <label css={pageStyles.statslabel2}>
            {memberlist &&
              memberlist.today.stats.numNewAskSubscriptions +
                memberlist.today.stats.numNewViewSubscriptions +
                memberlist.today.stats.numRenewals}
          </label>
          <br />
          <label style={{ color: "#356859" }} css={pageStyles.statslabel3}>
            Ask :&nbsp;
            {memberlist && memberlist.today.stats.numNewAskSubscriptions}
            &nbsp; /&nbsp; View :&nbsp;
            {memberlist && memberlist.today.stats.numNewViewSubscriptions}
            &nbsp; /&nbsp; Renewal :&nbsp;
            {memberlist && memberlist.today.stats.numRenewals}
          </label>
          <br />
        </div>
        <div css={pageStyles.borderDiv}></div>
        <div css={pageStyles.statssubDiv}>
          <label css={pageStyles.statslabel1}>Week</label>
          <br />
          <label css={pageStyles.statslabel2}>
            {memberlist &&
              memberlist.thisWeek.stats.numNewAskSubscriptions +
                memberlist.thisWeek.stats.numNewViewSubscriptions +
                memberlist.thisWeek.stats.numRenewals}
          </label>
          <br />
          <label style={{ color: "#356859" }} css={pageStyles.statslabel3}>
            Ask :&nbsp;
            {memberlist && memberlist.thisWeek.stats.numNewAskSubscriptions}
            &nbsp; /&nbsp; View :&nbsp;
            {memberlist && memberlist.thisWeek.stats.numNewViewSubscriptions}
            &nbsp; /&nbsp; Renewal :&nbsp;
            {memberlist && memberlist.thisWeek.stats.numRenewals}
          </label>
          <br />
        </div>
        <div css={pageStyles.borderDiv}></div>
        <div css={pageStyles.statssubDiv}>
          {" "}
          <label css={pageStyles.statslabel1}>Month</label>
          <br />
          <label css={pageStyles.statslabel2}>
            {" "}
            {memberlist &&
              memberlist.thisMonth.stats.numNewAskSubscriptions +
                memberlist.thisMonth.stats.numNewViewSubscriptions +
                memberlist.thisMonth.stats.numRenewals}
          </label>
          <br />
          <label style={{ color: "#356859" }} css={pageStyles.statslabel3}>
            Ask :&nbsp;
            {memberlist && memberlist.thisMonth.stats.numNewAskSubscriptions}
            &nbsp; /&nbsp; View :&nbsp;
            {memberlist && memberlist.thisMonth.stats.numNewViewSubscriptions}
            &nbsp; /&nbsp; Renewal :&nbsp;
            {memberlist && memberlist.thisMonth.stats.numRenewals}
          </label>
          <br />
        </div>
      </div>
      <div css={pageStyles.tableContainer}>
        <table css={pageStyles.table}>
          <thead>
            <tr>
              <th>Month</th>
              <th>New Ask</th>
              <th>New View</th>
              <th>Renewals</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {memberlist &&
              Object.keys(memberlist.pastMonths).map((transaction, idx) => (
                <tr
                  // key={`row-${transaction}`}
                  css={idx % 2 !== 0 ? pageStyles.evenRow : {}}
                >
                  <td> {moment(transaction).format("MM-YYYY")}</td>
                  <td>
                    {
                      memberlist.pastMonths[transaction].stats
                        .numNewAskSubscriptions
                    }
                  </td>
                  <td>
                    {
                      memberlist.pastMonths[transaction].stats
                        .numNewViewSubscriptions
                    }
                  </td>
                  <td>
                    {" "}
                    {memberlist.pastMonths[transaction].stats.numRenewals}
                  </td>
                  <td>
                    {memberlist.pastMonths[transaction].stats
                      .numNewAskSubscriptions +
                      memberlist.pastMonths[transaction].stats
                        .numNewViewSubscriptions +
                      memberlist.pastMonths[transaction].stats.numRenewals}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default MembersTable;
