/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import { Loading, View } from "../../components/core";
import {
  loadCurrentUserDetails,
  setUserBio,
  setUserAvatar,
} from "../../services/state/actionCreators";
import { ProfilePage } from "./ProfilePage";

class ProfilePageDataProvider extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        slug: PropTypes.string,
        // deeplink directly to a single question with id.
        id: PropTypes.string,
      }),
    }).isRequired,
    loadUser: PropTypes.func.isRequired,
    // loadUserSlug: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object,
    userLoading: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    currentUser: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    currentCreatorSlug: PropTypes.string,
    editBio: PropTypes.func.isRequired,
    uploadNewAvatar: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    currentUser: null,
    currentCreatorSlug: "",
  };

  componentDidMount() {
    this.loadUserIfNeeded();
  }

  componentDidUpdate() {
    this.loadUserIfNeeded();
  }

  loadUserIfNeeded() {
    const { user, loadUser, userLoading } = this.props;
    if (!userLoading && user === null) {
      loadUser();
    }
  }

  render() {
    const {
      user,
      currentUser,
      editBio,
      uploadNewAvatar,
      currentCreatorSlug,
    } = this.props;

    if (!user || Object.keys(user).length < 1) {
      return (
        <View f="none" pt={64}>
          <Loading />
        </View>
      );
    }
    return (
      <ProfilePage
        isOwnProfile={user.id === currentUser.userId}
        onEditBio={(newBio) => {
          editBio(newBio, user);
        }}
        onEditAvatar={(data) => uploadNewAvatar(data)}
        creatorSlug={currentUser.userRoleId === 3 ? "" : currentCreatorSlug}
        user={user}
        {...user}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: {
        slug: userSlug,
        // id: questionId,
      },
    },
  } = props;
  const userId = _get(state, "user.userId");
  const userProfileInt = parseInt(userId, 10);
  const isUserIdInStoreArray =
    Array.isArray(_get(state, "users.ids", false)) &&
    state.users.ids.includes(userProfileInt);
  const anyUserLoading = _get(state, "users.pendingRequest", false);

  return {
    userLoading: anyUserLoading,
    user: isUserIdInStoreArray ? state.users.users[userProfileInt] : null,
    currentUser: state.user,
    currentCreatorSlug: userSlug,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadUser: () => {
    dispatch(loadCurrentUserDetails());
  },
  editBio: (newBio, userData) => {
    dispatch(setUserBio(newBio, userData));
  },
  uploadNewAvatar: (data) => {
    dispatch(setUserAvatar(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePageDataProvider);
