import _get from 'lodash/get';

import { useReducer, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getVideoThumnail } from '../../services/api/video';

export const usePublisherParam = () => {
  const { params: { slug } } = useRouteMatch();
  return slug;
};

/**
 * usePublisherIdParam
 *
 * @returns {number} publisher ID.
*/
export const usePublisherIdParam = () => {
  const slug = usePublisherParam();
  return useSelector((state) => _get(state, `users.slugs[${slug}]`));
};

/**
 * usePublisherDataParam
 *
 * @returns {object} The publisher data object.
*/
export const usePublisherDataParam = () => {
  const userId = usePublisherIdParam();
  return useSelector((state) => _get(state, `users.users[${userId}]`, {}));
};

export const useCurrentUser = () => {
  const userId = useSelector((state) => _get(state, 'user.userId'));
  return useSelector((state) => _get(state, `users.users[${userId}]`));
};

export const useUserData = () => useSelector((state) => _get(state, 'user'));

const ACTIONS = {
  REQUEST: 'inflight',
  SUCCESS: 'success',
  ERROR: 'error',
};

const initialState = {
  data: '',
  loading: false,
  requested: false,
  error: null,
};

const loaderReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.REQUEST:
      return {
        ...state,
        loading: true,
        requested: true,
      };
    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    default:
      return initialState;
  }
};

export const useGetThumbnailForVideo = (videoId) => {
  const [state, dispatch] = useReducer(loaderReducer, initialState);
  useEffect(() => {
    if (!state.requested && videoId) {
      dispatch({ type: ACTIONS.REQUEST });
      getVideoThumnail(videoId)
        .then((_data) => {
          dispatch({
            type: ACTIONS.SUCCESS,
            data: _data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ACTIONS.ERROR,
            error,
          });
        });
    }
    // passing an empty array makes it only call the first time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);
  return state;
};

export default {};
