import _get from "lodash/get";

import {
  POST_QUESTION_ATTEMPT,
  POST_QUESTION_FAILED,
  POST_QUESTION_SUCCESS,
  LOAD_QUESTIONS_ATTEMPT,
  LOAD_QUESTIONS_FAILED,
  LOAD_QUESTIONS_SUCCESS,
  LOAD_QUESTION_ATTEMPT,
  LOAD_QUESTION_FAILED,
  LOAD_QUESTION_SUCCESS,
  POST_REPLY_ATTEMPT,
  POST_REPLY_FAILED,
  POST_REPLY_SUCCESS,
  CLEAR_QUESTIONS,
  FAV_QUESTION_SUCCESS,
  LOAD_FAVS_SUCCESS,
} from "./actions";
import defaultState from "./defaultState";

const questionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_QUESTION_ATTEMPT:
    case LOAD_QUESTIONS_ATTEMPT:
    case POST_QUESTION_ATTEMPT:
    case POST_REPLY_ATTEMPT:
      return {
        ...state,
        ...{
          pendingRequest: true,
        },
      };
    case LOAD_QUESTIONS_FAILED:
    case POST_QUESTION_FAILED:
    case LOAD_QUESTION_FAILED:
    case POST_REPLY_FAILED:
      return {
        ...state,
        ...{
          pendingRequest: false,
          error: action.payload,
        },
      };
    case LOAD_QUESTION_SUCCESS: {
      const { question } = action.payload;
      const byPublisher = { ...state.idsByPublisherId };
      const byUser = { ...state.idsByUserId };
      const byID = {};

      const publisherID = _get(question, "publisher.id");
      const authorID = _get(question, "author.id");
      const questionID = question.id;
      byPublisher[publisherID] = [questionID]
        .concat(byPublisher[publisherID])
        .filter((q) => !!q);
      byUser[authorID] = [questionID]
        .concat(byUser[authorID])
        .filter((q) => !!q);
      byID[questionID] = question;

      return {
        ...state,
        ...{
          idsByPublisherId: byPublisher,
          idsByUserId: byUser,
          questionsById: { ...state.questionsById, ...byID },
          pendingRequest: false,
        },
      };
    }
    case LOAD_QUESTIONS_SUCCESS: {
      const questions = action.payload;
      const byPublisher = { ...state.idsByPublisherId };
      const byUser = { ...state.idsByUserId };
      const byID = {};
      if (!Array.isArray(questions)) {
        throw new Error("Questions is not an array");
      }
      questions.forEach((question) => {
        const publisherID = _get(question, "publisher.id");
        const authorID = _get(question, "author.id");
        const questionID = question.id;
        byPublisher[publisherID] = [questionID]
          .concat(byPublisher[publisherID])
          .filter((q) => !!q);
        byUser[authorID] = [questionID]
          .concat(byUser[authorID])
          .filter((q) => !!q);
        byID[questionID] = question;
      });
      return {
        ...state,
        ...{
          idsByPublisherId: byPublisher,
          idsByUserId: byUser,
          questionsById: { ...state.questionsById, ...byID },
          pendingRequest: false,
        },
      };
    }
    case POST_QUESTION_SUCCESS: {
      const question = action.payload;
      const publisherID = _get(question, "publisher.id");
      const authorID = _get(question, "author.id");
      const questionID = question.id;
      const byPublisher = { ...state.idsByPublisherId };
      const byUser = { ...state.idsByUserId };

      const byID = {};
      byPublisher[publisherID] = [questionID]
        .concat(byPublisher[publisherID])
        .filter((q) => !!q);
      byUser[authorID] = [questionID]
        .concat(byUser[authorID])
        .filter((q) => !!q);
      byID[questionID] = question;

      return {
        ...state,
        ...{
          idsByPublisherId: byPublisher,
          idsByUserId: byUser,
          questionsById: { ...state.questionsById, ...byID },
          pendingRequest: false,
        },
      };
    }
    case POST_REPLY_SUCCESS: {
      // Only need to update the question w/ the new data.
      const question = action.payload;
      const byID = { [question.id]: action.payload };
      return {
        ...state,
        ...{
          questionsById: { ...state.questionsById, ...byID },
          pendingRequest: false,
        },
      };
    }
    case LOAD_FAVS_SUCCESS: {
      const fullQuestionData = _get(action, "payload.questions", []);
      const byPublisher = { ...state.idsByPublisherId };
      const byUser = { ...state.idsByUserId };
      const byID = {};
      fullQuestionData.forEach((question) => {
        const publisherID = _get(question.item, "publisher.id");
        const authorID = _get(question.item, "author.id");
        const questionID = question.item.id;
        byPublisher[publisherID] = [questionID]
          .concat(byPublisher[publisherID])
          .filter((q) => !!q);
        byUser[authorID] = [questionID]
          .concat(byUser[authorID])
          .filter((q) => !!q);
        byID[questionID] = question;
      });
      return {
        ...state,
        idsByPublisherId: byPublisher,
        idsByUserId: byUser,
        questionsById: { ...state.questionsById, ...byID },
        favoriteQuestionIds: _get(action, "payload.favIds", []),
      };
    }
    case FAV_QUESTION_SUCCESS: {
      const { questionId } = action.payload;
      if (state.favoriteQuestionIds.includes(questionId)) {
        // Need to unfavorite it.
        return {
          ...state,
          favoriteQuestionIds: state.favoriteQuestionIds.filter(
            (qid) => qid !== questionId
          ),
        };
      }
      return {
        ...state,
        favoriteQuestionIds: [...state.favoriteQuestionIds, questionId],
      };
    }
    case CLEAR_QUESTIONS: {
      return {
        ...state,
        ...{
          idsByPublisherId: {},
          idsByUserId: {},
          questionsById: {},
          pendingRequest: false,
        },
      };
    }
    default:
      return state;
  }
};

export default questionsReducer;
