import { Vimeo } from 'vimeo';
import _get from 'lodash/get';
import _last from 'lodash/last';

import vimeo from '../../../secrets';

const sleep = (ms) => (
  new Promise((resolve) => setTimeout(resolve, ms))
);

const MAX_STATUS_TRIES = 40;

const VIMEO_STATUS = {
  COMPLETE: 'complete',
  IN_PROGRESS: 'in_progress',
  UNKNOWN: 'unknown',
  ERROR: 'error',
};

if (!vimeo || !vimeo.clientId) {
  throw new Error('VIMEO credntials missing, did you upload them?');
}

const vimeoClient = new Vimeo(
  vimeo.clientId,
  vimeo.clientSecret,
  vimeo.clientToken,
);

const checkStatus = (videoURI) => new Promise((resolve, reject) => {
  vimeoClient.request(
    `${videoURI}?fields=transcode.status`,
    (error, body) => {
      if (error) {
        reject(error);
      }
      resolve(_get(body, 'transcode.status', VIMEO_STATUS.UNKNOWN));
    },
  );
});

const getViedoData = (videoURI) => new Promise((resolve, reject) => {
  vimeoClient.request(
    `${videoURI}?fields=link,embed,pictures,uri`,
    (error, body) => {
      if (error) {
        reject(error);
      }
      if (!body.link) {
        // eslint-disable-next-line no-console
        console.warn(body);
        reject(new Error('body did not contian link.'));
      }
      resolve(body);
    },
  );
});

const defaultPercentWatcher = (percent) => {
  // eslint-disable-next-line no-console
  console.log(`uploading: ${percent}%`);
};

// Vimeo below.
export const upload = (file, data = {}, percentWatcher = defaultPercentWatcher) => (
  new Promise((resolve, reject) => {
    vimeoClient.upload(
      file,
      {
        name: _get(data, 'fileName', _get(file, 'name', 'Unknown Name')),
        description: _get(data, 'fileDescription', _get(file, 'name', 'Unknown Descripton')),
        content_rating: _get(data, 'rating', 'safe'),
      },
      async (uri) => {
        let tries = 0;
        let status = VIMEO_STATUS.IN_PROGRESS;
        while (tries < MAX_STATUS_TRIES && status === VIMEO_STATUS.IN_PROGRESS) {
          tries += 1;
          // We intentionally loop until we see that the video is finished processing.
          // eslint-disable-next-line no-await-in-loop
          await sleep(1000 * 15);
          // wait 15 seconds between checks.
          // with 40 tries, this gives us a full 10 minutes to tanscode.
          // eslint-disable-next-line no-await-in-loop
          status = await checkStatus(uri);
        }
        // keep doing this until it's done.
        if (status !== VIMEO_STATUS.COMPLETE) {
          throw new Error(`Vimeo transcoding never completed in ${tries} tries`);
        }
        const finalLink = await getViedoData(uri);
        resolve(finalLink);
      },
      (bytesUploaded, bytesTotal) => {
        const percent = Math.ceil((bytesUploaded / bytesTotal) * 100);
        percentWatcher(percent);
      },
      (error) => {
        reject(error);
      },
    );
  })
);

export const getVideoThumnail = (videoId) => (
  new Promise((resolve, reject) => {
    vimeoClient.request(
      `/videos/${videoId}/pictures`,
      (error, body) => {
        if (error) {
          reject(error);
        }
        const photos = _get(body, 'data[0].sizes', undefined);
        if (!Array.isArray(photos)) {
          // eslint-disable-next-line no-console
          console.warn(body);
          reject(new Error('body did not contian link.'));
        }
        resolve(_get(_last(photos), 'link'));
      },
    );
  })
);

export default upload;
