/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, Fragment } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";

import { Button, Text } from "../../core";
import { PostMedia } from "../postMedia";
import { Answer } from "../answer";
import { ConnectedPost } from "../post";
import { Reply } from "../reply";

import { questionPropType } from "../../../services/schemas/commonProps";
import styles from "./styles";
import messageIcon from "../post/img/message-square.svg";

export const Question = ({ question, onReply, lastError, noBackground }) => {
  const [replyMode, setReplyMode] = useState(false);
  let cta = (
    <div onClick={() => setReplyMode(!replyMode)}>
      <img style={{ cursor: "pointer" }} src={messageIcon} alt="reply" />
      <label css={styles.replyLabel}>Reply</label>
    </div>
  );
  if (replyMode) {
    // TODO, extract this, connect to redux, connect to service.
    cta = (
      <Fragment>
        <Reply
          onCancel={() => setReplyMode(false)}
          onSubmit={(content, files) => onReply(question.id, content, files)}
        />
        {lastError ? (
          <Text css={styles.errorMessage}>{lastError}</Text>
        ) : (
          <Fragment />
        )}
      </Fragment>
    );
  }
  const numReplies =
    _get(question, "numReplies", false) ||
    _get(question, "replies.length", false);
  const hideCTA = numReplies !== 0 || !onReply;
  const replies = _get(question, "replies", []);
  return (
    <ConnectedPost
      userName={question.author.name}
      postTime={question.createdAt ? question.createdAt : question.publishedAt}
      mb={45}
      mx="auto"
      cta={hideCTA ? <Fragment /> : cta}
      noBackground={noBackground}
      avatarUrl={_get(question, "author.avatar.medium")}
      questionId={_get(question, "id")}
      data-testid="question"
    >
      <Text f="none" mb={24} data-testid="question-content">
        {question.content ? question.content : question.title}
      </Text>
      <PostMedia
        attachments={
          question.attachments && question.attachments.length > 0
            ? question.attachments
            : [
                {
                  attachmentTypeId: 1,
                  url:
                    question.image &&
                    question.image.original.indexOf(
                      "img/default_post_image_original.jpg"
                    ) < 0
                      ? question.image.original
                      : "",
                },
                {
                  attachmentTypeId: 2,
                  thumbnailUrl: question.embeddedVideo
                    ? question.embeddedVideo.thumbnailUrl
                    : "",
                  mediaId: question.embeddedVideo
                    ? question.embeddedVideo.mediaId
                    : "",
                  embedCode: question.embeddedVideo
                    ? question.embeddedVideo.embedCode
                    : "",
                },
              ]
        }
      />
      {replies.map((reply) => (
        <Answer
          userName={_get(question, "publisher.name")}
          userAvatarUrl={_get(question, "publisher.avatar.medium")}
          postTime={_get(reply, "createdAt")}
          key={reply.id}
          noBackground={noBackground}
          mt={24}
        >
          {reply.content.split("\n").map((s, idx) => (
            <Text
              f="none"
              mb={12}
              data-testid="answer-content"
              // eslint-disable-next-line react/no-array-index-key
              key={`${s.trim().substring(0, 20).replace(" ", "_")}-${idx}`}
            >
              {s || "\u00A0"}
            </Text>
          ))}
          <PostMedia attachments={reply.attachments} />
        </Answer>
      ))}
    </ConnectedPost>
  );
};

Question.propTypes = {
  lastError: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  question: questionPropType.isRequired,
  onReply: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  noBackground: PropTypes.bool,
};

Question.defaultProps = {
  lastError: false,
  noBackground: false,
  // eslint-disable-next-line no-console
  onReply: false,
};

export default Question;
