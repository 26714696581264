import globalStyles from "../../../styles";

const styles = {
  root: {
    backgroundColor: "#000000",
    justifyContent: "space-between",
    alignItems: "center",
    [globalStyles.layout.maxScreenQueries.md]: {
      display: "block",
      paddingBottom: 20,
    },
  },
  children: {
    textAlign: "center",
    fontFamily: "Source Serif Pro",
    width: "100%",
    color: globalStyles.colors.neutral.neutral1,
  },
  linkStyles: {
    a: {
      fontFamily: "Source Serif Pro",
      color: globalStyles.colors.neutral.neutral1,
      ...globalStyles.typography.links,
    },
  },
  footerLinksContainer: {
    justifyContent: "flex-end",
    [globalStyles.layout.maxScreenQueries.md]: {
      justifyContent: "center",
      margin: "10px 0",
    },
  },
  footerSocialIconsContainer: {
    justifyContent: "start",
    [globalStyles.layout.maxScreenQueries.md]: {
      justifyContent: "center",
      margin: "10px 0",
    },
  },
};

export default styles;
