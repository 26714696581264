/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import { View } from '../../core';
import styles from './styles';

// icons
import Twitter from './img/twitter.svg';
import Email from './img/email.svg';
import Instagram from './img/instagram.svg';

const iconMap = {
  email: Email,
  twitter: Twitter,
  instagram: Instagram,
};

export const SocialMedia = ({ icon }) => (
  <View css={styles.root} f="none">
    <img
      css={styles.icon}
      src={iconMap[icon]}
      alt={icon}
    />
  </View>
);

SocialMedia.propTypes = {
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
};

export default SocialMedia;
