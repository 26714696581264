export const maxScreenQueries = {
  sm: "@media(max-width: 624px)",
  md: "@media(max-width: 1023px)",
};

export const minScreenQueries = {
  sm: "@media(min-width: 625px)",
  md: "@media(min-width: 1024px)",
};

export default {
  maxScreenQueries,
  minScreenQueries,
};
