/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { jsx } from "@emotion/react";
import _get from "lodash/get";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import dashboardStyles from "../dashboard/styles";

import { Text, View } from "../../components/core";

import ChangePasswordForm from "./updateUserData";
import NotificationSettings from "./notificationSettings";
import MembershipTable from "./membershipTable";
import PaymentMethod from "./cards";
import PaymentHistory from "./paymentHistory";

import styles from "./styles";
import { EditableUserProfile } from "../../components/shared";
import {
  setUserBio,
  setUserAvatar,
  loadUserBySlug,
} from "../../services/state/actionCreators";
import { usePublisherParam } from "../../components/shared/hooks";
import rightArrow from "./img/rightArrow.svg";
import leftArrow from "./img/arrowLeft.svg";

export const SettingsPage = ({ user, editBio, uploadNewAvatar, loadUser }) => {
  // const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const slug = usePublisherParam();
  const [nav, setNav] = useState({
    myAccount: false,
    notifications: false,
    payout: false,
    subscriptions: false,
    membership: false,
    transactions: false,
    payment: false,
  });
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("membership"));
    if (data !== null && data === true) {
      setNav({
        myAccount: false,
        notifications: false,
        payout: false,
        subscriptions: false,
        membership: true,
        transactions: false,
        payment: false,
      });
      sessionStorage.removeItem("membership");
    }
  });

  useEffect(() => {
    if (!user?.id) {
      dispatch(loadUserBySlug(slug));
    }
  }, []);

  return (
    // <View f="none" pb={36} css={styles.page}>
    //   <Text f="1 1 auto" type="h3" centered style={{ marginBottom: "30px" }}>
    //     Settings
    //   </Text>
    //   {/* <EditableUserProfile
    //   name={user.name}
    //   avatarUrl={_get(user.avatar, 'medium')}
    //   bio={user.bio ? user.bio : 'No Bio, Please update'}
    //   mb={42}
    //   onEditBio={(newBio) => { editBio(newBio, user); }}
    //   onEditAvatar={(data) => uploadNewAvatar(data)}
    // /> */}
    //   {user && (
    //     <React.Fragment>
    //       <ChangePasswordForm />
    //       {/* TODO, waiting on Victor */}
    //       <NotificationSettings data={user} />
    //       {user.userRoleId !== 3 && <MembershipTable />}
    //       {user.userRoleId !== 3 && <PaymentMethod />}
    //       {user.userRoleId !== 3 && <PaymentHistory />}
    //     </React.Fragment>
    //   )}
    // </View>
    <div>
      {nav.myAccount === false &&
        nav.notifications === false &&
        nav.payout === false &&
        nav.subscriptions === false &&
        nav.membership === false &&
        nav.transactions === false &&
        nav.payment === false && (
          <div css={dashboardStyles.navDiv}>
            <div
              css={dashboardStyles.inActiveLabel}
              onClick={() => {
                setNav({
                  myAccount: true,
                  notifications: false,
                  payout: false,
                  subscriptions: false,
                });
              }}
            >
              My account
              <img css={dashboardStyles.arrowIcon} src={rightArrow} />
            </div>
            <div
              onClick={() => {
                setNav({
                  myAccount: false,
                  notifications: true,
                  payout: false,
                  subscriptions: false,
                });
              }}
              css={dashboardStyles.inActiveLabel}
            >
              Notifications
              <img css={dashboardStyles.arrowIcon} src={rightArrow} />
            </div>
            {user && user.userRoleId === 3 && (
              <div
                css={dashboardStyles.inActiveLabel}
                onClick={() => {
                  setNav({
                    myAccount: false,
                    notifications: false,
                    payout: true,
                    subscriptions: false,
                  });
                }}
              >
                Payout
                <img css={dashboardStyles.arrowIcon} src={rightArrow} />
              </div>
            )}
            {user && user.userRoleId === 3 && (
              <div
                css={dashboardStyles.inActiveLabel}
                onClick={() => {
                  setNav({
                    myAccount: false,
                    notifications: false,
                    payout: false,
                    subscriptions: true,
                  });
                }}
              >
                Subscriptions
                <img css={dashboardStyles.arrowIcon} src={rightArrow} />
              </div>
            )}
            {user && user.userRoleId !== 3 && (
              <div>
                <div
                  css={dashboardStyles.inActiveLabel}
                  onClick={() => {
                    setNav({
                      myAccount: false,
                      notifications: false,
                      payout: false,
                      subscriptions: false,
                      membership: true,
                      transactions: false,
                      payment: false,
                    });
                  }}
                >
                  Membership
                  <img css={dashboardStyles.arrowIcon} src={rightArrow} />
                </div>
                <div
                  css={dashboardStyles.inActiveLabel}
                  onClick={() => {
                    setNav({
                      myAccount: false,
                      notifications: false,
                      payout: false,
                      subscriptions: false,
                      membership: false,
                      transactions: true,
                      payment: false,
                    });
                  }}
                >
                  Transactions
                  <img css={dashboardStyles.arrowIcon} src={rightArrow} />
                </div>
                <div
                  css={dashboardStyles.inActiveLabel}
                  onClick={() => {
                    setNav({
                      myAccount: false,
                      notifications: false,
                      payout: false,
                      subscriptions: false,
                      membership: false,
                      transactions: false,
                      payment: true,
                    });
                  }}
                >
                  Payment
                  <img css={dashboardStyles.arrowIcon} src={rightArrow} />
                </div>
              </div>
            )}
          </div>
        )}
      {(nav.myAccount === true ||
        nav.notifications === true ||
        nav.payout === true ||
        nav.subscriptions === true ||
        nav.membership === true ||
        nav.transactions === true ||
        nav.payment === true) && (
        <div
          onClick={() => {
            setNav({
              myAccount: false,
              notifications: false,
              payout: false,
              subscriptions: false,
              membership: false,
              transactions: false,
              payment: false,
            });
          }}
          css={dashboardStyles.titleNavLabel}
        >
          {nav.myAccount === true
            ? "My account"
            : nav.notifications === true
            ? "Notifications"
            : nav.payout === true
            ? "Payout"
            : nav.subscriptions === true
            ? "Subscriptions"
            : nav.membership === true
            ? "Membership"
            : nav.transactions === true
            ? "Transactions"
            : nav.payment === true
            ? "Payment"
            : ""}
          <img css={dashboardStyles.arrowLeftIcon} src={leftArrow} />
        </div>
      )}
      {user && (
        <React.Fragment>
          {(nav.myAccount === true ||
            nav.payout === true ||
            nav.subscriptions === true) && (
            <ChangePasswordForm
              accountDetails={nav.payout === true ? false : true}
              planDetails={nav.subscriptions === true ? true : false}
            />
          )}
          {nav.notifications === true && <NotificationSettings data={user} />}
          {user.userRoleId !== 3 && nav.membership === true && (
            <MembershipTable />
          )}
          {user.userRoleId !== 3 && nav.payment === true && <PaymentMethod />}
          {user.userRoleId !== 3 && nav.transactions === true && (
            <PaymentHistory />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

SettingsPage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    // twitterTag,
    numTwitterFollowers: PropTypes.number,
    // featuredVideo,
    bio: PropTypes.string,
    id: PropTypes.number,
    token: PropTypes.string,
    slug: PropTypes.string,
    activeSubscribers: PropTypes.shape({
      numViewers: PropTypes.number,
      numAskers: PropTypes.number,
    }),
    products: PropTypes.shape({
      ask: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
        cost: PropTypes.number.isRequired,
      }).isRequired,
      view: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        maxActiveSubscriptions: PropTypes.number,
        cost: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  editBio: PropTypes.func.isRequired,
  uploadNewAvatar: PropTypes.func.isRequired,
};

// SettingsPage.defaultProps = {
//   user: undefined,
// };

const mapDispatchToProps = (dispatch) => ({
  editBio: (newBio, userData) => {
    dispatch(setUserBio(newBio, userData));
  },
  uploadNewAvatar: (data) => {
    dispatch(setUserAvatar(data));
  },
});

export default connect(mapDispatchToProps)(SettingsPage);
// export default SettingsPage;
