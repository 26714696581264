/** @jsx jsx */
import React, { useState } from "react";
import { jsx } from "@emotion/react";

import { View, Button } from "../../components/core";
import { Feature } from "../../components/shared/feature";
import img1 from "../../components/shared/feature/img/About.svg";
import img2 from "../../components/shared/feature/img/Vector.png";
import dollarSign from "../../components/shared/feature/img/dollar-sign.svg";
import calendar from "../../components/shared/feature/img/calendar.svg";
import messageCircle from "../../components/shared/feature/img/message-circle.svg";
import top1 from "../../components/shared/feature/img/Top1.svg";
import top2 from "../../components/shared/feature/img/Top2.svg";
import topIntersect from "../../components/shared/feature/img/TopIntersect1.svg";
import topIntersect2 from "../../components/shared/feature/img/TopIntersect2.svg";
import topEllipse1 from "../../components/shared/feature/img/TopEllipse1.svg";
import topEllipse2 from "../../components/shared/feature/img/TopEllipse2.svg";
import Ellipse1_Expert from "../../components/shared/feature/img/Ellipse1_Expert.svg";
import Ellipse2_Expert from "../../components/shared/feature/img/Ellipse2_Expert.svg";
import tick from "../../components/shared/feature/img/tick.svg";
import styles from "./styles";
import { useHistory } from "react-router-dom";
import TempPopup from "./temporaryModal";
import deananywhere from "./img/deananywhere.png";
import gloriaglows from "./img/gloriaglows.png";
import ollieom from "./img/ollieom.png";

const names = [
  "Fitness",
  "Art",
  "Beauty",
  "Wellness",
  "Politics",
  "Parenting",
  "Fashion",
  "Religion",
  "Income",
  "Gaming",
  "Dating",
  "Finance",
  "Pets",
  "Lifestyle",
  "Food",
  "Travel",
];

export const FirstFeatures = () => (
  <View css={styles.featureContainer}>
    <Feature icon="person">
      <label css={styles.featureContainerLabel}>
        Your followers <b>purchase</b> a membership to your Q&A platform
      </label>
    </Feature>
    <Feature icon="social">
      <label css={styles.featureContainerLabel}>
        Members <b>ask</b> questions, posting directly to your Q&A feed
      </label>
    </Feature>
    <Feature icon="book">
      <label css={styles.featureContainerLabel}>
        You <b>reply</b> to member questions, all saved to a static feed for
        members to view
      </label>
    </Feature>
  </View>
);

export const FeedExamples = () => {
  const history = useHistory();
  return (
    <div>
      <div css={styles.examplesDiv}>
        <label css={styles.examplelabel1}>This could be you</label>
        <label css={styles.examplelabel2}>
          Curious what your Q&A feed will look like?
        </label>
        <label css={styles.examplelabel2}>
          Check out our sample feeds for some platform inspiration.
        </label>
      </div>
      <div css={styles.exampleContainer}>
        <div
          onClick={() => {
            window.scrollTo(0, 0);
            history.push("/deananywhere");
          }}
          css={styles.deanDiv}
        >
          <img src={deananywhere} css={styles.userImg} alt="deananywhere" />
          <div style={{ paddingLeft: 20 }}>
            <span>
              <label css={styles.deanTitle}>deananywhere</label>
              <label css={styles.deanDemoTitle}>Demo</label>
            </span>
            <label css={styles.deanCategories}>
              exploring | discovering | growing
              <br />
              presets and editing courses → deananywhere.life
            </label>
          </div>
        </div>
        <div
          onClick={() => {
            window.scrollTo(0, 0);
            history.push("/gloriaglows");
          }}
          css={styles.deanDiv}
        >
          <img src={ollieom} css={styles.userImg} alt="deananywhere" />
          <div style={{ paddingLeft: 20 }}>
            <span>
              <label css={styles.deanTitle}>gloriaglows</label>
              <label css={styles.deanDemoTitle}>Demo</label>
            </span>
            <br />
            <label css={styles.deanCategories}>
              Beauty Vlogger + Healthy Skinthusiast
              <br />
              Founder - Inner Glow Skin
            </label>
          </div>
        </div>
        <div
          onClick={() => {
            window.scrollTo(0, 0);
            history.push("/ollieom");
          }}
          css={styles.deanDiv}
        >
          <img src={gloriaglows} css={styles.userImg} alt="deananywhere" />
          <div style={{ paddingLeft: 20 }}>
            <span>
              <label css={styles.deanTitle}>ollieom</label>
              <label css={styles.deanDemoTitle}>Demo</label>
            </span>
            <br />
            <label css={styles.deanCategories}>
              scaling the collective vibration
              <br />
              author of Raise Your Vibration
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SecondFeatures = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        background: "#356859",
        borderRadius: "8px",
        maxWidth: window.screen.width < 624 ? "95%" : "1265px",
        minHeight: "256px",
        margin: window.screen.width < 624 ? "8px" : {},
        marginBottom: window.screen.width < 624 ? "65px" : {},
        padding: window.screen.width < 624 ? "15px" : {},
      }}
      css={styles.rootAbout}
    >
      <div
        css={styles.about}
        style={{
          paddingTop: "0px",
          paddingLeft: window.screen.width < 624 ? "5px" : "25px",
        }}
      >
        <label css={styles.secondFeaturesLabel1}>
          Your Platform, Your Rules
        </label>
        <label css={styles.secondFeaturesLabel2}>
          Get started for free. We don’t take a penny until you’re getting paid.
        </label>
        <Button
          onClick={() => {
            history.push("/expert-sign-up");
          }}
          // onClick={() => {
          //   setOpen(true);
          // }}
          style={{
            color: "#356859",
            background: "#fff",
            marginTop: "20px",
            width: "327px",
          }}
        >
          Create free account
        </Button>
      </div>
      <div
        style={{
          paddingLeft: "0px",
          paddingBottom: window.screen.width < 624 ? "40px" : {},
          textAlign: window.screen.width < 624 ? "left" : "center",
          width: window.screen.width < 624 ? "100%" : "34%",
        }}
        css={styles.about}
      >
        <div
          style={{ paddingTop: "15px", alignItems: "center" }}
          css={styles.rootAbout1}
        >
          <img
            style={{ width: "24px", height: "60px" }}
            css={styles.aboutImg}
            src={dollarSign}
            alt={"About"}
          />
          <label
            style={{
              fontWeight: "bold",
              color: "#fff",
              paddingTop: "8px",
              paddingLeft: "8px",
            }}
            css={styles.aboutdescription}
          >
            Set your own prices
          </label>
        </div>
        <div
          style={{ margin: 0, alignItems: "center", justifyContent: "unset" }}
          css={styles.rootAbout1}
        >
          <img
            style={{ width: "24px", height: "60px" }}
            css={styles.aboutImg}
            src={messageCircle}
            alt={"About"}
          />
          <label
            style={{
              fontWeight: "bold",
              color: "#fff",
              paddingTop: "8px",
              paddingLeft: "8px",
            }}
            css={styles.aboutdescription}
          >
            Choose your own per month question limit
          </label>
        </div>
        <div
          style={{ margin: 0, alignItems: "center", justifyContent: "unset" }}
          css={styles.rootAbout1}
        >
          <img
            style={{ width: "24px", height: "60px" }}
            css={styles.aboutImg}
            src={calendar}
            alt={"About"}
          />
          <label
            style={{
              fontWeight: "bold",
              color: "#fff",
              paddingTop: "8px",
              paddingLeft: "8px",
            }}
            css={styles.aboutdescription}
          >
            Engage and earn on your own terms
          </label>
        </div>
      </div>
      <TempPopup open={open} onclose={() => setOpen(false)} />
    </div>
  );
};
export const ExpertType = () => {
  return (
    <div css={styles.rootAbout}>
      <div css={styles.about} style={{ justifyContent: "center" }}>
        <img css={styles.Ellipse1_Expert} src={Ellipse1_Expert} alt="ellipse" />
        <img css={styles.Ellipse2_Expert} src={Ellipse2_Expert} alt="ellipse" />
        <label
          style={{
            color: "#000000",
            fontFamily: "Source Serif Pro",
            lineHeight: "56px",
            fontSize: window.screen.width < 624 ? 36 : 48,
          }}
          css={styles.titleAbout}
        >
          Featuring experts across diverse industries
        </label>
      </div>
      <div
        style={{ paddingLeft: window.screen.width < 624 ? 50 : {} }}
        css={styles.aboutStyles}
      >
        <div css={styles.listStyle}>
          {names.map((data) => (
            <div>
              <img src={tick} alt={"About"} />
              <label
                style={{ fontWeight: "400", paddingLeft: "10px" }}
                css={styles.aboutdescription}
              >
                {data}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export const About = () => (
  // <View css={styles.featureContainer}>
  <div
    style={{
      padding: window.screen.width < 624 ? {} : "0px 75px",
      margin: 0,
      maxWidth: "100%",
      background: "#F8F8F8",
      paddingTop: "60px",
    }}
    css={styles.rootAbout}
  >
    <div css={styles.aboutStyles}>
      <label
        style={{ fontSize: window.screen.width < 624 ? 35 : "54px" }}
        css={styles.titleAbout}
      >
        <span css={styles.whylabel}>why</span>GuideReply
      </label>
      <label css={styles.aboutdescription}>
        Your comments section is flooded with questions. Prioritize your most
        engaged followers and build a reliable, sustainable revenue stream.
      </label>
    </div>
    <div
      style={{ paddingBottom: window.screen.width < 624 ? "50px" : {} }}
      css={styles.aboutStyles}
    >
      <div
        style={{
          paddingTop: "60px",
          paddingBottom: window.screen.width < 624 ? "25px" : {},
        }}
        css={styles.rootAbout1}
      >
        <img css={styles.aboutImg} src={img1} alt={"About"} />
        <label
          style={{
            fontWeight: "bold",
            color: "#535353",
            letterSpacing: "0.15px",
          }}
          css={styles.aboutdescription}
        >
          Let the value you provide your audience provide you with a living
        </label>
      </div>
      <div
        css={window.screen.width < 624 ? styles.rootAbout1 : styles.rootAbout}
      >
        <img css={styles.aboutImg} src={img2} alt={"About"} />
        <label
          style={{
            fontWeight: "bold",
            color: "#535353",
            letterSpacing: "0.15px",
          }}
          css={styles.aboutdescription}
        >
          Answer only to your fans, rather than sponsorship-based revenue models
          requiring you to consider another brand’s values
        </label>
      </div>
    </div>
  </div>
  // </View>
);
export const LaunchText = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        background: "#356859",
        borderRadius: "8px 8px 0px 0px",
        minHeight: "200px",
        maxWidth: "100%",
        alignItems: "center",
        justifyContent: "center",
        columnGap: "100px",
        margin: 0,
      }}
      css={styles.rootAbout}
    >
      <div
        css={styles.about}
        style={{ justifyContent: "center", width: "auto" }}
      >
        <label
          style={{
            color: "#fff",
            fontSize: 34,
            fontFamily: "Source Serif Pro",
            marginTop: 20,
          }}
          css={styles.titleAbout}
        >
          Ready to start earning money on your own terms?
        </label>
      </div>
      <Button
        css={styles.launchButton}
        onClick={() => {
          history.push("/expert-sign-up");
        }}
        // onClick={() => {
        //   setOpen(true);
        // }}
      >
        Create free account
      </Button>
      <TempPopup open={open} onclose={() => setOpen(false)} />
    </div>
  );
};
export const TopContent = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <div style={{ margin: "50px auto 10px" }} css={styles.rootAbout}>
      <div css={styles.about}>
        <label css={styles.topTitle}>
          Engage with your top followers. Earn a living on your own terms.{" "}
        </label>
        <span css={styles.topSubTitle}>
          Build a reliable revenue stream with your own membership-based Q&A
          platform
        </span>
        <Button
          css={styles.homeButton}
          onClick={() => {
            history.push("/expert-sign-up");
          }}
          // onClick={() => {
          //   setOpen(true);
          // }}
          style={{
            color: "#fff",
            background: "#356859",
            marginTop: "20px",
          }}
        >
          Get started for free
        </Button>
      </div>
      <div
        style={{
          margin: window.screen.width < 624 ? "15px" : "55px 0px 0px 0px",
        }}
        css={styles.about}
      >
        <div css={styles.rootAbout2}>
          <img src={top1} alt={"About"} css={styles.card1} />
          <img css={styles.card2} src={top2} alt={"About"} />
          <img css={styles.topEllipse1} src={topEllipse1} alt={"About"} />
          <img
            css={styles.topEllipse1}
            style={{ right: window.screen.width < 624 ? "540px" : "795px" }}
            src={topEllipse2}
            alt={"About"}
          />
          <img css={styles.topIntersect2} src={topIntersect2} alt={"About"} />
        </div>
        <img css={styles.topIntersect1} src={topIntersect} alt={"About"} />
      </div>
      <TempPopup open={open} onclose={() => setOpen(false)} />
    </div>
  );
};

export default {};
