import globalStyles from "../../../styles";

export default {
  ccInput: {
    ...globalStyles.typography.body,
  },
  title: {
    fontFamily: "Source Serif Pro",
    fontWeight: 600,
    color: "#263238",
    fontSize: 34,
    marginTop: 45,
  },
  paymentInput: {
    fontFamily: "IBM Plex Sans",
    borderRadius: 8,
    border: "1px solid #556670",
    marginBottom: 10,
    "&:focus": {
      outline: "none",
      border: "1.5px solid #356859",
    },
  },
  confirmStyles: {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 700,
    borderRadius: "8px",
    width: 248,
    height: 48,
    marginRight: 10,
  },
  tableContainer: {
    "@media(max-width: 500px)": {
      width: "100%",
      margin: 0,
      overflow: "auto",
    },
  },
  cancelStyles: {
    boxShadow: "none",
    background: "#fff",
    color: "#356859",
    fontFamily: "Inter",
    fontSize: 14,
    marginRight: 25,
    fontWeight: 700,
    "&:hover": {
      boxShadow: "none",
      background: "#fff",
    },
    "@media(max-width: 500px)": {
      padding: "5px 5px",
    },
  },
  title1: {
    fontFamily: "IBM Plex Sans",
    fontSize: 16,
    lineHeight: "24px",
    color: "#000000",
    fontWeight: 600,
    width: 320,
    letterSpacing: "0.1px",
    marginTop: 15,
    marginBottom: 7,
  },
  ccInputWrapper: {
    justifyContent: "center",
  },
  modalInputContainer: {
    alignItems: "center",
  },
  nameInput: {
    width: "100%",
  },
  input: {
    marginBottom: 12,
  },
  addnewCard: {
    color: "#356859",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "0.25px",
    cursor: "pointer",
    marginLeft: 25,
    marginTop: 40,
  },
  titleLabel: {
    fontSize: 26,
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    color: "#263238",
    lineHeight: "60px",
    margin: "15px 0px 0px 23px",
  },
  table: {
    marginRight: 20,
    marginLeft: 20,
    padding: 0,
    // border: "1px solid #737373",
    borderSpacing: 0,
    overflow: "hidden",
    borderRadius: 6,
    "@media(max-width: 500px)": {
      marginRight: 5,
      marginLeft: 5,
    },
    " tr:last-child td": {
      borderBottom: "none",
    },
    " td": {
      padding: 20,
      textAlign: "left",
      fontSize: 14,
      fontFamily: "IBM Plex Sans",
      fontWeight: 400,
      color: "#263238",
      letterSpacing: "0.25px",
    },
    " th": {
      borderBottom: "1px solid #888888",
      padding: 20,
      fontSize: 14,
      fontFamily: "IBM Plex Sans",
      fontWeight: 700,
      color: "#263238",
      textAlign: "left",
      letterSpacing: "0.25px",
      width: "100vw",
    },
  },
  evenRow: {
    " td": {
      backgroundColor: "#eef9f6",
    },
  },
};
