/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import verified from "../userProfile/img/Verified.svg";
import Avatar from "../avatar";
import { View, Text, DateComponent } from "../../core";
import moment from "moment";

import styles from "./styles";

/**
 * User
 * this is an inline component. Small and used before content to indicate who shared the content.
 */
const poststyling = {
  fontSize: 16,
  color: "#303030",
  fontWeight: 600,
  letterSpacing: "0.1px",
  lineHeight: "24px",
};
export const User = ({
  name,
  avatarUrl,
  postTime,
  answer,
  postStyle,
  ...rest
}) => {
  let timeComponent;

  if (postTime) {
    timeComponent = (
      <Text
        style={{
          color: "#737373",
          fontSize: 14,
          fontWeight: 300,
          paddingLeft: 0,
        }}
        type="small"
        css={styles.timeAgo}
      >
        {moment(postTime).fromNow()}
        {/* <DateComponent mode="">{postTime}</DateComponent> */}
      </Text>
    );
  }
  return (
    <View css={styles.root} {...rest}>
      <Avatar url={avatarUrl || undefined} alt={name} css={styles.avatar} />
      <Text
        type="small"
        style={
          postStyle
            ? poststyling
            : { color: "#000000", fontSize: 14, paddingLeft: 0 }
        }
        css={styles.userName}
      >
        {name}
      </Text>
      {answer && <img css={styles.verificationIcon} src={verified} />}
      {timeComponent}
    </View>
  );
};

User.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  avatarUrl: PropTypes.any,
  postTime: PropTypes.string,
};

User.defaultProps = {
  name: "user",
  avatarUrl: undefined,
  postTime: undefined,
};

export default User;
