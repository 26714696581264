import globalStyles from "../../../styles";

export default {
  root: {
    maxWidth: 600,
    "@media(max-width: 500px)": {
      maxWidth: "350px",
      padding: "20px 20px",
    },
  },
  withBackground: {
    background: "#FFFFFF",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
  },
  heading: {
    justifyContent: "space-between",
  },
  content: {
    p: {
      color: "#535353",
      fontFamily: "IBM Plex Sans",
      fontSize: 16,
      lineHeight: "21px",
      letterSpacing: "0.25px",
      paddingLeft: "7px",
    },
  },
  bookmarkIcon: {
    width: 16,
  },
  callToAction: {
    justifyContent: "center",
    width: "100%",
  },
  button: {
    padding: 0,
    margin: 0,
    borderColor: "transparent",
    borderStyle: "none",
    borderWidth: 0,
    cursor: "pointer",
    background: "transparent",
  },
};
