import _get from "lodash/get";

import * as USER_ACTIONS from "../actions";
import * as QUESTION_ACTIONS from "../../question/actions";
import UserClient from "../../../api/user";
import QuestionClient from "../../../api/question";
import { loadUserById } from "../../users/actionCreators";
import { loadFavorites } from "../../question/actionCreators";
import { loadSubscriptions } from "./subscription";

// TODO this might need some work on the order-of-operations side
export const logout = () => (dispatch) => {
  dispatch({
    type: QUESTION_ACTIONS.CLEAR_QUESTIONS,
  });
  UserClient.logout();
  dispatch({
    type: USER_ACTIONS.LOGOUT,
  });
  window.location = window.location.href;
};

export const loginSuccessful = (userData, extra = {}) => (dispatch) => {
  [UserClient, QuestionClient].forEach((client) => {
    client.setToken(userData);
  });
  UserClient.storeInLocalStorage();
  dispatch({
    type: USER_ACTIONS.LOGIN_SUCCESS,
    payload: {
      ...userData,
      ...{ pendingRequest: false },
      ...extra,
    },
  });
  dispatch(loadUserById(userData.userId));
  dispatch(loadSubscriptions());
  dispatch(loadFavorites());
};

export const loginFailed = (failureMessage) => ({
  type: USER_ACTIONS.LOGIN_FAILED,
  payload: {
    error: failureMessage,
    pendingRequest: false,
  },
});

export const login = ({ email, password }) => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.ATTEMPT_LOGIN,
    payload: {
      email,
      pendingRequest: true,
    },
  });
  // Now attempt to login.
  let response;
  try {
    response = await UserClient.login(email, password);
    if (response.status !== 200) {
      throw new Error("User Credentials Invalid");
    }
  } catch (error) {
    const prettyErrorMessage = _get(
      error,
      "response.data.validationErrors",
      _get(error, "response.data.error", error.message)
    );
    dispatch(loginFailed(prettyErrorMessage));
    return;
  }

  // Extract the data we need to fetch the user's data next.
  const { token, userId } = response.data;
  if (!token || !userId) {
    throw new Error("Token or ID not present in response.");
  }
  // Now that we have all the user's data we can dispatch the success event.
  dispatch({
    type: QUESTION_ACTIONS.CLEAR_QUESTIONS,
  });
  dispatch(loginSuccessful(response.data));
};
