import globalStyles from "../../styles";

const sectionContentStyles = {
  maxWidth: 740,
  margin: "0 auto",
};

export default {
  // TODO, about to move these styles to each page instead
  // of the container to fix the notification background.
  page: {
    width: "100%",
  },
  userSettings: {
    root: {
      ...sectionContentStyles,
      marginBottom: 48,
    },
    cancelButton: {
      color: "#356859",
      fontWeight: 700,
      fontFamily: "IBM Plex Sans",
      fontSize: 14,
      border: "none",
      boxShadow: "none",
      background: "#ffffff",
      width: "143.5px",
      "&:hover": {
        background: "none",
        boxShadow: "none",
      },
    },
    sendButton: {
      background: "#356859",
      width: "143.5px",
      borderRadius: "8px",
      height: "48px",
      boxShadow:
        "0px 2px 2px rgba(0, 0, 0, 0.07), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)",
    },
    input: {
      margin: "0 12px",
      minWidth: 520,
      minHeight: 56,
      border: "1px solid #737373",
      borderRadius: 8,
      "@media(max-width: 500px)": {
        minWidth: "100%",
      },
    },
    inputButton: {
      minWidth: 143.5,
      minHeight: 48,
      borderRadius: 8,
      "@media(max-width: 500px)": {
        width: "100%",
        margin: "0px 10px",
      },
    },
    avatarLabel: {
      cursor: "pointer",
      position: "absolute",
      marginTop: 170,
      marginLeft: 262,
      left: 0,
      color: "#356859",
      fontWeight: 700,
      fontSize: 14,
      fontFamily: "Inter",
      "@media(max-width: 500px)": {
        marginLeft: 38,
        marginTop: 20,
        position: "inherit",
      },
    },
    paymentLabel: {
      fontSize: 26,
      fontFamily: "IBM Plex Sans",
      fontWeight: 400,
      color: "#263238",
      lineHeight: "60px",
      margin: "15px 0px 20px 32px",
      "@media(max-width: 500px)": {
        margin: "15px 0px 20px 10px",
      },
    },
    directText: {
      textAlign: "left",
      marginTop: 5,
      marginLeft: 17,
      color: "#556670",
      fontWeight: 400,
      fontFamily: "IBM Plex Sans",
    },
    planSubheader: {
      marginLeft: 40,
      fontFamily: "IBM Plex Sans",
      fontSize: 14,
      letterSpacing: "0.25px",
      color: "#556670",
      marginBottom: 35,
    },
    inputButtonDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 45,
      marginBottom: 45,
      marginRight: 165,
      "@media(max-width: 500px)": {
        marginRight: 0,
      },
    },
    inputContainer: {
      padding: "5px 0 30px",
      justifyContent: "center",
      marginLeft: 200,
      "@media(max-width: 500px)": {
        margin: "0px 10px",
      },
    },
    videoIconDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 193,
      "@media(max-width: 500px)": {
        alignItems: "inherit",
        justifyContent: "inherit",
      },
    },
    uploadIcon: {
      width: "91px",
      height: "122px",
      padding: "9px 0",
    },
    uploadText: {
      color: "#356859",
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: 14,
      cursor: "pointer",
      marginTop: 5,
      marginLeft: -40,
    },
    editVideoText: {
      color: "#356859",
      fontWeight: 700,
      fontFamily: "Inter",
      fontSize: 14,
      margin: "15px 0px",
      cursor: "pointer",
    },
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: 14,
    lineHeight: "21px",
    color: "#000000",
    fontWeight: 700,
    letterSpacing: "0.25px",
    textAlign: "center",
    "@media(max-width: 500px)": {
      textAlign: "left",
      margin: "0px 10px",
    },
  },
  notificationSettings: {
    root: {
      borderRadius: 0,
    },
    content: {
      maxWidth: 740,
      margin: "0 auto",
      "@media(max-width: 500px)": {
        maxWidth: "100%",
        margin: 0,
      },
    },
    header: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 400,
      fontSize: 26,
      color: "#263238",
      marginRight: 70,
      "@media(max-width: 500px)": {
        marginRight: 0,
        textAlign: "left",
        marginLeft: 20,
      },
    },
    notificationSetting: {},
    notificationIcon: {
      marginRight: 8,
      width: 29,
      height: 20,
      cursor: "pointer",
      "&:hover": {
        opacity: 0.5,
      },
    },
    notificationIconDisabled: {
      opacity: 0.5,
      "&:hover": {
        opacity: 1,
      },
    },
  },
  membershipTable: {
    actionLabel: {
      cursor: "pointer",
      color: "#356859",
      fontWeight: 700,
      fontFamily: "Inter",
      fontSize: 14,
      letterSpacing: "0.25px",
      margin: "0 10px",
    },
    activeText: {
      height: 29,
      background: "#E8F5E9",
      color: "#2E7D31",
      fontFamily: "IBM Plex Sans",
      fontWeight: 400,
      fontSize: 14,
      border: "1px solid #FFFFFF",
      borderRadius: 4,
      textAlign: "center",
      paddingTop: 3,
    },
    table: {
      marginRight: 20,
      marginLeft: 20,
      padding: 0,
      borderSpacing: 0,
      overflow: "hidden",
      borderRadius: 6,
      "@media(max-width: 500px)": {
        marginRight: 5,
        marginLeft: 5,
      },
      " tr:last-child td": {
        borderBottom: "none",
      },
      " td": {
        padding: 20,
        textAlign: "left",
        fontSize: 14,
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        color: "#263238",
        letterSpacing: "0.25px",
      },
      " th": {
        borderBottom: "1px solid #888888",
        padding: 20,
        fontSize: 14,
        fontFamily: "IBM Plex Sans",
        fontWeight: 700,
        color: "#263238",
        textAlign: "left",
        letterSpacing: "0.25px",
        width: "100vw",
      },
    },
    titleMembership: {
      fontSize: 26,
      fontFamily: "IBM Plex Sans",
      fontWeight: 400,
      color: "#263238",
      lineHeight: "60px",
      margin: "15px 0px 20px 32px",
    },
    tableContainer: {
      width: "75vw",
      margin: "0 auto 24px",
      "@media(max-width: 500px)": {
        width: "100%",
        margin: 0,
        overflow: "auto",
      },
    },
    activePlan: {
      alignItems: "center",
      justifyContent: "flex-start",
    },
    cancelButton: {
      marginLeft: 10,
    },
  },
  currentPlan: {
    pricingCard: {
      margin: "0 auto",
    },
    container: {
      maxWidth: 900,
      margin: "0 auto 64px",
    },
    buyLabel: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 700,
      color: "#263238",
      fontSize: 20,
    },
  },
  paymentHistory: {
    paymentContainer: {
      "@media(max-width: 500px)": {
        width: "100%",
        margin: 0,
        overflow: "auto",
      },
    },
    table: {
      marginRight: 20,
      marginLeft: 20,
      padding: 0,
      // border: "1px solid #737373",
      borderSpacing: 0,
      overflow: "hidden",
      borderRadius: 6,
      "@media(max-width: 500px)": {
        marginRight: 5,
        marginLeft: 5,
      },
      " tr:last-child td": {
        borderBottom: "none",
      },
      " td": {
        padding: 20,
        textAlign: "left",
        fontSize: 14,
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        color: "#263238",
        letterSpacing: "0.25px",
      },
      " th": {
        borderBottom: "1px solid #888888",
        padding: 20,
        fontSize: 14,
        fontFamily: "IBM Plex Sans",
        fontWeight: 700,
        color: "#263238",
        textAlign: "left",
        letterSpacing: "0.25px",
        width: "100vw",
      },
    },
    evenRow: {
      " td": {
        backgroundColor: "#eef9f6",
      },
    },
    root: {
      margin: "0 auto 64px",
    },
    titlelabel: {
      fontSize: 26,
      fontFamily: "IBM Plex Sans",
      fontWeight: 400,
      color: "#263238",
      lineHeight: "60px",
      margin: "15px 0px 0px 23px",
    },
  },
};
