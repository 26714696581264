/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import styles from './styles';

export const Button = ({
  children,
  css,
  ghost,
  flat,
  secondary,
  ...rest
}) => (
  <button
    type="button"
    css={{
      ...styles.button,
      ...(secondary ? styles.secondary : {}),
      ...(ghost ? styles.ghost : {}),
      ...(flat ? styles.flat : {}),
      ...(flat && ghost ? styles.ghostFlat : {}),
      ...css,
    }}
    data-testid="button"
    {...rest}
  >
    { children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  css: PropTypes.object,
  secondary: PropTypes.bool,
  ghost: PropTypes.bool,
  flat: PropTypes.bool,
};

Button.defaultProps = {
  children: [],
  css: {},
  secondary: false,
  ghost: false,
  flat: false,
};

export default Button;
